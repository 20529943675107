import React from "react";
import { Layout } from "antd";
//Componentes
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Sider from "../components/Sider/Sider";
import Proceso from "../components/Proceso_Menu/Proceso_Menu";
//Logos
import { ReactComponent as Logo_Header } from "../assets/img/ID_docs.svg";
import { Route, Switch, Redirect } from "react-router-dom";
//Se importa el context para obtener la información del usuario
import useAuth from "../hooks/useAuth";
//pages
import AdminLogin from "../pages/Admin/Login/Login";
import "./LayoutAdmin.scss";
//import SecureStorage from "secure-web-storage";

export default function LayoutAdmin(props) {
  const { Content } = Layout; //Se obtiene el componente hijo de Layout
  const { user, isLoading, estado, distrito, idDistrito, versionUsuario, descripcion } = useAuth();
  const { routes, history} = props;

  const [activarSider, setActivarSider] = React.useState(false);
  //Verifica si el usuario existe si no lo redirecciona al login
  if (!user && !isLoading) {
    return (
      <>
        <Route path="/" component={AdminLogin} />
        <Redirect to="/" />
      </>
    );
  }

  //Si existe renderiza el Layout principal
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/**Se importa el header y se le envía el logo correspondiente */}
      <Header Logo={Logo_Header} history={history}/>
      <Layout>
        <Sider />
        <Layout className="layout">
          <Proceso history={history} />
          <Content className="content">
            {/**Contenido, se iteran las rutas para poder navegar entre ellas */}
            <LoadRoutes routes={routes} />
          </Content>
        </Layout>
      </Layout>
      {/**Footer con la versión del sistema */}
      <Footer version="Versión 1.0" anio="2020" />
    </Layout>
  )
}
//Función que itera las rutas individualmente
function LoadRoutes({ routes }) {
  const { user } = useAuth();
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          //component={route.component}
          render={(props) => <route.component {...props} user = {user} />}
        />
      ))}
    </Switch>
  );
}