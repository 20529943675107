import React from "react";
//import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./Revision.css";
import { List, Col, Row, Typography, Divider, Select, Button, Upload, Modal, Input, Checkbox, message, Form } from "antd";
import axios from "axios";

import { basePath } from "../../../api/config";
import { ArrowLeftOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import Mensajes from "../../../components/Mensajes/Mensajes";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { Redirect } from 'react-router';
import { base64toBlob } from "../../../utils/Utils"
import set from "lodash/fp/set";

const { Option } = Select;
const { Title } = Typography;

var tiempoMensaje = 5;

const urlConsultaValidacionArticulo = `${basePath}/consultaValidacionArticulo`;
const guardaValidacionArticulo = `${basePath}/guardaValidacionArticulo`;
const permisos = localStorage.getItem('ROL_USUARIO');
const isRolCAU = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAU.OC' ? true : false;

class ValidacionObservaciones extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickDescargar = this.handleClickDescargar.bind(this);
    this.handleGuardar = this.handleGuardar.bind(this);
    this.onChangeObsTec = this.onChangeObsTec.bind(this);
    this.onChangeObsDis = this.onChangeObsDis.bind(this);

    const estadoSel = localStorage.getItem('ID_ESTADO_SELECCIONADO');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const detalleSel = localStorage.getItem('ID_DETALLE_PROCESO');

    this.state = {
      consulta: false,
      seleccion: 0,
      posts: null,
      urlPDF1: null,
      urlPDF2: null,
      idArticulo: this.props.location.state.idArticulo,
      nombreArticulo: this.props.location.state.nombreArticulo,
      idTipoDocumentacion: this.props.location.state.idTipoDocumentacion,
      idEstadoSeleccionado: estadoSel,
      idProcesoSeleccionado: procesoSel,
      idDetalleProcesoSeleccionado: detalleSel,
      uploadEnable: false,
      redirect: false,
      file: null,
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      }
    };
  }


  componentDidMount() {
    console.log("ESTADO", this.state);
    const currentToken = localStorage.getItem('accessToken');
    const anio = localStorage.getItem('ANIO');
    const tipoProceso = localStorage.getItem("TIPO_PROCESO");
    let data = JSON.stringify({
      parametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado,
        anioProceso: anio,
        tipoProceso: tipoProceso
      },
      tipodocumentacion: this.state.idTipoDocumentacion,
      idArticulo: this.state.idArticulo,
      //etapaHistorico: value
    });
    axios
      .post(
        urlConsultaValidacionArticulo, data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: response.data.entity.causa
              }
            }); */
          message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message
                }
              }); */
            message.warning(response.data.entity.message, tiempoMensaje);
          }
          else {
            let urlPDF1 = null;
            let urlPDF2 = null;
            if (response.data.entity.archivoUnicoBase64 !== null && response.data.entity.archivoUnicoBase64 !== "") {
              const disenioUnicoBase64 = 'data:application/pdf;base64,' + response.data.entity.archivoUnicoBase64;
              const blob1 = base64toBlob(disenioUnicoBase64);
              urlPDF1 = URL.createObjectURL(blob1);
            }
            if (response.data.entity.cargaHistoricoBase64 !== null && response.data.entity.cargaHistoricoBase64 !== "") {
              const disenioOPLBase64 = 'data:application/pdf;base64,' + response.data.entity.cargaHistoricoBase64;
              const blob2 = base64toBlob(disenioOPLBase64);
              urlPDF2 = URL.createObjectURL(blob2);
            }
            let cuentaInactivos = 0;
            response.data.entity.especificacionesDisenio.forEach(spec => {
              if (!spec.checkactivo || spec.checkactivo === null)
                cuentaInactivos++;
            })
            response.data.entity.especificacionesTecnicas.forEach(spec => {
              if (!spec.checkactivo)
                cuentaInactivos++;
            })
            if (cuentaInactivos > 0) {
              this.setState({
                uploadEnable: true,
                file: null,
                posts: response.data.entity,
                urlPDF1: urlPDF1,
                urlPDF2: urlPDF2,
                consulta: true
              });
            }
            else if (cuentaInactivos === 0) {
              this.setState({
                posts: response.data.entity,
                urlPDF1: urlPDF1,
                urlPDF2: urlPDF2,
                consulta: true
              });
            }
            console.log("datos de la consulta", this.state.posts);

          }
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log("Error", error);
        if (error.response != null && error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        this.setState({
          visibleAlert: {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
          },
          consulta: false
        });
      });
  }

  handleSelect(value) {
  }

  handleClose() {
    this.setState({
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      }
    });
  }

  obtenHistoricoRevision(etapa) {
    this.handleSelect(etapa);
  }

  handleGuardar() {
    console.log("Handle buttom guardar");
    let validacionForm = false;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        validacionForm = true;
      }
    });
    console.log("Handle buttom guardar :=>" + validacionForm);
    if (!validacionForm)
      return;

    let cuentaObservacionesSinTexto = 0;
    let cuentaEspecificacionesDes = 0;
    this.state.posts.especificacionesTecnicas.forEach(spec => {

      if (spec.checkactivo === null)
        spec.checkactivo = false;

      if (!spec.checkactivo)
        cuentaEspecificacionesDes++;
      if (!spec.checkactivo && (spec.observaciones === null || spec.observaciones === ""))
        cuentaObservacionesSinTexto++;
    });
    this.state.posts.especificacionesDisenio.forEach(spec => {
      if (!spec.checkactivo)
        cuentaEspecificacionesDes++;
      if (!spec.checkactivo && (spec.observaciones === null || spec.observaciones === ""))
        cuentaObservacionesSinTexto++;
    });

    if (cuentaObservacionesSinTexto > 0) {
      /* this.setState(
        {
          visibleAlert: {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Los campos de observaciones son obligatorios."
          }
        }); */
      message.error("Los campos de observaciones son obligatorios.", tiempoMensaje);
      return false;
    }
    else {
      if (cuentaEspecificacionesDes > 0 && this.state.file === null) {
        /* this.setState(
          {
            visibleAlert: {
              activado: true,
              tipoMensaje: "error",
              mensaje: "Debe capturar la evidencia de las observaciones."
            }
          }); */
        message.error("Debe capturar la evidencia de las observaciones.", tiempoMensaje);
        return false;
      }
      else {
        const currentToken = localStorage.getItem('accessToken');
        const anio = localStorage.getItem('ANIO');
        const tipoProceso = localStorage.getItem("TIPO_PROCESO");
        const formData = new FormData();
        formData.append("parametros.idEstado", this.state.idEstadoSeleccionado);
        formData.append("parametros.idProceso", this.state.idProcesoSeleccionado);
        formData.append("parametros.idDetalleProceso", this.state.idDetalleProcesoSeleccionado);
        formData.append("parametros.anioProceso", anio);
        formData.append("parametros.tipoProceso", tipoProceso);
        formData.append("idArticulo", this.state.idArticulo);
        formData.append("idTipoDocumentacion", this.state.idTipoDocumentacion);
        console.log("this.state.file:=>", this.state.file);
        if (this.state.file !== null)
          formData.append("archivo", this.state.file);

        let numberEspe = 0;
        this.state.posts.especificacionesTecnicas.forEach(spec => {
          formData.append("especificaciones[" + numberEspe + "].id", spec.id);
          formData.append("especificaciones[" + numberEspe + "].nombre", spec.nombre);
          formData.append("especificaciones[" + numberEspe + "].tipoEspecificacion", spec.tipoEspecificacion);
          formData.append("especificaciones[" + numberEspe + "].observaciones", spec.checkactivo ? null : spec.observaciones.replace(/\r?\n|\r/g, " "));
          formData.append("especificaciones[" + numberEspe + "].checkactivo", spec.checkactivo);
          numberEspe++;
        });
        this.state.posts.especificacionesDisenio.forEach(spec => {
          formData.append("especificaciones[" + numberEspe + "].id", spec.id);
          formData.append("especificaciones[" + numberEspe + "].nombre", spec.nombre);
          formData.append("especificaciones[" + numberEspe + "].tipoEspecificacion", spec.tipoEspecificacion);
          formData.append("especificaciones[" + numberEspe + "].observaciones", spec.checkactivo ? null : spec.observaciones.replace(/\r?\n|\r/g, " "));
          formData.append("especificaciones[" + numberEspe + "].checkactivo", spec.checkactivo);
          numberEspe++;
        });

        axios
          .post(
            guardaValidacionArticulo, formData,
            {
              headers: {
                'Authorization': currentToken,
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                //"Access-Control-Allow-Origin": "*"
              }
            }
          )
          .then(response => {
            if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
              /* this.setState(
                {
                  visibleAlert: {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: response.data.entity.causa
                  }
                }); */
              message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
            }
            else if (response.data.entity.code === 200) {
              if (response.data.entity.message !== null && response.data.entity.message !== "") {
                /* this.setState(
                  {
                    visibleAlert: {
                      activado: true,
                      tipoMensaje: "warning",
                      mensaje: response.data.entity.message
                    }
                  }); */
                message.warning(response.data.entity.message, tiempoMensaje);
              }
              else {
                /* this.setState({
                  
                  visibleAlert: {
                    activado: true,
                    tipoMensaje: "success",
                    mensaje: "Se guardó su revisón de manera correcta."},
                    redirect: true
                }) */

                message.success("Se guardó su revisión de manera correcta.", tiempoMensaje);
                window.location = "/revision/validacion"
              }
            }
            else {
              localStorage.clear();
              // console.log("Borro el local storage");
              window.location = "/login"
            }
          }

          )
          .catch(error => {
            console.log(error);
            if (error.response != null && error.response.status === 403) {
              localStorage.clear();
              window.location = "/login"
              return;
            }
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "error",
                  mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                }
              }); */
            message.error("Ha ocurrido un error al realizar la petición solicitada.", tiempoMensaje);
          });
        this.handleSelect(this.state.seleccion);
      }
    }
  }

  handleClickDescargar() {
    console.log("Entro a descagar");
    let a = document.createElement('a');
    a.href = this.state.urlPDF2;
    /*  a.download = "DOCSMATS-" + this.state.posts.nombrecargaHistorico; */
    a.download = "DOCSMATS-" + "validacionHistorico";
    a.click();
    //window.location.href = consulta.url;
  }

  onChangeSpecTec = (item) => {
    let posts = this.state.posts;
    const index = posts.especificacionesTecnicas.indexOf(item);
    item.checkactivo = !item.checkactivo;
    if (!item.checkactivo)
      item.observaciones = null;
    posts.especificacionesTecnicas = set(`[${index}]`, item, posts.especificacionesTecnicas);

    let cuentaInactivos = 0;
    this.state.posts.especificacionesDisenio.forEach(spec => {
      if (!spec.checkactivo)
        cuentaInactivos++;
    })
    this.state.posts.especificacionesTecnicas.forEach(spec => {
      if (!spec.checkactivo)
        cuentaInactivos++;
    })
    console.log("cuentaInactivos:=> ---------------- ", cuentaInactivos);
    if (cuentaInactivos > 0) {
      this.setState({
        posts: posts,
        uploadEnable: true,
        //file: null
      });
    }
    else {
      this.setState({
        posts: posts,
        uploadEnable: false,
        file: null
      });
    }
    console.log("cuentaInactivos:=> ---------------- ", cuentaInactivos);
  }

  onChangeSpecDis = (item) => {
    let posts = this.state.posts;
    const index = posts.especificacionesDisenio.indexOf(item);
    item.checkactivo = !item.checkactivo;
    if (!item.checkactivo)
      item.observaciones = null;
    posts.especificacionesDisenio = set(`[${index}]`, item, posts.especificacionesDisenio);

    let cuentaInactivos = 0;
    this.state.posts.especificacionesDisenio.forEach(spec => {
      if (!spec.checkactivo || spec.checkactivo === null)
        cuentaInactivos++;
    })
    this.state.posts.especificacionesTecnicas.forEach(spec => {
      if (!spec.checkactivo)
        cuentaInactivos++;
    })
    if (cuentaInactivos > 0) {
      this.setState({
        posts: posts,
        uploadEnable: true,
        //file: null
      });
    }
    else {
      this.setState({
        posts: posts,
        uploadEnable: false,
        file: null
      });
    }
    console.log("cuentaInactivos:=> ---------------- ", cuentaInactivos);
  }

  onChangeObsTec = (item, e) => {
    let posts = this.state.posts;
    const index = posts.especificacionesTecnicas.indexOf(item);
    item.observaciones = e.target.value;
    posts.especificacionesTecnicas = set(`[${index}]`, item, posts.especificacionesTecnicas);
    this.setState({
      posts: posts
    });
  }

  onChangeObsDis = (item, e) => {
    let posts = this.state.posts;
    const index = posts.especificacionesDisenio.indexOf(item);
    item.observaciones = e.target.value;
    posts.especificacionesDisenio = set(`[${index}]`, item, posts.especificacionesDisenio);

    this.setState({
      posts: posts
    });

  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={
        { pathname: '/revision/validacion' }} />
    }
  }

  render() {
    const propsUpload = {
      showUploadList: {
        showPreviewIcon: false,
        showDownloadIcon: false,
        showRemoveIcon: true,
        //fileList: this.state.fileList,
      },
      multiple: false,
      onRemove: file => {
        console.log("Entró en onRemove.");
        this.setState({
          file: null,
          uploadEnable: true
        })
      },
      beforeUpload: file => {
        const isPDF = file.type === 'application/pdf';
        const isSomething = file.size > 0;
        const isSize = (file.size / 1024 / 1024) < 50;
        console.log(file);

        if (!isPDF) {
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: "El formato del archivo no corresponde a un PDF. Favor de verificarlo."
              }
            }); */
          message.error("El formato del archivo no corresponde a un PDF. Favor de verificarlo.", tiempoMensaje);
        }
        if (!isSomething) {
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: "El archivo viene vacío. Favor de verificarlo."
              }
            }); */
          message.error("El archivo viene vacío. Favor de verificarlo.", tiempoMensaje);
        }
        if (!isSize) {
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: "El archivo pesa más de 50 MB. Favor de verificarlo."
              }
            }); */
          message.error("El archivo pesa más de 50 MB. Favor de verificarlo.", tiempoMensaje);
        }
        else {
          this.setState({
            file: file,
            uploadEnable: false
          })
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "success",
                mensaje: "El archivo se ha cargado correctamente, para reemplazar el existente favor de eliminar el anterior."
              }
            }); */
          message.success("El archivo se ha cargado correctamente, para reemplazar el existente favor de eliminar el anterior.", tiempoMensaje);
        }
        return false;
      },
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {this.renderRedirect()}
        <Button
          id="regresar"
          type="link"
          href="/revision/validacion">
          <ArrowLeftOutlined />Regresar</Button>
        {(permisos === "DOCSYMAT.CAPTURA.JL" || permisos === "DOCSYMAT.CONSULTA.JL") ?
          <h1 id="titulo">Revisión de documentos y materiales</h1> :
          (permisos === "DOCSYMAT.ADMIN.OC" || permisos === "DOCSYMAT.CAPTURA.OC" ||
            permisos === "DOCSYMAT.CONSULTA.OC" || permisos === "DOCSYMAT.SUPERVISOR.OC" || permisos === "DOCSYMAT.REVISOR.OC") ?
            <h1 id="titulo">Validación de documentos y materiales</h1> : <h1 id="titulo">Revisión/Validación de documentos</h1>}
        <p id="msg">
          En esta sección podrá revisar las especificaciones y diseños relacionados al material o documento para validar si son correctos y en caso de no serlo dar las observaciones correspondientes.
         <br />
          <span id="smsg">*</span>Histórico de revisiones en la sección de consulta
        </p>
        {this.state.visibleAlert.activado ?
          (
            <Mensajes tipoMensaje={this.state.visibleAlert.tipoMensaje} mensaje={this.state.visibleAlert.mensaje} handleClose={this.handleClose} />
          ) : null}
        {this.state.consulta ?
          (console.log("NOMBRE ARTICULO", this.state.nombreArticulo),
            <Form id="observaciones" layout="vertical">
              <div>
                <div className="principal-div" >
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                    <div style={{ width: '48%', float: 'left', margin: '5px' }}>
                      <h3>{this.state.nombreArticulo} (Diseño Único)</h3>
                    </div>
                    <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                    <div style={{ height: '750px', width: '48%', float: 'left', margin: '5px' }}>
                      {this.state.urlPDF1 !== null ?
                        (
                          <Worker
                            /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                            workerUrl={window.location.origin + "/pdf.worker.min.js"}
                          >
                            <Viewer fileUrl={this.state.urlPDF1} />
                          </Worker>
                        )
                        :
                        (
                          <div
                            style={{
                              alignItems: 'center',
                              border: '2px dashed rgba(0, 0, 0, .3)',
                              display: 'flex',
                              fontSize: '2rem',
                              height: '100%',
                              justifyContent: 'center',
                              width: '100%',
                            }}
                          >
                          </div>
                        )}
                    </div>
                    <div style={{ height: '750px', width: '48%', float: 'right', margin: '5px' }}>
                      {this.state.urlPDF2 !== null ?
                        (
                          <Worker
                            /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                            workerUrl={window.location.origin + "/pdf.worker.min.js"}
                          >
                            <Viewer fileUrl={this.state.urlPDF2} />
                          </Worker>
                        )
                        :
                        (
                          <div
                            style={{
                              alignItems: 'center',
                              border: '2px dashed rgba(0, 0, 0, .3)',
                              display: 'flex',
                              fontSize: '2rem',
                              height: '100%',
                              justifyContent: 'center',
                              width: '100%',
                            }}
                          >
                          </div>
                        )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                    <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                    </div>
                    <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                      <Button className="descargar" onClick={this.handleClickDescargar}> <DownloadOutlined />Descargar </Button>
                    </div>
                  </div>
                  <div>
                    <br />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>

                    <div style={{ width: '48%', float: 'left', margin: '5px' }}>
                      <br />
                      <h4><span>Especificaciones Anexo 4.1</span></h4>
                      <ul>
                        {this.state.posts.especificacionesTecnicas.map((item) => (
                          //console.log(item),
                          <div>
                            <li>
                              <Checkbox checked={item.checkactivo} onChange={this.onChangeSpecTec.bind(this, item)}></Checkbox>{item.nombre}
                              {!item.checkactivo ?
                                <Form.Item name={`observacionTec-${item.id}`} hasFeedback>
                                  {getFieldDecorator(`observacionesTec-${item.id}`, {
                                    initialValue: item.observaciones !== undefined && item.observaciones !== null
                                      ? item.observaciones : null,
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Campo obligatorio.',
                                      },
                                      { max: 150, message: 'El tamaño máximo del campo es de 150 caracteres' },
                                      { whitespace: true, message: 'Campo obligatorio.' }
                                    ],
                                  })(
                                    <Input.TextArea placeholder="Observaciones"
                                      onChange={(e) => this.onChangeObsTec(item, e)}
                                      rows={2} autoSize={false} allowClear={true}
                                      defaultValue={item.observaciones}
                                      maxLength={150}
                                    />
                                  )}
                                </Form.Item>
                                : null}
                            </li>
                            <br />
                          </div>

                        ))}
                      </ul>
                    </div>
                    <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                      <br />
                      <h4><span>Especificaciones Únicas</span></h4>
                      <ul>
                        {this.state.posts.especificacionesDisenio.map((item) => (
                          //console.log(item),
                          <div>
                            <li><Checkbox checked={item.checkactivo} onChange={this.onChangeSpecDis.bind(this, item)}></Checkbox>{item.nombre}
                              {!item.checkactivo ?
                                <Form.Item name={`observacionDis-${item.id}`} hasFeedback>
                                  {getFieldDecorator(`observacionesDis-${item.id}`, {
                                    initialValue: item.observaciones !== undefined && item.observaciones !== null
                                      ? item.observaciones : null,
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Campo obligatorio.',
                                      },
                                      { max: 150, message: 'El tamaño máximo del campo es de 150 caracteres' },
                                      { whitespace: true, message: 'Campo obligatorio.' }
                                    ],
                                  })(
                                    <Input.TextArea placeholder="Observaciones"
                                      onChange={(e) => this.onChangeObsTec(item, e)}
                                      rows={2} autoSize={false} allowClear={true}
                                      defaultValue={item.observaciones}
                                      maxLength={150}
                                    />
                                  )}
                                </Form.Item>
                                : null}</li><br />
                          </div>
                        ))}
                      </ul>
                    </div>

                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <div style={{ width: '500px', float: 'left', margin: '5px' }}>
                    </div>
                    <div style={{ display: 'flex', width: '500px', float: 'right', margin: '5px', justifyContent: 'flex-end' }}>
                      <Upload id="subirEvidencia" 
                        {...propsUpload} 
                        accept=".pdf"
                        fileList={this.state.file !== undefined && this.state.file !== null ? [this.state.file] : []}
                      >
                        <Button className="subir" disabled={!this.state.uploadEnable || isRolCAU}>
                          <UploadOutlined />Evidencia
                            </Button>
                      </Upload>
                    </div>
                  </div>
                </div>
                <div className="div-guardar">
                  <Button id="btonc" disabled={!this.state.posts.enableModificar || isRolCAU} onClick={this.handleGuardar}> Guardar </Button>
                </div>
              </div>
            </Form>
          ) : null}
      </div >
    );
  }
}
export default ValidacionObservaciones;