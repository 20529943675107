
import React, { useState, useRef, useEffect, useCallback } from "react";

import "./sesion.scss";
import {
    Form, Input, InputNumber, Select, TimePicker, Checkbox,
    Table, Popconfirm, Button, Divider, Row, Col, Upload, Radio, Spin, DatePicker, message
} from 'antd';
import { UploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Mensajes from "../../../components/Mensajes/Mensajes";
//import SelectDocumentos from "../ListaDocs/SelectDocs";
import { basePath } from "../../../api/config";
//import VerPdf from "../ListaDocs/VerPdf";
//import VerPdfx from "../ListaDocs/VerPdfx";
import axios from "axios";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { base64toBlob } from "../../../utils/Utils";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Redirect } from 'react-router';
moment.locale('es');

let isRolConsulta = false;
let isRolCAU = false;
let isRolVerifica = false;
var tiempoMensaje = 5;

export default function ModificarSesion(props) {
    let history = useHistory();
    let regresar = false;
    const Option = Select.Option;
    const RadioGroup = Radio.Group;
    const timeFormat = "HH:mm";
    const { TextArea } = Input;
    const urlConsultaSesion = `${basePath}/consultaSesionConsejo`;
    const urlModificaSesion = `${basePath}/modificaSesionConsejo`;
    const date = new Date();
    const fechaActual = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    let clasificacion = "";
    const inputNombre = React.createRef();
    const inputNumero = React.createRef();
    const inputObs = React.createRef();

    const [optionsTS, setOptionsTS] = useState({
        data: ["Ordinaria", "Extraordinaria", "Especial"],
        loadingData: true
    });
    const [cambioFechaSesion, setCambioFechaSesion] = useState(0);
    const [idAprobacion, setIdAprobacion] = useState({
        id: props.location.state.idAprobacion
    });
    const dateFormat = "DD-MM-YYYY";
    //console.log("idAprobacion.idAprobacion: ", idAprobacion.id);
    const [consulta, setConsulta] = useState({
        posts: [],
        url: null,
        cargada: false
    });
    const [guardando, setGuardando] = useState(false);
    const [fechaSesion, setFechaSesion] = useState({
        fecha: ""
    });
    const [tipoClasificacionSesion, setTipoClasificacionSesion] = useState({
        tipo: 0
    });
    const [tipoSesion, setTipoSesion] = useState({
        tipos: ""
    });
    const [fechaCaptura, setFechaCaptura] = useState({
        fechac: ""
    });
    const [nombreAcuerdo, setNombreAcuerdo] = useState({
        nombre: ""
    });
    const [numeroAcuerdo, setNumeroAcuerdo] = useState({
        numero: ""
    });
    const [archivoSesion, setArchivoSesion] = useState({
        uploading: false,
        file: "",
        urlPDF: null
    });
    const [observacion, setObservacion] = useState({
        observaciones: ""
    });
    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    isRolConsulta = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.OC' ? true :
        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.OC' ? true :
            localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.JL' ? true :
                localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.JL' ? true :
                    localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.UTVOPL.OC' ? true :
                        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.OPLE.JL' ? true :
                            localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.SUPERVISOR.OC' ? true :
                                localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.REVISOR.OC' ? true : false;

    isRolCAU = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.ADMIN.OC' ? true :
        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.OPLE.JL' ? true : false; //puede hacer todo

    isRolVerifica = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAU.OC' ? true : false;//puede hacer todo menos guardar

    const handleClose = () => {
        //setVisibleAlert(false);
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
        if (regresar === true) {
            regresar = false;
            history.push('/aprobacion/registraSesion')
        }
    }


    const propsSesion = {
        multiple: false,
        onRemove: file => {
            setArchivoSesion(archivoSesion => {
                return {
                    file: archivoSesion.file
                };
            });
        },
        beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            const isSomething = file.size > 0;
            const isSize = (file.size / 1024 / 1024) < 50; //Limita hasta 50 MB
            if (!isPDF) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "El formato del archivo no corresponde a un PDF. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El formato del archivo no corresponde a un PDF. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
                return true;
            }
            if (!isSomething) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El archivo viene vacío. Favor de verificarlo."
                    mensaje: "El archivo viene vacío. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo viene vacío. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
                return true;
            }
            if (!isSize) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El archivo pesa más de 10 MB. Favor de verificarlo."
                    mensaje: "El archivo pesa más de 10 MB. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo pesa más de 50 MB. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
                return false;
            }
            else {
                const url = URL.createObjectURL(file);
                setArchivoSesion({
                    file: file,
                    urlPDF: url,
                    uploading: true,
                });

                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "success",
                    mensaje: "El archivo fue cargado exitosamente. Para poder subir otro PDF primero debe eliminar el existente."
                }); */
                message.success("El archivo fue cargado exitosamente. Para poder subir otro PDF primero debe eliminar el existente.", tiempoMensaje);
            }
            return false;
        },
        archivoSesion
    };
    console.log("Archivo", archivoSesion);

    const estadoSel = localStorage.getItem('ID_ESTADO_SELECCIONADO');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const detalleSel = localStorage.getItem('ID_DETALLE_PROCESO');
    const rol = localStorage.getItem('ROL_USUARIO');
    let dataSel = JSON.stringify({
        idProcesoElectoral: procesoSel,
        idEstado: estadoSel,
        idDetalleProceso: detalleSel,
        rolUsuario: rol
    });
    const [dataSelectState, setDataSelectState] = useState({
        dataSelect: dataSel
    });
    const [idEstadoSeleccionadoState, setIdEstadoSeleccionadoState] = useState({
        idEstadoSeleccionado: estadoSel
    });
    const [idProcesoSeleccionadoState, setIdProcesoSeleccionadoState] = useState({
        idProcesoSeleccionado: procesoSel
    });
    const [idDetalleProcesoSeleccionadoState, setIdDetalleProcesoSeleccionadoState] = useState({
        idDetalleProcesoSeleccionado: detalleSel
    });

    const [redirect, setRedirect] = useState({
        redireccion: false,
        idAprobacion: -1
    });


    function doRequest() {
        console.log("Entró en doRequest()");
        let data = JSON.stringify({
            idEstado: localStorage.getItem('ID_ESTADO_SELECCIONADO'),
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            anioProceso: localStorage.getItem('ANIO'),
            tipoProceso: localStorage.getItem("TIPO_PROCESO"),
            rolUsuario: localStorage.getItem("ROL_USUARIO"),
            idAprobacion: idAprobacion.id
        })
        console.log("doRequest.data: ", data);

        axios
            .post(
                urlConsultaSesion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        //console.log("Hubo un error en la consulta");
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                        message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        console.log("Fue éxitosa la consulta");
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            console.log("No tiene un mensaje de advertencia");
                            /* setVisibleAlert(
                                {
                                    //activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                            message.warning(response.data.entity.message, tiempoMensaje);
                        }
                        else {
                            console.log("Tiene un mensaje de advertencia");
                            console.log("-----response.data.entity: ", response.data.entity);
                            console.log("response.data.entity: ", response.data.entity);

                            setFechaSesion({
                                fecha: response.data.entity.fechaSesionString
                            });

                            setTipoClasificacionSesion({
                                tipo: response.data.entity.idTipoSesion
                            });

                            setFechaCaptura({
                                fechac: response.data.entity.fechaHora
                            });
                            setNombreAcuerdo({
                                nombre: response.data.entity.nombreAcuerdo
                            });

                            setNumeroAcuerdo({
                                numero: response.data.entity.numeroAcuerdo
                            });

                            let url = null;
                            if (response.data.entity.acuerdoBase64 !== null && response.data.entity.acuerdoBase64 !== "") {
                                const archivoSesionBase64 = 'data:application/pdf;base64,' + response.data.entity.acuerdoBase64;
                                const blob = base64toBlob(archivoSesionBase64);
                                url = URL.createObjectURL(blob);
                                archivoSesion.uploading = true;
                                //archivoSesion.file = response.data.entity.disenioUnicoBase64;
                                archivoSesion.file = blob;
                                //archivoSesion.urlPDF = URL.createObjectURL(blob);
                                //console.log("url: ", url);
                            }

                            setObservacion({
                                observaciones: response.data.entity.observaciones
                            });
                            setConsulta({
                                posts: response.data.entity,
                                url: url,
                                cargada: true
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        window.location = "/login"
                    }
                }
            ).catch();
    };
    const handleClickCancelar = useCallback((id, conAlerta) => {
        console.log("Entró en handleClickCancelar");
        setRedirect({
            redireccion: true,
            idAprobacion: id
        })
    });

    const renderRedirect = () => {
        if (redirect.redireccion) {
            console.log("Se va a consultar: ");
            // Colocar la redireccion
            return <Redirect to={{
                pathname: '/aprobacion/registraSesion',

            }
            } />
        }
    }

    useEffect(() => {
        if (!consulta.cargada)
            doRequest();
    }, [consulta]);


    const handleInputFechaSesion = event => {
        console.log('DatePicker change: ', (event && event.format(dateFormat)));
        event && (fechaSesion.fecha = event.format(dateFormat));
        console.log('fecha', fechaSesion.fecha);
        setCambioFechaSesion(cambioFechaSesion + 1);
    };

    const handleInputTipoSesion = event => {
        console.log("Entró en handleInputTipoSesion");
        console.log("event: ", event);
        if (event === "Ordinaria" ||
            event === "Extraordinaria" ||
            event === "Especial") {
            tipoSesion.tipos = event;
        } else {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "El tipo de sesión es incorrecto."
                }); */
            message.error("El tipo de sesión es incorrecto.", tiempoMensaje);

        }
    };

    const handleInputNombreAcuerdo = event => {
        console.log("Entró en handleInputNombreAcuerdo");
        nombreAcuerdo.nombre = event.target.value;
    };

    const handleInputObsevacion = event => {
        observacion.observaciones = event.target.value;
    };

    const handleInputNumeroAcuerdo = event => {
        console.log("Entró en handleInputNumeroAcuerdo");
        numeroAcuerdo.numero = event.target.value;
    };


    if (tipoClasificacionSesion.tipo == 1)
        clasificacion = "Ordinaria";
    else if (tipoClasificacionSesion.tipo == 2)
        clasificacion = "Extraordinaria";
    if (tipoClasificacionSesion.tipo == 3)
        clasificacion = "Especial";

    console.log("fecha", moment(fechaSesion.fecha, dateFormat));
    console.log("FECHA", fechaSesion.fecha);


    return (

        <div id="principal" className="div_consultaadj">
            {renderRedirect()}
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null
            }

            {!consulta.cargada || guardando ? (<Spin size="large" style={{ alignItems: "center" }} />) : (

                <Form>

                    <div className="div_titulo">
                        <Button id="regresar"
                            type="link"
                            href="/aprobacion/registraSesion">
                            <ArrowLeftOutlined />Regresar
                        </Button>
                    </div>
                    <h1 className="titulo-sesion">Sesión del OPL</h1>
                    <label className="texto-14-sesion">Los datos con ( <label className="rojo-sesion">*</label> ) son requeridos.</label>
                    <br />
                    <br />
                    <Row>
                        <Col span={4}>
                            <Col span={23}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion">Fecha de sesión</label>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion">Tipo de sesión</label>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion">Número de acuerdo</label>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion">Nombre del acuerdo</label>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <label className="rojo-sesion">* </label><label className="texto-16-sesion"> Fecha de modificación</label>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={4}>
                            <Col span={23}>
                                <Form.Item name="fechaSesion"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Seleccione la fecha de la sesión.',
                                        },
                                    ]}>
                                    <DatePicker
                                        placeholder="Selecciona"
                                        format={dateFormat}
                                        locale={'es'}
                                        onChange={handleInputFechaSesion}
                                        disabledDate={current => {
                                            return current.startOf('day').diff(moment().startOf('day'), 'days') > 0

                                        }}
                                        defaultValue={moment(fechaSesion.fecha, dateFormat)}
                                    />
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <Form.Item name="tipoSesion"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese un tipo de sesion: Ordinaria, Extraordinaria o Especial.',
                                        },
                                    ]} >
                                    {console.log("optionsTS.data tiene: ", optionsTS.data)}
                                    <Select placeholder="Selecciona una opción" defaultValue={clasificacion}
                                        onChange={handleInputTipoSesion}>
                                        {optionsTS.data.map((value, index) => {
                                            return <Option id="tiposSesion" key={value}>{value}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Col>

                        <Col span={5}>
                            <Col span={23}>
                                <Form.Item name="numeroAcuerdo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese el número de acuerdo.',
                                        },
                                    ]}>
                                    <Input placeholder="Ingresa número de acuerdo"
                                        maxLength={50}
                                        defaultValue={numeroAcuerdo.numero}
                                        onChange={handleInputNumeroAcuerdo}
                                        ref={inputNumero}
                                    />
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <Form.Item name="nombreAcuerdo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese el nombre del acuerdo.',
                                        },
                                    ]}>
                                    <Input placeholder="Ingresa nombre del acuerdo"
                                        maxLength={524}
                                        defaultValue={nombreAcuerdo.nombre}
                                        onChange={handleInputNombreAcuerdo}
                                        ref={inputNombre}
                                    />
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="fechaCaptura">
                                <span>{fechaActual} </span>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={10}>
                            <Col span={23}>
                                <label className="texto-16-sesion">Observaciones</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <Form.Item name="observaciones">

                                    <TextArea placeholder="Ingrese las observaciones"
                                        rows={2} autoSize={{ minRows: 2, maxRows: 2 }}
                                        allowClear={true}
                                        maxLength={1500}
                                        defaultValue={observacion.observaciones}
                                        ref={inputObs}
                                        onChange={handleInputObsevacion} />

                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>


                    <div>
                        {consulta.url !== null ? (
                            <Worker
                                /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                                workerUrl={window.location.origin + "/pdf.worker.min.js"}
                            >
                                <div style={{ height: '1110px', width: '95%' }}>
                                    <Viewer fileUrl={consulta.url} />
                                </div>
                            </Worker>
                        ) :
                            (
                                <div
                                    style={{
                                        alignItems: 'center',
                                        border: '2px dashed rgba(0, 0, 0, .3)',
                                        display: 'flex',
                                        fontSize: '2rem',
                                        height: '100%',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                </div>
                            )
                        }
                    </div>
                    <div className="div_modificarsesion">
                        <div className="div_archivoSesion">
                            <div className="div_boton_agregar">
                                <Upload id="subirDisUni"
                                    {...propsSesion}
                                    accept=".pdf">
                                    <Button className="subir">
                                        <UploadOutlined />Reemplazar
                                </Button>
                                </Upload>
                            </div>
                        </div>

                        <div className="div_botones_finales">
                            <Form.Item>
                                <Button className="subir"
                                    type="link"
                                    onClick={handleClickCancelar}> Cancelar </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary"
                                    htmlType="submit"
                                    className="guardarc"
                                    onClick={handleGuardar}
                                    disabled={isRolVerifica || isRolConsulta}> Guardar cambios
                                    </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );


    function handleGuardar() {
        const formData = new FormData();
        console.log("fechaSesion.fecha: ", moment(fechaSesion.fecha, dateFormat));
        console.log("cambioFechaSesion: ", cambioFechaSesion);
        formData.append("idDetalleProceso", localStorage.getItem('ID_DETALLE_PROCESO'));
        formData.append("idProcesoElectoral", localStorage.getItem('ID_PROCESO_ELECTORAL'));
        formData.append("anioProceso", localStorage.getItem('ANIO'));
        formData.append("idEstado", localStorage.getItem('ID_ESTADO_SELECCIONADO'));
        formData.append("tipoProceso", localStorage.getItem('TIPO_PROCESO'));
        formData.append("rolUsuario", localStorage.getItem('ROL_USUARIO'));
        formData.append("idAprobacion", idAprobacion.id);
        formData.append("fechaSesion", fechaSesion.fecha);
        /*if (cambioFechaSesion > 0) {
            console.log("fechaSesion.fecha: ", moment(fechaSesion.fecha, dateFormat));
        }
        else {
            var sentence = fechaSesion.fecha.split("T");
            var fechadescompuesta = sentence[0].split("-")
            formData.append("fechaSesion", fechadescompuesta[2] + "-" + fechadescompuesta[1] + "-" + fechadescompuesta[0]);
            console.log("fechaSesion: ", fechadescompuesta[2] + "-" + fechadescompuesta[1] + "-" + fechadescompuesta[0]);
        }*/
        if (tipoSesion.tipos !== "") {
            console.log("tipoSesion: ", tipoSesion.tipos);
            formData.append("tipoSesion", tipoSesion.tipos);
        } else {
            console.log("tipoSesion: ", clasificacion);
            formData.append("tipoSesion", clasificacion);
        }
        formData.append("nombreAcuerdo", nombreAcuerdo.nombre);
        formData.append("numeroAcuerdo", numeroAcuerdo.numero);
        formData.append("acuerdos", archivoSesion.file);
        if (observacion.observaciones === undefined || observacion.observaciones === null) {
            formData.append("observaciones", "");
        }
        else {
            formData.append("observaciones", observacion.observaciones);
        }
        setGuardando(true);
        axios.post(urlModificaSesion, formData,
            {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json"
                }
            }).then(response => {

                if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                    /*  this.setState(
                       {
                         visibleAlert: {
                           activado: true,
                           tipoMensaje: "error",
                           mensaje: ("No se pudo modificar el Acuerdo de Aprobación " + nombreAcuerdo.nombre + " inténtelo más tarde.")
                         }
                       }); */
                    message.error("No se pudo modificar el Acuerdo de Aprobación " + nombreAcuerdo.nombre + " inténtelo más tarde.", tiempoMensaje);
                }
                else if (response.data.entity.code === 200) {
                    console.log("Fue correcta la actualización");
                    /* setVisibleAlert({
                        activado: true,
                        tipoMensaje: "success",
                        mensaje: ("El Acuerdo de Aprobación " + nombreAcuerdo.nombre + " se modificó de manera correcta.")
                    }); */
                    message.success("El Acuerdo de Aprobación " + nombreAcuerdo.nombre + " se modificó de manera correcta.", tiempoMensaje);
                }
                else if (response.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                }
                regresar = true;
                setTimeout(handleClose, 6000);
            });
    }

}