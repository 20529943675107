import React from "react";
import { Layout, Row, Col } from "antd";
import "./Footer.scss";
import logoINE from "../../assets/img/logoINE_bco.svg";

export default function Footer(props) {
  const { Footer } = Layout;

  return (
    <Footer className="footer columna-footer">
            {/* Le puedes asignar un class directamente al componente de ant desig  sino sería ant-layout-footer*/}
            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 24 }} md={{ span: 3 }}>
                    <div className="instituto">
                    <img src={logoINE} alt="Logo" />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }} md={{ span: 0 }}>
                    <div className="instituto">
                      <img src={logoINE} alt="Logo" />
                    </div>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 3 }}>
                    <div className="links-ine">
                        <a href="https://www.ine.mx/" target="blank" style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
                            INE México {props.anio}
                        </a>
                    </div>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 3 }}>
                    <div className="links-ine">
                        <a href="https://cau.ine.mx/" target="blank" style={{ marginLeft: '10px', marginRight: '10px' }}>
                            CAU
                        </a>
                        <span className="revision">{`1.0.0 Rev.4 23/06/2021 11:02`}</span>
                    </div>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 8 }}>
                    <div className="area">
                        <span>Unidad Técnica de Servicios de Informática</span>
                    </div>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 7 }}>
                    <div className="version">
                        <span>{props.version} / {new Date().getFullYear()}</span>
                    </div>
                </Col>
            </Row>
        </Footer>
  );
}
