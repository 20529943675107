//Layout
//Se creo un Layout principal para que solo se manden a llamar las paginas y muestren su contenido
import LayoutAdmin from '../layouts/LayoutAdmin';
//admin pages
import AdminHome from '../pages/Admin';
import AdminLogin from '../pages/Admin/Login/Login';

import Catalogos from '../pages/Admin/Configuracion/Catalogos';
import ConsultarCatalogos from '../pages/Admin/Configuracion/ConsultarCatalogos';
import CrearArticulo from '../pages/Admin/Configuracion/CrearArticulo';
import ModificarArticulo from '../pages/Admin/Configuracion/ModificarArticulo';
import CargasDocumentos from '../pages/Revision/Cargas/Cargas';
import CargasEsp from '../pages/Revision/Cargas/CargasEsp';
import ValidacionDocumentos from '../pages/Revision/Validacion/Validacion';
import ValidacionConsulta from '../pages/Revision/Validacion/ValidacionConsulta';
import ConsultarArticulo from '../pages/Admin/Configuracion/ConsultarArticulo';
import Error404 from '../pages/Error404';
import ValidacionObservaciones from '../pages/Revision/Validacion/ValidacionObservaciones';
import SeguimientoProduccion from '../pages/Produccion/Seguimiento/Seguimiento';
import ConsultaSeguimientoProduccion from '../pages/Produccion/Seguimiento/ConsultaSeguimiento';
import ConsultarArticulosAdjudicaciones from '../pages/Adjudicacion/Registro/ConsultarArticulosAdjudicaciones';
import ConsultarAdjudicaciones from '../pages/Adjudicacion/Registro/ConsultarAdjudicaciones';
import RegistrarAdjudicaciones from '../pages/Adjudicacion/Registro/RegistrarAdjudicaciones';
import ModificarAdjudicaciones from '../pages/Adjudicacion/Registro/ModificarAdjudicaciones';
import ConsultarVerificaciones from '../pages/Produccion/Verificaciones/ConsultarVerificaciones';
import ConsultarVerificacion from '../pages/Produccion/Verificaciones/ConsultarVerificacion';
import RegistrarVerificacion from '../pages/Produccion/Verificaciones/RegistrarVerificacion';
import RegistrarSesion from '../pages/Aprobacion/Sesion/RegistrarSesion';
import ModificarSesion from '../pages/Aprobacion/Sesion/ModificarSesion';
import ConsultaSesion from '../pages/Aprobacion/Sesion/ConsultaSesion';
import Reportes from '../pages/Reportes/Reportes';

import { Form } from "antd";

//import Sesion from '../pages/Aprobacion/Sesion/Sesion';
//Se crea un array con todas las rutas a utilizar 
const routes = [
    {
        path: "/",
        component: LayoutAdmin,
        exact: false,
        routes: [
            {
                path: "/home",
                component: AdminHome,
                exact: true
            },
            {
                path: "/login",//capcha
                component: AdminLogin,
                exact: true
            },
            {
                path: "/configuracion/catalogos",
                component: Catalogos,
                exact: true
            },
            {
                path: "/configuracion/consultarcatalogos",
                component: ConsultarCatalogos,
                exact: true
            },
            {
                path: "/configuracion/consultararticulo",
                component: ConsultarArticulo,
                exact: true
            },
            {
                path: "/configuracion/creararticulo",
                component: CrearArticulo,
                exact: true
            },
            {
                path: "/configuracion/modificararticulo",
                component: ModificarArticulo,
                exact: true
            },
            {
                path: "/revision/cargas",
                // component: CargasDocumentos,
                component: Form.create({ name: 'consulta' })(CargasDocumentos),
                exact: true
            },
            {
                path: "/revision/cargasesp",
                component: CargasEsp,
                exact: true,
            },
            {
                path: "/revision/validacion",
                component: ValidacionDocumentos,
                exact: true
            },
            {
                path: "/revision/validacionconsulta",
                component: ValidacionConsulta,
                exact: true,
            },
            {
                path: "/revision/validacionobservaciones",
                component: Form.create({ name: 'ValidacionObservaciones' })(ValidacionObservaciones),
                exact: true,
            },
            {
                path: "/produccion/seguimiento",
                component: SeguimientoProduccion,
                exact: true
            },
            {
                path: "/produccion/consultaseguimiento",
                component: ConsultaSeguimientoProduccion,
                exact: true
            },
            {
                path: "/adjudicacion/consultarArticulosAdjudicaciones",
                component: ConsultarArticulosAdjudicaciones,
                exact: true
            },
            {
                path: "/adjudicacion/consultarAdjudicaciones",
                component: ConsultarAdjudicaciones,
                exact: true
            },
            {
                path: "/adjudicacion/registrarAdjudicaciones",
                component: RegistrarAdjudicaciones,
                exact: true
            },
            {
                path: "/adjudicacion/modificarAdjudicaciones",
                component: ModificarAdjudicaciones,
                exact: true
            },
            /*
          
           {
               path: "/aprobacion/registraSesion",
               component: ModificarSesion,
               exact: true
           },
           {
               path: "/aprobacion/registraSesion",
               component: Sesion,
               exact: true
           },*/
            {
                path: "/aprobacion/registraSesion",
                component: RegistrarSesion,
                exact: true
            },

            {
                path: "/aprobacion/modificaSesion",
                component: ModificarSesion,
                exact: true
            },
            {
                path: "/aprobacion/consultaSesion",
                component: ConsultaSesion,
                exact: true
            },
            {
                path: "/reportes",
                component: Reportes,
                exact: true
            },
            {
                path: "/produccion/verificaciones",
                component: ConsultarVerificaciones,
                exact: true
            },
            {
                path: "/produccion/registrarverificacion",
                component: RegistrarVerificacion,
                exact: true
            },
            {
                path: "/produccion/consultarVerificacion",
                component: ConsultarVerificacion,
                exact: true
            },
            {
                component: Error404
            }
        ]
    }

];

export default routes;