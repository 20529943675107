import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import "./ModificarArticulo.scss";
//import { View } from "react-native";
import { Form, Input, Button, Upload, Radio, Spin, Select, message } from 'antd';
import { UploadOutlined, ArrowLeftOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Mensajes from "../Mensajes/Mensajes";
import SelectDocumentos from "../ListaDocs/SelectDocs";
import { basePath } from "../../api/config";
//import VerPdf from "../ListaDocs/VerPdf";
//import VerPdfx from "../ListaDocs/VerPdfx";
import axios from "axios";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { base64toBlob } from "../../utils/Utils";
import { useHistory } from "react-router-dom";
const { Option } = Select;

export default function ModificarArticulo(props) {

    var tiempoMensaje = 5;
    let history = useHistory();
    const FormItem = Form.Item;
    const { TextArea } = Input;
    //const inputEspTec = React.createRef();
    //const inputEspDis = React.createRef();
    //const urlTiposClasificacion = `${basePath}/obtenTiposClasificacionEleccion`;
    const urlTiposClasificacion = `${basePath}//obtenTiposClasElecConValorDefecto`;
    const urlTiposClasificacionByTipoArticulo = `${basePath}/obtenTiposClasElecPorTipoArticulo`;
    //const urlCrearArticulo = `${basePath}/crearArticulo`;
    const urlConsultaArticulo = `${basePath}/consultaArticulo`;
    const urlModificaArticulo = `${basePath}/modificaArticulo`;
    
    const [tiposSubclasificacionHook, setTiposSubclasificacionHook] = useState({
        datos: {'-1': 'SIN CLASIFICACION ADICIONAL', '0': 'DOCUMENTACION SIN EMBLEMAS', '1': 'DOCUMENTACION CON EMBLEMAS', '2': 'BOLETAS'}
    });
    const [urlTiposClasi, setUrlTiposClasi] = useState({
        url: urlTiposClasificacion  
    });
    const [indexEsp, setIndexEsp] = useState(0);
    const [count, setCount] = useState(0); 
    const [idArticuloHook, setIdArticuloHook] = useState({
        idArticulo: props.idArticulo
    });    
    const [consultaHook, setConsultaHook] = useState({
        posts: [],
        url: null,
        cargada: false
    });
    const [nombreArticuloHook, setNombreArticuloHook] = useState({
        nombre: ""
    });
    const [tipoArticuloHook, setTipoArticuloHook] = useState({
        tipo: 0
    });
    const [tieneEmblemaHook, setTieneEmblemaHook] = useState({
        tiene: 0
    });
    const [tipoClasificacionEleccionHook, setTipoClasificacionEleccionHook] = useState({
        tipo: 0
    });
    const [tipoSubclasificacionEleccionHook, setTipoSubclasificacionEleccionHook] = useState({
        tipo: -2
    });
    const [valorDefectoSubclasiHook, setValorDefectoSubclasiHook] = useState({
        valor: -2
    });
    const [listaEspEliminadas, setListaEspEliminadas] = useState({
        especificaciones : new Map()
    });
    const [archivoDisenioUnico, setArchivoDisenioUnico] = useState({
        uploading: false,
        file: "",
        urlPDF: null
    });
    const [justificacionHook, setJustificacionHook] = useState({
        justificacion: ""
    });
    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });
    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }
    const propsDisenioUnico = {
        multiple: false,
        onRemove: file => {
            setArchivoDisenioUnico(archivoDisenioUnico => {
                return {
                    file: archivoDisenioUnico.file
                };
            });
        },
        beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            const isSomething = file.size > 0;
            const isSize = (file.size / 1024 / 1024) < 50; //Limita hasta 50 MB
            if (!isPDF) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",                   
                    mensaje: "El formato del archivo no corresponde a un CSV. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El formato del archivo no corresponde a un PDF. Favor de borrarlo y cargar uno válido.",tiempoMensaje);
                return true;
            }
            if (!isSomething) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",                  
                    mensaje: "El archivo viene vacío. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo viene vacío. Favor de borrarlo y cargar uno válido.",tiempoMensaje);
                return true;
            }
            if (!isSize) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",                  
                    mensaje: "El archivo pesa más de 10 MB. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo pesa más de 50 MB. Favor de borrarlo y cargar uno válido.",tiempoMensaje);
                return false;
            }
            else {
                const url = URL.createObjectURL(file);
                setArchivoDisenioUnico({
                    file: file,
                    urlPDF: url,
                    uploading: true,
                });

                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "success",
                    mensaje: "El archivo fue cargado exitosamente. Para poder subir otro PDF primero debe eliminar el existente."
                }); */
                message.success("El archivo fue cargado exitosamente. Para poder subir otro PDF primero debe eliminar el existente.",tiempoMensaje);
            }
            return false;
        },
        archivoDisenioUnico
    };

    const ListaEspecificaciones = function (props) {
        return (
            <div>
                <ul>
                    {[...props.especificaciones.keys()].map(k => (
                        <li key={k}>{props.especificaciones.get(k)}</li>
                    ))}
                </ul>
            </div>
        );
    };

    const estadoSel = localStorage.getItem('ID_ESTADO_SELECCIONADO');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const detalleSel = localStorage.getItem('ID_DETALLE_PROCESO');
    let dataSel = JSON.stringify({
        idProceso: procesoSel,
        idEstado: estadoSel,
        idDetalleProceso: detalleSel,
        idTipoClasificacion: props.clasificacion
    });
    const [dataSelectState, setDataSelectState] = useState({
        dataSelect: dataSel
    });
    const [idEstadoSeleccionadoState, setIdEstadoSeleccionadoState] = useState({
        idEstadoSeleccionado: estadoSel
    });
    const [idProcesoSeleccionadoState, setIdProcesoSeleccionadoState] = useState({
        idProcesoSeleccionado: procesoSel
    });
    const [idDetalleProcesoSeleccionadoState, setIdDetalleProcesoSeleccionadoState] = useState({
        idDetalleProcesoSeleccionado: detalleSel
    });
    const [redirect, setRedirect] = useState({
        redireccion: false,
        idArticulo: -1
    });

    function doRequest() {
        let data = JSON.stringify({
            idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            anio: localStorage.getItem('ANIO'),
            tipoProceso: localStorage.getItem("TIPO_PROCESO"),
            idArticulo: props.idArticulo,            
        })

            axios
            .post(
                urlConsultaArticulo, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {                  
                            setNombreArticuloHook({
                                nombre: response.data.entity.nombreArticulo
                            });

                            setTipoArticuloHook({
                                tipo: response.data.entity.tipoArticulo
                            });
                            setTieneEmblemaHook({
                                tiene: response.data.entity.tieneEmblema
                            });
                            setTipoClasificacionEleccionHook({
                                tipo: response.data.entity.clasificacion
                            });
                            setValorDefectoSubclasiHook({
                                valor: response.data.entity.subclasificacion
                            });
                            setTipoSubclasificacionEleccionHook({
                                tipo: response.data.entity.subclasificacion
                            });
                            if (response.data.entity.tipoArticulo == 1) {
                                setTiposSubclasificacionHook({
                                    datos: {'-1': 'SIN CLASIFICACION ADICIONAL'}
                                });
                            }
                            else {
                                setTiposSubclasificacionHook({
                                    datos: {'0': 'DOCUMENTACION SIN EMBLEMAS', '1': 'DOCUMENTACION CON EMBLEMAS', '2': 'BOLETAS'}
                                });
                            }                            
                            let url = null;
                            if (response.data.entity.disenioUnicoBase64 !== null && response.data.entity.disenioUnicoBase64 !== "") {
                                const disenioUnicoBase64 = 'data:application/pdf;base64,' + response.data.entity.disenioUnicoBase64;
                                const blob = base64toBlob(disenioUnicoBase64);
                                url = URL.createObjectURL(blob);
                                archivoDisenioUnico.uploading = true;                   
                                archivoDisenioUnico.file = blob;
                            }
                            setJustificacionHook({
                                justificacion: response.data.entity.justificacion
                            });                        
                            setConsultaHook({ //Se guarda toda la información consultada en posts
                                posts: response.data.entity,
                                url: url,
                                cargada: true
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        window.location = "/login"
                    }
                }
            ).catch(error => {
                console.log(error);
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
        
    };

    useEffect(() => {
        //console.log("Entró en useEffect()");
        if (!consultaHook.cargada)
            doRequest();
    }, [consultaHook]);

    const handleInputNombreArticulo = event => {
        nombreArticuloHook.nombre = event.target.value;
    };
    const handleTipoArticulo = event => {
        const valor = event.target.value;   
        let dataSelByTipoArticulo = JSON.stringify({
            idProceso: procesoSel,
            idEstado: estadoSel,
            idDetalleProceso: detalleSel,       
            idTipoArticulo: valor
        });
        setTipoArticuloHook({
            tipo: valor
        });
        setDataSelectState({
            dataSelect: dataSelByTipoArticulo
        });
        setUrlTiposClasi({
            url: urlTiposClasificacionByTipoArticulo
        });
        setTipoClasificacionEleccionHook({
            tipo: 0
        });

        if (valor === 1) {
            setTiposSubclasificacionHook({
                datos: {'-1': 'SIN CLASIFICACION ADICIONAL'}
            });
        }
        else {
            setTiposSubclasificacionHook({
                datos: {'0': 'DOCUMENTACION SIN EMBLEMAS', '1': 'DOCUMENTACION CON EMBLEMAS', '2': 'BOLETAS'}
            });
        }

        setValorDefectoSubclasiHook({
            valor: "Seleccionar opción"
        });
        setTipoSubclasificacionEleccionHook({
            tipo: -2
        });      
    };
    const handleTieneEmblema = event => {
        tieneEmblemaHook.tiene = event.target.value;
    };
    const handleInputEspAne = index => e => {
        consultaHook.posts.especificacionesTecnicas[index].nombre = e.target.value;
    }
    const handleInputEspDis = index => e => {
        consultaHook.posts.especificacionesDisenio[index].nombre = e.target.value;
    }
    const handleInputJusti = event => {
        justificacionHook.justificacion = event.target.value;
    }

    return (
        <div className="div_modificararticulo">
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null
            }         
            {!consultaHook.cargada ? (<Spin size="large" />) : (
                <Form> 
                    <div className="div_titulo">
                        <Button id="regresar"
                            type="link"
                            href="/configuracion/consultarcatalogos">
                            <ArrowLeftOutlined />Regresar
                        </Button>
                    </div>
                    <div className="div_nombrearticulo">                        
                        <Input placeholder="Ingresa el nombre del artículo"
                            defaultValue={nombreArticuloHook.nombre}
                            onChange={handleInputNombreArticulo} />                        
                    </div>
                    <div>
                        <span id="smsg">*</span> Tipo de artículo a modificar
                    </div>
                    <div className="div_nombrearticulo">
                        <Radio.Group name="radiogroup"
                            defaultValue={tipoArticuloHook.tipo}
                            onChange={handleTipoArticulo}>
                            <Radio value={1}>Material</Radio>
                            <Radio value={2}>Documento</Radio>
                        </Radio.Group>
                    </div>
                    {tipoArticuloHook.tipo === 0 ? (
                        <div>
                            {console.log("Entró en el 1er. if")}    
                        </div>
                    ) : ( tipoArticuloHook.tipo === 1 ? (
                        <div className="div_nombrearticulo">
                            <div>
                                <span id="smsg">*</span> Clasificación del documento o material
                            </div>
                            <div>
                                <div>
                                    <SelectDocumentos                                    
                                        onChange={handleSelect}
                                        data={dataSelectState.dataSelect}                                        
                                        rutaWS={urlTiposClasi.url}                                        
                                    />
                                </div>
                            </div>                            
                        </div>
                    ) : (
                        <div className="div_nombrearticulo">
                            <div>
                                <span id="smsg">*</span> Clasificación del documento o material
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <SelectDocumentos                                    
                                            onChange={handleSelect}
                                            data={dataSelectState.dataSelect}                                   
                                            rutaWS={urlTiposClasi.url}                                           
                                        />
                                    </div>                                    
                                </div>
                            </div>                            
                        </div>
                    ) )}        
                    <div className="div_nombrearticulo">
                            <div>
                                <span id="smsg">*</span> Subclasificación del documento o material
                            </div>
                            {tipoArticuloHook.tipo === 1 ? (
                                <div>                                
                                    <Select   
                                        defaultValue={valorDefectoSubclasiHook.valor.toString()}                                       
                                        placeholder="Seleccionar opción"                       
                                        onChange={handleSelectSubClasi}                                                                            
                                    >
                                        {Object.entries(tiposSubclasificacionHook.datos).map((value, index) => {
                                            return <Option key={value[0]} value={value[0]}> {value[1]} </Option>;
                                        })}
                                    </Select>                                 
                                </div>
                            ) : (
                                <div> 
                                    <div>
                                        <Select   
                                            defaultValue={valorDefectoSubclasiHook.valor.toString()}                                            
                                            placeholder="Seleccionar opción"                       
                                            onChange={handleSelectSubClasi}                                                                            
                                        >
                                            {Object.entries(tiposSubclasificacionHook.datos).map((value, index) => {
                                                return <Option key={value[0]} value={value[0]}> {value[1]} </Option>;
                                            })}
                                        </Select>
                                    </div>                                                                      
                                </div>
                            )}
                                                        
                        </div>
                    <div>
                        <span id="smsg">*</span> &iquest;Tiene emblema el documento o material?
                    </div>
                    <div className="div_nombrearticulo">
                        <Radio.Group name="radiogroup"
                            defaultValue={tieneEmblemaHook.tiene}
                            onChange={handleTieneEmblema}>
                            <Radio value={1}>Sí</Radio>
                            <Radio value={0}>No</Radio>
                        </Radio.Group>
                    </div>
                    <div>
                        {consultaHook.url !== null ? (
                            <Worker                               
                                workerUrl={window.location.origin + "/pdf.worker.min.js"}
                            >
                                <div style={{ height: '750px', width: '900px' }}>
                                    <Viewer fileUrl={consultaHook.url} />
                                </div>
                            </Worker>
                        ) :
                            (
                                <div
                                    style={{
                                        alignItems: 'center',
                                        border: '2px dashed rgba(0, 0, 0, .3)',
                                        display: 'flex',
                                        fontSize: '2rem',
                                        height: '100%',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                </div>
                            )
                        }
                    </div>
                    <div className="div_diseniounico">
                        <div className="div_boton_agregar">
                            <Upload id="subirDisUni"
                                {...propsDisenioUnico}
                                accept=".pdf">
                                <Button className="subir">
                                    <UploadOutlined />Reemplazar
                                </Button>
                            </Upload>
                        </div>
                    </div>

                    <div className="div_especificaciones">
                        
                        <Form className="div_especificaciones_form" onSubmit={crearEspDis}> 
                            <div className="div_especificaciones_col">
                                <div className="div_titulo_especificaciones">
                                    <div className="div_encabezado_especificaciones">
                                        <span id="smsg">*</span> Especificaciones Anexo 4.1
                                    </div>
                                    <div className="div_agregar_especificaciones">
                                        <button type="submit" className="agregar"> Agregar </button>
                                    </div>                                
                                </div>
                                <FormItem className="div_esp">
                                    {consultaHook.posts.especificacionesTecnicas.map((item, index) => (                                    
                                        <div key={"div-esptec-" + item.id}>
                                            <Input style={{width: '90%'}} key={"esptec-" + item.id}
                                            placeholder="Agrega una especificación"
                                            defaultValue={item.nombre}
                                            onChange={handleInputEspAne(index)}                                    
                                            className="campoespecificacion" />
                                            <Button style={{width: '45px'}} key={"button-esptec-" + item.id}
                                            type="link"
                                            onClick={() => handleEliminarEsp(item, index)}> <MinusCircleOutlined /> </Button> 
                                            
                                        </div>                                                                                                            
                                    ))}                                
                                </FormItem>                            
                            </div>
                        </Form>
                        
                        <Form className="div_especificaciones_form" onSubmit={crearEspTec}> 
                            <div className="div_especificaciones_col">
                                <div className="div_titulo_especificaciones">
                                    <div className="div_encabezado_especificaciones">
                                        <span id="smsg">*</span> Especificaciones Únicas
                                    </div>
                                    <div className="div_agregar_especificaciones">
                                        <button type="submit" className="agregar"> Agregar </button>
                                    </div>
                                </div>
                                <FormItem className="div_esp">
                                    {consultaHook.posts.especificacionesDisenio.map((item, index) => (
                                        <div key={"div-espdis-" + item.id}>
                                            <Input style={{width: '90%'}} key={"espdis-" + item.id} 
                                            placeholder="Agrega una especificación"
                                            defaultValue={item.nombre}                                  
                                            onChange={handleInputEspDis(index)}
                                            className="campoespecificacion" />
                                            <Button style={{width: '45px'}} key={"button-espdis-" + item.id}
                                            type="link" 
                                            onClick={() => handleEliminarEsp(item, index)}> <MinusCircleOutlined /> </Button> 
                                        </div>                                    
                                    ))}
                                </FormItem>
                            </div>   
                        </Form>                                             
                    </div>
                    <div className="div_justificacion">
                        <div className="justificacion">
                            Justificación 
                        </div>
                        <div>
                            <FormItem>
                                <TextArea rows={3}
                                    defaultValue={justificacionHook.justificacion}
                                    placeholder="Justifica la captura de un nuevo documento o material" 
                                    onChange={handleInputJusti} 
                                    className="justificacion" />                                        
                            </FormItem>
                        </div>
                    </div>

                    <div className="div_botones_finales">
                        <Button className="cancelarc"
                            type="link"                     
                            href="/configuracion/consultarcatalogos"> Cancelar </Button>
                        <Button type="primary"
                            className="guardarc"
                            disabled={!consultaHook.posts.enableModificar}                
                            onClick={handleGuardar}> Guardar cambios </Button>
                    </div>
                </Form>
            )}
        </div>
    );

    function crearEspDis(e) {
        console.log("Entró en crearEspDis()");
        e.preventDefault(); 
        setIndexEsp(indexEsp - 1); 
        consultaHook.posts.especificacionesTecnicas.push({id:indexEsp, consecutivo:null, nombre:"", tipoEspecificacion:2});
    }

    function crearEspTec(e) {
        console.log("Entró en crearEspTec()");
        e.preventDefault();       
        setIndexEsp(indexEsp - 1);  
        consultaHook.posts.especificacionesDisenio.push({id:indexEsp, consecutivo:null, nombre:"", tipoEspecificacion:1});        
    }

    function handleEliminarEsp(value, index) {
        listaEspEliminadas.especificaciones.set(value.id,value.id)
        if (value.tipoEspecificacion === 1) {
            consultaHook.posts.especificacionesDisenio.splice(index, 1);        
        }
        else {     
            consultaHook.posts.especificacionesTecnicas.splice(index, 1);            
        }

        setCount(count+1);
    }

    function handleSelect(value) {
        let data = JSON.stringify({
            consultaParametros: {
                idProceso: idProcesoSeleccionadoState.idProcesoSeleccionado,
                idEstado: idEstadoSeleccionadoState.idEstadoSeleccionado,
                idDetalleProceso: idDetalleProcesoSeleccionadoState.idDetalleProcesoSeleccionado
            },
            tipoClasificacionEleccion: value
        });
        tipoClasificacionEleccionHook.tipo = value;
    }

    function handleSelectSubClasi(value) {
        console.log("value en handleSelectSubClasi(): ", value);
        tipoSubclasificacionEleccionHook.tipo = value;
    }

    function is_numeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    function handleGuardar() {
        console.log("---tipoArticuloHook.tipo: ", tipoArticuloHook.tipo);
        console.log("---tipoSubclasificacionEleccionHook.tipo: ", tipoSubclasificacionEleccionHook.tipo);        
        /*if (is_numeric(tipoSubclasificacionEleccionHook.tipo)) {
            console.log("---El tipoSubclasificacionEleccionHook.tipo es un número");
        }*/       
       
        if (nombreArticuloHook.nombre !== null && nombreArticuloHook.nombre !== "" &&
            archivoDisenioUnico.file !== null && archivoDisenioUnico.file!== "" &&
            justificacionHook.justificacion !== null && //justificacionHook.justificacion !== "" &&
            tipoClasificacionEleccionHook.tipo !== 0 && tipoSubclasificacionEleccionHook.tipo !== -2){
            //console.log("tipoArticuloHook.tipo: ", tipoArticuloHook.tipo);
            //console.log("tieneEmblemaHook.tiene: ", tieneEmblemaHook.tiene);
            if ((tipoArticuloHook.tipo === 2 && (tieneEmblemaHook.tiene === 1 || tieneEmblemaHook.tiene === 0)) || 
            tipoArticuloHook.tipo === 1){
                if ((tipoArticuloHook.tipo == 2 && tipoClasificacionEleccionHook.tipo < 8) || 
                    (tipoArticuloHook.tipo == 1 && tipoClasificacionEleccionHook.tipo == 8)) {
                    if ((tipoArticuloHook.tipo == 2 && tipoSubclasificacionEleccionHook.tipo > -1) ||
                    (tipoArticuloHook.tipo == 1 && tipoSubclasificacionEleccionHook.tipo == -1)) {
                        const formData = new FormData();
                        formData.append("idDetalleProceso", localStorage.getItem('ID_DETALLE_PROCESO'));
                        formData.append("idProcesoElectoral", localStorage.getItem('ID_PROCESO_ELECTORAL'));
                        if (localStorage.getItem('ID_ESTADO_SELECCIONADO') !== null) {                    
                            formData.append("idEstado", localStorage.getItem('ID_ESTADO_SELECCIONADO'));                                        
                        }    
                        else {         
                            formData.append("idEstado", localStorage.getItem('ID_ESTADO'));
                        } 
                        formData.append("anio", localStorage.getItem('ANIO'));
                        formData.append("tipoProceso", localStorage.getItem('TIPO_PROCESO'));

                        //console.log("nombreArticuloHook.nombre: ", nombreArticuloHook.nombre);
                        let listaEspTecRequest = [];

                        consultaHook.posts.especificacionesTecnicas.map((esp) => 
                            listaEspTecRequest = [...listaEspTecRequest, esp.nombre.split(',').join("%coma%")]
                        );               
                        //console.log("listaEspTecRequest: ", listaEspTecRequest);

                        let listaEspDisRequest = [];

                        consultaHook.posts.especificacionesDisenio.map((esp) => 
                            listaEspDisRequest = [...listaEspDisRequest, esp.nombre.split(',').join("%coma%")]
                        );
                        //console.log("listaEspDisRequest: ", listaEspDisRequest);
                        
                        let listaEspEliminadasRequest = [];     
                        
                        [...listaEspEliminadas.especificaciones.keys()].map(k => (
                            listaEspEliminadasRequest = [...listaEspEliminadasRequest, listaEspEliminadas.especificaciones.get(k)]
                        ))          
                        //console.log("listaEspEliminadasRequest: ", listaEspEliminadasRequest);                                
                        console.log("---tipoSubclasificacionEleccionHook.tipo: ", tipoSubclasificacionEleccionHook.tipo);

                        formData.append("idArticulo", idArticuloHook.idArticulo);
                        formData.append("listaEspTec", listaEspTecRequest);
                        formData.append("listaEspDis", listaEspDisRequest);
                        formData.append("listaEspEli", listaEspEliminadasRequest);
                        formData.append("nombreArticulo", nombreArticuloHook.nombre);
                        formData.append("tipoArticulo", tipoArticuloHook.tipo);
                        formData.append("tipoClasificacionEleccion", tipoClasificacionEleccionHook.tipo);
                        formData.append("tipoSubclasificacionEleccion", tipoSubclasificacionEleccionHook.tipo);
                        formData.append("tieneEmblema", tieneEmblemaHook.tiene);
                        formData.append("archivos[0].archivo", archivoDisenioUnico.file);
                        formData.append("justificacion", justificacionHook.justificacion);
                        
                        axios.post(urlModificaArticulo, formData,
                            {
                                headers: {
                                    'Authorization': localStorage.getItem('accessToken'),
                                    "Content-Type": "multipart/form-data",
                                    Accept: "application/json"
                                }
                            }).then(response => {
                                if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                                    /* setVisibleAlert(
                                        {
                                            activado: true,
                                            tipoMensaje: "error",
                                            mensaje: response.data.entity.causa
                                        }); */
                                        message.error(response.data.entity.causa,tiempoMensaje);
                                }
                                else if (response.data.entity.code === 200) {
                                    if (response.data.entity.message !== null && response.data.entity.message !== "") {
                                        /* setVisibleAlert(
                                            {
                                                activado: true,
                                                tipoMensaje: "warning",
                                                mensaje: response.data.entity.message
                                            }); */
                                            message.warning(response.data.entity.message,tiempoMensaje);
                                    }
                                    else {
                                        console.log("Fue correcta la actualización");
                                        message.success("Se modificó correctamente el artículo",tiempoMensaje);
                                        history.push('/configuracion/consultarcatalogos');
                                    }
                                }
                                else {
                                    localStorage.clear();
                                    window.location = "/login"
                                }
                            }).catch(error => {
                                console.log("Error:=>: doRequestOptions", error)
                                if (error.response.status === 403) {
                                    localStorage.clear();
                                    window.location = "/login"
                                    return;
                                }
                                /* setVisibleAlert(
                                    {
                                        activado: true,
                                        tipoMensaje: "error",
                                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                                    }); */
                                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
                            });
                    }
                    else {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: "La subclasificación elegida es inválida para este tipo de artículo"
                            }); */
                            message.error("La subclasificación elegida es inválida para este tipo de artículo.",tiempoMensaje);
                    } 
                }
                else {
                    /* setVisibleAlert(
                        {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: "La clasificación elegida es inválida para este tipo de artículo"
                        }); */
                        message.error("La clasificación elegida es inválida para este tipo de artículo.",tiempoMensaje);
                }                               
            }
            else {
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Al ser un documento se debe seleccionar si tiene emblema o no"
                    }); */
                    message.error("Al ser un documento se debe seleccionar si tiene emblema o no.",tiempoMensaje);
            }
        }  
        else {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "No se ha ingresado todos los campos requeridos"
                }); */
                message.error("No se han ingresado todos los campos requeridos.",tiempoMensaje);
        }     
    }

}