import React from "react";
import './ReporteAprobacion.scss';
import { List, Col, Row, Skeleton} from "antd";
import { Redirect } from 'react-router';



const headerAcuerdoPrincipal =     [
                                    {
                                      tag:"Acuerdo de aprobación de los documentos electorales",  
                                      width:24,
                                      style:"encabezado_acuerdo"
                                    }
                                   ];
const headerAcuerdosSecundarios =  [
                                    {  
                                      tag:"En caso que exista otro(s) acuerdo(s) relacionado(s)", 
                                      width:24,
                                      style:"encabezado_otro_acuerdo"
                                    }
                                   ];
                    

const subHeaders =   [  
                      {tag: "Número de acuerdo",   field: "numeroAcuerdo",   width:9, style:"encabezado_lista"},
                      {tag: "Nombre de acuerdo",   field: "nombreAcuerdo",   width:9, style:"encabezado_lista"},
                      {tag: "Fecha de aprobación", field: "fechaAprobacion", width:9, style:"encabezado_lista"},
                      {tag: "Observaciones",       field: "observaciones",   width:9, style:"encabezado_lista"},
                     ];
                        
const subHeadersOc = [
                      {tag: "Entidad",             field: "nombreEstado",    width:3, style:"encabezado_lista"},
                      {tag: "Número de acuerdo",   field: "numeroAcuerdo",   width:9, style:"encabezado_lista"},
                      {tag: "Nombre de acuerdo",   field: "nombreAcuerdo",   width:9, style:"encabezado_lista"},
                      {tag: "Fecha de aprobación", field: "fechaAprobacion", width:9, style:"encabezado_lista"},
                      {tag: "Observaciones",       field: "observaciones",   width:9, style:"encabezado_lista"},
                    ];
                         

class ReporteAprobacionDocumentos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
       
            redirect: false,
   
        };

    }

    componentDidMount() {


    }

    buildHeaders = (structure) =>{

        let headers=[];
    
        for(var i=0;i<structure.length;i++){
          
            headers.push(
                <Col xs={structure[i].width} className={structure[i].style} key={i}>
                {structure[i].tag}                
               </Col>
            )
        }
        return headers;
    };


    generateTableData = (data,structure) =>{
        let tableData=[];
        const mapa = new Map(Object.entries(data));
        for(var i =0; i < structure.length; i++){
            tableData.push(
                <Row className="registro_lista" key={i}>
                    <Col xs={3} className={structure[i].style}>
                         {structure[i].tag}
                    </Col>
                    <Col xs={structure[i].width} className="cuerpo_lista">
                        {mapa.get(structure[i].field)}
                    </Col>
                </Row>
            )
        }
        return tableData;
    };


    printList = (headers,paginationValue,loading,data,subheaders) =>{
        return(
            <List
                                header={
                                <Row>
                                    {this.buildHeaders(headers)}
                                </Row>
                                }
                                pagination={paginationValue}
                                size="large"
                                loading={loading}
                                itemLayout="horizontal"
                                dataSource={data}
                                renderItem={item =>
                                    <List.Item className="list-aprobacion">
                                        <Skeleton title={false} loading={loading} >
                                            <List.Item.Meta
                                                description={
                                                    <Row>
                                                        {this.generateTableData(item,subheaders)}
                                                    </Row>
                                                }
                                            />
                                        </Skeleton>
                                    </List.Item>
                                }
                            />
        );
    }

    render() {

        if (this.state.redirect) {
            return <Redirect push to="/reportes" />;
        }

        
        return (

            <div>
          
                <br />
                {this.printList(headerAcuerdoPrincipal,
                                false,
                                this.props.isLoading,
                                this.props.documentos.slice(0,1) || [],
                                this.props.isOc==true?subHeadersOc:subHeaders
                                )}

                {this.printList(headerAcuerdosSecundarios,
                                {pageSize: 1,},
                                this.props.isLoading,
                                this.props.documentos.slice(1,this.props.documentos.length) || [],
                                this.props.isOc==true?subHeadersOc:subHeaders
                                )}
               
                </div>
        );
    
}
}

export default ReporteAprobacionDocumentos;