//import { basePath, apiVersion } from "./config";
import { basePath} from "./config";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../utils/constanst";
import axios from "axios";
import jwtDecode from "jwt-decode";
//Obtiene el token que se almacena en el local storage
export function getAccessTokenApi() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);//Se verifica que el token exista en el localstorage

  if (!accessToken || accessToken === "null") {
    return null;
  }

  return willExpireToken(accessToken) ? null : accessToken;
}
//Función que verifa el token de refresToken para tambien actualizarlo(Ejemplo)
export function getRefreshTokenApi() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (!refreshToken || refreshToken === "null") {
    return null;
  }

  return willExpireToken(refreshToken) ? null : refreshToken;
}

//Función que vuelve a solicitar el token 
export function refreshAccessTokenApi(refreshToken) {
  /*const url = `${basePath}/${apiVersion}/refresh-access-token`;
  const bodyObj = {
    refreshToken: refreshToken
  };
  const params = {
    method: "POST",
    body: JSON.stringify(bodyObj),
    headers: {
      "Content-Type": "application/json"
    }
  };

  fetch(url, params)
    .then(response => {
      if (response.status !== 200) {
        return null;
      }
      return response.json();
    })
    .then(result => {
      if (!result) {
        logout();
      } else {
        const { accessToken, refreshToken } = result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
      }
    });*/
}
//Elimina el token del navegador
export function logout() {
  const url = `${basePath}/cierraSesion`;
  const jwt = localStorage.getItem(ACCESS_TOKEN);  
  localStorage.removeItem('ACTIVAR_SIDER');
  localStorage.removeItem('LISTA_DETALLES');
  localStorage.removeItem('VERSION_USUARIO');
  localStorage.removeItem('DESCRIPCION');
  localStorage.removeItem('DISTRITO');
  localStorage.removeItem('AMBITO');
  localStorage.removeItem('ID_SISTEMA');
  localStorage.removeItem('ESTADO');
  localStorage.removeItem('ROL_USUARIO');
  localStorage.removeItem('ID_ESTADO_SELECCIONADO');
  localStorage.removeItem('ID_PROCESO_ELECTORAL');
  localStorage.removeItem('ID_ESTADO');
  localStorage.removeItem('INFO_MENU');
  localStorage.removeItem('ID_MUNICIPIO');
  localStorage.removeItem('ID_DETALLE_PROCESO');
  localStorage.removeItem('ID_DISTRITO');
  localStorage.removeItem('ANIO');
  localStorage.removeItem('TIPO_PROCESO');
  localStorage.removeItem('menu');
  localStorage.removeItem("ID_ARTICULO_SEG");
  localStorage.removeItem("ID_TIPO_DOC_SEG");
  localStorage.removeItem("ID_APROBACION_SEG");
  localStorage.removeItem("NOMBRE_ARTICULO_SEG");
  localStorage.removeItem('ID_ADJUDICACION_EMPRESA_SEG');
  if (jwt) {
        axios.post(url, {},
        {
          headers: { Authorization: jwt}
        }).finally(() => {
          localStorage.removeItem(ACCESS_TOKEN); 
          
        });
    }
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);  
}
//Verifica que no haya expirado el token 
function willExpireToken(token) {
  const seconds = 60;
  const metaToken = jwtDecode(token);//decodifica el token 
  const { exp } = metaToken;//Se obtiene el tiempo de vida del token
  const now = (Date.now() + seconds) / 1000;
  return now > exp;//verifica que no hay expirado
}

