import React, { useState, useEffect } from "react";
import { Cascader, Tabs, Button } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import TablaReporteAjudicacion from "./TablaReporteAdjudicacion";
import axios from "axios";
import { basePath } from "../../../api/config";
import useAuth from "../../../hooks/useAuth";
import { base64ToArrayBuffer, saveByteArray } from "../../../utils/Utils"
import './ReporteAdjudicacion.css';

export default function ReporteAdjudicacion(props) {

    const urlConsultCatalogoDocumentos = `${basePath}/consultaCatalogoDocumentos`;
    const urlConsultaTipoDocumentacionTodosLosDocumentos = `${basePath}/consultaTipoDocumentacionTodosLosDocumentos`;
    const urlDescargaReporteAdjudicacion = `${basePath}/descargaReporteAdjudicacion`;
    const tipoArticuloDocumentos = 2;
    const tipoArticuloMateriales = 1;

    const { user } = useAuth();

    const { TabPane } = Tabs;

    const [optionsTiposDocumentacion, setOptionsTiposDocumentacion] = useState({
        loading: true,
        data: [],
        tipoArticuloMateriales: tipoArticuloMateriales,
        tipoArticuloDocumentos: tipoArticuloDocumentos,
    });

    const [reporteAdjudicacion, setReporteAdjudicacion] = useState({
        loadingDocumentos: false,
        loadingMateriales: true,
    });

    const [selection, setSelection] = useState([]);

    const [selectionValue, setSelectionValue] = useState([]);

    const [tabActivo, setTabActivo] = useState(optionsTiposDocumentacion.tipoArticuloDocumentos);

    useEffect(() => {
        if (optionsTiposDocumentacion.loading)
            doRequestCatalogoDocumentos();
    }, [optionsTiposDocumentacion])

    function changeTab(key) {
        console.log("key:=>", key);
        if (Number(key) === optionsTiposDocumentacion.tipoArticuloDocumentos) {
            setReporteAdjudicacion({
                loadingDocumentos: true,
                loadingMateriales: false,
            });
        }
        else {
            setReporteAdjudicacion({
                loadingDocumentos: false,
                loadingMateriales: true,
            });
        }
        setTabActivo(Number(key));
    }

    function onChangeFiltro(value, seleccion) {
        setSelection(seleccion);
        setSelectionValue(value);
    }

    function clickDownloadExcel() {
        doRequestDownloadExcel();
    }

    function doRequestCatalogoDocumentos() {
        let idEstado = user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado;
        let data = JSON.stringify({
            idProcesoElectoral: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
            idEstado: idEstado,
            idCorte: user.infoMenu.detalleSelec.corte,
        })
        axios
            .post(
                urlConsultCatalogoDocumentos, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /*setVisibleAlert(
                          {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: response.data.entity.causa
                          });*/
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.causa !== null && response.data.entity.causa !== "") {
                            /*setVisibleAlert(
                              {
                                activado: true,
                                tipoMensaje: "warning",
                                mensaje: response.data.entity.message
                              });*/
                        }
                        else {
                            let options = [];
                            response.data.entity.listaTipoDocumentacion.map((item, index) => {
                                options = [...options, { value: item.idTipoDocumentacion, label: item.nombreDocumentacion }]
                            })
                            setOptionsTiposDocumentacion({
                                data: options,
                                loading: false,
                                tipoArticuloMateriales: response.data.entity.tipoArticulos["Material"],
                                tipoArticuloDocumentos: response.data.entity.tipoArticulos["Documento"],
                            });
                            doRequestComplementoCatalogoDocumentos(options);
                        }
                    }
                    else {
                        localStorage.clear();
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                setOptionsTiposDocumentacion({
                    data: [],
                    loading: false,
                    tipoArticuloMateriales: tipoArticuloMateriales,
                    tipoArticuloDocumentos: tipoArticuloDocumentos,
                });
            });
    }

    function doRequestComplementoCatalogoDocumentos(options) {
        let idEstado = user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado;
        let data = JSON.stringify({
            idProcesoElectoral: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
            idEstado: idEstado,
            idCorte: user.infoMenu.detalleSelec.corte,
        })
        axios
            .post(
                urlConsultaTipoDocumentacionTodosLosDocumentos, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /*setVisibleAlert(
                          {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: response.data.entity.causa
                          });*/
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.causa !== null && response.data.entity.causa !== "") {
                            /*setVisibleAlert(
                              {
                                activado: true,
                                tipoMensaje: "warning",
                                mensaje: response.data.entity.message
                              });*/
                        }
                        else {
                            response.data.entity.listaTipoDocumentacion.map((item, index) => {
                                options = [{ value: item.idTipoDocumentacion, label: item.nombreDocumentacion }, ...options]
                            })
                            setOptionsTiposDocumentacion({
                                data: options,
                                loading: false,
                                tipoArticuloMateriales: optionsTiposDocumentacion.tipoArticuloMateriales,
                                tipoArticuloDocumentos: optionsTiposDocumentacion.tipoArticuloDocumentos,
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
            });
    }

    function doRequestDownloadExcel() {
        let idEstado = user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado;
        let nombreEstado = user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.nombreEstado : "";
        let data = JSON.stringify({
            idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
            idEstado: idEstado,
            idCorte: user.infoMenu.detalleSelec.corte,
            nombreEstado: nombreEstado,
            tipoDocumentacion: selection.length > 0 ? selection[0].label : "",
            idTipoDocumentacion: selection.length > 0 ? selection[0].value : "",
        })
        axios
            .post(
                urlDescargaReporteAdjudicacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /*setVisibleAlert(
                          {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: response.data.entity.causa
                          });*/
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.causa !== null && response.data.entity.causa !== "") {
                            /*setVisibleAlert(
                              {
                                activado: true,
                                tipoMensaje: "warning",
                                mensaje: response.data.entity.message
                              });*/
                        }
                        else {
                            var sampleArr = base64ToArrayBuffer(response.data.entity.contentFileReporteAdjudicacion);
                            saveByteArray("reporteAdjudicacion.xlsx", sampleArr);
                        }
                    }
                    else {
                        localStorage.clear();
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
            });
    }

    return (
        <div>
            <h1 className="titulo-reportes">Adjudicación de la documentación y materiales electorales del OPL</h1>
            <label className="texto-12-reportes">Los datos con ( <label className="rojo-reportes">*</label> ) son requeridos.</label>
            <br />
            <br />
            {tabActivo === optionsTiposDocumentacion.tipoArticuloDocumentos &&
                <label className="texto-18-reportes"><label className="rojo-reportes">*</label> Tipo de clasificación de elección</label>}
            {tabActivo === optionsTiposDocumentacion.tipoArticuloDocumentos &&
                <br />}
            {tabActivo === optionsTiposDocumentacion.tipoArticuloDocumentos &&
                <Cascader style={{ width: 390 }} options={optionsTiposDocumentacion.data}
                    onChange={onChangeFiltro}
                    value={selectionValue}
                    placeholder="Seleccione una opción" />
            }
            {!optionsTiposDocumentacion.loading ?
                (
                    < div >
                        <Tabs defaultActiveKey={`${optionsTiposDocumentacion.tipoArticuloDocumentos}`} onChange={changeTab}>
                            <TabPane tab="Documentos" key={optionsTiposDocumentacion.tipoArticuloDocumentos.toString()} >
                                <div className="subreporte">
                                    <TablaReporteAjudicacion
                                        tipoArticulo={optionsTiposDocumentacion.tipoArticuloDocumentos}
                                        idTipoDocumentacion={selectionValue.length > 0 ? selectionValue[0] : -1}
                                        tipoArticuloDocumentos={optionsTiposDocumentacion.tipoArticuloDocumentos}
                                        loading={reporteAdjudicacion.loadingDocumentos && selectionValue.length > 0}
                                    />
                                </div>
                            </TabPane>

                            <TabPane tab="Materiales" key={optionsTiposDocumentacion.tipoArticuloMateriales.toString()} >
                                <div className="subreporte">
                                    <TablaReporteAjudicacion
                                        tipoArticulo={optionsTiposDocumentacion.tipoArticuloMateriales}
                                        idTipoDocumentacion={0}
                                        tipoArticuloDocumentos={optionsTiposDocumentacion.tipoArticuloDocumentos}
                                        loading={reporteAdjudicacion.loadingMateriales}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                        <div className="div_boton_descarga">
                            <Button disabled={!(selectionValue.length > 0)}
                                onClick={clickDownloadExcel}
                                className="descarga"
                            >
                                <VerticalAlignBottomOutlined /> Descargar
                            </Button>
                        </div>
                    </div>
                ) : null
            }

        </div >
    );

}