//export const basePath = "https://localhost:8444/docs_ws_central/ws";//url del servidor local
//export const basePath = "https://pru-pef21-docsymatopl-deoe.ine.mx/docs_ws_central/ws";//url del servidor balanceador PRUEBAS del central 
//export const basePath = "https://cap-pef21-docsymatopl-deoe.ine.mx/docs_ws_central/ws"; // url del servidor balanceador CAPACITA del central
//export const basePath = "https://seg-pef21-docsymatopl-deoe.ine.mx/docs_ws_central/ws"; // url del servidor balanceador seguridad del central
export const basePath = "https://pef2021-docsymatoplcentral-ws.ine.mx/docs_ws_central/ws"; // url del servidor balanceador PRODUCCION del central
//export const basePath = "https://10.0.38.106:8443/docs_ws_central/ws"; // url del servidor balanceador PRODUCCION del central
//export const pathSubirCat = "https://10.35.12.102:8443/docs_ws_config/ws";//url del servidor para cargas
//export const pathSeguimiento = "https://localhost:8445/docs_ws_seguimiento/ws";//url del servidor
//export const pathSeguimiento = "https://pru-pef21-wsdocsymatopl-deoe.ine.mx/docs_ws_seguimiento/ws";//url del servidor pruebas
//export const pathSeguimiento = "https://cap-pef21-wsdocsymatopl-deoe.ine.mx/docs_ws_seguimiento/ws";//url del servidor CAPACITACION
//export const pathSeguimiento = "https://seg-pef21-wsdocsymatopl-deoe.ine.mx/docs_ws_seguimiento/ws";//url del servidor  SEGURIDAD
export const pathSeguimiento = "https://pef2021-docsymatopl-ws.ine.mx/docs_ws_seguimiento/ws";//url del servidor  PRODUCCION