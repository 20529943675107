import React from "react";

import ConsultarVerificaciones from "../../../components/ConsultarVerificaciones/ConsultarVerificaciones"; //Se importa el componente para los catálogos

export default function ConsultarVerificacionesPrincipal(props) {

    return (
        <ConsultarVerificaciones />
    );

}