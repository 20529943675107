import React from "react";
import CrearArticulo from "../../../components/CrearArticulo/CrearArticulo";

export default function CrearArticulos(props) {
    return (
        <div>
            {props.location.state !== undefined ?
                <CrearArticulo enableModificar={props.location.state.enableModificar} /> : <CrearArticulo enableModificar={false} />
            }
        </div>
    );
}