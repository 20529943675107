import React, { useState, useEffect, useCallback } from "react";
import { FormOutlined, CheckOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import {
    Form,
    Input,
    InputNumber,
    Select,
    DatePicker,
    TimePicker,
    Checkbox,
    Radio,
    Table,
    Popconfirm,
    Button,
    Divider
} from "antd";
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import "./TablaRegistroAdjudicaciones.scss";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

const getColumnSearchProps = dataIndex => ({
    filter: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <Input />
    )
})

const plColumns = [
    {
        /*title: (
            <div style={{ textAlign: 'center' }}>
                <div className="filtro">Empresa</div>
                {/*<div className="filtro"><Input style={{ width: '100%' }}/></div>}
            </div>
        ),*/
        title: "Empresa",
        dataIndex: "empresa",
        width: "300px",
        editable: true,
    },
    {
        title: "Tipo de adjudicación",
        dataIndex: "tipoAdjudicacion",
        width: "150px",
        editable: true
    },
    {
        title: "Fecha de adjudicación",
        dataIndex: "fechaAdjudicacion",
        width: "135px",
        editable: true
    },
    {
        title: "Monto adjudicado",
        dataIndex: "montosAdjudicados",
        width: "150px",
        editable: true,
        render: (text, record) => {
            return "$" + text.toLocaleString('en-US', { maximumFractionDigits: 3 });
        }
    },
    {
        title: "Cantidad a producir",
        dataIndex: "cantidadProducir",
        width: "150px",
        editable: true,
        render: (text, record) => {
            return text.toLocaleString('en-US', { maximumFractionDigits: 3 });
        }
    },
    {
        title: "Total",
        dataIndex: "total",
        width: "150px",
        editable: true,
        render: (text, record) => {
            return "$" + text.toLocaleString('en-US', { maximumFractionDigits: 3 });
        }
    },
    {
        title: "Fecha inicio prod",
        dataIndex: "fechaInicioProduccion",
        width: "135px",
        editable: true
    },
    {
        title: "Fecha fin prod",
        dataIndex: "fechaFinProduccion",
        width: "135px",
        editable: true
    },
    {
        title: "Observaciones",
        dataIndex: "observaciones",
        width: "400px",
        editable: true
    }
];

function EditableCell(
    props,
    optionsTipoAdjudicaciones,
    rowEditing,
    setRowEditing
) {
    const handleRangeNumber = (rule, value, callback) => {
        if (value && (value > rule.max || value < rule.min)) {
            callback(rule.message)
        }
        callback()
    }
    const dateFormat = "DD-MM-YYYY";
    const timeFormat = "HH:mm";
    const getInput = (record, dataIndex, title, getFieldDecorator, getFieldValue, setFieldsValue) => {
        switch (props.inputType) {
            case "float":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    type: 'float',
                                    message: `Campo ${title} no válido.`,
                                },
                                {
                                    required: true,
                                    message: `Ingrese el campo ${title}.`,
                                }
                            ],
                            initialValue: record[dataIndex]
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        )}
                    </FormItem>
                );
            case "integer":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    type: 'integer',
                                    message: `Campo ${title} no válido.`,
                                },
                                {
                                    required: true,
                                    message: `Ingrese el campo ${title}.`,
                                },
                            ],
                            initialValue: record[dataIndex]
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                placeholder="Ingresa"
                            />
                        )}
                    </FormItem>
                );
            case "number":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `Ingrese el campo ${title}.`,
                                }
                            ],
                            initialValue: record[dataIndex]
                        })(
                            <InputNumber formatter={value => value} parser={value => value} />
                        )}
                    </FormItem>
                );
            case "date":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: moment(record[dataIndex], dateFormat)
                        })(<DatePicker format={dateFormat} />)}
                    </FormItem>
                );
            case "time":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: moment(record[dataIndex], timeFormat)
                        })(<TimePicker format={timeFormat} />)}
                    </FormItem>
                );
            case "checkbox":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record[dataIndex] === "true",
                            valuePropName: "checked"
                        })(<Checkbox />)}
                    </FormItem>
                );

            case "radio":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record[dataIndex]
                        })(
                            <RadioGroup>
                                <Radio value={"a"}>a</Radio>
                                <Radio value={"b"}>b</Radio>
                            </RadioGroup>
                        )}
                    </FormItem>
                );
            case "select":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record[dataIndex]
                        })(
                            <Select style={{ width: 150 }}>
                                {[...Array(11).keys()]
                                    .filter(x => x > 0)
                                    .map(c => `Product ${c}`)
                                    .map((p, index) => (
                                        <Option value={p} key={index}>
                                            {p}
                                        </Option>
                                    ))}
                            </Select>
                        )}
                    </FormItem>
                );
            case "empresa":
                return (
                    <Form.Item name="empresa" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            //initialValue: record[dataIndex],
                            initialValue: rowEditing.empresa,
                            rules: [
                                {
                                    required: true,
                                    message: `Ingrese el campo ${title}.`,
                                }
                            ],
                        })(
                            <Input
                                placeholder="Ingresa nombre de la empresa"
                                disabled={record.conRegistroSemanal}
                                maxLength={524}
                            />
                        )}
                    </Form.Item>
                )
            case "tipoAdjudicacion":
                return (
                    <FormItem name="tipoAdjudicacion" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            //initialValue: record[dataIndex]
                            initialValue: rowEditing.tipoAdjudicacion,
                        })(
                            <Select
                                style={{ width: '100%' }}
                                disabled={record.conRegistroSemanal}
                            >
                                {optionsTipoAdjudicaciones.map((value, index) => {
                                    return <Select.Option key={value}>{value}</Select.Option>;
                                })}
                            </Select>
                        )}
                    </FormItem>
                );
            case "fechaAdjudicacion":
                return (
                    <FormItem name="fechaAdjudicacion" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            //initialValue: moment(record[dataIndex], dateFormat)
                            initialValue: moment(rowEditing.fechaAdjudicacion, dateFormat),
                            rules: [
                                {
                                    required: true,
                                    message: 'Seleccione una fecha de adjudicación.',
                                }
                            ],
                        })
                            (
                                <DatePicker
                                    format={dateFormat}
                                    disabled={record.conRegistroSemanal}
                                    disabledDate={current => {
                                        return current.startOf('day') > moment().endOf('day')
                                    }}
                                    onChange={value => {
                                        if (value !== null) {
                                            if (getFieldValue('fechaInicioProduccion') !== null && getFieldValue('fechaInicioProduccion') !== undefined
                                                && value.startOf('day') > getFieldValue('fechaInicioProduccion').endOf('day')) {
                                                setFieldsValue({
                                                    fechaInicioProduccion: null,
                                                    fechaFinProduccion: null,
                                                })
                                            }
                                        }
                                        else {
                                            setFieldsValue({
                                                fechaInicioProduccion: null,
                                                fechaFinProduccion: null,
                                            })
                                        }
                                    }}
                                />
                            )}
                    </FormItem>
                );
            case "fechaInicioProduccion":
                return (
                    <FormItem name="fechaInicioProduccion" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            //initialValue: moment(record[dataIndex], dateFormat)
                            initialValue: rowEditing.fechaInicioProduccion !== null ? moment(rowEditing.fechaInicioProduccion, dateFormat) : null,
                            rules: [
                                {
                                    required: true,
                                    message: 'Seleccione una fecha inicio de producción.',
                                }
                            ],
                        })
                            (
                                <DatePicker
                                    format={dateFormat}
                                    disabled={getFieldValue('fechaAdjudicacion') === null || record.conRegistroSemanal}
                                    disabledDate={current => {
                                        /*return (
                                            current.startOf('day').diff(moment().startOf('day'), 'days') < 0 ||
                                            (
                                                moment(rowEditing.fechaFin, dateFormat) !== null &&
                                                moment(rowEditing.fechaFin, dateFormat).startOf('day').diff(current.startOf('day'), 'days') < 1
                                            )
                                        )*/
                                        return getFieldValue('fechaAdjudicacion') !== null
                                            && current.startOf('day') < getFieldValue('fechaAdjudicacion').startOf('day')
                                            || moment(rowEditing.fechaFinProduccion, dateFormat) !== null && current.endOf('day') >= moment(rowEditing.fechaFinProduccion, dateFormat).endOf('day')
                                    }}
                                    onChange={value => {
                                        setRowEditing({
                                            empresa: getFieldValue('empresa'),
                                            tipoAdjudicacion: getFieldValue('tipoAdjudicacion'),
                                            fechaAdjudicacion: getFieldValue('fechaAdjudicacion'),
                                            montosAdjudicados: getFieldValue('montosAdjudicados'),
                                            cantidadProducir: getFieldValue('cantidadProducir'),
                                            total: getFieldValue('total'),
                                            observaciones: getFieldValue('observaciones'),
                                            fechaFinProduccion: rowEditing.fechaFinProduccion,
                                            fechaInicioProduccion: value !== null ? value.startOf('day').format(dateFormat) : null,
                                        });
                                    }}
                                />
                            )}
                    </FormItem>
                );
            case "fechaFinProduccion":
                return (
                    <FormItem name="fechaFinProduccion" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            //initialValue: moment(record[dataIndex], dateFormat)
                            initialValue: rowEditing.fechaFinProduccion !== null ? moment(rowEditing.fechaFinProduccion, dateFormat) : null,
                            rules: [
                                {
                                    required: true,
                                    message: 'Seleccione una fecha fin de producción.',
                                }
                            ],
                        })
                            (
                                <DatePicker
                                    format={dateFormat}
                                    disabled={getFieldValue('fechaInicioProduccion') === null}
                                    disabledDate={current => {
                                        /*return (
                                            current.startOf('day').diff(moment(rowEditing.fechaInicioProduccion, dateFormat), 'days') < 1
                                        )*/
                                        return (
                                            !record.conRegistroSemanal && getFieldValue('fechaInicioProduccion') !== null
                                            && current.startOf('day') < getFieldValue('fechaInicioProduccion').endOf('day')
                                            || record.conRegistroSemanal && current.startOf('day') < moment(record.fechaFinProduccion, dateFormat).endOf('day')
                                        )
                                    }}
                                    onChange={value => {
                                        setRowEditing({
                                            empresa: getFieldValue('empresa'),
                                            tipoAdjudicacion: getFieldValue('tipoAdjudicacion'),
                                            fechaAdjudicacion: getFieldValue('fechaAdjudicacion'),
                                            montosAdjudicados: getFieldValue('montosAdjudicados'),
                                            cantidadProducir: getFieldValue('cantidadProducir'),
                                            total: getFieldValue('total'),
                                            observaciones: getFieldValue('observaciones'),
                                            fechaFinProduccion: value !== null ? value.startOf('day').format(dateFormat) : null,
                                            fechaInicioProduccion: rowEditing.fechaInicioProduccion,
                                        });
                                    }}
                                />
                            )}
                    </FormItem>
                );
            case "montosAdjudicados":
                return (
                    <Form.Item name="montosAdjudicados" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    type: 'number',
                                    message: `Campo ${title} no válido.`,
                                },
                                {
                                    required: true,
                                    message: `Ingrese el campo ${title}.`,
                                }
                            ],
                            //initialValue: record[dataIndex]
                            initialValue: rowEditing.montosAdjudicados
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled={record.conRegistroSemanal}
                                min={0.001}
                                max={99999.999}
                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                precision={3}
                                onChange={
                                    value => {
                                        const cantidadProducir = getFieldValue('cantidadProducir');
                                        setFieldsValue({
                                            total: isNaN(cantidadProducir) ? value : value * cantidadProducir
                                        })
                                    }
                                }
                            />
                        )}
                    </Form.Item>
                );
            case "cantidadProducir":
                return (
                    <Form.Item name="cantidadProducir" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    type: 'integer',
                                    message: `Campo ${title} no válido.`,
                                },
                                {
                                    required: true,
                                    message: `Ingrese el campo ${title}.`,
                                }
                            ],
                            //initialValue: record[dataIndex]
                            initialValue: rowEditing.cantidadProducir
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled={record.conRegistroSemanal}
                                min={1}
                                max={999999999}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={
                                    value => {
                                        const montosAdjudicados = getFieldValue('montosAdjudicados');
                                        setFieldsValue({
                                            total: isNaN(montosAdjudicados) ? value : value * montosAdjudicados
                                        })
                                    }
                                }
                            />
                        )}
                    </Form.Item>
                );
            case "total":
                return (
                    <Form.Item name="total" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    type: 'number',
                                    message: `Campo ${title} no válido.`,
                                },
                                {
                                    required: true,
                                    message: `Ingrese el campo ${title}.`,
                                },
                                {
                                    validator: handleRangeNumber,
                                    message: 'Canitdad fuera del rango permitido.',
                                    min: 0.001,
                                    max: 999999999.999
                                }
                            ],
                            //initialValue: record[dataIndex]
                            initialValue: rowEditing.total
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0.001}
                                max={999999999.999}
                                disabled={true}
                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                precision={3}
                            />
                        )}
                    </Form.Item>
                );
            case "observaciones":
                return (
                    <Form.Item name="observaciones" style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            //initialValue: record[dataIndex]
                            initialValue: rowEditing.observaciones
                        })(
                            <Input.TextArea
                                placeholder="Ingresa"
                                rows={2}
                                autoSize={{ minRows: 2, maxRows: 2 }}
                                allowClear={true}
                                maxLength={1500}
                            />
                        )}
                    </Form.Item>
                );
            default:
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `Ingrese el campo ${title}.`,
                                }
                            ],
                            initialValue: record[dataIndex]
                        })(<Input />)}
                    </FormItem>
                );
        }
    };

    const {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        ...restProps
    } = props;
    return (
        <EditableContext.Consumer>
            {form => {
                const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
                return (
                    <td {...restProps}>
                        {editing
                            ?
                            getInput(record, dataIndex, title, getFieldDecorator, getFieldValue, setFieldsValue)
                            : restProps.children}
                    </td>
                );
            }}
        </EditableContext.Consumer>
    );
}

export default function TablaRegistroAdjudicaciones(props) {

    const [tableData, setTableData] = useState({
        data: [],
        loadingData: false,
        editingKey: ""
    });

    const dateFormat = "DD-MM-YYYY";
    const timeFormat = "HH:mm";

    const columns = [
        ...plColumns,
        {
            title: "",
            width: "100px",
            align: "center",
            dataIndex: "operation",
            render: (text, record) => {
                const editable = isEditing(record);
                return (
                    <div>
                        {editable ? (
                            <div>
                                <EditableContext.Consumer>
                                    {form => (
                                        <Tooltip title="Aceptar">
                                            <Button
                                                className="boton-edicion-confirmar"
                                                type="primary"
                                                shape="circle"
                                                accept=""
                                                disabled={!props.enableCaptura}
                                                onClick={() => save(form, record.key)}
                                            >
                                                <CheckOutlined />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </EditableContext.Consumer>
                                < Divider className="dcolor" type="vertical" />
                                <Popconfirm
                                    title="¿Está seguro que desea Cancelar?"
                                    okText="Continuar" cancelText="Cancelar"
                                    onConfirm={() => cancel(record.key)}
                                >
                                    <Tooltip title="Cancelar">
                                        <Button
                                            className="boton-edicion-cancelar"
                                            type="primary"
                                            shape="circle"
                                            accept=""
                                        >
                                            <CloseOutlined />
                                        </Button>
                                    </Tooltip>
                                </Popconfirm>
                            </div>
                        ) : (
                                <div>
                                    {props.enableEdit !== undefined && props.enableEdit ? (
                                        <Tooltip title="Modificar">
                                            <Button
                                                className="boton-accion-circular"
                                                type="primary"
                                                shape="circle"
                                                accept=""
                                                disabled={!props.enableCaptura}
                                                onClick={() => {
                                                    setRowEditing({
                                                        empresa: record.empresa,
                                                        tipoAdjudicacion: record.tipoAdjudicacion,
                                                        fechaAdjudicacion: record.fechaAdjudicacion,
                                                        montosAdjudicados: record.montosAdjudicados,
                                                        cantidadProducir: record.cantidadProducir,
                                                        total: record.total,
                                                        observaciones: record.observaciones,
                                                        fechaInicioProduccion: record.fechaInicioProduccion,
                                                        fechaFinProduccion: record.fechaFinProduccion,
                                                    });
                                                    edit(record.key);
                                                }}
                                            >
                                                <FormOutlined />
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                    {props.enableEdit !== undefined && props.enableEdit &&
                                        props.enableDelete !== undefined && props.enableDelete && !record.conRegistroSemanal ? (
                                            < Divider className="dcolor" type="vertical" />
                                        ) : null
                                    }
                                    {props.enableDelete !== undefined && props.enableDelete && !record.conRegistroSemanal ? (
                                        <Popconfirm
                                            title="¿Está seguro que desea eliminar este registro?"
                                            okText="Continuar" cancelText="Cancelar"
                                            onConfirm={() => remove(record.key)}
                                        >
                                            <Tooltip title="Eliminar">
                                                <Button
                                                    className="boton-accion-circular"
                                                    type="primary"
                                                    shape="circle"
                                                    accept=""
                                                    disabled={!props.enableCaptura}
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </Tooltip>
                                        </Popconfirm>
                                    ) : null}
                                </div>
                            )}
                    </div>
                );
            }
        }
    ];

    const isEditing = record => {
        return record.key === tableData.editingKey;
    };

    function edit(key) {
        setTableData({
            editingKey: key,
            loadingData: true,
            data: tableData.data
        })
    }

    function remove(key) {
        if (!props.enableModificar)
            return true;
        const newData = [...tableData.data];
        const index = newData.findIndex(item => key === item.key);
        let rowRemove = newData.splice(index, 1);
        props.handleRemoveData(newData, rowRemove);
    }

    function save(form, key) {
        if (!props.enableModificar)
            return true;
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...tableData.data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                // pick the proper record based on primary key
                const item = newData[index];
                // Bugfix: preprocess date,time,boolean values to render properly on save
                /*const dateFormatted = row.date.format("DD-MM-YYYY");
                const timeFormatted = row.time.format("HH:mm");
                const booleanToString = row.freeclick.toString();
                const campToEuro = `€ ${row.camp_cpc}`;
                row.date = dateFormatted;
                row.time = timeFormatted;
                row.freeclick = booleanToString;
                row.camp_cpc = campToEuro;*/
                const fechaAdjudicacionFormatted = row.fechaAdjudicacion.format("DD-MM-YYYY");
                const fechaInicioProduccionFormatted = row.fechaInicioProduccion.format("DD-MM-YYYY");
                const fechaFinProduccionFormatted = row.fechaFinProduccion.format("DD-MM-YYYY");

                row.fechaAdjudicacion = fechaAdjudicacionFormatted;
                row.fechaInicioProduccion = fechaInicioProduccionFormatted;
                row.fechaFinProduccion = fechaFinProduccionFormatted;

                /*newData.splice(index, 1, {
                    ...item,
                    ...row
                });*/
                props.handleSaveData(item, index, row);
            }
        });
    }

    const cancel = () => {
        setTableData({
            editingKey: "",
            loadingData: true,
            data: tableData.data
        })
    };

    const [rowEditing, setRowEditing] = useState({
        empresa: null,
        tipoAdjudicacion: null,
        fechaAdjudicacion: null,
        montosAdjudicados: null,
        cantidadProducir: null,
        total: null,
        observaciones: null,
        fechaInicioProduccion: null,
        fechaFinProduccion: null,
    });

    const components = {
        body: {
            row: EditableFormRow,
            //cell: EditableCell
            cell: (cellProps) => {
                return EditableCell(
                    cellProps,
                    props.optionsTipoAdjudicaciones,
                    rowEditing,
                    setRowEditing
                );
            },
        }
    };

    const columnsMap = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => {
                const checkInput = index => {
                    switch (index) {
                        case "empresa":
                            return "empresa";
                        case "tipoAdjudicacion":
                            return "tipoAdjudicacion";
                        case "fechaAdjudicacion":
                            return "fechaAdjudicacion";
                        case "montosAdjudicados":
                            return "montosAdjudicados";
                        case "cantidadProducir":
                            return "cantidadProducir";
                        case "total":
                            return "total";
                        case "fechaInicioProduccion":
                            return "fechaInicioProduccion";
                        case "fechaFinProduccion":
                            return "fechaFinProduccion";
                        case "observaciones":
                            return "observaciones";
                        default:
                            return "text";
                    }
                };
                return {
                    record,
                    // inputType: col.dataIndex === "age" ? "number" : "text",
                    inputType: checkInput(col.dataIndex),
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record)
                };
            }
        };
    });

    useEffect(() => {
        setTableData({
            data: props.data,
            loadingData: true,
            editingKey: ""
        });
    }, [props.data]);

    // console.log(tableData.data);
    return (
        <Table
            className="tabla-adjudicaciones"
            components={components}
            bordered
            dataSource={tableData.data}
            columns={
                (props.enableEdit !== undefined && props.enableEdit || props.enableDelete !== undefined && props.enableDelete) ?
                    columnsMap : plColumns
            }
            scroll={{ x: 240 }}
            rowClassName="editable-row"
        />
    );
}