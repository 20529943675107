
import React from "react";
//import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./Revision.css";
import ListaDocs from "../../../components/ListaDocs/ListaDocs";
import { Typography, Divider, Select, Button, Upload, Modal, Input, Form, message } from "antd";
import SelectDocumentos from "../../../components/ListaDocs/SelectDocs";
import axios from "axios";
import { Tooltip } from '@material-ui/core';

import { basePath } from "../../../api/config";
import { DeleteOutlined, UploadOutlined, EyeOutlined, CheckOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import Mensajes from "../../../components/Mensajes/Mensajes";
import { Redirect } from 'react-router';

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

const urlConsultaDocumentos = `${basePath}/consultaArticulosV`;
const urlTiposClasificacion = `${basePath}/obtenTiposClasificacionEleccionV`;
//const urlCargaArchivoArticulo = `${basePath}/cargaArchivoArticulo`;
const urlDesactivaArticulo = `${basePath}/desactivaArticuloV`;
const enviaNotificacion = `${basePath}/enviaNotificacion`;
let desactivoc = "";
let desactivom = "";
let desactivoe = "";
let desactivoi = "";
let desactivog = "";
const permisos = localStorage.getItem('ROL_USUARIO');
//const permisos="DOCSYMAT.CONSULTA.OPLE.JL";
var tiempoMensaje = 5;

let isRolConsulta = false;
let isRolCAU = false;
class ValidacionDocumentos extends React.Component {

  constructor(props) {
    super(props);

    const estadoSel = localStorage.getItem('ID_ESTADO_SELECCIONADO');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const detalleSel = localStorage.getItem('ID_DETALLE_PROCESO');


    let data = JSON.stringify({
      idProceso: procesoSel,
      idEstado: estadoSel,
      idDetalleProceso: detalleSel
    })

    this.handleClickConsultar = this.handleClickConsultar.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleModalEnviar = this.handleModalEnviar.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNotificar = this.handleNotificar.bind(this);
    this.state = {
      consulta: false,
      seleccion: 0,
      posts: [],
      idArticulo: -1,
      nombreArticulo: "",
      modifica: -1,
      captura: -1,
      desactiva: -1,
      idEstadoSeleccionado: estadoSel,
      idProcesoSeleccionado: procesoSel,
      idDetalleProcesoSeleccionado: detalleSel,
      disableNotificacion: false,
      disableModificar: false,
      disableCapturar: false,
      modalVisible: false,
      modalLoading: false,
      redirect: false,
      dataSelect: data,
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: "",
      },
      urli: ""
    };
  }



  componentDidMount() {
    /*  if(this.state.disableNotificacion !==null)
      {
        if(this.state.disableNotificacion === true && this.state.disableModificar === true && this.state.disableCapturar === false)
        desactivoc = true;
        desactivom = true;
        desactivoe = true;
        desactivoi = true;
        desactivog = true;
      } */
    //console.log("Permisos generales estoy cargando la aplicación", permisos, desactivoc, desactivom, desactivoe, desactivoi, desactivog);

    if (permisos === ("DOCSYMAT.ADMIN.OC" || "DOCSYMAT.CAPTURA.OC" || "DOCSYMAT.CAPTURA.JL" || "DOCSYMAT.SUPERVISOR.OC" || "DOCSYMAT.REVISOR.OC")) {
      desactivoc = false;
      desactivom = false;
      desactivoe = false;
      desactivoi = false;
      desactivog = false;
      //console.log("Permisosgenerales", permisos, desactivoc, desactivom, desactivoe, desactivoi, desactivog);
    }

    else if (permisos === ("DOCSYMAT.CONSULTA.OC" || "DOCSYMAT.CONSULTA.JL" || "DOCSYMAT.CONSULTA.UTVOPL.OC")) {
      desactivoc = false;
      desactivom = true;
      desactivoe = true;
      desactivoi = true;
      desactivog = true;
      // console.log("Permisos consulta", permisos, desactivoc, desactivom, desactivoe, desactivoi, desactivog);
    }
    else if (permisos === "DOCSYMAT.CAU.OC") {
      desactivoc = false;
      desactivom = false;
      desactivoe = true;
      desactivoi = true;
      desactivog = true;
      //console.log("Permisos GENERALES NO GUARDAR", permisos, desactivoc, desactivom, desactivoe, desactivoi, desactivog);
      //tomar en cuenta que en los guardar de las acciones no es valido
    }
    else if (permisos === ("DOCSYMAT.CONSULTA.OPLE.JL" || "DOCSYMAT.CAPTURA.OPLE.JL")) {
      desactivoc = true;
      desactivom = true;
      desactivoe = true;
      desactivoi = true;
      desactivog = true;
      //console.log("Permisos NO TIENE", permisos, desactivoc, desactivom, desactivoe, desactivoi, desactivog);


    }

    isRolConsulta = ((permisos == "DOCSYMAT.CONSULTA.OC") ||
      (permisos == "DOCSYMAT.CONSULTA.JL") ||
      (permisos == "DOCSYMAT.CONSULTA.UTVOPL.OC")) ? true : false;
    isRolCAU = permisos == 'DOCSYMAT.CAU.OC' ? true : false;

  }


  handleSelect(value) {
    let data = JSON.stringify({
      consultaParametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado
      },
      tipoClasificacionEleccion: value
    });
    const currentToken = localStorage.getItem('accessToken');
    axios
      .post(
        urlConsultaDocumentos, data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        if (response.data.entity.code == 200) {
          this.setState({
            disableNotificacion: response.data.entity.notificacionDisable,
            disableModificar: response.data.entity.enableModificar,
            disableCapturar: response.data.entity.enableCaptura,
            posts: response.data.entity.articulos,
            modalVisible: false,
            seleccion: value,
            consulta: true,
            modalLoading: false
          });
        }
        else if (response.data.entity.code == 403) {
          localStorage.clear();
          window.location = "/login"
        }
      })
      .catch(function (error) {
        this.setState({ modalVisible: false, seleccion: value, consulta: true, modalLoading: false });
      });
  }

  handleClickConsultar(id, nombre, conAlerta) {
    console.log("Handle consultar");
    this.setState({
      redirect: true,
      idArticulo: id,
      nombreArticulo: nombre,
      urli: "/revision/validacionconsulta"
    });
  }

  handleClickCargar(id, nombre, conAlerta) {
    console.log("Handle cargar");
    this.setState({
      redirect: true,
      idArticulo: id,
      nombreArticulo: nombre,
      urli: "/revision/validacionobservaciones"
    });

  }

  handleClickModificar(id, nombre, conAlerta) {
    console.log("Handle modificar");
    this.setState({
      redirect: true,
      idArticulo: id,
      nombreArticulo: nombre,
      urli: "/revision/validacionobservaciones"
    });
  }

  handleClickEliminar(id, conAlerta) {
    console.log("Handle elimnar");
    this.setState({ modalVisible: true, idArticulo: id });
  }

  handleModalCancel() {
    console.log("Handle buttom cancel");
    this.handleSelect(this.state.seleccion);
  }

  handleModalEnviar() {
    console.log("Handle buttom enviar");
    this.setState({ modalLoading: true });
    const currentToken = localStorage.getItem('accessToken');
    let comentario = this.refs.comentarioArea.state.value;
    console.log("comentario", comentario);
    let data = JSON.stringify({
      parametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado
      },
      idTipoDocumentacion: this.state.seleccion,
      idArticulo: this.state.idArticulo,
      justificacion: comentario
    });
    axios
      .post(
        urlDesactivaArticulo, data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        this.refs.comentarioArea.state.value = "";
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: response.data.entity.causa
              }
            }); */
          message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message
                }
              }); */
            message.warning(response.data.entity.message, tiempoMensaje);
          }
          else {
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "success",
                  mensaje: "El artículo se ha desactivado correctamente."
                }
              }); */
            message.success("El artículo se ha desactivado correctamente.", tiempoMensaje);
          }
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    this.handleSelect(this.state.seleccion);
  }

  handleClose() {
    this.setState({
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      }
    });
  }


  handleNotificar() {
    console.log("VALOR MODIFICA", this.state.disableModificar);
    console.log("VALOR CAPTURA", this.state.disableCapturar);

    if (this.state.disableModificar === null)
      this.state.modifica = 3;
    this.state.captura = 1;
    if (this.state.disableCapturar === true)
      this.state.captura = 1;
    else if (this.state.disableCapturar === false)
      this.state.captura = 2;
    if (this.state.disableModificar === true)
      this.state.modifica = 2;
    else if (this.state.disableModificar === false)
      this.state.modifica = 1;
    if (this.state.disableModificar === null && this.state.disableCapturar === null) {
      this.state.modifica = 3;
      this.state.captura = 3;
    }

    console.log("VALOR MODIFICA N", this.state.modifica);
    console.log("VALOR CAPTURA N", this.state.captura);
    console.log("Handle buttom notificar");
    const currentToken = localStorage.getItem('accessToken');
    const anio = localStorage.getItem('ANIO');
    const tipoProceso = localStorage.getItem('TIPO_PROCESO');
    const rol = localStorage.getItem('VERSION_USUARIO');
    let data = JSON.stringify({
      parametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado,
        anioProceso: anio,
        tipoProceso: tipoProceso,
        rol: rol

      },
      idTipoDocumentacion: this.state.seleccion,
      modifica: this.state.modifica,
      captura: this.state.captura
      // modifica: this.state.disableModificar,
      //captura: this.state.disableCapturar
      //areaResponsable: 1,
      //tipoPlantillaCorreo: 1
    });
    axios
      .post(
        enviaNotificacion, data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        this.handleSelect(this.state.seleccion);
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          console.log("Error al mandar la notificacion")
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: response.data.entity.causa,
                
              }
            }); */
          message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            console.log("se mando la notificacion")
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message
                }
              }); */
            message.warning(response.data.entity.message, tiempoMensaje);
          }
          else {
            console.log("Se mando la notificacion 1")
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "success",
                  mensaje: "Se ha enviado la notificación al área correspondiente."
                }
              }); */
            message.success("Se ha enviado la notificación al área correspondiente.", tiempoMensaje);
          }
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    this.handleSelect(this.state.seleccion);
  }

  onFinish(values) {
    console.log("Success:", values);
  };

  onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      //const urli='/revision/cargasesp';
      console.log("NOMBRE ARTICULO", this.state.nombreArticulo);
      return <Redirect to={{
        pathname: this.state.urli,
        state: {
          idTipoDocumentacion: this.state.seleccion,
          idArticulo: this.state.idArticulo,
          nombreArticulo: this.state.nombreArticulo
        }
      }} />
    }
  }

  render() {
    console.log("-----------------------------------------Se dibuja el componente");
    const eventHandlers = [{ "cargar": this.handleClickCargar }, { "consultar": this.handleClickConsultar }, { "modificar": this.handleClickModificar }, { "eliminar": this.handleClickEliminar }];
    //Se definen las columnas de la tabla de Listado de cargas
    //const { fileList } = this.state;
    const columns = [
      {
        Header: "No.",
        accessor: "consecutivo",
        sortable: false,
        filterable: false,
        style: {
          textAlign: "center"
        },
        width: 50,
        maxWidth: 50,
        minWidth: 50
      },

      {
        Header: "Documento/Material",
        accessor: "nombre",
      },
      {
        Header: "Observaciones",
        accessor: "conObservaciones",

        style: {
          textAlign: "center"
        },
        width: 200,
        maxWidth: 200,
        minWidth: 100,

      },
      {
        Header: "Emblema",
        accessor: "conEmblema",
        style: {
          textAlign: "center"
        },
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: "Acciones",
        //accessor: "acciones",
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        Cell: props => {
          return (
            console.log("Se pintan las celdas"),
            <div id="centrar">
              {props.original.acciones.map((value, index) => {
                //console.log("value.nombre", value.nombre);
                if (value.nombre === "consultar") {
                  //   if(value.isActivo != null)
                  //  desactivoc= !value.isActivo;
                  return [
                    <Tooltip title="Consultar">
                      <Button
                        id="ilist"
                        type="link"
                        shape="circle"
                        onClick={this.handleClickConsultar.bind(this, props.original.id, props.original.nombre, value.conAlerta)}
                        disabled={desactivoc}
                      //href="/revision/cargasesp"
                      >
                        <EyeOutlined />
                      </Button>
                    </Tooltip>,
                    <Divider id="dcolor" type="vertical" />
                  ];
                } else if (value.nombre === "cargar") {
                  return [
                    <Tooltip title="Validar">
                      <Button
                        id="ilist"
                        type="primary"
                        shape="circle"
                        accept=""
                        onClick={this.handleClickCargar.bind(this, props.original.id, props.original.nombre, value.conAlerta)}
                        //beforeUpload={this.handleUpload}
                        disabled={desactivoi || isRolConsulta}
                      >
                        <CheckOutlined />
                      </Button>
                    </Tooltip>,
                    <Divider id="dcolor" type="vertical" />
                  ];
                } /* else if (value.nombre === "eliminar") {
                    return [
                      <Button
                        id="ilist"
                        type="primary"
                        shape="circle"
                        onClick={this.handleClickEliminar.bind(this, props.original.id, value.conAlerta)}
                        disabled={desactivoe || isRolConsulta}
                      >
                        <DeleteOutlined />
                      </Button>,
                      <Divider id="dcolor" type="vertical" />
                    ];
                  } */
                else if (value.nombre === "modificar") {
                  return [
                    <Tooltip title="Modificar">
                      <Button
                        id="ilist"
                        type="link"
                        shape="circle"
                        onClick={this.handleClickModificar.bind(this, props.original.id, props.original.nombre, value.conAlerta)}
                        disabled={desactivom || isRolConsulta}

                      >
                        <CheckOutlined />
                      </Button>
                    </Tooltip>,
                    <Divider id="dcolor" type="vertical" />
                  ];
                }
                else return <span />;
              }, <Divider id="dcolor" type="vertical" />)}
            </div>
          );
        },
        style: {
          color: "blue"
        },
        sortable: false,
        filterable: false
      }
    ];
    return (
      console.log("En el return"),
      <div>
        {this.renderRedirect()}
        {this.state.visibleAlert.activado ?
          (
            <Mensajes tipoMensaje={this.state.visibleAlert.tipoMensaje} mensaje={this.state.visibleAlert.mensaje} handleClose={this.handleClose} />
          ) : null}
        {(permisos === "DOCSYMAT.CAPTURA.JL" || permisos === "DOCSYMAT.CONSULTA.JL") ?
          <h1 id="titulo">Revisión de documentos y materiales</h1> :
          (permisos === "DOCSYMAT.ADMIN.OC" || permisos === "DOCSYMAT.CAPTURA.OC" ||
            permisos === "DOCSYMAT.CONSULTA.OC" || permisos === "DOCSYMAT.SUPERVISOR.OC" || permisos === "DOCSYMAT.REVISOR.OC") ?
            <h1 id="titulo">Validación de documentos y materiales</h1> : <h1 id="titulo">Revisión/Validación de documentos</h1>}
        <p id="msg">
          <span id="smsg">*</span>Tipo de clasificación de elección
        </p>
        <p>
          <SelectDocumentos
            onChange={this.handleSelect}
            data={this.state.dataSelect}
            rutaWS={urlTiposClasificacion}
          />
        </p>

        <h3>Listado de documentos y materiales a validar</h3>
        <p>
          {this.state.consulta ? <ListaDocs eventos={eventHandlers} datos={this.state.posts} columnas={columns} filterable={true} /> : null}
        </p>

        <p id="contb">
          {this.state.consulta ? (
            <Button id="btonc" disabled={this.state.disableNotificacion || isRolCAU || isRolConsulta} onClick={this.handleNotificar}> Notificar </Button>
          ) : null}
        </p>
        <Modal
          visible={this.state.modalVisible}
          title="Confirmación para desactivar el Documento/Material seleccionado"
          okButtonProps={{ disabled: isRolCAU }}
          onOk={this.handleModalEnviar}
          onCancel={this.handleModalCancel}
          footer={[
            <div>
              <Form.Item>
                <Button key="submit" disabled={isRolCAU} type="primary" loading={this.state.modalLoading} onClick={this.handleModalEnviar}>
                  Enviar
                  </Button>
              </Form.Item>,
                      <Button key="back" onClick={this.handleModalCancel}>
                Cancelar
                    </Button>,
              </div>
          ]}
        >
          <Form
            name="basic"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >

            <p>Está seguro que desea desactivar este artículo, en caso de confirmar capture la justificación.</p>
            <Form.Item
              label="Comentario"
              name="Comentario"
              rules={[{ required: true, message: 'Por favor ingresa la justificación!' }]}
            >
              <TextArea ref="comentarioArea" rows={6} autoSize={false} allowClear={true} />
            </Form.Item>
          </Form>
        </Modal>
      </div >
    );
  }
}
export default ValidacionDocumentos;