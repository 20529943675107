import React, { useState, useEffect, createContext } from "react";
import jwtDecode from "jwt-decode";
import {
  getAccessTokenApi,
  getRefreshTokenApi,
  refreshAccessTokenApi,
  logout
} from "../api/auth";
//Se crea un contexto global que obtendra la información del usuario y se la pasará a todos los componentes hijos
//Sin necesidad de poner manualmente la información del usuario en cada componente 
export const AuthContext = createContext();

export default function AuthProvider(props) {
  const { children } = props;//Todos loc componentes que esten dentro de AutjProvider
  const [user, setUser] = useState({//Hook para almacenar la información del usuario
    user: null,
    isLoading: true,
    proceso: null,
    estado: null,
    distrito: null,            
    descripcion: null,
    listaDetalles: null,    
    infoMenu: null,
    idSistema: null,
    idDetalleProceso: null,
    idProcesoElectoral: null,
    idEstado: null,
    idEstadoSeleccionado: null,
    idDistrito: null,
    idMunicipio: null,
    ambito: null,
    anio: null,
    tipoProceso : null,
    rolUsuario: null,
    versionUsuario: null
  });

  useEffect(() => {
    checkUserLogin(setUser);//Verificar la información
  }, []);

  //Se le manda la información del usuario a todos los componentes hijos
  return <AuthContext.Provider value={{user,setUser}}>{children}</AuthContext.Provider>;
}

function checkUserLogin(setUser) {
  const accessToken = getAccessTokenApi();//Se obtiene el token que esta almacenado en localStorage
  if (!accessToken) {
    const refreshToken = getRefreshTokenApi();//Comprueba si existe el segundo token

    if (!refreshToken) {//Si no existe cierra la sesión 
      logout();
      setUser(()=>{
        return{
          user: null,
          isLoading: false
      }});
    } else {//Refresca el token
      refreshAccessTokenApi(refreshToken);
    }
  } else {//Si existe el token lo almacena en el hook
    setUser({
      user: jwtDecode(accessToken),
      isLoading: false,
      estado: localStorage.getItem('ESTADO'),
      distrito: localStorage.getItem('DISTRITO'),
      descripcion: localStorage.getItem('DESCRIPCION'),
      listaDetalles: localStorage.getItem('LISTA_DETALLES'),
      infoMenu: JSON.parse(localStorage.getItem('INFO_MENU')),
      idSistema: localStorage.getItem('ID_SISTEMA'),
      idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
      idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
      idEstado: localStorage.getItem('ID_ESTADO'),
      idEstadoSeleccionado: localStorage.getItem('ID_ESTADO_SELECCIONADO'),
      idDistrito: localStorage.getItem('ID_DISTRITO'),
      idMunicipio: localStorage.getItem('ID_MUNICIPIO'),
      anio: localStorage.getItem('ANIO'),
      ambito: localStorage.getItem('AMBITO'),
      tipoProceso: localStorage.getItem('TIPO_PROCESO'),
      rolUsuario: localStorage.getItem('ROL_USUARIO'),
      versionUsuario: localStorage.getItem('VERSION_USUARIO')
    });
  }
}