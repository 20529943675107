import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";
import { Form, Spin, Button, Modal, message } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import moment from "moment";
import useAuth from "../../hooks/useAuth";
import "./RegistrarAdjudicaciones.scss";
import { basePath } from "../../api/config";
import Mensajes from "../Mensajes/Mensajes";
import FormRegistroAdjudicacion from "./FormRegistroAdjudicacion"
import TablaRegistroAdjudicaciones from "./TablaRegistroAdjudicaciones"

export default function RegistraAdjudicacion(props) {

    const urlConsultaTiposAdjudicacion = `${basePath}/consultaTiposAdjudicacion`;
    //const urlConsultaTiposAdjudicacion = `https://localhost:8443/docs_ws_adjudica/ws/consultaTiposAdjudicacion`;
    const urlGuardaAdjudicaciones = `${basePath}/guardaAdjudicaciones`;
    //const urlGuardaAdjudicaciones = `https://localhost:8443/docs_ws_adjudica/ws/guardaAdjudicaciones`;
    var tiempoMensaje = 5;
    const { user } = useAuth();

    const [optionsTA, setOptionsTA] = useState({
        data: [],
        loadingData: false
    });

    const [adjudicaciones, setAdjudicaciones] = useState({
        data: [],
        loadingData: false
    });

    const [acciones, setAcciones] = useState({
        enableCaptura: false,
        enableModificar: false
    });

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    function doRequestOptions() {
        axios
            .post(
                urlConsultaTiposAdjudicacion, null, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            //console.log("response:=>", response);
                            setOptionsTA({
                                data: response.data.entity.catalogos,
                                //data: response.data.catalogos,
                                loadingData: true,
                            });
                            setAcciones({
                                enableCaptura: response.data.entity.enableCaptura,
                                enableModificar: response.data.entity.enableModificar
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                setOptionsTA({
                    options: [],
                    loadingData: true
                });
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    };

    function doRequestGuarda() {
        let data = JSON.stringify({
            parametros: {
                idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
                idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
                idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
                anioProceso: user.infoMenu.detalleSelec.anio,
                tipoProceso: user.infoMenu.detalleSelec.tipoProceso
            },
            idTipoDocumentacion: props.idTipoDocumentacion,
            idArticulo: props.idArticulo,
            adjudicaciones: adjudicaciones.data,
            removeAdjudicaciones: []
        })
        axios
            .post(
                urlGuardaAdjudicaciones, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            message.success("Se guardó correctamente la adjudicación", tiempoMensaje);
                            console.log("response:=>", response);
                            window.location = "/adjudicacion/consultarArticulosAdjudicaciones"
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    };

    function showConfirmGuardar() {
        Modal.confirm({
            title: '¿Está seguro de guardar la información?',
            icon: <ExclamationCircleOutlined />,
            //content: 'Some descriptions',
            okText: 'Aceptar',
            cancelText: 'Cancelar',
            onOk() {
                console.log('Aceptar');
                doRequestGuarda();
            },
            onCancel() {
                console.log('Cancelar');
            },
        });
    }

    function showConfirmCancelar() {
        Modal.confirm({
            title: '¿Está seguro cancelar el guardado de la información.?',
            icon: <ExclamationCircleOutlined />,
            //content: 'Some descriptions',
            okText: 'Aceptar',
            cancelText: 'Cancelar',
            onOk() {
                console.log('Aceptar');
                window.location = "/adjudicacion/consultarArticulosAdjudicaciones"
            },
            onCancel() {
                console.log('Cancelar');
            },
        });
    }

    const dateFormat = "DD-MM-YYYY";
    const timeFormat = "HH:mm";

    function handleGuardaAdjudicacion(value) {
        console.log("value:=>", value);
        setVisibleAlert(
            {
                activado: false,
                tipoMensaje: "",
                mensaje: ""
            });
        value.fechaAdjudicacion = value.fechaAdjudicacion.format(dateFormat);
        value.fechaInicioProduccion = value.fechaInicioProduccion.format(dateFormat);
        value.fechaFinProduccion = value.fechaFinProduccion.format(dateFormat);
        value.key = -(new Date()).getTime();
        value.conRegistroSemanal = false;
        const findAdjudicacion = adjudicaciones.data.find(lista => lista.empresa.toLowerCase()
            .replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u")
            .includes(value.empresa.toLowerCase()
                .replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u")));
        if (findAdjudicacion !== undefined) {
            console.log("findAdjudicacion:=>", findAdjudicacion);
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Empresa ya registrada."
                }); */
                message.error("Empresa ya registrada.",tiempoMensaje);
        }
        else {
            setAdjudicaciones({
                data: [...adjudicaciones.data, value],
                loadingData: true
            });
        }
    }

    function handleRemoveData(newData, deleteRow) {
        console.log("handleRemoveData:=>newData ", newData);
        console.log("handleRemoveData:=>deleteRow ", deleteRow);
        setVisibleAlert(
            {
                activado: false,
                tipoMensaje: "",
                mensaje: ""
            });
        setAdjudicaciones({
            data: newData,
            loadingData: false
        });
    }

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    const handleClickGuardar = useCallback(() => {
        showConfirmGuardar();
    });

    const handleClickCancelar = useCallback(() => {
        showConfirmCancelar();
    });

    useEffect(() => {
        if (!optionsTA.loadingData)
            doRequestOptions();
    }, [optionsTA]);


    const WrappedFormRegistroAdjudicacion = Form.create({ name: 'adjudica' })(FormRegistroAdjudicacion);

    return (
        <div className="div_consultaadj">
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null}
            <br />
            {!optionsTA.loadingData ? (<Spin size="large" />) : (
                <div>
                    <WrappedFormRegistroAdjudicacion
                        optionsTipoAdjudicaciones={optionsTA.data}
                        nombreArticulo={props.nombreArticulo}
                        enableCaptura={acciones.enableCaptura}
                        handleSubmit={handleGuardaAdjudicacion} />
                    <TablaRegistroAdjudicaciones
                        optionsTipoAdjudicaciones={optionsTA.data}
                        data={adjudicaciones.data}
                        enableEdit={false}
                        enableDelete={true}
                        enableCaptura={acciones.enableCaptura}
                        enableModificar={acciones.enableModificar}
                        handleRemoveData={handleRemoveData.bind(this)}
                    />
                    <div className="div_botones">
                        <Button
                            className="cancelar"
                            onClick={handleClickCancelar.bind(this)}
                        > Cancelar </Button>
                        <Button
                            style={{ marginLeft: '10px' }}
                            className="guardar"
                            onClick={handleClickGuardar.bind(this)}
                            disabled={adjudicaciones.data.length === 0 || !acciones.enableModificar}
                        > Guardar </Button>
                    </div>
                </div>
            )}
        </div>
    )

}