import React, { useEffect, useState } from "react";
import { Layout, Menu, Icon, Button } from "antd";
import { Link } from 'react-router-dom';
import "./Sider.scss";
import useAuth from "../../hooks/useAuth";
import { SignalFilled, QuestionCircleOutlined } from "@ant-design/icons";
import pdfAyuda from '../../assets/files/Manual_DocsMatOPL.pdf';

export default function Sider() {
  const { user } = useAuth();
  const { SubMenu } = Menu;
  const { Sider } = Layout;
  const [menu, setMenu] = useState();


  useEffect(() => {
    let menuAux = user.infoMenu.listMenu;
    setMenu(menuAux);
  }, [user])


  return (
    <>
      {(menu && menu.length) ?
        <Sider collapsible style={{ background: "#f2f2f2" }} width={230}>
          <Menu
            defaultSelectedKeys={['1']}
            //defaultSelectedKeys={[defaultSelect]}
            //defaultOpenKeys={defaultOpen}
            mode="inline"
            style={{ marginTop: "50px" }}
          //onClick={infoMenu}
          //onOpenChange={cambiMenu}
          >
            {/* <Button icon="home" href="/home">
              Inicio
            </Button> */}
            {menu.map((itemMenu) =>

              <SubMenu
                key={itemMenu.nombreMenu}
                title={
                  <span>
                    {
                      itemMenu.nombreMenu.toLowerCase().replace("á", "a").
                        replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u") === 'configuracion' ?
                        <Icon style={{ fontSize: '25px' }} type="setting" />
                        : itemMenu.nombreMenu.toLowerCase().replace("á", "a").
                          replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u") === 'revision' ?
                          <Icon style={{ fontSize: '25px' }} type="file-search" />
                          : itemMenu.nombreMenu.toLowerCase().replace("á", "a").
                            replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u") === 'aprobacion' ?
                            <Icon style={{ fontSize: '25px' }} type="file-done" />
                            : itemMenu.nombreMenu.toLowerCase().replace("á", "a").
                              replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u") === 'adjudicacion' ?
                              <Icon style={{ fontSize: '25px' }} type="profile" />
                              : itemMenu.nombreMenu.toLowerCase().replace("á", "a").
                                replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u") === 'produccion' ?
                                <Icon style={{ fontSize: '25px' }} type="schedule" />
                                : itemMenu.nombreMenu.toLowerCase().replace("á", "a").
                                  replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u") === 'reportes' ?
                                  <Icon style={{ fontSize: '25px' }} type="" />
                                  : null
                    }
                    <span>{itemMenu.nombreMenu}</span>
                  </span>}>
                {itemMenu.subMenus[0].modulos.map((opt) =>
                  opt.estatus === 'A' && (
                    <Menu.Item key={opt.urlModulo}>
                      <Link
                        to={{
                          pathname: opt.urlModulo,
                          state: {
                            actionList: opt.listAccionesModulo
                          }
                        }}
                      />
                      {opt.nombreModulo}
                    </Menu.Item>
                  )
                )}
              </SubMenu>
            )}
            <div className="div_boton_reportes">       
            <Button  id="regresar"
                    type="link"
                    className="reportes"
                    href="/reportes"
                    >
                  <SignalFilled /> Reportes
                  </Button>
                  </div>
                  <div className="div_boton_reportes">       
            <Button  id="ayuda"
                    type="link"
                    className="ayuda"
                    href={pdfAyuda}
                    target="_blank"
                    >
                  <QuestionCircleOutlined /> Ayuda
                  </Button>
                  </div>
          </Menu>         
        </Sider> : <></>
      }
    </>
  );
}