import React, { useState, useEffect } from "react";
import { Row, Col, Button, List, Skeleton, message } from "antd";
import { DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
//import "./FormRegistroSesion.scss";
import { basePath } from "../../api/config";
import { Redirect } from 'react-router';
import useAuth from "../../hooks/useAuth";
import { base64toBlob } from "../../utils/Utils"
import Mensajes from "../Mensajes/Mensajes";
import axios from "axios";
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');
export default function ConsultarVerificacion(props) {

    const { user } = useAuth();
    var tiempoMensaje = 5;
    const urlConsultaVerificacion = `${basePath}/consultaVerificacion`;

    const dateFormat = "DD-MM-YYYY HH:mm";
    const timeFormat = "HH:mm";

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    const [verificacionConsulta, setVerificacionConsulta] = useState({
        verificacion: null,
        dataAspectosDocs: [],
        dataAspectosMats: [],
        fileName: null,
        fileBase64: null,
        enableModificar: false,
        enableCaptura: false,
        loaded: false,
    });

    const [redirect, setRedirect] = useState({
        redirecting: false,
        pathname: "",
        state: {},
    });

    function doRequestConsulta() {
        console.log("idVerificacion:=>", props.idVerificacion);
        let data = JSON.stringify({
            idVerificacion: props.idVerificacion,
        });
        axios
            .post(
                urlConsultaVerificacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            setVerificacionConsulta({
                                verificacion: response.data.entity.verificacion,
                                dataAspectosDocs: response.data.entity.aspectosDocs,
                                dataAspectosMats: response.data.entity.aspectosMats,
                                fileBase64: response.data.entity.fileBase64,
                                fileName: response.data.entity.fileName,
                                enableCaptura: response.data.entity.enableCaptura,
                                enableModificar: response.data.entity.enableModificar,
                                loaded: true
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
                setVerificacionConsulta({
                    verificacion: null,
                    dataAspectosDocs: [],
                    dataAspectosMats: [],
                    fileBase64: null,
                    fileName: null,
                    enableCaptura: false,
                    enableModificar: false,
                    loaded: true
                });
            });
    }

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    const handleClickDescargar = () => {
        console.log("handleClickDescargar");
        let a = document.createElement('a');
        if (verificacionConsulta.fileBase64 !== null && verificacionConsulta.fileBase64 !== "") {
            const fileBase64 = 'data:application/pdf;base64,' + verificacionConsulta.fileBase64;
            const blob1 = base64toBlob(fileBase64);
            a.href = URL.createObjectURL(blob1);
           /*  a.download = "DOCSMATS-" + verificacionConsulta.fileName; */
           a.download = "DOCSMATS-" + "evidenciaVerificacionProduccion";
            a.click();
        }
    };

    const handleClickModificar = () => {
        console.log("handleClickModificar");
        setRedirect(
            {
                redirecting: true,
                pathname: '/produccion/registrarverificacion',
                state: {
                    idVerificacion: props.idVerificacion
                }
            }
        );
    };

    const renderRedirect = () => {
        if (redirect.redirecting) {
            return <Redirect to={{
                pathname: redirect.pathname,
                state: redirect.state
            }
            } />
        }
    }

    useEffect(() => {
        if (!verificacionConsulta.loaded)
            doRequestConsulta();
    }, [verificacionConsulta]);

    return (
        <div>
            {renderRedirect()}
            <Button
                id="regresar"
                type="link"
                href="/produccion/verificaciones">
                <ArrowLeftOutlined />Regresar</Button>
            <br />
            <br />
            {visibleAlert.activado ?
                (
                    <div>
                        <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                        <br />
                    </div>
                ) : null}
            <h1 className="titulo-verificacion">Consulta de verificación de producción</h1>
            <br />
            <div>
                <Row>
                    <Col span={10}>
                        <Col span={23}>
                            <label className="texto-16-bold-verificacion">Nombre del OPL</label>
                        </Col>
                    </Col>
                    <Col span={7}>
                        <Col span={23}>
                            <label className="texto-16-bold-verificacion">Fecha de Verificación</label>
                        </Col>
                    </Col>
                    <Col span={7}>
                        <Col span={23}>
                            <label className="texto-16-bold-verificacion">Hora de Verificación</label>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col span={10} className="respuesta-col">
                        <Col span={23}>
                            <label className="texto-16-verificacion">
                                {
                                    verificacionConsulta.verificacion !== null ?
                                        verificacionConsulta.verificacion.ople
                                        : null
                                }
                            </label>
                        </Col>
                    </Col>
                    <Col span={7} className="respuesta-col">
                        <Col span={23}>
                            <label className="texto-16-verificacion">
                                {
                                    verificacionConsulta.verificacion !== null ?
                                        verificacionConsulta.verificacion.fechaVerificacion
                                        : null
                                }
                            </label>
                        </Col>
                    </Col>
                    <Col span={7} className="respuesta-col">
                        <Col span={23}>
                            <label className="texto-16-verificacion">
                                {
                                    verificacionConsulta.verificacion !== null ? (
                                        verificacionConsulta.verificacion.horaVerificacion !== null ?
                                            moment(verificacionConsulta.verificacion.horaVerificacion, timeFormat).format(timeFormat)
                                            : null)
                                        : null
                                }
                            </label>
                        </Col>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={12}>
                        <Col span={23}>
                            <label className="texto-16-bold-verificacion">Empresa</label>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} className="respuesta-col">
                        <Col span={23}>
                            <label className="texto-16-verificacion">
                                {
                                    verificacionConsulta.verificacion !== null ?
                                        verificacionConsulta.verificacion.empresa
                                        : null
                                }
                            </label>
                        </Col>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={10}>
                        <label className="texto-16-bold-verificacion"> Nombre del responsable OPL</label>
                    </Col>
                    <Col span={14}>
                        <label className="texto-16-bold-verificacion"> Cargo del responsable OPL</label>
                    </Col>
                </Row>

                <Row>
                    <Col span={10} className="respuesta-col">
                        <label className="texto-16-verificacion">
                            {
                                verificacionConsulta.verificacion !== null ?

                                    verificacionConsulta.verificacion.nombreResponsable + " "
                                    + verificacionConsulta.verificacion.primerApellidoResponsable + " "
                                    + (verificacionConsulta.verificacion.segundoApellidoResponsable !== null ? verificacionConsulta.verificacion.segundoApellidoResponsable : "")
                                    : null
                            }
                        </label>
                    </Col>
                    <Col span={14} className="respuesta-col">
                        <label className="texto-16-verificacion">
                            {
                                verificacionConsulta.verificacion !== null ?
                                    verificacionConsulta.verificacion.cargo
                                    : null
                            }
                        </label>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col span={23}>
                        <label className="texto-16-bold-verificacion">Tipo verificación</label>
                    </Col>
                </Row>
                <Row>
                    <Col span={23} className="respuesta-col">
                        <label className="texto-16-verificacion">
                            {verificacionConsulta.verificacion !== null ? (
                                verificacionConsulta.verificacion.tipoCaptura ? "Documentos" : "Materiales"
                            ) : ""
                            }
                        </label>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col span={24}>
                        <label className="texto-16-bold-verificacion">Nombre de (los) documento(s) que se están produciendo</label>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="respuesta-col">
                        <label className="texto-16-verificacion">
                            {
                                verificacionConsulta.verificacion !== null ?
                                    verificacionConsulta.verificacion.documentosProduccion : null
                            }
                        </label>
                    </Col>
                </Row>
                <br />
                <Row>
                    <List
                        header={
                            <div>
                                <Row className="header-1-tabla-gris">
                                    <Col span={10} className="header-1-texto-medium-16-white texto-centrado">
                                        Aspectos
                                    </Col>
                                    <Col span={3} offset={1} className="header-1-texto-medium-16-white texto-centrado">
                                        Respuesta
                                    </Col>
                                    <Col span={9} offset={1} className="header-1-texto-medium-16-white texto-centrado">
                                        Observaciones
                                        </Col>
                                </Row>
                                <Row className="header-2-tabla-gris">
                                    <Col span={10} className="header-2-texto-normal-16-white">
                                        {
                                            verificacionConsulta.verificacion !== null ? (
                                                verificacionConsulta.verificacion.tipoCaptura ? "Documentos" : "Materiales"
                                            ) : ""
                                        }
                                    </Col>
                                </Row>
                            </div>
                        }
                        size="large"
                        loading={!verificacionConsulta.loaded}
                        itemLayout="horizontal"
                        dataSource={
                            verificacionConsulta.verificacion !== null ? (
                                verificacionConsulta.verificacion.tipoCaptura ? verificacionConsulta.dataAspectosDocs : verificacionConsulta.dataAspectosMats
                            ) : []
                        }
                        renderItem={item =>
                            <List.Item>
                                <Skeleton title={false} loading={false} active>
                                    <List.Item.Meta
                                        description={
                                            <Row>
                                                <Col span={10} className="col-aspecto">
                                                    {item.aspecto}
                                                </Col>
                                                <Col span={3} offset={1} className="texto-16-verificacion texto-centrado">
                                                    {item.respuesta}
                                                </Col>
                                                <Col span={9} offset={1} className="col-aspecto">
                                                    {item.observaciones !== null ? item.observaciones : ""}
                                                </Col>
                                            </Row>
                                        }
                                    />
                                </Skeleton>
                            </List.Item>
                        }
                    >
                    </List>
                </Row>

                <br />
                <Row>
                    <Col span={16}>
                        <Col span={23}>
                            <label className="texto-16-bold-verificacion">Observaciones Adicionales</label>
                        </Col>
                    </Col>
                    <Col span={8}>
                        <Col span={23}>
                            <label className="texto-16-bold-verificacion">Evidencia</label>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col span={16} className="respuesta-col">
                        <Col span={24}>
                            <label className="texto-16-verificacion">
                                {
                                    verificacionConsulta.verificacion !== null ?
                                        verificacionConsulta.verificacion.observaciones
                                        :
                                        "Sin observaciones adicionales"
                                }
                            </label>
                        </Col>
                    </Col>
                    <Col span={8} className="respuesta-col">
                        <Col span={24}>
                            <Button
                                className="descargar-evidencia"
                                onClick={handleClickDescargar}
                            > <DownloadOutlined /> Descargar </Button>
                        </Col>
                    </Col>
                </Row>
                <br />
                <div className="div-botones">
                    <Button
                        className="modificar-verificacion"
                        onClick={handleClickModificar}
                        disabled={!verificacionConsulta.enableCaptura}
                    > Modificar </Button>
                </div>
            </div>
        </div >
    )
}