import React from "react";
import "antd/dist/antd.css";
//import "./Revision.css";
//import PDFViewer from "mgr-pdf-viewer-react";
import sources from './Sources';
import "./cargas.css";


const contenedorpdf = {
  width: '50%',
};
class PDFEscala extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1.0
    }
  }

  increaseScale = () => this.setState(({ scale }) => ({ scale: scale + 0.5 }))
  decreaseScale = () => this.setState(({ scale }) => ({ scale: scale - 0.5 }))

  render() {
    return (
      <div style={contenedorpdf}>


        {/*<button onClick={this.decreaseScale}>-</button>
        <span>Zomm: {this.state.scale}</span>
    <button onClick={this.increaseScale}>+</button>*/}
        {/* <PDFViewer
          document={{
            //url: sources.url
            base64: this.props.fileBase64
          }}
          scale={this.state.scale} /> */}

      </div>
    );
  }
}

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '50%',
};

const VerPdf = (pdfs) => (
  <div style={wrapperStyle}>
    {pdfs.pdf1 !== "" ? (<PDFEscala fileBase64={pdfs.pdf1} />) : null}
    {pdfs.pdf2 !== "" ? (<PDFEscala fileBase64={pdfs.pdf2} />) : null}
  </div>
);
export default VerPdf;
