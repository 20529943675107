import React from "react";
import { Redirect } from "react-router-dom";
//Se importan los componentes a utilizar
import Login from "../../../components/Login/Login";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";

import { ReactComponent as Logo_Header } from "../../../assets/img/ID_docs.svg";
import { ReactComponent as Logo_Login } from "../../../assets/img/ID_Docs2.svg";
//Componentes de ant desig
import { Layout, Col, Row } from "antd";
import "./Login.scss";
import Carrusel from "../../../components/Carrusel/Carrusel";

export default function LoginPrincipal() {
  const { Content } = Layout; //Se obtiene el componente hijo de Layout
  return (
    <Layout className="layout">
      {/**Se importa el header y se le envía el logo correspondiente */}
      <Header Logo={Logo_Header} />
      <Content className="content">
        {/**Contenido  */}
        <Row justify="center">
          <Col sm={{span:0}} md={{span:12,offset:3}} className="carrusel-container">               
           <Carrusel />        
          </Col>      
          <Col sm={{span:24}} md={{span:6}}>    
            <Login Logo={Logo_Login} />
          </Col>
        </Row>
      </Content>
      {/**Footer con la versión del sistema */}
      <Footer version="Versión 1.0" anio="2020" />
    </Layout>
  );
}
