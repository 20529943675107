import React, { useState, useEffect } from "react";
import "./Catalogos.scss";
import 'antd/dist/antd.css';
import { Upload, Button, message } from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from "axios";
import { basePath } from "../../api/config";
//import FileUpload from "./FileUpload";
import Mensajes from "../Mensajes/Mensajes";
import { Redirect } from 'react-router';

export default function Catalogo(props) {
    var tiempoMensaje = 5;
    var tiempoMensaje1 = 7;
    const [numeroCatalogo, setNumeroCatalogo] = useState({
        valor: 0
    });
    const [archivos, setArchivos] = useState({
        uploading: false,
        fileList: []
    });
    const [redirect, setRedirect] = useState({
        redireccion: false
    });

    const [permissions, setPermissions] = useState({
        enableModificar: false,
        loading: false
    })

    const propsSubir = {
        multiple: false,
        onRemove: file => {
            //console.log("Lista de archivos", archivos.fileList);
            setArchivos(archivos => {
                const index = archivos.fileList.indexOf(file);
                const newFileList = archivos.fileList.slice(); //Extrae todo el arreglo
                newFileList.splice(index, 1); //Inserta en una posición específica
                return {
                    fileList: newFileList
                };
            });
        },
        beforeUpload: file => {
            console.log("Entró en beforeUpload.");
            let isCSV = file.type === 'application/vnd.ms-excel';
            isCSV = isCSV | file.type === 'text/csv';
            const isSomething = file.size > 0;
            const isSize = (file.size / 1024 / 1024) < 10;
            file.tipoCatalogo = numeroCatalogo.valor;
            if (!isCSV) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El formato del archivo no corresponde a un CSV. Favor de verificarlo."
                    mensaje: "El formato del archivo no corresponde a un CSV. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El formato del archivo no corresponde a un CSV. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
            }
            if (!isSomething) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El archivo viene vacío. Favor de verificarlo."
                    mensaje: "El archivo se encuentra vacío. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo se encuentra vacío. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
            }
            if (!isSize) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El archivo pesa más de 10 MB. Favor de verificarlo."
                    mensaje: "El archivo pesa más de 10 MB. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo pesa más de 10 MB. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
            }
            if (isCSV && isSomething && isSize) {
                setArchivos({
                    fileList: [...archivos.fileList, file],
                    uploading: archivos.uploading
                });
                /* setVisibleAlert({
                    activado: false,
                    tipoMensaje: "success",
                    mensaje: "El archivo fue cargado exitosamente."
                }); */
                /* message.success("El archivo fue cargado exitosamente.",tiempoMensaje); */
            }


            console.log("Regresa: " + isCSV && isSomething && isSize);
            return !(isCSV && isSomething && isSize);
        },
        onChange: info => {
            console.log("Lista de archivos", archivos.fileList);
        },
        archivos
        //fileList2 //sigue dejando el archivo
    };

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });
    const handleClose = () => {
        //setVisibleAlert(false);
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }
    const renderRedirect = () => {
        if (redirect.redireccion) {
            return <Redirect to={{ pathname: '/configuracion/consultarcatalogos' }} />
        }
    }

    useEffect(() => {
        if (!permissions.loading) {
            loadPermissions();
        }
    }, [permissions]);

    /*
    if (window.File && window.FileReader && window.FileList && window.Blob){
        console.log("El documento a cargar es soportado");
    }
    else {
        console.log("El documento a cargar no es soportado");
    }*/ //No funciona, no hace algo.    

    return (
        <div className="div_catalogos">
            {renderRedirect()}
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null}
            <div className="div_titulo">
                <h1 id="titulo">Catálogos</h1>
                {/* <div className="encabezado"> Catálogos </div> */}
                <div> <InfoCircleOutlined /> Sube tus catálogos en formato CSV usando como separador el caracter | (pipe) y sin rebasar los 10 MB permitidos por el sistema. </div>
            </div>
            <div className="div_documentos">
                {/* <div className="titulo">Correos electrónicos</div> */}
                <h3>Correos electrónicos</h3>
                <div className="div_documentos__subir">
                    <div className="div_documentos__subir__docsubir">
                        {/* <div className="subtitulo">Correos electrónicos</div> */}
                        <p id="msg">Correos electrónicos</p>
                        <Upload id="catCorreos" {...propsSubir} accept=".csv">
                            <Button className="subir" value={1} onClick={handleClickButton}>
                                <UploadOutlined /> Subir
                            </Button>
                        </Upload>
                    </div>
                    <div className="div_documentos__subir__docsubir">
                    </div>
                    <div className="div_documentos__subir__docsubir"></div>
                </div>
            </div>

            <div className="div_documentos">
                {/* <div className="titulo"> Documentos </div> */}
                <h3> Documentos/Materiales</h3>
                <div className="div_documentos__subir">
                    <div className="div_documentos__subir__docsubir">
                        {/* <div className="subtitulo">Tipos de elección por estado</div> */}
                        <p id="msg">Tipos de clasificación de elección por estado</p>
                        <Upload id="catEleEst" {...propsSubir} accept=".csv">
                            <Button className="subir" value={2} onClick={handleClickButton}>
                                <UploadOutlined /> Subir
                            </Button>
                        </Upload>
                    </div>
                    <div className="div_documentos__subir__docsubir">
                        {/* <div className="subtitulo">Especificaciones Anexo 4.1</div> */}
                        <p id="msg">Especificaciones Anexo 4.1</p>
                        <Upload id="catEspTec" {...propsSubir} accept=".csv">
                            <Button className="subir" value={3} onClick={handleClickButton}>
                                <UploadOutlined /> Subir
                            </Button>
                        </Upload>
                    </div>
                    <div className="div_documentos__subir__docsubir">
                        {/* <div className="subtitulo">Especificaciones Únicas</div> */}
                        <p id="msg">Especificaciones Únicas</p>
                        <Upload id="catEspDis" {...propsSubir} accept=".csv">
                            <Button className="subir" value={4} onClick={handleClickButton}>
                                <UploadOutlined /> Subir
                            </Button>
                        </Upload>
                    </div>
                </div>
            </div>

            <div className="div_guardar">
                <Button
                    className="guardarc"
                    onClick={handleGuardar}
                    disabled={!permissions.enableModificar}
                > Guardar </Button>
                {/*href="/configuracion/consultarcatalogos"> Guardar </Button>*/}
            </div>
        </div>
    );

    function handleClickButton(event) {
        numeroCatalogo.valor = event.target.value;
    }

    function loadPermissions() {
        const url = `${basePath}/checkAdminPermissions`;
        axios
            .post(
                url,
                //"https://localhost:8443/docs_ws_central/ws/configuracionCatalogos",
                null,
                {
                    headers: {
                        // TODO Tenemos que poner el token
                        // 'Authorization': "currentToken",
                        'Authorization': localStorage.getItem('accessToken'),
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                }
            )
            .then(response => {
                setPermissions({
                    enableModificar: true,
                    loading: true
                })
            })
            .catch(error => {
                console.log(error);
                setPermissions({
                    enableModificar: false,
                    loading: true
                })
            });
    }

    function handleGuardar() {
        if (localStorage.getItem('ID_ESTADO_SELECCIONADO') === 'null') {
            message.warn("Para realizar la carga de catálogos debe seleccionar una entidad", tiempoMensaje);
            return;
        } else if (localStorage.getItem('ID_ESTADO_SELECCIONADO') !== 'null' && localStorage.getItem('ID_ESTADO_SELECCIONADO') === '0') {
            message.warn("Para realizar la carga de catálogos debe seleccionar una entidad", tiempoMensaje);
            return;
        }

        console.log("Cantidad de archivos subidos: ", archivos.fileList.length);
        //console.log("Catalogo: "+archivos.fileList[1].tipoCatalogo);
        if (archivos.fileList.length < props.numMinArchivos) {
            message.error("No se han cargado todos los catálogos. Favor de cargar los que faltan.", 5);
        }
        else if (archivos.fileList.length > 4) {
            var elemento;
            var arrayTipo = [];
            for (elemento of archivos.fileList) {
                if (arrayTipo.includes(elemento.tipoCatalogo, 0)) {
                    message.error("Se ha cargado más de un archivo por catálogo. Favor de cargar únicamente un archivo.", 5);
                    return;
                } else {
                    arrayTipo.push(elemento.tipoCatalogo);
                }
            }
        }
        else {
            const fileList = archivos.fileList;
            const formData = new FormData();
            //formData.append("accessToken", localStorage.getItem('accessToken'));
            formData.append("idDetalleProceso", localStorage.getItem('ID_DETALLE_PROCESO'));
            formData.append("idProcesoElectoral", localStorage.getItem('ID_PROCESO_ELECTORAL'));
            //formData.append("idEstado", localStorage.getItem('ID_ESTADO'));
            if (localStorage.getItem('ID_ESTADO_SELECCIONADO') !== null) {
                formData.append("idEstado", localStorage.getItem('ID_ESTADO_SELECCIONADO'));
            }


            formData.append("anio", localStorage.getItem('ANIO'));
            formData.append("tipoProceso", localStorage.getItem('TIPO_PROCESO'));

            if (localStorage.getItem('ESTADO') !== null) {
                formData.append("nombreEstado", localStorage.getItem('ESTADO'));
            }
            else if (localStorage.getItem('ESTADO') === null) {
                formData.append("nombreEstado", "OFICINAS CENTRALES");
            }
            console.log("fileList al enviar", fileList);
            let numberFile = 0;

            fileList.forEach(file => {
                const tipoArchivoAttr = "archivos[" + numberFile + "].tipoArchivo";
                const archivoAttr = "archivos[" + numberFile + "].archivo";
                formData.append(tipoArchivoAttr, file.tipoCatalogo); //Quitar el 2 y poner el número que le corresponde
                formData.append(archivoAttr, file);
                numberFile++;
            });

            setArchivos({
                uploading: true,
                fileList: archivos.fileList
            });
            const url = `${basePath}/configuracionCatalogos`;
            axios
                .post(
                    url,
                    //"https://localhost:8443/docs_ws_central/ws/configuracionCatalogos",
                    formData,
                    {
                        headers: {
                            // TODO Tenemos que poner el token
                            // 'Authorization': "currentToken",
                            'Authorization': localStorage.getItem('accessToken'),
                            "Content-Type": "multipart/form-data",
                            Accept: "application/json"
                        }
                    }
                )
                .then(response => {
                    console.log("code: ", response.data.entity.code);

                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                        var cadena = response.data.entity.causa.substr(0, 89);
                        //console.log("CADENA CAUSA", cadena);
                        if (cadena !== "A different object with the same identifier value was already associated with the session") {
                            if (response.data.entity.causa !== null)
                                message.error(response.data.entity.causa, tiempoMensaje1);
                            else
                                message.error("10. Ocurrió un error. Comunícate al CAU", tiempoMensaje);
                        }
                        else
                            message.error("Se detectaron clasificaciones duplicadas, favor de revisar el archivo del apartado -Tipos de documentos por estado-", tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                            message.warning(response.data.entity.message, tiempoMensaje);
                        }
                        else {
                            setArchivos({
                                uploading: false,
                                fileList: []
                            });
                            console.log("Upload successfully.");
                            setRedirect({
                                redireccion: true
                            })
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                })
                .catch(error => {
                    console.log("Error:=>: doRequestOptions", error)
                    if (error.response.status === 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                    /* setVisibleAlert(
                        {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                        }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.", tiempoMensaje);
                });
        }
    }

}
