import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";
import { Form, Spin, Button, message } from "antd";
import { Redirect } from 'react-router';
import { ArrowLeftOutlined } from "@ant-design/icons";
import useAuth from "../../hooks/useAuth";
import { basePath } from "../../api/config";
import Mensajes from "../Mensajes/Mensajes";
import TablaRegistroAdjudicaciones from "../RegistrarAdjudicaciones/TablaRegistroAdjudicaciones"

export default function ConsultarAdjudicaciones(props) {
    var tiempoMensaje=5;
    const urlConsultaAdjudicaciones = `${basePath}/consultaAdjudicaciones`;
    //const urlConsultaAdjudicaciones = `https://localhost:8443/docs_ws_adjudica/ws/consultaAdjudicaciones`;

    const { user } = useAuth();

    const [adjudicaciones, setAdjudicaciones] = useState({
        data: [],
        articuloDesactivadoAdj: false,
        justificacion: false,
        loadingData: false,
        enableModificar: false,
        enableCaptura: false,
    });

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    const [redirect, setRedirect] = useState({
        redirecting: false,
        url: "",
        idArticulo: -1,
        idTipoDocumentacion: -1,
        nombreArticulo: ""
    });

    function doRequestAjudicaciones() {
        let data = JSON.stringify({
            parametros: {
                idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
                idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
                idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
                anioProceso: user.infoMenu.detalleSelec.anio,
                tipoProceso: user.infoMenu.detalleSelec.tipoProceso
            },
            idTipoDocumentacion: props.idTipoDocumentacion,
            idArticulo: props.idArticulo,
        })
        axios
            .post(
                urlConsultaAdjudicaciones, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /*setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            });*/

                            message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /*setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                });*/
                                message.warning(response.data.entity.causa, tiempoMensaje);
                        }
                        else {
                            //console.log("response:=>", response);
                            setAdjudicaciones({
                                data: response.data.entity.adjudicaciones,
                                loadingData: true,
                                enableModificar: response.data.entity.enableModificar,
                                enableCaptura: response.data.entity.enableCaptura,
                                articuloDesactivadoAdj: response.data.entity.articuloDesactivadoAdj,
                                justificacion: response.data.entity.justificacion,
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                setAdjudicaciones({
                    data: [],
                    articuloDesactivadoAdj: false,
                    justificacion: false,
                    loadingData: false,
                    enableModificar: false,
                    enableCaptura: false,
                });
                /*setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    });*/
                    message.error("Ha ocurrido un error al realizar la petición solicitada", tiempoMensaje);
            });
    };

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    useEffect(() => {
        if (!adjudicaciones.loadingData)
            doRequestAjudicaciones();
    }, [adjudicaciones]);

    const renderRedirect = () => {
        if (redirect.redirecting) {
            return <Redirect to={{
                pathname: redirect.url,
                state: {
                    idArticulo: redirect.idArticulo,
                    idTipoDocumentacion: redirect.idTipoDocumentacion,
                    nombreArticulo: redirect.nombreArticulo,
                }
            }
            } />
        }
    }

    const handleClickModificar = useCallback(() => {
        setRedirect({
            redirecting: true,
            url: '/adjudicacion/modificarAdjudicaciones',
            idArticulo: props.idArticulo,
            idTipoDocumentacion: props.idTipoDocumentacion,
            nombreArticulo: props.nombreArticulo,
        })
    });

    return (
        <div>
            {renderRedirect()}
            <Button
                id="regresar"
                type="link"
                href="/adjudicacion/consultarArticulosAdjudicaciones">
                <ArrowLeftOutlined />Regresar</Button>
            <br />
            <div className="div_consultaadj">
                {visibleAlert.activado ?
                    (
                        <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                    ) : null}
                <br />
                {!adjudicaciones.loadingData ? (<Spin size="large" />) : (
                    adjudicaciones.articuloDesactivadoAdj ? (
                        <div>
                            <label className="texto-14-adjudicacion">{adjudicaciones.justificacion}</label>
                        </div>
                    ) : (
                            <div>
                                <h1 className="titulo-adjudicacion">{props.nombreArticulo}</h1>
                                <br />

                                <TablaRegistroAdjudicaciones
                                    data={adjudicaciones.data}
                                    enableEdit={false}
                                    enableDelete={false}
                                />
                                <br />
                                <div className="div_botones">
                                    <Button
                                        className="guardar"
                                        onClick={handleClickModificar.bind(this)}
                                        disabled={!adjudicaciones.enableCaptura}
                                    > Modificar </Button>
                                </div>
                            </div>
                        )
                )}
            </div>
        </div>
    )

}