import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Form, Button, Modal, message} from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { basePath } from "../../api/config";
import useAuth from "../../hooks/useAuth";
import Mensajes from "../Mensajes/Mensajes";
import { base64toBlob } from "../../utils/Utils"
import FormRegistroVerificacion from "./FormRegistroVerificacion";
import './RegistrarVerificacion.css'

export default function RegistrarVerificacion(props) {

    const urlConsultaRegistroVerificacion = `${basePath}/consultaRegistroVerificacion`;
    var tiempoMensaje = 5;
    const WrappedFormRegistroVerificacion = Form.create({ name: 'verificacion' })(FormRegistroVerificacion);

    const { user } = useAuth();

    const [formData, setFormData] = useState({
        verificacion: {},
        dataAspectosDocs: [],
        dataAspectosMats: [],
        oples: {},
        cargos: {},
        empresas: {},
        selected: {},
        file: null,
        aspectosRequeridosDocs: 0,
        aspectosRequeridosMats: 0,
        enableModificar: false,
        enableCaptura: false,
        loaded: false,
    });

    const [modalVisible, setModalVisible] = useState(false);

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    useEffect(() => {
        if (!formData.loaded)
            doRequestFormData();
    }, [formData]);

    function showConfirmCancelar() {
        Modal.confirm({
            title: '¿Está seguro cancelar el guardado de la información.?',
            icon: <ExclamationCircleOutlined />,
            //content: 'Some descriptions',
            okText: 'Aceptar',
            cancelText: 'Cancelar',
            onOk() {
                console.log('Aceptar');
                window.location = "/produccion/verificaciones"
            },
            onCancel() {
                console.log('Cancelar');
            },
        });
    }

    const handleClickCancelar = useCallback(() => {
        showConfirmCancelar();
    });

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    function doRequestFormData() {
        let data = JSON.stringify({
            idEntidad: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
            idVerificacion: props.idVerificacion,
            idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
        })
        axios
            .post(
                urlConsultaRegistroVerificacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            let selected = {};
                            let file = null;
                            let aspectosRequeridosDocs = 0;
                            let aspectosRequeridosMats = 0;
                            response.data.entity.aspectosDocs.map((value, index) => {
                                let llave = value.idAspecto;
                                let valor = value.respuesta;
                                selected = { ...selected, [llave]: { respuesta: valor, tipoArticulo: value.tipoArticulo, tipoRespuesta: value.tipoRespuesta } };
                                if (value.tipoRespuesta === 1 || value.tipoRespuesta === 2)
                                    aspectosRequeridosDocs++;
                            });
                            response.data.entity.aspectosMats.map((value, index) => {
                                let llave = value.idAspecto;
                                let valor = value.respuesta;
                                selected = { ...selected, [llave]: { respuesta: valor, tipoArticulo: value.tipoArticulo, tipoRespuesta: value.tipoRespuesta } };
                                if (value.tipoRespuesta === 1 || value.tipoRespuesta === 2)
                                    aspectosRequeridosMats++;
                            });
                            if (response.data.entity.fileBase64 !== null && response.data.entity.fileBase64 !== "") {
                                const fileBase64 = 'data:application/pdf;base64,' + response.data.entity.fileBase64;
                                const blob1 = base64toBlob(fileBase64);
                                file = {
                                    uid: '1',
                                    name: response.data.entity.fileName,
                                    type: "application/pdf",
                                    status: 'done',
                                    file: blob1
                                }
                            }
                            setFormData({
                                verificacion: response.data.entity.verificacion,
                                dataAspectosDocs: response.data.entity.aspectosDocs,
                                dataAspectosMats: response.data.entity.aspectosMats,
                                cargos: response.data.entity.cargos,
                                oples: response.data.entity.oples,
                                empresas: response.data.entity.empresas,
                                selected: selected,
                                file: file,
                                aspectosRequeridosDocs: aspectosRequeridosDocs,
                                aspectosRequeridosMats: aspectosRequeridosMats,
                                enableModificar: response.data.entity.enableModificar,
                                enableCaptura: response.data.entity.enableCaptura,
                                loaded: true,
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    }

    return (
        <div>
            <Modal
                visible={modalVisible}
                title='¿Está seguro cancelar el registro de verificación?'
                footer={[
                    <div>
                        <Button key="back"
                            onClick={() => {
                                setModalVisible(false);
                            }}
                            className="boton-modal-cancelar"
                        >
                            Cancelar
                  </Button>
                        <Button key="aceptar" type="primary"
                            onClick={() => {
                                window.location = "/produccion/verificaciones"
                            }}
                            className="boton-modal-aceptar"
                        >
                            Aceptar
                  </Button>
                    </div>
                ]}
            />
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null}
            <br />
            <WrappedFormRegistroVerificacion
                verificacion={formData.verificacion}
                dataAspectosDocs={formData.dataAspectosDocs}
                dataAspectosMats={formData.dataAspectosMats}
                oples={formData.oples}
                empresas={formData.empresas}
                selected={formData.selected}
                file={formData.file}
                aspectosRequeridosMats={formData.aspectosRequeridosMats}
                aspectosRequeridosDocs={formData.aspectosRequeridosDocs}
                cargos={formData.cargos}
                loaded={formData.loaded}
            />
            <br />
            <div className="div-botones">
                <Button
                    className="cancelar-evidencia"
                    onClick={() => {
                        setModalVisible(true);
                    }}
                > Cancelar </Button>
                <Button
                    form="verificacion"
                    htmlType="submit"
                    className="guardar-evidencia"
                    disabled={(formData.dataAspectosDocs.length + formData.dataAspectosMats.length) === 0 || !formData.enableModificar}
                > Guardar{props.idVerificacion !== undefined && props.idVerificacion !== null ? " cambios" : ""}</Button>
            </div>
        </div>
    )

}