import React from "react";
import './ReporteInventario.css';
import { List, Col, Row, Skeleton } from "antd";
import { Redirect } from 'react-router';

const headers = [{ tag: "No.", width: 1, style: "texto-centrado", field: "id" },
{ tag: "Material", width: 15, style: "texto-izquierda", field: "nombre" },
{ tag: "Entregó", width: 3, style: "texto-centrado", field: "entrego" },
{ tag: "Fecha de entrega", width: 4, style: "texto-centrado", field: "fecha" }];

const headersOc = [{ tag: "No.", width: 1, style: "texto-centrado", field: "id" },
{ tag: "Entidad", width: 4, style: "texto-centrado", field: "nombreEstado" },
{ tag: "Material", width: 11, style: "texto-izquierda", field: "nombre" },
{ tag: "Entregó", width: 3, style: "texto-centrado", field: "entrego" },
{ tag: "Fecha de entrega", width: 4, style: "texto-centrado", field: "fecha" }];


class ReporteInventarioMateriales extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
        };

    }

    componentDidMount() {
    }

    buildHeaders = (structure) => {
        let headers = [];
        for (var i = 0; i < structure.length; i++) {
            headers.push(
                <Col xs={structure[i].width} className={structure[i].style} key={i}>
                    {structure[i].tag}
                </Col>
            )
        }
        return headers;
    };

    generateTableData(data, structure) {
        let tableData = [];
        const mapa = new Map(Object.entries(data));
        for (var i = 0; i < structure.length; i++) {
            tableData.push(
                <Col span={structure[i].width} className={structure[i].style + ' fila'} key={i}>
                    {structure[i].field !== 'entrego' ? mapa.get(structure[i].field)
                        :
                        (mapa.get(structure[i].field) === "1" ? "Sí" : "No")
                    }
                </Col>
            )
        }
        return tableData;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/reportes" />;
        }

        return (
            <div>
                <br />

                <List

                    header={
                        <Row className="header-tabla-gris">
                            {this.buildHeaders(this.props.isOc == true ? headersOc : headers)}
                        </Row>
                    }
                    pagination={{
                        pageSize: 6,
                    }}
                    size="large"
                    loading={this.props.isLoading}
                    itemLayout="horizontal"
                    dataSource={this.props.materiales}
                    renderItem={item =>
                        <List.Item>
                            <Skeleton title={false} loading={this.props.isLoading} active>
                                <List.Item.Meta
                                    description={
                                        <Row>
                                            {this.generateTableData(item, this.props.isOc ? headersOc : headers)}
                                        </Row>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    }
                />
            </div>
        );
    }
}

export default ReporteInventarioMateriales;