import React, { useState, useEffect, useCallback } from "react";
import { Select, Button, Divider, Spin, Form, Input, Modal, message } from 'antd';
import { EyeOutlined, PlusOutlined, FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { Redirect } from 'react-router';
import { Tooltip } from '@material-ui/core';

import Table from "react-table-v6";
import Paginacion from "../ListaDocs/Paginacion";

import GridFilters from "../../utils/GridFilters";

import axios from "axios";
import useAuth from "../../hooks/useAuth";
import "./ConsultarArticulosAdjudicaciones.scss";
import { basePath } from "../../api/config";
import Mensajes from "../Mensajes/Mensajes";

const { Option } = Select;
const { TextArea } = Input;

export default function ConsultarArticulosAdjudicaciones(props) {
    var tiempoMensaje = 5;
    const urlConsultaTiposDocumentacionAdjudicacion = `${basePath}/consultaTiposDocumentacion`;
    //const urlConsultaTiposDocumentacionAdjudicacion = `https://localhost:8443/docs_ws_adjudica/ws/consultaTiposDocumentacion`;
    const urlConsultaArticulosAdjudicacion = `${basePath}/consultaArticulosAdjudicacion`;
    //const urlConsultaArticulosAdjudicacion = `https://localhost:8443/docs_ws_adjudica/ws/consultaArticulos`;
    const urlEnviaNotificacionAdjudicacion = `${basePath}/enviaNotificacionAdjudicacion`;
    //const urlEnviaNotificacionAdjudicacion = `https://localhost:8443/docs_ws_adjudica/ws/enviaNotificacionAdjudicacion`;
    const urlDesactivaAdjudicacion = `${basePath}/desactivaAdjudicacion`;

    const { user } = useAuth();

    const { getFieldDecorator } = props.form;

    const [options, setOptions] = useState({
        data: [],
        loadingData: false
    });

    const [selected, setSelected] = useState({
        data: null
    });

    const [articulosData, setArticulosData] = useState({
        data: [],
        notificacionDisable: true,
        loadingData: false
    });

    const [acciones, setAcciones] = useState({
        enableCaptura: false,
        enableModificar: false
    });

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    const [redirect, setRedirect] = useState({
        redirecting: false,
        url: "",
        idArticulo: -1,
        idTipoDocumentacion: -1,
        nombreArticulo: "",
    });

    const [eliminar, setEliminar] = useState({
        visible: false,
        idArticulo: -1,
        idTipoDocumentacion: -1
    });

    const [muestraJustificacion, setMuestraJustificacion] = useState({
        visible: false,
        nombreArticulo: "",
        justificacion: "",
    });

    const handleSelect = useCallback((value) => {
        console.log("TipoDocumentacion:=>", value);
        setSelected({
            data: value
        });
        setArticulosData({
            data: [],
            notificacionDisable: true,
            loadingData: false
        });
        doRequestArticulos(value);
    });

    const handleNotificar = useCallback(() => {
        doRequestNotificacion();
    });

    const handleClickRegistrar = useCallback((id, nombre, conAlerta) => {
        console.log("Handle registrar adjudicación");
        setRedirect({
            redirecting: true,
            url: '/adjudicacion/registrarAdjudicaciones',
            idArticulo: id,
            idTipoDocumentacion: selected.data,
            nombreArticulo: nombre,
        })
    });

    const handleClickConsultar = useCallback((id, nombre, conAlerta, justificacion) => {
        console.log("Handle consultar adjudicación");
        if (conAlerta) {
            setMuestraJustificacion({
                visible: true,
                nombreArticulo: nombre,
                justificacion: justificacion,
            });
        }
        else {
            setRedirect({
                redirecting: true,
                url: '/adjudicacion/consultarAdjudicaciones',
                idArticulo: id,
                idTipoDocumentacion: selected.data,
                nombreArticulo: nombre,
            })
        }
    });

    const handleClickModificar = useCallback((id, nombre, conAlerta) => {
        console.log("Handle modificar adjudicación");
        setRedirect({
            redirecting: true,
            url: '/adjudicacion/modificarAdjudicaciones',
            idArticulo: id,
            idTipoDocumentacion: selected.data,
            nombreArticulo: nombre,
        })
    });

    const handleClickEliminar = useCallback((id, conAlerta) => {
        setEliminar({
            visible: true,
            idArticulo: id,
            idTipoDocumentacion: selected.data
        });
    });

    //Se definen las columnas de la tabla de Correos electrónicos
    const columns = [
        {
            Header: "No.",
            accessor: "consecutivo",
            style: {
                textAlign: "left"
            },
            width: 75,
            maxWidth: 75,
            minWidth: 75,
            filterable: false,
            sortable: true,
            style: {
                textAlign: "center"
            },
        },
        {
            Header: "Documento/Material",
            accessor: "nombre",
            sortable: true,
            ...GridFilters
        },
        {
            Header: "Acciones",
            style: {
                color: "blue"
            },
            width: 125,
            maxWidth: 125,
            minWidth: 125,
            Cell: props => {
                return (
                    console.log("Se pintan las celdas"),
                    <div className="div-acciones">
                        {props.original.acciones.map((value, index) => {
                            //console.log("value.nombre", value.nombre);
                            if (value.nombre === "consultar") {
                                return [
                                    <Tooltip title="Consultar">
                                        <Button
                                            className="ilist"
                                            type="link"
                                            shape="circle"
                                            onClick={handleClickConsultar.bind(
                                                this,
                                                props.original.id,
                                                props.original.nombre,
                                                value.conAlerta,
                                                props.original.justificacion,
                                            )}
                                            disabled={!value.isActivo}
                                        >
                                            <EyeOutlined />
                                        </Button>
                                    </Tooltip>,
                                    <Divider className="dcolor" type="vertical" />
                                ];
                            } else if (value.nombre === "agregar") {
                                return (
                                    <Tooltip title="Adjudicar">
                                        <Button
                                            className="ilist"
                                            type="primary"
                                            shape="circle"
                                            accept=""
                                            onClick={handleClickRegistrar.bind(
                                                this,
                                                props.original.id,
                                                props.original.nombre,
                                                props.original.conAlerta)}
                                            disabled={!value.isActivo || !acciones.enableCaptura}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </Tooltip>
                                )
                            } else if (value.nombre === "modificar") {
                                return (
                                    <Tooltip title="Modificar">
                                        <Button
                                            className="ilist"
                                            type="primary"
                                            shape="circle"
                                            onClick={handleClickModificar.bind(
                                                this,
                                                props.original.id,
                                                props.original.nombre,
                                                props.original.conAlerta)}
                                            disabled={!value.isActivo || !acciones.enableCaptura}
                                        >
                                            <FormOutlined />
                                        </Button>
                                    </Tooltip>
                                )
                            }
                            else if (value.nombre === "eliminar") {
                                return [
                                    <Divider className="dcolor" type="vertical" />,
                                    <Tooltip title="No Adjudicar">
                                        <Button
                                            className="ilist"
                                            type="primary"
                                            shape="circle"
                                            onClick={handleClickEliminar.bind(this, props.original.id, props.original.nombre, props.original.conAlerta)}
                                            disabled={!value.isActivo || !acciones.enableCaptura}
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </Tooltip>
                                ]
                            }
                            else return <span />;
                        })}
                    </div>
                );
            },
            style: {
                color: "blue"
            },
            sortable: false,
            filterable: false
        },
    ];

    function doRequestOptions() {
        if ((user.infoMenu.estadoSelec === null && user.infoMenu.detalleSelec.idEstado === 0) || (user.infoMenu.estadoSelec !== null && user.infoMenu.estadoSelec.idEstado === 0)) {
            /* setVisibleAlert({
                activado: true,
                tipoMensaje: "warning",
                mensaje: "Favor de seleccionar una entidad en el menú del proceso electoral",
            })
            return; */
            message.warning("Favor de seleccionar una entidad en el menú del proceso electoral.",tiempoMensaje);
            return;
        }
        let data = JSON.stringify({
            consultaParametros: {
                idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
                idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
                idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
                anioProceso: user.infoMenu.detalleSelec.anio,
                tipoProceso: user.infoMenu.detalleSelec.tipoProceso
            }
        })

        axios
            .post(
                urlConsultaTiposDocumentacionAdjudicacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            console.log("response:=>", response);
                            setOptions({
                                data: response.data.entity.select,
                                //data: response.data.select,
                                loadingData: true,
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                setOptions({
                    data: [],
                    loadingData: true
                });
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    };

    function doRequestArticulos(idTipoDocumentacion) {
        let data = JSON.stringify({
            consultaParametros: {
                idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
                idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
                idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
                anioProceso: user.infoMenu.detalleSelec.anio,
                tipoProceso: user.infoMenu.detalleSelec.tipoProceso
            },
            idTipoDocumentacion: idTipoDocumentacion
        })
        axios
            .post(
                urlConsultaArticulosAdjudicacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error(response.data.entity.causa,tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            //console.log("response:=>", response);
                            setArticulosData({
                                data: response.data.entity.articulos,
                                notificacionDisable: response.data.entity.notificacionDisable,
                                loadingData: true
                            });
                            setAcciones({
                                enableCaptura: response.data.entity.enableCaptura,
                                enableModificar: response.data.entity.enableModificar
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestArticulos", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                setArticulosData({
                    data: [],
                    notificacionDisable: true,
                    loadingData: false
                });
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    }

    function doRequestNotificacion() {
        let data = JSON.stringify({
            parametros: {
                idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
                idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
                idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
                anioProceso: user.infoMenu.detalleSelec.anio,
                tipoProceso: user.infoMenu.detalleSelec.tipoProceso
            },
            idTipoDocumentacion: selected.data
        })
        axios
            .post(
                urlEnviaNotificacionAdjudicacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error(response.data.entity.causa,tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            setArticulosData({
                                data: [],
                                notificacionDisable: true,
                                loadingData: false
                            });
                            /* setVisibleAlert({
                                activado: true,
                                tipoMensaje: "success",
                                mensaje: "Se ha enviado la notificación al área correspondiente."
                            }) */
                            message.success("Se ha enviado la notificación al área correspondiente",tiempoMensaje);
                            doRequestArticulos(selected.data);
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestArticulos", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error a relizar la petición solicitada.", tiempoMensaje);
            });
    }

    function doRequestDesactivaAdjudicacion() {
        let data = JSON.stringify({
            parametros: {
                idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
                idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
                idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
                anioProceso: user.infoMenu.detalleSelec.anio,
                tipoProceso: user.infoMenu.detalleSelec.tipoProceso
            },
            idTipoDocumentacion: eliminar.idTipoDocumentacion,
            idArticulo: eliminar.idArticulo,
            justificacion: props.form.getFieldValue('justificacion')
        })

        axios
            .post(
                urlDesactivaAdjudicacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error(response.data.entity.causa,tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            console.log("response:=>", response);
                            props.form.resetFields();
                            setArticulosData({
                                data: [],
                                notificacionDisable: true,
                                loadingData: false
                            });
                            doRequestArticulos(selected.data);
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                props.form.resetFields();
                setOptions({
                    data: [],
                    loadingData: true
                });
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    };

    useEffect(() => {
        if (!options.loadingData)
            doRequestOptions();
    }, [options]);

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    const renderRedirect = () => {
        if (redirect.redirecting) {
            return <Redirect to={{
                pathname: redirect.url,
                state: {
                    idArticulo: redirect.idArticulo,
                    nombreArticulo: redirect.nombreArticulo,
                    idTipoDocumentacion: redirect.idTipoDocumentacion,
                }
            }
            } />
        }
    }

    const handleModalEnviar = () => {
        props.form
            .validateFields()
            .then(values => {
                doRequestDesactivaAdjudicacion();
                setEliminar({
                    visible: false,
                    idArticulo: -1,
                    idTipoDocumentacion: -1
                });
                props.form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    const handleModalCancel = () => {
        props.form.resetFields();
        setEliminar({
            visible: false,
            idArticulo: -1,
            idTipoDocumentacion: -1
        });
    }

    const handleModalCancelJustificacion = () => {
        setMuestraJustificacion({
            visible: false,
            nombreArticulo: "",
            justificacion: "",
        });
        props.form.resetFields();
    }

    return (
        <div>
            {renderRedirect()}
            <div>
                {visibleAlert.activado ?
                    (
                        <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                    ) : null}
                <br />
                <h1 className="titulo-adjudicacion">Registro de adjudicación</h1>
                <label className="texto-14-adjudicacion">Los datos con ( <label className="rojo-adjudicacion">*</label> ) son requeridos.</label>
                <br />
                <br />
                <br />
                <label className="texto-18-adjudicacion"><label className="rojo-adjudicacion">*</label> Tipo de clasificación de elección</label>
                <br />
                <Select

                    onSelect={handleSelect.bind(this)}
                    defaultValue="Seleccionar opción"
                    placeholder="Seleccionar opción"
                    id="opcion"
                >
                    {Object.entries(options.data).map((value, index) => {
                        return <Option id="opcion" key={value[0]}>{value[1]}</Option>;
                    })}
                </Select>
            </div>
            {articulosData.loadingData ?
                (<div>
                    <div className="div-container-table-adjudicacion">
                        <Table
                            columns={columns}
                            data={articulosData.data}
                            defaultPageSize={10}
                            PaginationComponent={Paginacion}
                            filterable={true}
                            noDataText={"Sin datos que mostrar"}
                        />
                    </div>
                    <div className="div-container-notificar">
                        <Button className="button-notificar"
                            disabled={articulosData.notificacionDisable}
                            onClick={handleNotificar.bind(this)}> Notificar </Button>
                    </div>
                    <Modal
                        visible={eliminar.visible}
                        title="Artículo no adjudicado"
                        //onOk={handleModalEnvíar}
                        onCancel={handleModalCancel}
                        footer={[
                            <div className="justificacion-footer">
                                <Button key="back" onClick={handleModalCancel} className="boton-modal-cancelar"
                                >
                                    Cancelar
                                </Button>
                                <Button key="submit" type="primary"
                                    //loading={this.state.modalLoading} 
                                    onClick={handleModalEnviar}
                                    className="boton-modal-aceptar"
                                    disabled={!acciones.enableModificar}
                                >
                                    Aceptar
                                </Button>
                            </div>
                        ]}
                    >
                        <Form layout="vertical">
                            <Form.Item
                                label="Justificación"
                                name="justificacion"
                                hasFeedback
                            >
                                {getFieldDecorator('justificacion', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor ingresa la justificación!',
                                        }
                                    ],
                                })(
                                    <TextArea rows={4} autoSize={false} allowClear={true} maxLength={250} />
                                )}
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        visible={muestraJustificacion.visible}
                        title={muestraJustificacion.nombreArticulo}
                        //onOk={handleModalEnvíar}
                        onCancel={handleModalCancelJustificacion}
                        footer={[]}
                    >
                        <label className="texto-16-adjudicacion-modal-bold">Justificación</label>
                        <br />
                        <div className="contenidoJustificacion">
                            <label className="texto-16-adjudicacion-modal">{muestraJustificacion.justificacion}</label>
                        </div>
                    </Modal>
                </div>)
                :
                (
                    <div className="div_cargando">
                        <br />
                        {selected.data !== null ? (<Spin size="large" />) : null}
                    </div>
                )
            }
        </div>
    );
}