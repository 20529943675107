import React, { useState, useEffect } from "react";
import "./CrearArticulo.scss";
import { Form, Input, Button, Upload, Radio, Select, message } from 'antd';
import { PlusOutlined, UploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Mensajes from "../Mensajes/Mensajes";
import SelectDocumentos from "../ListaDocs/SelectDocs";
import { basePath } from "../../api/config";
import axios from "axios";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { useHistory } from "react-router-dom";
import { Tooltip } from '@material-ui/core';
const { Option } = Select;

export default function CrearArticulo(props) {

    var tiempoMensaje = 5;
    let history = useHistory();
    const { TextArea } = Input;
    const inputEspTec = React.createRef();
    const inputEspDis = React.createRef();
    const urlTiposClasificacion = `${basePath}/obtenTiposClasificacionEleccion`;
    const urlTiposClasificacionByTipoArticulo = `${basePath}/obtenTiposClasElecPorTipoArticulo`;
    //const urlTiposSubclasificacion = `${basePath}/obtenTiposSubclasificacionEleccion`;
    //const urlTiposSubclasificacionByTipoArticulo = `${basePath}/obtenTiposSubclasElecPorTipoArticulo`;
    const urlCrearArticulo = `${basePath}/crearArticulo`;

    const [tiposSubclasificacionHook, setTiposSubclasificacionHook] = useState({
        datos: {'-1': 'SIN CLASIFICACION ADICIONAL', '0': 'DOCUMENTACION SIN EMBLEMAS', '1': 'DOCUMENTACION CON EMBLEMAS', '2': 'BOLETAS'}
    });
    //var tiposSubclasificacionVar = { '-1': 'SIN CLASIFICACION ADICIONAL', '0': 'DOCUMENTACION SIN EMBLEMAS', '1': 'DOCUMENTACION CON EMBLEMAS', '2': 'BOLETAS'};
    const [urlTiposClasi, setUrlTiposClasi] = useState({
        url: urlTiposClasificacion  
    });
    /*const [urlTiposSubclasi, setUrlTiposSubclasi] = useState({
        url: urlTiposSubclasificacion  
    });*/
    const [nombreArticuloHook, setNombreArticuloHook] = useState({
        nombre: ""
    });
    const [tipoArticuloHook, setTipoArticuloHook] = useState({
        tipo: -1
    });
    const [tieneEmblemaHook, setTieneEmblemaHook] = useState({
        tiene: -1
    });
    const [listaEspTec, setListaEspTec] = useState({
        especificaciones: new Map()
    });
    const [listaEspDis, setListaEspDis] = useState({
        especificaciones: new Map()
    });
    const [archivoDisenioUnico, setArchivoDisenioUnico] = useState({
        uploading: false,
        file: "",
        urlPDF: null
    });
    const [justificacionHook, setJustificacionHook] = useState({
        justificacion: ""
    });
    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });
    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }
    const propsDisenioUnico = {
        multiple: false,
        onRemove: file => {
            setArchivoDisenioUnico(archivoDisenioUnico => {
                return {
                    file: archivoDisenioUnico.file
                };
            });
        },
        beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            const isSomething = file.size > 0;
            const isSize = (file.size / 1024 / 1024) < 50; //Limita hasta 50 MB
            if (!isPDF) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",                   
                    mensaje: "El formato del archivo no corresponde a un CSV. Favor de borrarlo y cargar uno válido."
                }); */
               message.error("El formato del archivo no corresponde a un PDF. Favor de borrarlo y cargar uno válido.",tiempoMensaje);
                return true;
            }
            if (!isSomething) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",   
                    mensaje: "El archivo se encuentra vacío. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo se encuentra vacío. Favor de borrarlo y cargar uno válido.",tiempoMensaje);
                return true;
            }
            if (!isSize) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "El archivo pesa más de 10 MB. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo pesa más de 50 MB. Favor de borrarlo y cargar uno válido.",tiempoMensaje);
                return false;
            }
            else {
                const url = URL.createObjectURL(file);
                setArchivoDisenioUnico({
                    file: file,
                    urlPDF: url,
                    uploading: true,
                });

                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "success",
                    mensaje: "El archivo fue cargado exitosamente. Para poder subir otro PDF primero debe eliminar el existente."
                }); */
                message.success("El archivo fue cargado exitosamente. Para poder subir otro PDF primero debe eliminar el existente.",tiempoMensaje);
            }
            return false;
        },
        archivoDisenioUnico
    };

    const ListaEspecificaciones = function (props) {
        return (
            <div>
                <ul>
                    {[...props.especificaciones.keys()].map(k => (
                        <li key={k}>{props.especificaciones.get(k)}</li>
                    ))}
                </ul>
            </div>
        );
    };

    useEffect(() => {
        console.log("-----Entró en useEffect()");
        if (!archivoDisenioUnico.uploading) {            
        }
    }, [archivoDisenioUnico]);    


    /**************************************
     * Código para el Select del Tipo de Proceso Electoral
     **************************************/
    const estadoSel = localStorage.getItem('ID_ESTADO_SELECCIONADO');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const detalleSel = localStorage.getItem('ID_DETALLE_PROCESO');
    let dataSel = JSON.stringify({
        idProceso: procesoSel,
        idEstado: estadoSel,
        idDetalleProceso: detalleSel
    });     
    const [dataSelectState, setDataSelectState] = useState({
        dataSelect: dataSel
    });
    const [idEstadoSeleccionadoState, setIdEstadoSeleccionadoState] = useState({
        idEstadoSeleccionado: estadoSel
    });
    const [idProcesoSeleccionadoState, setIdProcesoSeleccionadoState] = useState({
        idProcesoSeleccionado: procesoSel
    });
    const [idDetalleProcesoSeleccionadoState, setIdDetalleProcesoSeleccionadoState] = useState({
        idDetalleProcesoSeleccionado: detalleSel
    });
    const [tipoClasificacionEleccionHook, setTipoClasificacionEleccionHook] = useState({
        tipo: 0
    });
    const [tipoSubclasificacionEleccionHook, setTipoSubclasificacionEleccionHook] = useState({
        tipo: -2
    });
    //**************************************** */

    const handleTipoArticulo = event => {
        const valor = event.target.value;           
        let dataSelByTipoArticulo = JSON.stringify({
            idProceso: procesoSel,
            idEstado: estadoSel,
            idDetalleProceso: detalleSel,
            idTipoArticulo: valor
        });
        setTipoArticuloHook({
            tipo: valor
        });
        setDataSelectState({
            dataSelect: dataSelByTipoArticulo
        });
        setUrlTiposClasi({
            url: urlTiposClasificacionByTipoArticulo
        });      

        if (valor === 1) {
            setTiposSubclasificacionHook({
                datos: {'-1': 'SIN CLASIFICACION ADICIONAL'}
            });
        }
        else {
            setTiposSubclasificacionHook({
                datos: {'0': 'DOCUMENTACION SIN EMBLEMAS', '1': 'DOCUMENTACION CON EMBLEMAS', '2': 'BOLETAS'}
            });
        }

        setTipoSubclasificacionEleccionHook({
            tipo: -2
        });
    };
    const handleTieneEmblema = event => {
        const valor = event.target.value;
        tieneEmblemaHook.tiene = valor;
    };
    const handleSubmitNombreArticulo = event => {
        console.log("event: ", event);
    };
    const handleInputNombreArticulo = event => {
        nombreArticuloHook.nombre = event.target.value;
    };
    const handleSubmitJustificacion = event => {
        console.log("event: ", event);
    };
    const handleInputJustificacion = event => {
        justificacionHook.justificacion = event.target.value;
    };

    return (
        <div className="div_creararticulo">
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null}     
            <Form>
            <div className="div_titulo">
                        <Button id="regresar"
                            type="link"
                            href="/configuracion/consultarcatalogos">
                            <ArrowLeftOutlined />Regresar
                        </Button>
                    </div>
                <div className="div_titulo">
                    <h1 id="titulo">Nuevo registro de documento o material</h1>
                    <p id="msg">Los datos con (<span id="smsg">*</span>) son requeridos</p>
                </div>
                <div className="div_nombrearticulo">
                    <p id="msg">
                        <span id="smsg">*</span>Nombre del documento o material
                    </p>
                    <form onSubmit={handleSubmitNombreArticulo}>
                        <Input
                            placeholder="Ingresa el nombre del documento o material"
                            type="text"
                            onChange={handleInputNombreArticulo}
                            maxlength={300}
                        />
                    </form>
                </div>
                <div className="div_nombrearticulo">           
                    <p id="msg">
                        <span id="smsg">*</span>Tipo de artículo a registrar
                    </p>
                    <div>
                        <Radio.Group onChange={handleTipoArticulo}>
                            <Radio value={1}> Material </Radio>
                            <Radio value={2}> Documento</Radio>
                        </Radio.Group>
                    </div>
                </div>
                {tipoArticuloHook.tipo === -1 ? (
                    <div className="div_nombrearticulo">            
                        <p>
                            <span id="smsg">*</span>Clasificación del documento o material
                        </p>
                        <div>
                           <SelectDocumentos                                
                                onChange={handleSelect}
                                data={dataSelectState.dataSelect}    
                                rutaWS={urlTiposClasi.url} 
                           />   
                        </div>
                    </div>
                ) : ( tipoArticuloHook.tipo === 1 ? (
                    <div className="div_nombrearticulo">
                        <p>
                            <span id="smsg">*</span>Clasificación del documento o material
                        </p>
                        <div>
                            <div>
                                <SelectDocumentos                        
                                    onChange={handleSelect}
                                    data={dataSelectState.dataSelect}
                                    rutaWS={urlTiposClasi.url}        
                                />
                            </div>                            
                        </div>
                    </div>
                ) : (        
                    <div className="div_nombrearticulo">   
                        <p>
                            <span id="smsg">*</span>Clasificación del documento o material
                        </p>
                        <div>
                            <div>
                                <div>
                                    <SelectDocumentos                        
                                        onChange={handleSelect}
                                        data={dataSelectState.dataSelect}    
                                        rutaWS={urlTiposClasi.url}                 
                                    />
                                </div>                            
                            </div>                        
                        </div>
                    </div>
                ))}    
                <div className="div_nombrearticulo">            
                        <p>
                            <span id="smsg">*</span>Subclasificación del documento o material
                        </p>                        
                        {tipoArticuloHook.tipo === 1 ? (
                            <div>
                                <Select 
                                    defaultValue="Seleccionar opción"
                                    placeholder="Seleccionar opción"
                                    onSelect={handleSelectSubClasi}
                                >
                                    {Object.entries(tiposSubclasificacionHook.datos).map((value, index) => {
                                        return <Option key={value[0]} value={value[0]}> {value[1]} </Option>;
                                    })}
                                </Select>
                            </div>
                        ) : (
                            <div>
                                <div>                                
                                    <Select 
                                        defaultValue="Seleccionar opción"
                                        placeholder="Seleccionar opción"
                                        onSelect={handleSelectSubClasi}
                                    >
                                        {Object.entries(tiposSubclasificacionHook.datos).map((value, index) => {
                                            return <Option key={value[0]} value={value[0]}> {value[1]} </Option>;
                                        })}
                                    </Select>
                                </div>
                            </div>
                        )}                                                    
                </div>
                <div className="div_nombrearticulo">
                    <p id="msg">
                        <span id="smsg">*</span>&iquest;Tiene emblema el documento o material?
                    </p>
                    <div>
                        <Radio.Group onChange={handleTieneEmblema}> 
                            <Radio value={1}> Sí </Radio>
                            <Radio value={0}> No </Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className="div_diseniounico">
                    <div>
                        <Worker
                            workerUrl={window.location.origin + "/pdf.worker.min.js"}
                        >
                            <div style={{ height: '750px', width: '900px' }}>
                                {archivoDisenioUnico.uploading ? (
                                    console.log("urlPDF", archivoDisenioUnico.urlPDF),
                                    <Viewer
                                        fileUrl={archivoDisenioUnico.urlPDF} />
                                ) : (
                                        <div
                                            style={{
                                                alignItems: 'center',
                                                border: '2px dashed rgba(0, 0, 0, .3)',
                                                display: 'flex',
                                                fontSize: '2rem',
                                                height: '100%',
                                                justifyContent: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            Área de pre-visualización
                                        </div>
                                    )}
                            </div>
                        </Worker>
                    </div>
                    <div className="div_boton_agregar">
                        <Upload id="subirDisUni" {...propsDisenioUnico} accept=".pdf">
                            <Tooltip title="Se permiten únicamente archivos en formato PDF">
                            <Button className="subir">
                                <UploadOutlined />Subir
                            </Button>
                            </Tooltip>
                        </Upload>
                    </div>
                </div>

                <div className="div_especificaciones">
                    <div className="div_especificaciones_col">
                        <Form onSubmit={crearEspTec}> 
                            <p id="msg">
                                <span id="smsg">*</span>Especificaciones Anexo 4.1
                            <button type="submit" className="agregar"> <PlusOutlined />Agregar </button>
                            </p>
                            <input type="text" placeholder="Agrega una especificación" style={{ width: '85%' }} ref={inputEspTec} maxlength={1500}/>
                            <ListaEspecificaciones especificaciones={listaEspTec.especificaciones} />
                        </Form>
                        <p id="msg">
                            Justificación
                        </p>
                        <div>
                            <form onSubmit={handleSubmitJustificacion}>
                                <TextArea rows={3}
                                    placeholder="Justifica la captura del nuevo documento o material"
                                    onChange={handleInputJustificacion}
                                    maxlength={150} />
                            </form>
                        </div>
                    </div>


                    <div className="div_especificaciones_col">
                        <Form onSubmit={crearEspDis}>
                            <p id="msg">
                                <span id="smsg">*</span>Especificaciones Únicas
                            <button type="submit" className="agregar"> <PlusOutlined /> Agregar </button>
                            </p>

                        <input type="text" placeholder="Agrega una especificación" style={{ width: '81%' }} ref={inputEspDis} maxlength={1500} />
                            <ListaEspecificaciones especificaciones={listaEspDis.especificaciones} />
                        </Form>
                    </div>
                </div>

                <div className="div_botones_finales">
                    <Button className="cancelarc"
                        type="link"
                        href="/configuracion/consultarcatalogos"> Cancelar
                    </Button>
                    <Button type="primary" disabled={!props.enableModificar} className="guardarc" onClick={handleGuardar}> Guardar </Button>
                </div>
            </Form>
        </div>
    );

    function handleSelect(value) {
        let data = JSON.stringify({
            consultaParametros: {
                idProceso: idProcesoSeleccionadoState.idProcesoSeleccionado,
                idEstado: idEstadoSeleccionadoState.idEstadoSeleccionado,
                idDetalleProceso: idDetalleProcesoSeleccionadoState.idDetalleProcesoSeleccionado
            },
            tipoClasificacionEleccion: value
        });
        tipoClasificacionEleccionHook.tipo = value;
    }

    function handleSelectSubClasi(value) {
        console.log("value en handleSelectSubClasi(): ", value);
        tipoSubclasificacionEleccionHook.tipo = value;
    }

    function addEspecificacionTec(especificacion) {
        var timestamp = (new Date()).getTime();
        let idSpec = 'espTec-' + timestamp;
        setListaEspTec({
            especificaciones: listaEspTec.especificaciones.set(idSpec, especificacion)
        });
    }

    function addEspecificacionDis(especificacion) {
        var timestamp = (new Date()).getTime();
        let idSpec = 'espTec-' + timestamp;
        setListaEspDis({
            especificaciones: listaEspDis.especificaciones.set(idSpec, especificacion)
        });
    }

    function crearEspTec(e) {
        e.preventDefault();
        var espTec = inputEspTec.current.value;
        if (typeof espTec === 'string' && espTec.length > 0) { //No funciona bien la comparación para verificar si es una cadena o no.     
            addEspecificacionTec(espTec);
            inputEspTec.current.value = "";
        }
    }

    function crearEspDis(e) {
        console.log("ESPECIFICACION DISEÑO", e);        
        e.preventDefault();
        var espDis = inputEspDis.current.value;
        if (typeof espDis === 'string' && espDis.length > 0) { 
            addEspecificacionDis(espDis);
            inputEspDis.current.value = "";
        }
    }

    function handleGuardar() {
        console.log("Entró al método handleGuardar()");
        const formData = new FormData();
        formData.append("idDetalleProceso", localStorage.getItem('ID_DETALLE_PROCESO'));
        formData.append("idProcesoElectoral", localStorage.getItem('ID_PROCESO_ELECTORAL'));  
        if (localStorage.getItem('ID_ESTADO_SELECCIONADO') !== 'null') {
            console.log("ID_ESTADO_SELECCIONADO !== null");
            formData.append("idEstado", localStorage.getItem('ID_ESTADO_SELECCIONADO'));
        }
        else {
            console.log("ID_ESTADO_SELECCIONADO == null");
            formData.append("idEstado", localStorage.getItem('ID_ESTADO'));
        }
        formData.append("anio", localStorage.getItem('ANIO'));
        formData.append("tipoProceso", localStorage.getItem('TIPO_PROCESO'));

        const regex = /,/gi;
        let listaEspTecRequest = [];

        [...listaEspTec.especificaciones.keys()].map(k => (
            listaEspTecRequest = [...listaEspTecRequest, listaEspTec.especificaciones.get(k).replace(regex,'%coma%')]
        ))

        let listaEspDisRequest = [];

        [...listaEspDis.especificaciones.keys()].map(k => (
            listaEspDisRequest = [...listaEspDisRequest, listaEspDis.especificaciones.get(k).replace(regex,'%coma%')]
        ))

        //console.log("---justificacionHook.justificacion.length: ", justificacionHook.justificacion.length);
        //console.log("---tipoSubclasificacionEleccionHook.tipo: ", tipoSubclasificacionEleccionHook.tipo);
        //Validaciones
        if (nombreArticuloHook.nombre.length === 0) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "El campo del nombre del artículo no puede estar vacío"
                }); */
                message.error("El campo del nombre del artículo no puede estar vacío.",tiempoMensaje);
        }
        else if (tipoArticuloHook.tipo === -1) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Se debe seleccionar qué tipo de artículo es"
                }); */
                message.error("Se debe seleccionar el tipo de artículo.",tiempoMensaje);
        }
        else if (tipoClasificacionEleccionHook.tipo === 0) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Se debe seleccionar un tipo de clasificación"
                }); */
                message.error("Se debe seleccionar un tipo de clasificación.",tiempoMensaje);
        }        
        else if (tipoSubclasificacionEleccionHook.tipo === -2) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Se debe seleccionar un tipo de subclasificación"
                }); */
                message.error("Se debe seleccionar un tipo de subclasificación.",tiempoMensaje);
        }
        else if (tipoArticuloHook.tipo == 2 && tipoSubclasificacionEleccionHook.tipo == -1) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "La subclasificación elegida es inválida para este tipo de artículo"
                }); */
                message.error("La subclasificación elegida es inválida para este tipo de artículo.",tiempoMensaje);
        }
        else if (tipoArticuloHook.tipo == 1 && tipoSubclasificacionEleccionHook.tipo > -1) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "La subclasificación elegida es inválida para este tipo de artículo"
                }); */
                message.error("La subclasificación elegida es inválida para este tipo de artículo.",tiempoMensaje);
        }
        else if (tipoArticuloHook.tipo === 2 && tieneEmblemaHook.tiene === -1) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Se debe seleccionar si tiene emblema o no"
                }); */
                message.error("Se debe seleccionar si tiene o no emblema el artículo.",tiempoMensaje);
        }        
        else if (archivoDisenioUnico.file.length === 0) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "No hay un diseño único cargado, favor de cargar uno"
                }); */
                message.error("No hay un diseño único cargado, favor de cargarlo.",tiempoMensaje);
        }
        else if (listaEspTecRequest.length === 0) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Debe haber al menos una especificación del anexo 4.1"
                }); */
                message.error("Debe haber al menos una especificación del anexo 4.1.",tiempoMensaje);
        }
        else if (listaEspDisRequest.length === 0) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Debe haber al menos una especificación única"
                }); */
                message.error("Debe haber al menos una especificación única.",tiempoMensaje);
        }
        //else if (justificacionHook.justificacion.length === 0) {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Se debe escribir una justificación"
                }); */
        /*        message.error("Se debe escribir una justificación.",tiempoMensaje);
        }*/
        else {
            formData.append("listaEspTec", listaEspTecRequest);
            formData.append("listaEspDis", listaEspDisRequest);
            formData.append("nombreArticulo", nombreArticuloHook.nombre);
            formData.append("tipoArticulo", tipoArticuloHook.tipo);
            formData.append("tipoClasificacionEleccion", tipoClasificacionEleccionHook.tipo);
            formData.append("tipoSubclasificacionEleccion", tipoSubclasificacionEleccionHook.tipo);
            formData.append("tieneEmblema", tieneEmblemaHook.tiene);
            formData.append("archivos[0].archivo", archivoDisenioUnico.file);
            formData.append("justificacion", justificacionHook.justificacion);

            axios.post(urlCrearArticulo, formData,
                {
                    headers: {
                        'Authorization': localStorage.getItem('accessToken'),
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json"
                    }
                }).then(response => {
                    
                   /*const { code, message } = response.data.entity;
                    console.log("code: ", code);
                    console.log("message: ", message);*/
                    if (response.data.entity.code === 200) {
                                    if (response.data.entity.message !== null && response.data.entity.message !== "") {
                                        /* setVisibleAlert(
                                            {
                                                activado: true,
                                                tipoMensaje: "warning",
                                                mensaje: response.data.entity.message
                                            }); */
                                            message.warning(response.data.entity.message,tiempoMensaje);
                                    }
                                    else {
                                        console.log("Fue correcta la creación y guardado del artículo");
                                        message.success("Se creo correctamente el artículo", tiempoMensaje);
                                        
                                    }
                        history.push('/configuracion/consultarcatalogos');
                    }
                    else {
                        console.log("Hubo error");
                        //alert(message);
                        /* setVisibleAlert({
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: { message }
                        }); */
                        message.error("Ocurrió un error, trate más tarde", tiempoMensaje);
                        localStorage.clear();
                        window.location = "/login"
                    }
                }).catch(error => {
                    console.log(error);
                    if (error.response.status === 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                });
        }
    }

}