import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Spin, Button, Form, Input, message } from 'antd';
import { DownloadOutlined, ArrowLeftOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import { basePath } from "../../../api/config";
import "./sesion.scss"
//import PDFViewer from "mgr-pdf-viewer-react";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import Mensajes from "../../../components/Mensajes/Mensajes";
import { base64toBlob } from "../../../utils/Utils"
import moment from 'moment';
import { Redirect } from 'react-router';

moment.locale('es')

let isRolConsulta = false;
let isRolCAU = false;
let isRolVerifica = false;

export default function ConsultaSesion(props) {
    var tiempoMensaje = 5;
    let clasificacion = "";
    const dateFormat = "DD-MM-YYYY";
    const urlConsultaSesion = `${basePath}/consultaSesionConsejo`;
    const { TextArea } = Input;
    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    const [consulta, setConsulta] = useState({
        posts: [],
        url: null,
        cargada: false
    });

    const [redirect, setRedirect] = useState({
        redirecting: false,
        url: "",
        idAprobacion: -1,

    });
    const [idAprobacion, setIdAprobacion] = useState({
        id: props.location.state.idAprobacion
    });
    const [archivoSesion, setArchivoSesion] = useState({
        uploading: false,
        file: "",
        urlPDF: null
    });

    const handleClickModificar = useCallback((id) => {
        console.log("Handle consultar sesion");
        setRedirect({
            redirecting: true,
            url: '/aprobacion/modificaSesion',
            idAprobacion: id,
        })
    });

    const handleClickDescargar = useCallback(() => {
        console.log("Entro a descagar");
        let a = document.createElement('a');
        a.href = consulta.url;
        /*  a.download = "DOCSMATS-" + consulta.posts.nombreAcuerdo; */
        a.download = "DOCSMATS-" + "acuerdoAprobacion";
        a.click();
        //window.location.href = consulta.url;
    })

    isRolConsulta = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.OC' ? true :
        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.OC' ? true :
            localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.JL' ? true :
                localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.JL' ? true :
                    localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.UTVOPL.OC' ? true :
                        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.OPLE.JL' ? true :
                            localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.SUPERVISOR.OC' ? true :
                                localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.REVISOR.OC' ? true : false;

    isRolCAU = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.ADMIN.OC' ? true :
        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.OPLE.JL' ? true : false; //puede hacer todo

    isRolVerifica = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAU.OC' ? true : false;//puede hacer todo menos guardar

    const renderRedirect = () => {
        if (redirect.redirecting) {
            return <Redirect to={{
                pathname: redirect.url,
                state: {
                    idAprobacion: redirect.idAprobacion,
                }
            }
            } />
        }
    }

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    function doRequest() {
        console.log("Entró en doRequest()");
        let data = JSON.stringify({
            idEstado: localStorage.getItem('ID_ESTADO_SELECCIONADO'),
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            anioProceso: localStorage.getItem('ANIO'),
            tipoProceso: localStorage.getItem("TIPO_PROCESO"),
            rolUsuario: localStorage.getItem("ROL_USUARIO"),
            idAprobacion: idAprobacion.id
        })
        console.log("doRequest.data: ", data);

        axios
            .post(
                urlConsultaSesion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                        message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    //activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                            message.success(response.data.entity.message, tiempoMensaje);
                        }
                        else {
                            let url = null;
                            if (response.data.entity.acuerdoBase64 !== null && response.data.entity.acuerdoBase64 !== "") {
                                const archivoSesionBase64 = 'data:application/pdf;base64,' + response.data.entity.acuerdoBase64;
                                const blob = base64toBlob(archivoSesionBase64);
                                url = URL.createObjectURL(blob);
                                archivoSesion.uploading = true;
                                archivoSesion.file = blob;

                            }
                            setConsulta({
                                posts: response.data.entity,
                                url: url,
                                cargada: true
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch();
    };



    if (consulta.posts.idTipoSesion == 1)
        clasificacion = "Ordinaria";
    else if (consulta.posts.idTipoSesion == 2)
        clasificacion = "Extraordinaria";
    if (consulta.posts.idTipoSesion == 3)
        clasificacion = "Especial";

    const n = "";
    const f = "";
    //consulta.posts.fechaSesion&&(
    // console.log("FECHA", consulta.posts.fechaSesion)

    // n = consulta.posts.fechaSesion.toString(dateFormat)
    // f = n.slice(1, -2),
    // console.log("fecha", f)
    // )

    //consulta.posts.fechaSesion= moment(consulta.post.fechaSesion, dateFormat))
    //  value.fechaSesion = value.fechaSesion.format(dateFormat);
    //const fechaCapturaFormatted = consulta.posts.fechaHora.format("DD-MM-YYYY")
    useEffect(() => {
        if (!consulta.cargada)
            doRequest();
    }, [consulta]);


    return (
        <div id="principal" className="div_consultaadj">
            {renderRedirect()}
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null}
            {!consulta.cargada ? (<Spin size="large" />) : (
                <div>

                    <Form>

                        <div className="div_titulo">
                            <Button id="regresar"
                                type="link"
                                href="/aprobacion/registraSesion">
                                <ArrowLeftOutlined />Regresar
                            </Button>
                        </div>
                        <h1 className="titulo-sesion">Sesión del OPL</h1>
                        <br />
                        <br />
                        <Row>
                            <Col span={4}>
                                <Col span={23}>
                                    <label className="rojo-sesion">* </label><label className="texto-16-sesion">Fecha de sesión</label>
                                </Col>
                            </Col>
                            <Col span={5}>
                                <Col span={23}>
                                    <label className="rojo-sesion">* </label><label className="texto-16-sesion">Tipo de sesión</label>
                                </Col>
                            </Col>
                            <Col span={5}>
                                <Col span={23}>
                                    <label className="rojo-sesion">* </label><label className="texto-16-sesion">Número de acuerdo</label>
                                </Col>
                            </Col>
                            <Col span={5}>
                                <Col span={23}>
                                    <label className="rojo-sesion">* </label><label className="texto-16-sesion">Nombre del acuerdo</label>
                                </Col>
                            </Col>
                            <Col span={4}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion"> Fecha de captura</label>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={4}>
                                <Col span={23}>
                                    <Form.Item name="fechaSesion">
                                        <TextArea id="text" rows={1}

                                            value={consulta.posts.fechaSesionString}
                                            readOnly={true}
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={5}>
                                <Col span={23}>
                                    <Form.Item name="tipoSesion">
                                        <TextArea id="text" rows={1}

                                            value={clasificacion}
                                            readOnly={true}
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={5}>
                                <Col span={23}>
                                    <Form.Item name="numeroAcuerdo">
                                        <TextArea id="text" rows={1}
                                            readOnly={true}
                                            value={consulta.posts.numeroAcuerdo}
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={5}>
                                <Col span={23}>
                                    <Form.Item name="nombreAcuerdo">
                                        <TextArea id="text" rows={1}

                                            readOnly={true}
                                            value={consulta.posts.nombreAcuerdo}
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="fechaCaptura">
                                    <TextArea id="text" rows={1}

                                        readOnly={true}
                                        value={consulta.posts.fechaHoraString}
                                    />

                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={10}>
                                <Col span={23}>
                                    <label className="texto-16-sesion">Observaciones</label>
                                </Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Col span={23}>
                                    <Form.Item name="observaciones">

                                        <TextArea placeholder="Ingrese las observaciones"
                                            rows={3} autoSize={{ minRows: 2, maxRows: 2 }}
                                            value={consulta.posts.observaciones}
                                            readOnly={true} />

                                    </Form.Item>
                                </Col>
                            </Col>
                        </Row>
                        <div className="div_archivo">
                            {consulta.url !== null ? (
                                <Worker
                                    /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                                    workerUrl={window.location.origin + "/pdf.worker.min.js"}
                                >
                                    <div style={{ height: '1110px', width: '95%' }}>
                                        <Viewer
                                            fileUrl={consulta.url} /></div>
                                </Worker>
                            ) :
                                (
                                    <div
                                        style={{
                                            alignItems: 'center',
                                            border: '2px dashed rgba(0, 0, 0, .3)',
                                            display: 'flex',
                                            fontSize: '2rem',
                                            height: '100%',
                                            justifyContent: 'center',
                                            width: '100%',
                                        }}
                                    >
                                    </div>
                                )
                            }
                        </div>

                    </Form>



                    <div className="div_consultarsesion">
                        <div className="div_infosesion">

                            {/*<div className="div_boton_descargar">
                                {consulta.url !== null ? (
                                    <Button className="descargar" onClick={handleClickDescargar.bind(this)}> <DownloadOutlined /> Descargar </Button>
                                ) : null}
                                </div>*/}
                        </div>
                    </div>
                    <div className="div_boton_modificar">
                        <Button id="btonc" onClick={handleClickModificar.bind(this, consulta.posts.idAprobacion)} disabled={isRolConsulta}> Modificar </Button>
                    </div>
                </div>
            )
            }
        </div>
    );

}