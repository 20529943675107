import React from "react";
import "./Mensajes.scss";
import 'antd/dist/antd.css'
import { Alert } from 'antd';

export default function Catalogo(props) { 
    return (
        <div>
            <Alert 
                className={`div_mensaje__${props.tipoMensaje}`} 
                message={props.mensaje} 
                type={props.tipoMensaje} 
                showIcon 
                closable 
                afterClose={props.handleClose} 
                style={{ borderRadius: "0px" }}
            />
        </div>        
    );
}