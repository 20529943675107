import React, { useState } from "react";
import { Select, Row, Col, Form, Upload, DatePicker, Input, Button, Modal, message } from "antd";
import { PlusOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import "./FormRegistroSesion.scss";
import { basePath } from "../../../api/config";
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');
export default function FormRegistroSesion(props) {
    var fechaCreacion = moment().format('DD-MM-YYYY');
    var tiempoMensaje = 5;
    let archivor = [];
    const handleGuardaSesion = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            console.log("values en handleGuardaSesion()", values);
            //valores = [...valores, 'fechaHora'];
            if (!err) {
                console.log('Received values of form: ', values);
                props.handleSubmit(values);
                /*Modal.confirm({
                    title: '¿Está seguro de guardar la información?',
                    icon: <ExclamationCircleOutlined />,
                    //content: 'Some descriptions',
                    okText: 'Aceptar',
                    cancelText: 'Cancelar',
                    onOk() {
                        console.log('Aceptar');
                        props.handleSubmit(values);
                    },
                    onCancel() {
                        console.log('Cancelar');
                    },
                });*/
            }
        });
    };
    //archivor=cargaArchivo.archivo;

    const date = new Date();
    // const fechaActual= date.getDate() + "-" + (date.getMonth() +1) + "-" + date.getFullYear();


    const handleValuesChange = () => console.log('Value changes');

    const { getFieldDecorator } = props.form;

    const dateFormat = "DD-MM-YYYY";

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    const [cargaArchivo, setCargaArchivo] = useState({
        archivo: null,
        consultar: false,
        urlPDF: null
    });


    const propsUpload = {
        multiple: false,
        onRemove: file => {
            setCargaArchivo(cargaArchivo => {
                return {
                    file: cargaArchivo.archivo
                };
            });
        },
        beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            const isSomething = file.size > 0;
            const isSize = (file.size / 1024 / 1024) < 50; //Limita hasta 250 MB
            if (!isPDF) {
                /*  setVisibleAlert({
                     activado: true,
                     tipoMensaje: "error",
                     //mensaje: "El formato del archivo no corresponde a un CSV. Favor de verificarlo."
                     mensaje: "El formato del archivo no corresponde a un PDF. Favor de borrarlo y cargar uno válido."
                 }); */
                message.error("El formato del archivo no corresponde a un PDF. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
                return true;
            }
            if (!isSomething) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El archivo viene vacío. Favor de verificarlo."
                    mensaje: "El archivo se encuentra vacío. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo se encuentra vacío. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
                return true;
            }
            if (!isSize) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El archivo pesa más de 10 MB. Favor de verificarlo."
                    mensaje: "El archivo pesa más de 50 MB. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo pesa más de 50 MB. Favor de borrarlo y cargar uno válido.", tiempoMensaje);
                return false;
            }
            else {
                const url = URL.createObjectURL(file);
                setCargaArchivo({
                    archivo: file,
                    urlPDF: url,
                    consultar: true,
                });

                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "success",
                    mensaje: "El archivo fue cargado exitosamente. Para poder subir otro PDF primero debe eliminar el existente."
                }); */
                message.success("El archivo fue cargado exitosamente. Para poder subir otro PDF primero debe eliminar el existente.", tiempoMensaje);

            }
            return false;
        },
        cargaArchivo
    };

    return (
        <div>
            <h1 className="titulo-sesion">Sesión del OPL</h1>
            <label className="texto-14-sesion">Los datos con ( <label className="rojo-sesion">*</label> ) son requeridos.</label>
            <br />
            <br />
            {props.mostrarFormulario && <div>
                <Form onSubmit={handleGuardaSesion} layout="vertical">
                    <Row>
                        <Col span={4}>
                            <Col span={23}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion">Fecha de sesión</label>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion">Tipo de sesión</label>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion">Número de acuerdo</label>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <label className="rojo-sesion">* </label><label className="texto-16-sesion">Nombre del acuerdo</label>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <label className="rojo-sesion">* </label><label className="texto-16-sesion"> Acuerdo/documento</label>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={4}>
                            <Col span={23}>
                                <Form.Item name="fechaSesion" hasFeedback>
                                    {getFieldDecorator('fechaSesion', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione la fecha de la sesión.',
                                            }
                                        ],
                                    })(
                                        <DatePicker
                                            placeholder="Selecciona"
                                            format={dateFormat}
                                            locale={'es'}
                                            disabledDate={current => {
                                                return current.startOf('day').diff(moment().startOf('day'), 'days') > 0
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <Form.Item name="tipoSesion" hasFeedback>
                                    {getFieldDecorator('tipoSesion', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione un tipo de sesion.',
                                            }
                                        ],
                                    })(
                                        <Select
                                            placeholder="Seleccione una opción"
                                        >
                                            {props.optionsTiposSesion.map((value, index) => {
                                                return <Select.Option key={value}>{value}</Select.Option>;
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <Form.Item name="numeroAcuerdo" hasFeedback>
                                    {getFieldDecorator('numeroAcuerdo', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese número de acuerdo.',
                                            }
                                        ],
                                    })(<Input placeholder="Ingrese número de acuerdo"
                                        maxLength={50}
                                    />)}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={5}>
                            <Col span={23}>
                                <Form.Item name="nombreAcuerdo" hasFeedback>
                                    {getFieldDecorator('nombreAcuerdo', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese nombre del acuerdo.',
                                            }
                                        ],
                                    })(<Input placeholder="Ingrese nombre del acuerdo"
                                        maxLength={1000}
                                    />)}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="fechaCaptura" hasFeedback>


                                {getFieldDecorator('Archivo', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Debe subir el archivo del acuerdo.',
                                        }
                                    ],
                                })
                                    (
                                        <Upload {...propsUpload} accept=".pdf">
                                            <Button type="submit" className="subir">
                                                <UploadOutlined /> Subir </Button>
                                        </Upload>
                                    )}
                                {
                                    <label className="texto-16-sesion" value={fechaCreacion}> {fechaCreacion} </label>
                                }

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={10}>
                            <Col span={23}>
                                <label className="texto-16-sesion">Observaciones</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <Form.Item name="observaciones">
                                    {getFieldDecorator('observaciones', {})(
                                        <Input.TextArea placeholder="Ingrese las observaciones" rows={2} autoSize={{ minRows: 2, maxRows: 2 }} allowClear={true} maxLength={1500} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={4} offset={8}>
                            <Col>
                                <Form.Item >
                                    <Button className="agregar" type="primary" htmlType="submit" disabled={!props.enableAgregar}>
                                        <PlusOutlined />Agregar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>
                </Form>
            </div>
            }
        </div>
    )
}