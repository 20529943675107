import React from "react";
import './ReporteInventario.css';
import { Cascader, Button, Tabs, message } from "antd";
import axios from "axios";
import { basePath } from "../../../api/config";
import { Redirect } from 'react-router';
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import ReporteInventarioDocumentos from "./ReporteInventarioDocumentos";
import ReporteInventarioMateriales from "./ReporteInventarioMateriales";


const { TabPane } = Tabs;
var tiempoMensaje = 5;
class ReporteInventario extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            activeTab: "1",
            comboHidden: true,
            options: [],
            isLoadingDocumentos: false,
            isLoadingMateriales: false,
            isDescargando: false,
            redirect: false,
            documentos: [],
            materiales: [],
            selectedOption: [],
            selectedOptionObject: [],
            optionsMateriales: [],
            optionTodosLosDocumentos: []
        };

    }

    componentDidMount() {


        this.initReporteDocumentos();

        this.initReporteMateriales();

    }

    initReporteDocumentos = () => {

        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if (this.isEstateDefined(entidad) == false) {
            entidad = 0;
        }

        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: parseInt(entidad)
        })
        console.log('consultando catalogo documentos ', data)

        axios.post(
            `${basePath}/consultaTipoDocumentacionTodosLosDocumentos`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {

                console.log('respuesta TODOS LOS DOCUMENTOS:', resp)

                if (resp.data.entity.code === 200) {
                    resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                        this.state.optionTodosLosDocumentos.push({
                            value: item.idTipoDocumentacion,
                            label: item.nombreDocumentacion,
                        });
                    });
                    console.log('todos los doumentos tiene ahora: ', this.state.optionTodosLosDocumentos.length)

                    this.getCatalogoDocumentos();


                } 
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                }
                else {
                    console.log("Error.componentDidMount: ", resp.data.message)
                    message.error("Ocurrió un error al consultar la documentación electoral. Inténtalo más tarde",tiempoMensaje);
                    /* alert(resp.data.causa) */
                    
                }
            }).catch(err => {
                
                console.log("Error.componentDidMount: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                message.error("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.",tiempoMensaje);
                /* alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.") */
            })
    }



    getCatalogoDocumentos = () => {

        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if (this.isEstateDefined(entidad) == false) {
            entidad = 0;
        }

        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: parseInt(entidad),
        })
        console.log('consultando catalogo documentos ', data)

        axios.post(
            `${basePath}/consultaCatalogoDocumentos`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {

                console.log('respuesta ', resp)
                this.complementaCombo();
                if (resp.data.entity.code === 200) {
                    resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                        this.state.options.push({
                            value: item.idTipoDocumentacion,
                            label: item.nombreDocumentacion,
                        });
                    });

                } 
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                }
                else {
                    console.log("Error.componentDidMount: ", resp.data.message)
                    message.error(resp.data.causa,tiempoMensaje);
                   /*  alert(resp.data.causa) */
                }
            }).catch(err => {

                console.log("Error.componentDidMount: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                message.error("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.",tiempoMensaje);
               /*  alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.") */
            })



    }

    complementaCombo = () => {

        console.log('opcion todos los documentos: ', this.state.optionTodosLosDocumentos)
        if (this.state.optionTodosLosDocumentos.length > 0) {
            console.log('agregando opcion de todas')
            this.state.options.push({
                value: this.state.optionTodosLosDocumentos[0].value,
                label: this.state.optionTodosLosDocumentos[0].label,
            })
        } else {
            console.log('no se agrego otra opcion porque: ', this.state.optionTodosLosDocumentos.length)
            console.log('y ademas ', this.state.optionTodosLosDocumentos[0])
        }
    }

    initReporteMateriales = () => {

        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if (this.isEstateDefined(entidad) == false) {
            entidad = 0;
        }

        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: parseInt(entidad),
        })
        console.log('consultando catalogo documentos ', data)

        axios.post(
            `${basePath}/consultaTipoDocumentacionMateriales`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {

                console.log('respuesta ', resp)

                if (resp.data.entity.code === 200) {
                    resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                        this.state.optionsMateriales.push({
                            value: item.idTipoDocumentacion,
                            label: item.nombreDocumentacion,
                        });
                    });

                    this.getMateriales();

                }
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                }
                else {
                    console.log("Error.componentDidMount: -", resp.data.message)
                    message.error("Ocurrió un error al consultar los materiales electorales. Inténtalo más tarde.",tiempoMensaje);
                    /* alert(resp.data.causa) */
                }
            }).catch(err => {

                console.log("Error.componentDidMount: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                message.error("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.",tiempoMensaje);
                /* alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.") */
            })
    }


    onChangeFiltro = (value, seleccion) => {

        this.getDocumentos(value, seleccion);

    }

    getDocumentos = (valor, seleccion) => {

        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if (this.isEstateDefined(entidad) == false) {
            entidad = 0;
        }

        this.setState({
            isLoadingDocumentos: true,
            selectedOption: valor,
            selectedOptionObject: seleccion
        });

        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: parseInt(entidad),
            idTipoDocumentacion: parseInt(valor)
        })
        console.log('consultando documentos: ', data)

        axios.post(
            `${basePath}/consultaReporteInventario`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {
                if (resp.data.entity.code === 200) {
                    this.setState({

                        documentos: resp.data.entity.reporteInventario.articulos,
                        isLoadingDocumentos: false
                    });

                } 
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                }
                else {
                    console.log("Error.componentDidMount: ", resp.data.message)
                    message.error("Ocurrió un error al obtener el reporte, favor de intentar más tarde.",tiempoMensaje);
                    /* alert(resp.data.causa) */

                    this.setState({
                        documentos: [],
                    });
                }
            }).catch(err => {
                console.log("Error.componentDidMount: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                message.error("Ocurrió un error al obtener la información del reporte, favor de intentar más tarde.",tiempoMensaje);
                /* alert("Ocurrió un error al obtener la información del reporte, favor de intentar más tarde.") */

                this.setState({

                    documentos: [],
                    isLoadingDocumentos: false,
                });

            })
    }



    getMateriales = () => {
        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if (this.isEstateDefined(entidad) == false) {
            entidad = 0;
        }

        if (this.state.optionsMateriales.length > 0) {

            this.setState({
                isLoadingMateriales: true
            });

            let data = JSON.stringify({
                idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
                idEstado: parseInt(entidad),
                idTipoDocumentacion: this.state.optionsMateriales[0].value
            })

            console.log('consultando materiales: ', data)
            axios.post(
                `${basePath}/consultaReporteInventario`, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            })
                .then(resp => {
                    if (resp.data.entity.code === 200) {
                        this.setState({

                            materiales: resp.data.entity.reporteInventario.articulos,
                            isLoadingMateriales: false
                        });

                    } 
                    else if (resp.data.entity.code == 403) {
                        localStorage.clear();
                        window.location = "/login"
                    }
                    else {
                        console.log("Error.componentDidMount: ", resp.data.message)
                        message.error("Ocurrió un error al obtener el reporte, favor de intentar más tarde.",tiempoMensaje);
                        /* alert(resp.data.causa) */

                        this.setState({
                            materiales: [],
                        });
                    }
                }).catch(err => {
                    console.log("Error.componentDidMount: ", err)
                    if (err.response != null && err.response.status == 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                    message.error("Ocurrió un error al obtener el reporte, favor de intentar más tarde.",tiempoMensaje);
                    /* alert("Ocurrió un error al obtener el reporte, favor de intentar más tarde.") */

                    this.setState({

                        materiales: [],
                        isLoadingMateriales: false,
                    });

                })
        }
    }

    manejaComboHidden = () => {

        if (this.state.activeTab === "1") {
            this.setState({
                comboHidden: false
            });
        } else {
            this.setState({
                comboHidden: true
            });
        }

    };

    execChildActions = () => {
        if (this.state.activeTab === "1") {
            this.getMateriales();
        }

    };

    changeTab = activeKey => {

        this.setState({
            activeTab: activeKey
        });

        this.manejaComboHidden()
        this.execChildActions();
    };

    downloadExcel = () => {
        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if (this.isEstateDefined(entidad) == false) {
            entidad = 0;
        }

        this.setState({
            isDescargando: true
        });

        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: entidad,
            idClasificacionEleccion: this.state.selectedOptionObject[0].value,
            nombreEstado: localStorage.getItem('ESTADO') !== "null" ? localStorage.getItem('ESTADO') : "",
            clasificacionEleccion: this.state.selectedOptionObject[0].label,
            /*documentos: this.state.documentos,
            materiales: this.state.materiales,*/
        })

        axios.post(
            `${basePath}/descargaReporteInventario`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {
                if (resp.data.entity.code === 200) {
                    this.setState({
                        isDescargando: false,
                        disabledFiltro: false,

                    });

                    var sampleArr = base64ToArrayBuffer(resp.data.entity.inventario);
                    saveByteArray("reporteInventario.xlsx", sampleArr);

                }
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                }
                else {
                    console.log("Error.downloadExcel: ", resp.data.entity.message)
                    message.error(resp.data.entity.causa,tiempoMensaje);
                    /* alert(resp.data.entity.causa) */
                }
            }).catch(err => {
                console.log("Error.downloadExcel: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                this.setState({
                    isDescargando: false,
                });
                message.error("Ocurrió un error al descargar el archivo, favor de intentar más tarde.",tiempoMensaje);
                /* alert("Ocurrió un error al descargar el archivo, favor de intentar más tarde.") */
            })


        function base64ToArrayBuffer(base64) {
            var binaryString = window.atob(base64);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        }

        function saveByteArray(reportName, byte) {
            var blob = new Blob([byte], { type: "application/xlsx" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = reportName;
            link.download = fileName;
            link.click();
        }

    }

    isEstateDefined = (entidad) => {
        if (entidad == 'null' || entidad == 'undefined' || entidad == null) {
            return false;
        } else {
            return true;
        }
    }

    isOc = () => {
        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');
        if (entidad == 0 || this.isEstateDefined(entidad) == false) {
            return true;
        } else {

            return false;
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/reportes" />;
        }

        return (
            <div>
                <h1 className="titulo-reportes">Diseño de la documentación y materiales electorales del OPL</h1>
                <label className="texto-12-reportes">Los datos con ( <label className="rojo-reportes">*</label> ) son requeridos.</label>
                <br />
                <br />
                <br />

                {this.state.comboHidden &&
                    <label className="texto-18-reportes"><label className="rojo-reportes">*</label> Tipo de clasificación de elección</label>

                }
                {this.state.comboHidden &&
                    <br />
                }
                {this.state.comboHidden &&
                    <Cascader style={{ width: 390 }} options={this.state.options} onChange={this.onChangeFiltro} value={this.state.selectedOption} placeholder="Seleccione una opción" />
                }


                <Tabs defaultActiveKey="1" onChange={this.changeTab}>

                    <TabPane tab="Documentos" key="1" >
                        <div className="subreporte">
                            <ReporteInventarioDocumentos documentos={this.state.documentos} isLoading={this.state.isLoadingDocumentos} isOc={this.isOc()} />
                        </div>
                    </TabPane>

                    <TabPane tab="Materiales" key="2"  >
                        <div className="subreporte">
                            <ReporteInventarioMateriales materiales={this.state.materiales} isLoading={this.state.isLoadingMateriales} isOc={this.isOc()} />
                        </div>
                    </TabPane>

                </Tabs>

                <div className="div_boton_descarga">
                    <Button disabled={!(this.state.selectedOptionObject.length > 0)} id="regresar"
                        onClick={this.downloadExcel}
                        className="descarga"

                    >
                        <VerticalAlignBottomOutlined /> Descargar
          </Button>
                </div>
            </div>


        );
    }
}

export default ReporteInventario;