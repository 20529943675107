import React from "react";
//import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./Revision.css";
import ListaDocs from "../../../components/ListaDocs/ListaDocs";
import { Typography, Divider, Select, Button, Upload, Modal, Input, Form, Spin, message } from "antd";
import SelectDocumentos from "../../../components/ListaDocs/SelectDocs";
import axios from "axios";
import { Tooltip } from '@material-ui/core';

import { basePath } from "../../../api/config";
import { DeleteOutlined, UploadOutlined, EyeOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Mensajes from "../../../components/Mensajes/Mensajes";
import { Redirect } from 'react-router';
import GridFilters from "../../../utils/GridFilters";

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

const urlConsultaDocumentos = `${basePath}/consultaArticulos`;
const urlTiposClasificacion = `${basePath}/obtenTiposClasificacionEleccion`;
const urlCargaArchivoArticulo = `${basePath}/cargaArchivoArticulo`;
const urlDesactivaArticulo = `${basePath}/desactivaArticulo`;
const enviaNotificacionJL = `${basePath}/enviaNotificacionJL`;

var tiempoMensaje = 5;

class CargasDocumentos extends React.Component {

  constructor(props) {
    console.log("props:=>", props);
    super(props);
    const estadoSel = props.user.infoMenu.estadoSelec !== null ? props.user.infoMenu.estadoSelec.idEstado : props.user.infoMenu.detalleSelec.idEstado;
    const procesoSel = props.user.infoMenu.detalleSelec.idProcesoElectoral;
    const detalleSel = props.user.infoMenu.detalleSelec.idDetalleProceso;
    let data = JSON.stringify({
      idProceso: procesoSel,
      idEstado: estadoSel,
      idDetalleProceso: detalleSel
    })

    this.handleClickConsultar = this.handleClickConsultar.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleModalEnviar = this.handleModalEnviar.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNotificar = this.handleNotificar.bind(this);
    this.getFieldDecorator = props.form.getFieldDecorator;
    this.state = {
      consulta: false,
      seleccion: -1,
      posts: [],
      idArticulo: -1,
      nombreArticulo: "",
      idEstadoSeleccionado: estadoSel,
      idProcesoSeleccionado: procesoSel,
      idDetalleProcesoSeleccionado: detalleSel,
      disableNotificacion: true,
      enableCaptura: false,
      enableModificar: false,
      modalVisible: false,
      modalLoading: false,
      redirect: false,
      dataSelect: data,
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      }
    };
  }
  componentDidMount() {
    if (this.state.idEstadoSeleccionado == null || this.state.idEstadoSeleccionado == 0) {
      /* this.setState(
        {
          visibleAlert: {
            activado: true,
            tipoMensaje: "warning",
            mensaje: "Favor de seleccionar una entidad en el menú del proceso electoral",
          },
        }); */
      message.warning("Favor de seleccionar una entidad en el menú del proceso electoral.", tiempoMensaje);
    }
  }
  handleSelect(value) {
    /*if(this.state.idEstadoSeleccionado == 0)
      alert("No se ha seleccionado una entidad, favor de seleccionar una entidad en el menú del proceo electoral.");*/
    this.setState(
      {
        posts: [],
        consulta: false,
        seleccion: value,
      });
    let data = JSON.stringify({
      consultaParametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado
      },
      tipoClasificacionEleccion: value
    });
    const currentToken = localStorage.getItem('accessToken');
    axios
      .post(
        urlConsultaDocumentos, data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          this.setState(
            {
              /* visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: response.data.entity.causa
              }, */
              posts: [],
              consulta: false,
            });
          message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            this.setState(
              {
                /* visibleAlert: {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message,
                }, */
                posts: [],
                consulta: false,
              });
            message.warning(response.data.entity.message, tiempoMensaje);
          }
          else {
            this.setState({
              disableNotificacion: response.data.entity.notificacionDisable,
              enableCaptura: response.data.entity.enableCaptura,
              enableModificar: response.data.entity.enableModificar,
              posts: response.data.entity.articulos,
              modalVisible: false,
              consulta: true,
              modalLoading: false
            });
          }
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        this.setState({
          /* visibleAlert: {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
          }, */
          modalVisible: false,
          seleccion: value,
          consulta: true,
          modalLoading: false
        });
        message.error("Ha ocurrido un error al realizar la petición solicitada.", tiempoMensaje);
      });
  }
  handleClickConsultar(id, nombre, conAlerta) {
    console.log("Handle consultar");
    this.setState({
      redirect: true,
      idArticulo: id,
      nombreArticulo: nombre
    })
    //<Redirect to={{ pathname: 'revision/cargasesp', state: { seleccion: this.state.seleccion, idArticulo: id } }} />
  }
  handleClickCargar(id, conAlerta) {
    console.log("Handle cargar");
    this.setState({ idArticulo: id });
  }
  handleClickModificar(id, conAlerta) {
    console.log("Handle modificar");
  }
  handleClickEliminar(id, conAlerta) {
    console.log("Handle elimnar");
    this.setState({ modalVisible: true, idArticulo: id });
  }

  handleModalCancel() {
    console.log("Handle buttom cancel");
    this.handleSelect(this.state.seleccion);
    this.props.form.resetFields();
  }

  handleModalEnviar() {
    this.props.form
      .validateFields()
      .then(values => {
        this.setState({ modalLoading: true });
        const currentToken = localStorage.getItem('accessToken');
        let data = JSON.stringify({
          parametros: {
            idProceso: this.state.idProcesoSeleccionado,
            idEstado: this.state.idEstadoSeleccionado,
            idDetalleProceso: this.state.idDetalleProcesoSeleccionado
          },
          idTipoDocumentacion: this.state.seleccion,
          idArticulo: this.state.idArticulo,
          justificacion: this.props.form.getFieldValue('justificacion')
        });
        axios
          .post(
            urlDesactivaArticulo, data,
            {
              headers: {
                'Authorization': currentToken,
                'Content-Type': 'application/json',
                Accept: "application/json",
                //"Access-Control-Allow-Origin": "*"
              }
            }
          )
          .then(response => {
            if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
              /* this.setState(
                {
                  visibleAlert: {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: response.data.entity.causa
                  }
                }); */
              message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
            }
            else if (response.data.entity.code === 200) {
              if (response.data.entity.message !== null && response.data.entity.message !== "") {
                /* this.setState(
                  {
                    visibleAlert: {
                      activado: true,
                      tipoMensaje: "warning",
                      mensaje: response.data.entity.message
                    }
                  }); */
                message.warning(response.data.entity.message, tiempoMensaje);
              }
              else {
                /* this.setState(
                  {
                    visibleAlert: {
                      activado: true,
                      tipoMensaje: "success",
                      mensaje: "El artículo se ha desactivado correctamente."
                    }
                  }); */
                message.success("El artículo se ha desactivado correctamente.", tiempoMensaje);
              }
            }
            else {
              localStorage.clear();
              // console.log("Borro el local storage");
              window.location = "/login"
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response.status === 403) {
              localStorage.clear();
              window.location = "/login"
              return;
            }
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "error",
                  mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                }
              }); */
            message.error("Ha ocurrido un error al realizar la petición solicitada.", tiempoMensaje);
          });
        this.handleSelect(this.state.seleccion);
        this.props.form.resetFields();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }

  handleClose() {
    this.setState({
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      }
    });
  }

  handleNotificar() {
    if (!this.state.enableModificar)
      return;
    console.log("Handle buttom notificar");
    const currentToken = localStorage.getItem('accessToken');
    const anio = this.props.user.infoMenu.detalleSelec.anio;
    const tipoProceso = this.props.user.infoMenu.detalleSelec.tipoProceso;
    let data = JSON.stringify({
      parametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado,
        anioProceso: anio,
        tipoProceso: tipoProceso
      },
      idTipoDocumentacion: this.state.seleccion
    });
    axios
      .post(
        enviaNotificacionJL, data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        this.handleSelect(this.state.seleccion);
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: response.data.entity.causa
              }
            }); */
          message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message
                }
              }); */
            message.warning(response.data.entity.message, tiempoMensaje);
          }
          else {
            /* this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "success",
                  mensaje: "Se ha enviado la notificación al área correspondiente."
                }
              }); */
            message.success("Se ha enviado la notificación al área correspondiente.", tiempoMensaje);
          }
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        /* this.setState(
           {
             visibleAlert: {
               activado: true,
               tipoMensaje: "error",
               mensaje: "Ha ocurrido un error al realizar la petición solicitada"
             }
           }); */
        message.error("Ha ocurrido un error al realizar la petición solicitada.", tiempoMensaje);
      });
    this.handleSelect(this.state.seleccion);
  }

  onFinish(values) {
    console.log("Success:", values);
  };

  onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={
        {
          pathname: '/revision/cargasesp',
          state: {
            idTipoDocumentacion: this.state.seleccion,
            idArticulo: this.state.idArticulo,
            nombreArticulo: this.state.nombreArticulo
          }
        }} />
    }
  }

  render() {
    console.log("-----------------------------------------Se dibuja el componente");
    const eventHandlers = [{ "cargar": this.handleClickCargar }, { "consultar": this.handleClickConsultar }, { "modificar": this.handleClickModificar }, { "eliminar": this.handleClickEliminar }];
    //Se definen las columnas de la tabla de Listado de cargas
    //const { fileList } = this.state;
    const { fileList } = [];
    const propsUpload = {
      showUploadList: {
        showPreviewIcon: false,
        showDownloadIcon: false,
        showRemoveIcon: false,
        //fileList: this.state.fileList,
      },
      beforeUpload: file => {
        const isPDF = file.type === 'application/pdf';
        const isSomething = file.size > 0;
        const isSize = (file.size / 1024 / 1024) < 50;

        if (!isPDF) {
          /*
          this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: "El formato del archivo no corresponde a un PDF. Favor de verificarlo."
              }
            });*/
          message.error("El formato del archivo no corresponde a un PDF. Favor de verificarlo.", tiempoMensaje);
          return false;
        }
        if (!isSomething) {
          /*
          this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: "El archivo se encuentra vacío. Favor de verificarlo."
              }
            });*/
          message.error("El archivo se encuentra vacío. Favor de verificarlo.", tiempoMensaje);
          return false;
        }
        if (!isSize) {
          /*
          this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: "El archivo pesa más de 50 MB. Favor de verificarlo."
              }
            });*/
          message.error("El archivo pesa más de 50 MB. Favor de verificarlo.", tiempoMensaje);
          return false;
        }
        else {
          if (!this.state.enableModificar)
            return false;

          this.setState({consulta: false});

          const currentToken = localStorage.getItem('accessToken');
          const anio = localStorage.getItem('ANIO');
          const tipoProceso = localStorage.getItem("TIPO_PROCESO");
          const formData = new FormData();
          formData.append("parametros.idEstado", this.state.idEstadoSeleccionado);
          formData.append("parametros.idProceso", this.state.idProcesoSeleccionado);
          formData.append("parametros.idDetalleProceso", this.state.idDetalleProcesoSeleccionado);
          formData.append("parametros.anioProceso", anio);
          formData.append("parametros.tipoProceso", tipoProceso);
          formData.append("idArticulo", this.state.idArticulo);
          formData.append("idTipoDocumentacion", this.state.seleccion);
          formData.append("archivo", file);
          axios
            .post(
              urlCargaArchivoArticulo, formData,
              {
                headers: {
                  'Authorization': currentToken,
                  'Content-Type': 'multipart/form-data',
                  Accept: "application/json",
                  //"Access-Control-Allow-Origin": "*"
                }
              }
            )
            .then(response => {
              //this.setState({ posts: response.data.entity });
              this.handleSelect(this.state.seleccion);
              this.setState({consulta: true});
              if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                /*
                this.setState(
                  {
                    visibleAlert: {
                      activado: true,
                      tipoMensaje: "error",
                      mensaje: response.data.entity.causa
                    }
                  });
                  */
                message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
              }
              else if (response.data.entity.code === 200) {
                if (response.data.entity.message !== null && response.data.entity.message !== "") {
                  /*
                  this.setState(
                    {
                      visibleAlert: {
                        activado: true,
                        tipoMensaje: "warning",
                        mensaje: response.data.entity.message
                      }
                    });*/
                  message.warning(response.data.entity.message, tiempoMensaje);
                }
                else {
                  /*
                  this.setState(
                    {
                      visibleAlert: {
                        activado: true,
                        tipoMensaje: "success",
                        mensaje: "El archivo fue cargado exitosamente"
                      }
                    });*/
                  message.success("El archivo fue cargado exitosamente.", tiempoMensaje);
                }
              }
              else {
                localStorage.clear();
                // console.log("Borro el local storage");
                window.location = "/login"
              }
            })
            .catch(error => {
              console.log(error);
              if (error.response.status === 403) {
                localStorage.clear();
                window.location = "/login"
                return;
              }
              /* this.setState(
                {
                  visibleAlert: {
                    activado: true,
                    tipoMensaje: "error",
                    mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                  }
                }); */
              this.setState({consulta: true});
              message.error("Ha ocurrido un error al realizar la petición solicitada.", tiempoMensaje);
            });
          return false;
        }
      },
      fileList
    };
    const columns = [
      {
        Header: "No.",
        accessor: "consecutivo",
        sortable: false,
        filterable: false,
        style: {
          textAlign: "center"
        },
        width: 50,
        maxWidth: 50,
        minWidth: 50
      },

      {
        Header: "Documento/Material",
        accessor: "nombre",
        ...GridFilters
      },
      {
        Header: "Observaciones",
        accessor: "conObservaciones",
        style: {
          textAlign: "center"
        },
        width: 200,
        maxWidth: 200,
        minWidth: 100,
        ...GridFilters,
      },
      {
        Header: "Emblema",
        accessor: "conEmblema",
        style: {
          textAlign: "center"
        },
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        ...GridFilters,
      },
      {
        Header: "Acciones",
        //accessor: "acciones",
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        Cell: props => {
          return (
            console.log("Se pintan las celdas"),
            <div id="centrar">
              {props.original.acciones.map((value, index) => {
                //console.log("value.nombre", value.nombre);
                if (value.nombre === "consultar") {
                  return [
                    <Tooltip title="Consultar">
                      <Button
                        id="ilist"
                        type="link"
                        shape="circle"
                        onClick={this.handleClickConsultar.bind(this, props.original.id, props.original.nombre, value.conAlerta)}
                        disabled={!value.isActivo}
                      //href="/revision/cargasesp"
                      >
                        <EyeOutlined />
                      </Button>
                    </Tooltip>,
                    <Divider id="dcolor" type="vertical" />
                  ];
                } else if (value.nombre === "cargar") {
                  return [
                    <Upload {...propsUpload}>
                      <Tooltip title="Subir archivo">
                        <Button
                          id="ilist"
                          type="primary"
                          shape="circle"
                          accept=""
                          onClick={this.handleClickCargar.bind(this, props.original.id, value.conAlerta)}
                          //beforeUpload={this.handleUpload}
                          disabled={!value.isActivo || !this.state.enableCaptura}
                        >
                          <UploadOutlined />
                        </Button>
                      </Tooltip>
                    </Upload>,
                    <Divider id="dcolor" type="vertical" />
                  ];
                } /* else if (value.nombre === "eliminar") {
                  return [
                    <Button
                      id="ilist"
                      type="primary"
                      shape="circle"
                      onClick={this.handleClickEliminar.bind(this, props.original.id, value.conAlerta)}
                      disabled={!value.isActivo || !this.state.enableCaptura}
                    >
                      <DeleteOutlined />
                    </Button>,
                    <Divider id="dcolor" type="vertical" />
                  ];
                } */
                else if (value.nombre === "modificar") {
                  return [
                    <Tooltip title="Modificar">
                      <Button
                        id="ilist"
                        type="link"
                        shape="circle"
                        onClick={this.handleClickModificar.bind(this, props.original.id, value.conAlerta)}
                        disabled={!value.isActivo || !this.state.enableCaptura}

                      >
                        <CheckOutlined />
                      </Button>
                    </Tooltip>,
                    <Divider id="dcolor" type="vertical" />
                  ];
                }
                else return <span />;
              }, <Divider id="dcolor" type="vertical" />)}
            </div>
          );
        },
        style: {
          color: "blue"
        },
        sortable: false,
        filterable: false
      }
    ];
    return (
      console.log("En el return"),
      <div>
        {this.renderRedirect()}
        {this.state.visibleAlert.activado ?
          (
            <Mensajes tipoMensaje={this.state.visibleAlert.tipoMensaje} mensaje={this.state.visibleAlert.mensaje} handleClose={this.handleClose} />
          ) : null}
        <h1 id="titulo">Cargas de documentos</h1>
        <p id="msg">
          <span id="smsg">*</span>Tipo de clasificación de elección
        </p>
        <p>
          <SelectDocumentos
            onChange={this.handleSelect}
            data={this.state.dataSelect}
            rutaWS={urlTiposClasificacion}
          />
        </p>

        <h3>Listado de cargas</h3>
        <p>
          {!this.state.consulta &&
            <div className="div_cargando">
              <br />
              {this.state.seleccion !== -1 ? (<Spin size="large" />) : null}
            </div>}
        </p>
        <p>
          {this.state.consulta ? <ListaDocs eventos={eventHandlers} datos={this.state.posts} columnas={columns} filterable={true} /> : null}
        </p>

        <p id="contb">
          {this.state.consulta ? (
            <Button id="btonc" disabled={this.state.disableNotificacion || !this.state.enableModificar} onClick={this.handleNotificar}> Notificar </Button>
          ) : null}
        </p>
        <Modal
          visible={this.state.modalVisible}
          title="Desactivar artículo"
          //onOk={handleModalEnvíar}
          onCancel={this.handleModalCancel}
          footer={[
            <div className="justificacion-footer">
              <Button key="back" onClick={this.handleModalCancel} className="boton-modal-cancelar"
              >
                Cancelar
              </Button>
              <Button key="submit" type="primary"
                //loading={this.state.modalLoading} 
                onClick={this.handleModalEnviar}
                className="boton-modal-aceptar"
                disabled={!this.state.enableModificar}
              >
                Aceptar
              </Button>
            </div>
          ]}
        >
          <Form layout="vertical">
            <Form.Item
              label="Justificación"
              name="justificacion"
              hasFeedback
            >
              {this.getFieldDecorator('justificacion', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor ingresa la justificación!',
                  }
                ],
              })(
                <TextArea rows={4} autoSize={false} allowClear={true} maxLength={150} />
              )}
            </Form.Item>
          </Form>
        </Modal>
      </div >
    );
  }
}
export default CargasDocumentos;
