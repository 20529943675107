import React from "react";
//import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./Revision.css";
import ListaDocs from "../../../components/ListaDocs/ListaDocs";
import { Checkbox, Typography, Divider, Select, Button, Upload, Modal, Input, Spin } from "antd";
import SelectDocumentos from "../../../components/ListaDocs/SelectDocs";
import axios from "axios";

import { basePath } from "../../../api/config";
import { ArrowLeftOutlined, DownloadOutlined, DeleteOutlined, UploadOutlined, EyeOutlined, CheckOutlined } from "@ant-design/icons";
import VerPdf from "../../../components/ListaDocs/VerPdf";
import Mensajes from "../../../components/Mensajes/Mensajes";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { base64toBlob } from "../../../utils/Utils"
import { Redirect } from 'react-router';
const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

const urlConsultaDocumentos = `${basePath}/consultaHistoricoV`;
const urlTiposClasificacion = `${basePath}/obtenHistoricoRevisionV`;
const permiso = localStorage.getItem('ROL_USUARIO');
const permisos = localStorage.getItem('ROL_USUARIO');
class ValidacionConsulta extends React.Component {
  constructor(props) {
    super(props);
    const estadoSel = localStorage.getItem('ID_ESTADO_SELECCIONADO');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const detalleSel = localStorage.getItem('ID_DETALLE_PROCESO');
    let data = JSON.stringify({
      parametros: {
        idProceso: procesoSel,
        idEstado: estadoSel,
        idDetalleProceso: detalleSel
      },
      idArticulo: this.props.location.state.idArticulo,
      idTipoDocumentacion: this.props.location.state.idTipoDocumentacion

      // TODO COLOCAR LOS OTROS PARAMETROS
    });

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickDescargar = this.handleClickDescargar.bind(this);
    this.handleClickModificar = this.handleClickModificar.bind(this);
    //this.obtenHistoricoRevision = this.obtenHistoricoRevision(this);
    this.state = {
      consulta: false,
      seleccion: 0,
      posts: [],
      urlPDF1: null,
      urlPDF2: null,
      idArticulo: this.props.location.state.idArticulo,
      nombreArticulo: this.props.location.state.nombreArticulo,
      idTipoDocumentacion: this.props.location.state.idTipoDocumentacion,
      idEstadoSeleccionado: -1,
      idProcesoSeleccionado: -1,
      idDetalleProcesoSeleccionado: -1,
      dataSelect: data,
      loading: true,
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      },
      redirect: false,
    };
  }
  componentDidMount() {
    const estadoSel = localStorage.getItem('ID_ESTADO_SELECCIONADO');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const detalleSel = localStorage.getItem('ID_DETALLE_PROCESO');
    let data = JSON.stringify({
      parametros: {
        idProceso: procesoSel,
        idEstado: estadoSel,
        idDetalleProceso: detalleSel
      },
      idTipoDocumentacion: this.state.idTipoDocumentacion,
      idArticulo: this.state.idArticulo
    })
    this.setState({ dataSelect: data, idEstadoSeleccionado: estadoSel, idProcesoSeleccionado: procesoSel, idDetalleProcesoSeleccionado: detalleSel });
  }
  handleSelect(value) {
    this.setState({
      /*posts: response.data.entity,
      urlPDF1: urlPDF1,
      urlPDF2: urlPDF2,
      urlZIP: urlZIP,
      modalVisible: false,
      seleccion: value,*/
      consulta: false
    });
    const currentToken = localStorage.getItem('accessToken');
    const anio = localStorage.getItem('ANIO');
    const tipoProceso = localStorage.getItem("TIPO_PROCESO");
    let data = JSON.stringify({
      parametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado,
        anioProceso: anio,
        tipoProceso: tipoProceso
      },
      tipodocumentacion: this.state.idTipoDocumentacion,
      idArticulo: this.state.idArticulo,
      etapaHistorico: value
    });
    axios
      .post(
        urlConsultaDocumentos, data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: "10.- Ocurrió un error. Comunícate al CAU"
              }
            });
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message
                },
                loading: false,
              });

          }
          else {
            let urlPDF1 = null;
            let urlPDF2 = null
            if (response.data.entity.archivoUnicoBase64 !== null && response.data.entity.archivoUnicoBase64 !== "") {
              const disenioUnicoBase64 = 'data:application/pdf;base64,' + response.data.entity.archivoUnicoBase64;
              const blo1 = base64toBlob(disenioUnicoBase64);
              urlPDF1 = URL.createObjectURL(blo1);
            }
            if (response.data.entity.cargaHistoricoBase64 !== null && response.data.entity.cargaHistoricoBase64 !== "") {
              const disenioOPLBase64 = 'data:application/pdf;base64,' + response.data.entity.cargaHistoricoBase64;
              const blo2 = base64toBlob(disenioOPLBase64);
              urlPDF2 = URL.createObjectURL(blo2);
            }
            this.setState({
              posts: response.data.entity,
              urlPDF1: urlPDF1,
              urlPDF2: urlPDF2,
              modalVisible: false,
              seleccion: value,
              consulta: true,
              loading: false
            });
          }
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        this.setState({
          visibleAlert: {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
          },
          loading: false,
          consulta: false

        });
      });
  }


  obtenHistoricoRevision(etapa) {
    this.handleSelect(etapa);
  }



  handleClickDescargar() {
    console.log("Entro a descagar");
    let a = document.createElement('a');
    a.href = this.state.urlPDF2;
    /* a.download = "DOCSMATS-" + this.state.posts.nombreArchivoUnico; */
    a.download = "DOCSMATS-" + "disenioOPL";
    a.click();
    //window.location.href = consulta.url;
  }

  handleClickModificar() {
    console.log("Handle modificar");
    this.setState({
      redirect: true,
      urli: "/revision/validacionobservaciones"
    });
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      //const urli='/revision/cargasesp';
      return <Redirect to={{
        pathname: this.state.urli,
        state: {
          idTipoDocumentacion: this.state.idTipoDocumentacion,
          idArticulo: this.state.idArticulo,
          nombreArticulo: this.state.nombreArticulo
        }
      }} />
    }
  }

  render() {

    const wrapperS = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',


    };


    return (
      <div>
        <Button
          id="regresar"
          type="link"
          href="/revision/validacion">
          <ArrowLeftOutlined />Regresar</Button>
          {(permisos === "DOCSYMAT.CAPTURA.JL" || permisos === "DOCSYMAT.CONSULTA.JL") ?
          <h1 id="titulo">Revisión de documentos y materiales</h1> :
          (permisos === "DOCSYMAT.ADMIN.OC" || permisos === "DOCSYMAT.CAPTURA.OC" ||
            permisos === "DOCSYMAT.CONSULTA.OC" || permisos === "DOCSYMAT.SUPERVISOR.OC" || permisos === "DOCSYMAT.REVISOR.OC") ?
            <h1 id="titulo">Validación de documentos y materiales</h1> : <h1 id="titulo">Revisión/Validación de documentos</h1>}
        <p id="msg">
          <span id="smsg">*</span>Histórico de revisiones
        </p>

        <p>
          <SelectDocumentos
            onChange={this.handleSelect}
            data={this.state.dataSelect}
            rutaWS={urlTiposClasificacion}
          />
        </p>
        {(this.state.loading != null && this.state.visibleAlert.activado == null) ?
          (
            <div className="div_cargando">
              <br />
              <Spin size="large" />
            </div>
          )
          : this.state.visibleAlert.activado ?
            (
              <Mensajes tipoMensaje={this.state.visibleAlert.tipoMensaje} mensaje={this.state.visibleAlert.mensaje} handleClose={this.handleClose} />
            )
            :
            (
              <div>
                {this.state.consulta ? (
                  <div>
                    <div className="principal-div">

                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                        <div style={{ width: '48%', float: 'left', margin: '5px' }}>
                          <h3>{this.state.nombreArticulo} (Diseño Unico)</h3>
                        </div>
                        <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                        <div style={{ height: '750px', width: '48%', float: 'left', margin: '5px' }}>
                          {this.state.urlPDF1 !== null ?
                            (
                              <Worker
                                /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                                workerUrl={window.location.origin + "/pdf.worker.min.js"}
                              >
                                <Viewer fileUrl={this.state.urlPDF1} />
                              </Worker>
                            )
                            :
                            (
                              <div
                                style={{
                                  alignItems: 'center',
                                  border: '2px dashed rgba(0, 0, 0, .3)',
                                  display: 'flex',
                                  fontSize: '2rem',
                                  height: '100%',
                                  justifyContent: 'center',
                                  width: '100%',
                                }}
                              >
                              </div>
                            )}
                        </div>
                        <div style={{ height: '750px', width: '48%', float: 'right', margin: '5px' }}>
                          {this.state.urlPDF2 !== null ?
                            (
                              <Worker
                                /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                                workerUrl={window.location.origin + "/pdf.worker.min.js"}
                              >
                                <Viewer fileUrl={this.state.urlPDF2} />
                              </Worker>
                            )
                            :
                            (
                              <div
                                style={{
                                  alignItems: 'center',
                                  border: '2px dashed rgba(0, 0, 0, .3)',
                                  display: 'flex',
                                  fontSize: '2rem',
                                  height: '100%',
                                  justifyContent: 'center',
                                  width: '100%',
                                }}
                              >
                              </div>
                            )}
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                        <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                        </div>
                        <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                          <Button className="descargar" onClick={this.handleClickDescargar}> <DownloadOutlined />Descargar </Button>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                        <div style={{ width: '48%', float: 'left', margin: '5px' }}>
                          <br />
                          <h4><span>Especificaciones Anexo 4.1</span></h4>
                          <ul>
                            {this.state.posts.especificacionesTecnicas.map((item) => (
                              console.log(item),
                              <div>
                                <li> <Checkbox checked={item.checkactivo} disabled={true} />{item.nombre}{item.observaciones && <ul>
                                  <TextArea value={item.observaciones} autoSize={true} allowClear={true} disabled={true} /></ul>
                                }</li>
                                <br />
                              </div>
                            ))}
                          </ul>
                        </div>
                        <div style={{ width: '500px', float: 'right', margin: '5px' }}>
                          <br />
                          <h4><span>Especificaciones Únicas</span></h4>
                          <ul>
                            {this.state.posts.especificacionesDisenio.map((item) => (
                              console.log(item),
                              <div>
                                <li> <Checkbox checked={item.checkactivo} disabled={true} />{item.nombre}{item.observaciones && <ul>
                                  <TextArea value={item.observaciones} autoSize={true} allowClear={true} disabled={true} /></ul>
                                }</li>
                                <br />


                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p>
                        {this.renderRedirect()}
                        <br />
                        <span> Sólo podrás modificar los artículos que no han sido revisados o que son parte de la revisión actual, artículos de revisiones pasadas no permiten modificaciones
           </span>
                        <br />
                        <br />
                        <Button id="btonc" disabled={this.state.posts.modificacionactiva} onClick={this.handleClickModificar.bind()} > Modificar </Button>
                      </p>
                    </div>
                  </div>
                ) : null}
              </div >
            )
        }
      </div >
    );
  }
}
export default ValidacionConsulta;