import React, { useState, useEffect, useCallback } from "react";
import { Spin, Button, message } from 'antd';
import { DownloadOutlined, ArrowLeftOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import { basePath } from "../../api/config";
import "./ConsultaArticulo.scss"
//import PDFViewer from "mgr-pdf-viewer-react";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import Mensajes from "../../components/Mensajes/Mensajes";
import { base64toBlob } from "../../utils/Utils"
import { Redirect } from 'react-router';

export default function ConsultaArticulo(props) {

    const urlConsultaArticulo = `${basePath}/consultaArticulo`;
    const urlmodificaArticulo = `${basePath}/modificararticulo`;
    var tiempoMensaje = 5;
    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    const [consulta, setConsulta] = useState({
        posts: [],
        url: null,
        cargada: false
    });

    const [redirect, setRedirect] = useState({
        redireccion: false,
        idArticulo: -1,
        clasificacion: -1
    });

    const handleClickModificar = useCallback((id,clasificacion) => {        
        //console.log("consulta.posts.clasificacion en handleClickModificar: ", consulta.posts.clasificacion);
        //console.log("clasificacion en handleClickModificar: ", clasificacion);
        setRedirect({
            redireccion: true,
            idArticulo: id,
            clasificacion: clasificacion
        })
    });

    const handleClickDescargar = useCallback(() => {
        console.log("Entro a descagar");
        let a = document.createElement('a');
        a.href = consulta.url;
        /* a.download = "DOCSMATS-" + consulta.posts.nombreArchivoDisenioUnico; */
        a.download = "DOCSMATS-" + "disenioUnico";
        a.click();
        //window.location.href = consulta.url;
    })

    const renderRedirect = () => {
        if (redirect.redireccion) {
            console.log("Se va a modificar: ", redirect.idArticulo);
            console.log("Tipo de clasificación: ", redirect.clasificacion);
            // Colocar la redireccion
            return <Redirect to={{
                pathname: '/configuracion/modificararticulo',
                state: { 
                    idArticulo: redirect.idArticulo,
                    clasificacion: redirect.clasificacion }
            }
            } />
        }
    }

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    function doRequest() {
        console.log("Entró en doRequest()");
        let data = JSON.stringify({
            idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            anio: localStorage.getItem('ANIO'),
            tipoProceso: localStorage.getItem("TIPO_PROCESO"),
            idArticulo: props.idArticulo
        })
        console.log("doRequest.data: ", data);

        axios
            .post(
                urlConsultaArticulo, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            let url = null;
                            if (response.data.entity.disenioUnicoBase64 !== null && response.data.entity.disenioUnicoBase64 !== "") {
                                const disenioUnicoBase64 = 'data:application/pdf;base64,' + response.data.entity.disenioUnicoBase64;
                                const blob = base64toBlob(disenioUnicoBase64);
                                url = URL.createObjectURL(blob);
                            }
                            setConsulta({
                                posts: response.data.entity,
                                url: url,
                                cargada: true
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(error => {
                console.log("Error:=>: doRequestOptions", error)
                if (error.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    };

    useEffect(() => {
        if (!consulta.cargada)
            doRequest();
    }, [consulta]);

    return (
        <div>
            {renderRedirect()}
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null}
            {!consulta.cargada ? (<Spin size="large" />) : (
                <div>
                    <div className="div_titulo">
                        <Button id="regresar"
                            type="link"
                            href="/configuracion/consultarcatalogos">
                            <ArrowLeftOutlined />Regresar
                        </Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <h3>{consulta.posts.nombreArticulo}</h3>
                    </div>                    
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <h4><span>Tipo de artículo</span></h4>
                    </div>
                    <div className="div_datos">
                        {consulta.posts.tipoArticulo == 1 ? 'MATERIAL' : 'DOCUMENTO'}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <h4><span>Clasificación</span></h4>
                    </div>
                    <div className="div_datos">
                        {consulta.posts.nombreClasificacion}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <h4><span>Subclasificación</span></h4>
                    </div>
                    <div className="div_datos">
                        {consulta.posts.nombreSubclasificacion}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <h4><span>&iquest;Tiene emblema?</span></h4>
                    </div>
                    <div className="div_datos">
                        {consulta.posts.tieneEmblema == 1 ? 'Sí' : (
                            consulta.posts.tieneEmblema == 0 ? 'No' : 'No aplica')}
                    </div>
                    <div className="div_consultararticulo">
                        <div className="div_infoarticulo">
                            <div className="div_diseniounico">
                                {consulta.url !== null ? (
                                    <Worker
                                        /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                                        workerUrl={window.location.origin + "/pdf.worker.min.js"}
                                    >
                                        <Viewer
                                            fileUrl={consulta.url} />
                                    </Worker>
                                ) :
                                    (
                                        <div
                                            style={{
                                                alignItems: 'center',
                                                border: '2px dashed rgba(0, 0, 0, .3)',
                                                display: 'flex',
                                                fontSize: '2rem',
                                                height: '100%',
                                                justifyContent: 'center',
                                                width: '100%',
                                            }}
                                        >
                                        </div>
                                    )
                                }
                            </div>
                            <div className="div_boton_descargar">
                                {consulta.url !== null ? (
                                    <Button className="descargar" onClick={handleClickDescargar.bind(this)}> <DownloadOutlined /> Descargar </Button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="div_especificaciones">
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ width: '500px', float: 'left', margin: '5px' }}>
                                <h4><span>Especificaciones Anexo 4.1</span></h4>
                                <ul>
                                    {consulta.posts.especificacionesTecnicas.map((item) => (
                                        <li key={"div-esptec-" + item.id}>{item.nombre}</li>
                                    ))}
                                </ul>
                            </div>
                            <div style={{ width: '500px', float: 'right', margin: '5px' }}>
                                <h4><span>Especificaciones Únicas</span></h4>
                                <ul>
                                    {consulta.posts.especificacionesDisenio.map((item) => (
                                        <li key={"div-espdis-" + item.id}>{item.nombre}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <h4><span>Justificación</span></h4>                        
                    </div>
                    <div className="div_datos">
                        {consulta.posts.justificacion !== null ? consulta.posts.justificacion : ''}
                    </div>
                    <div className="div_boton_modificar">
                        <Button id="btonc" disabled={!consulta.posts.enableCaptura && !consulta.posts.enableModificar} onClick={handleClickModificar.bind(this, props.idArticulo, consulta.posts.clasificacion)}> Modificar </Button>
                    </div>
                </div>
            )
            }
        </div>
    );

}