import Img1 from "../assets/img/Carrusel/img_carrousel01.svg";
import Img2 from "../assets/img/Carrusel/img_carrousel02.svg"
import Img3 from "../assets/img/Carrusel/img_carrousel03.svg"
import Img4 from "../assets/img/Carrusel/img_carrousel04.svg"

const data = [

    {
        id: 1,
        url: Img1
    },
    {
        id: 2,
        url: Img2
    },
    {
        id: 3,
        url: Img3
    },
    {
        id: 4,
        url: Img4
    },

];

export default data;