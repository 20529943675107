import React from "react";
import './Seguimiento.css';
import { Cascader, List, Col, Row, Skeleton, Button, Icon, Progress, Input, message } from "antd";
import { Tooltip } from '@material-ui/core';
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Mensajes from "../../../components/Mensajes/Mensajes";
import FormActaSeguimiento from '../../../components/FormActaSeguimiento/FormActaSeguimiento';
import { basePath } from "../../../api/config";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';

let isConsulta = false;
let isCau = false;
let noAvance = 0;
var tiempoMensaje = 5;

class SeguimientoProduccion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
            isLoadingTabla: false,
            disabledFiltro: true,
            listaTabla: [],
            listaTablaCopy: [],
            isModalVisible: false,
            isLoadingEmpresaModal: true,
            redirect: false,
            idTipoDocumentacion: null,
            refreshTable: false,
            visibleAlert: {
                activado: false,
                tipoMensaje: "",
                mensaje: "",
            }
        };

    }

    componentDidMount() {
        const roUsario = localStorage.getItem("ROL_USUARIO");

        isConsulta = roUsario == 'DOCSYMAT.CONSULTA.OC' ? true :
            roUsario == 'DOCSYMAT.CAPTURA.JL' ? true :
                roUsario == 'DOCSYMAT.CONSULTA.JL' ? true :
                    roUsario == 'DOCSYMAT.CONSULTA.UTVOPL.OC' ? true :
                        roUsario == 'DOCSYMAT.CONSULTA.OPLE.JL' ? true :
                            roUsario == 'DOCSYMAT.SUPERVISOR.OC' ? true :
                                roUsario == 'DOCSYMAT.REVISOR.OC' ? true : false;

        isCau = roUsario == 'DOCSYMAT.CAU.OC' ? true : false;

        let data = JSON.stringify({
            idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO')
        })


        // return axios.get("http://localhost:3000/JsonHelpers/clasificacionEleccion.json", {})
        axios.post(
            `${basePath}/consultaCatalogoTipoDocumentacionSeguimiento`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {
                if (resp.data.entity.code === 200) {
                    resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                        this.state.options.push({
                            value: item.idTipoDocumentacion,
                            label: item.nombreDocumentacion,
                        });
                    });
                }
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                }
                else {
                    console.log("Error.componentDidMount: ", resp.data.entity.message)
                    //alert(resp.data.causa)
                    /* this.setState(
                        {
                            visibleAlert: {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: resp.data.causa
                            }
                        }); */
                    message.error(resp.data.entity.causa, tiempoMensaje);
                }
            }).catch(err => {
                console.log("Error.componentDidMount: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                //alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.")
                /* this.setState(
                    {
                        visibleAlert: {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: "Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde."
                        }
                    }); */
                message.error("Ocurrió un error al obtener la clasificación de elección. Favor de intentar más tarde.", tiempoMensaje);
            })

    }

    onChangeFiltro = (value) => {


        if (value.length !== 0) {

            this.setState({
                isLoadingTabla: true,
                idTipoDocumentacion: parseInt(value),
                refreshTable: false,
            });

            let data = JSON.stringify({
                idEstado: parseInt(localStorage.getItem('ID_ESTADO_SELECCIONADO')),
                idTipoDocumentacion: parseInt(value),
                idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            })


            // axios.get("http://localhost:3000/JsonHelpers/tablaDocumentos.json", {})
            axios.post(
                `${basePath}/consultaTablaSeguimiento`, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            })
                .then(resp => {
                    if (resp.data.entity.code === 200) {
                        this.setState({
                            isLoadingTabla: false,
                            disabledFiltro: false,
                            listaTabla: resp.data.entity.tablaConsultaSeguimiento,
                            listaTablaCopy: resp.data.entity.tablaConsultaSeguimiento,
                        });
                    }
                    else if (resp.data.entity.code == 403) {
                        localStorage.clear();
                        window.location = "/login"
                    }
                    else {
                        console.log("Error.onChangeFiltro: ", resp.data.entity.message)
                        //alert(resp.data.causa)
                        /*  this.setState(
                             {
                                 visibleAlert: {
                                     activado: true,
                                     tipoMensaje: "error",
                                     mensaje: resp.data.causa
                                 }
                             }); */
                        message.error(resp.data.entity.causa, tiempoMensaje);
                    }
                }).catch(err => {
                    console.log("Error.onChangeFiltro: ", err)
                    if (err.response != null && err.response.status == 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                    this.setState({
                        isLoadingTabla: false,
                        listaTabla: [],
                        listaTablaCopy: [],
                    });
                    //alert("Ocurrió un error al obtener los documentos y materiales, favor de intentar más tarde.")
                    /* this.setState(
                        {
                            visibleAlert: {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: "Ocurrió un error al obtener los documentos y materiales, favor de intentar más tarde."
                            }
                        }); */
                    message.error("Ocurrió un error al obtener los documentos y materiales. Favor de intentar más tarde.", tiempoMensaje);
                })
        } else {
            this.setState({
                isLoadingTabla: false,
                disabledFiltro: true,
                listaTabla: [],
                listaTablaCopy: [],
            });
        }

        
    }

    handleSearch = (value) => {
        const tablaFiltrada = this.state.listaTabla.filter(listaTabla => listaTabla.nombreArticulo.toLowerCase()
            .replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u")
            .includes(value.toLowerCase()
                .replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u")))

        this.setState({ listaTablaCopy: tablaFiltrada })
    };

    abrirModal = (item) => {
        localStorage.setItem("ID_ARTICULO_SEG", item.idArticulo);
        localStorage.setItem("ID_TIPO_DOC_SEG", item.idTipoDocumentacion);
        localStorage.setItem("ID_APROBACION_SEG", item.idAprobacion);
        localStorage.setItem("NOMBRE_ARTICULO_SEG", item.nombreArticulo);
        this.setState({
            isModalVisible: true,
            refreshTable: false,
        });
    }

    setIsModalVisible = () => {
        this.setState({
            isModalVisible: false,
            refreshTable: true,
        });
    }

    setIsLoadingEmpresaModalTrue = () => {
        this.setState({
            isLoadingEmpresaModal: true
        });
    }

    setIsLoadingEmpresaModalFalse = () => {
        this.setState({
            isLoadingEmpresaModal: false
        });
    }

    abrirVentanaDescripcion = (item) => {
        localStorage.setItem("ID_ARTICULO_SEG", item.idArticulo);
        localStorage.setItem("ID_TIPO_DOC_SEG", item.idTipoDocumentacion);
        localStorage.setItem("ID_APROBACION_SEG", item.idAprobacion);
        localStorage.setItem("NOMBRE_ARTICULO_SEG", item.nombreArticulo);
        this.setState({
            redirect: true
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/produccion/consultaseguimiento" />;
        }

        if (this.state.idTipoDocumentacion != null && this.state.refreshTable)
            this.onChangeFiltro(this.state.idTipoDocumentacion);

        return (
            <div>

                {console.log("ALERTA", this.state.visibleAlert),
                    this.state.visibleAlert.activado ?
                        (
                            <Mensajes tipoMensaje={this.state.visibleAlert.tipoMensaje} mensaje={this.state.visibleAlert.mensaje} handleClose={this.handleClose} />
                        ) : null}
                <h1 className="titulo-seguimiento">Seguimiento a producción</h1>
                <label className="texto-14-seguimiento">Los datos con ( <label className="rojo-seguimiento">*</label> ) son requeridos.</label>
                <br />
                <br />
                <label className="texto-16-seguimiento"><label className="rojo-seguimiento">*</label> Tipo de clasificación de elección</label>
                <br />
                <br />
                <Cascader style={{ width: 390 }} options={this.state.options} onChange={this.onChangeFiltro} placeholder="Seleccione una opción" />
                <br />
                <br />
                <List
                    header={
                        <Row className="header-tabla-gris">
                            <Col span={2} className="roboto-normal-16-white texto-centrado">
                                No.
                            </Col>
                            <Col span={10} className="roboto-normal-16-white">
                                Documento/Material
                                <br />
                                <Input disabled={this.state.disabledFiltro} className="filtro-header" onChange={event => this.handleSearch(event.target.value)} placeholder="Filtro" />
                            </Col>
                            <Col span={5} className="roboto-normal-16-white">
                                Porcentaje de avance
                            </Col>
                            <Col span={4} className="roboto-normal-16-white texto-centrado">
                                Avance del día
                            </Col>
                            <Col span={3} className="roboto-normal-16-white texto-centrado">
                                Acciones
                            </Col>
                        </Row>
                    }
                    pagination={{
                        pageSize: 6,
                    }}
                    size="large"
                    loading={this.state.isLoadingTabla}
                    itemLayout="horizontal"
                    dataSource={this.state.listaTablaCopy}
                    renderItem={item =>
                        <List.Item>
                            <Skeleton title={false} loading={this.state.isLoadingTabla} active>
                                <List.Item.Meta
                                    description={
                                        <Row>
                                            <Col span={2} className="texto-centrado">
                                                {item.consecutivo}
                                            </Col>
                                            <Col span={10}>
                                                {item.nombreArticulo}
                                            </Col>
                                            <Col span={5}>
                                                <Progress
                                                    strokeColor={{
                                                        from: item.colorPorcentaje === "3" ? "#AB2525" : item.colorPorcentaje === "2" ? "#389b73" : "#1890ff",
                                                        to: item.colorPorcentaje === "3" ? "#AB2525" : item.colorPorcentaje === "2" ? "#389b73" : "#1890ff",
                                                    }}
                                                    percent={parseInt(item.porcentajeAvance)}
                                                />
                                            </Col>
                                            <Col span={4} className="texto-centrado">
                                                {item.avanceAlDia}
                                            </Col>
                                            <Col span={3} className="acciones-centrado">
                                                <Tooltip title="Consultar"><Button disabled={item.porcentajeAvance > noAvance ? false : true || false} type="link" onClick={() => this.abrirVentanaDescripcion(item)} className="boton-circular-rosa">{/* <Icon type="eye" theme="filled" className="icono-boton-circular"/> */}<EyeOutlined /></Button></Tooltip>
                                                <Tooltip title="Capturar"><Button type="link" disabled={isConsulta} onClick={() => this.abrirModal(item)} className="boton-circular-rosa">{/* <Icon type="plus" className="icono-boton-circular"/> */}<PlusOutlined /></Button></Tooltip>
                                            </Col>
                                        </Row>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    }
                />
                <FormActaSeguimiento
                    isVisible={this.state.isModalVisible}
                    isLoadingEmpresa={this.state.isLoadingEmpresaModal}

                    setIsVisible={this.setIsModalVisible}
                    setIsLoadingEmpresaTrue={this.setIsLoadingEmpresaModalTrue}
                    setIsLoadingEmpresaFalse={this.setIsLoadingEmpresaModalFalse}

                    isCau={isCau}
                />
            </div>
        );
    }
}

export default SeguimientoProduccion;