import React from "react";
import Catalogos from "../../../components/Catalogos/Catalogos"; //Se importa el componente para los catálogos

export default function CatalogosPrincipal(props) {
    return (
        <div>
            {props.location.state === undefined || props.location.state.numMinArchivos === undefined ?
                <Catalogos numMinArchivos={1} /> : <Catalogos numMinArchivos={props.location.state.numMinArchivos} />
            }
        </div>
    );
}