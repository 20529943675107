import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios from "axios";
import { basePath } from "../../../api/config";
import useAuth from "../../../hooks/useAuth";
import './ReporteAdjudicacion.css';

export default function TablaReporteAjudicacion(props) {

    const { user } = useAuth();
    const urlConsultReporteAdjudicacion = `${basePath}/consultaReporteAdjudicacion`;
    const tipoArticuloDocumentos = props.tipoArticuloDocumentos;

    const [reporteAdjudicacion, setReporteAjudicacion] = useState({
        data: [],
        loading: props.loading,
    });

    const [idEstado, setIdEstado] = useState(user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado)

    useEffect(() => {
        console.log("reporteAdjudicacion.loading:=>", reporteAdjudicacion.loading);
        console.log("props.loading:=>", props.loading);
        if (reporteAdjudicacion.loading)
            doRequestReporteAdjudicacion();
    }, [reporteAdjudicacion.loading])

    useEffect(() => {
        setReporteAjudicacion({
            data: [],
            loading: props.loading
        });
    }, [props.loading])

    useEffect(() => {
        if (props.idTipoDocumentacion !== -1)
            setReporteAjudicacion({
                data: [],
                loading: true
            });
    }, [props.idTipoDocumentacion])

    function doRequestReporteAdjudicacion() {
        let data = JSON.stringify({
            idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
            idEstado: idEstado,
            idCorte: user.infoMenu.detalleSelec.corte,
            anio: user.infoMenu.detalleSelec.anio,
            tipoProceso: user.infoMenu.detalleSelec.tipoProceso,
            idTipoDocumentacion: props.idTipoDocumentacion,
            tipoArticulo: props.tipoArticulo
        })
        axios
            .post(
                urlConsultReporteAdjudicacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /*setVisibleAlert(
                          {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: response.data.entity.causa
                          });*/
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /*setVisibleAlert(
                              {
                                activado: true,
                                tipoMensaje: "warning",
                                mensaje: response.data.entity.message
                              });*/
                        }
                        else {
                            setReporteAjudicacion({
                                data: response.data.entity.reporteAdjudicacion,
                                loading: false,
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                setReporteAjudicacion({
                    data: [],
                    loading: false,
                });
            });
    }

    const columnsMateriales = [
        {
            title: props.tipoArticulo === tipoArticuloDocumentos ? "Documento" : "Material",
            dataIndex: "nombreArticulo",
            width: "12%",
        },
        {
            title: "Cantidad estimada a producir",
            dataIndex: "cantidadProduccion",
            align: "center",
            width: "10%",
            render: (text, record) => {
                if (text !== null && text !== undefined)
                    return Number(text).toLocaleString("en-US");
            }
        },
        {
            title: "Tipo de adjudicación",
            dataIndex: "tipoAdjudicacion",
            width: "10%",
            render: (text, record) => {
                if (record.noAdjudicado)
                    return "Se produce por sistema"
                return text;
            }
        },
        {
            title: "Empresa adjudicada",
            dataIndex: "nombreEmpresa",
            width: "10%",
        },
        {
            title: "Fecha de adjudicación",
            dataIndex: "fechaAdjudicacion",
            width: "8%",
        },
        {
            title: "Inicio de producción",
            dataIndex: "fechaInicioProduccion",
            width: "8%",
        },
        {
            title: "Término de producción",
            dataIndex: "fechaFinProduccion",
            width: "8%",
        },
        {
            title: "Observaciones",
            dataIndex: "observaciones",
            width: "14%",
        },
    ];


    const columnsDocumentos = [
        {
            title: "Tipo de documentación",
            dataIndex: "tipoDocumentacion",
            width: "12%",
        },
        ...columnsMateriales,
    ]

    const columnsDocumentosOC = [
        {
            title: "Entidad",
            dataIndex: "nombreEstado",
            width: "10%",
        },
        ...columnsDocumentos,
    ]

    const columnsMaterialesOC = [
        {
            title: "Entidad",
            dataIndex: "nombreEstado",
            width: "10%",
        },
        ...columnsMateriales,
    ]

    return (
        <div>
            <Table
                className="tabla-reporte-produccion"
                pagination={{
                    pageSize: 10,
                }}
                rowClassName={
                    (record, index) => {
                        if (record.noAdjudicado)
                            return "renglon-no-adjudicado"
                        return "";
                    }
                }
                bordered
                dataSource={reporteAdjudicacion.data}
                columns={
                    idEstado === null || idEstado !== null && idEstado === 0 ?
                        (
                            props.tipoArticulo === tipoArticuloDocumentos ? columnsDocumentosOC : columnsMaterialesOC
                        ) :
                        (
                            props.tipoArticulo === tipoArticuloDocumentos ? columnsDocumentos : columnsMateriales
                        )
                }
                loading={reporteAdjudicacion.loading}
            //scroll={{ x: '130%'}}
            />
        </div>
    )

}