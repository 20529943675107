import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Carousel, Button, Select, Icon, Spin } from 'antd';
//import HomeRevision from "./HomeRevision/HomeRevision";
import { DownloadOutlined } from "@ant-design/icons";
import useAuth from "../../hooks/useAuth";
import { Chart } from '@antv/g2';
import "./Admin.scss";
import { base64toBlob } from "../../utils/Utils"
import axios from "axios";
import { basePath } from "../../api/config";
import throttle from 'lodash.throttle';

const getDeviceConfig = (width) => {
  if (width < 320) {
    return ['xs']
  } else if (width >= 320 && width < 720) {
    return ['xs', 'sm']
  } else if (width >= 720 && width < 1024) {
    return ['xs', 'sm', 'md']
  } else if (width >= 1024) {
    return ['xs', 'sm', 'md', 'lg']
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth))
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
}

export default function Admin() {

  const { TabPane } = Tabs;
  const urlConsultaHome = `${basePath}/consultaHome`;
  const urlConsultaHomeAprobacion = `${basePath}/consultaHomeAprobacion`;
  const urlConsultaHomeAdjudicacion = `${basePath}/consultaHomeAdjudicaciones`;
  const urlConsultaHomeProduccion = `${basePath}/consultaHomeProduccion`;
  const tamanioColumn = 70;
  const points = useBreakpoint();
  const [expandir, setExpandir] = useState(false);

  const { user } = useAuth();

  /*const [chartAdjudicacion, setChartAdjudicacion] = useState({
    loading: false,
    chart: null
  });*/

  const [homeData, setHomeData] = useState({
    loading: false,
    homeRevision: null,
    entidades: [],
    etapaActual: -2,
    etapaActualMaxima: -2,
    chartsRevision: {
      chartCargas: null,
      chartValidaciones: null,
      loading: true
    },
    homeProduccion: {
      loading: false,
      dataDocumentos: [],
      dataMateriales: []
    },
    chartsProduccion: {
      loading: true,
      chartDocumentos: null,
      chartMateriales: null,
    }
  });

  const [homeAprobacion, setHomeAprobacion] = useState({
    loading: true,
    data: null,
    urlAcuerdo: null,
    idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : (user.infoMenu.detalleSelec !== null ? user.infoMenu.detalleSelec.idEstado : null)
  })

  useEffect(() => {
    //console.log(screens);
    let expandir = true;
    points.forEach(item => {
      if (item === 'lg') 
        expandir = false;
    });
    console.log("expandir=>", expandir);
    setExpandir(expandir);
  }, [points]);

  /*const [homeAdjudicacion, setHomeAdjudicacion] = useState({
    loading: false,
    data: [],
  })*/

  function doRequestHome() {
    let idEstado = user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado;
    let data = JSON.stringify({
      idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
      idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
      idEstado: idEstado,
      idCorte: user.infoMenu.detalleSelec.corte,
      anio: user.infoMenu.detalleSelec.anio,
      tipoProceso: user.infoMenu.detalleSelec.tipoProceso,
    })
    axios
      .post(
        urlConsultaHome, data, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
          Accept: "application/json",
        }
      }
      ).then(
        response => {
          if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
            setHomeData({
              loading: true,
              homeRevision: null,
              entidades: [],
              etapaActual: -1,
              etapaActualMaxima: -1,
              chartsRevision: homeData.chartsRevision,
              homeProduccion: {
                dataDocumentos: [],
                dataMateriales: [],
                loading: false,
              },
              chartsProduccion: homeData.chartsProduccion
            });
          }
          else if (response.data.entity.code === 200) {
            if (response.data.entity.message !== null && response.data.entity.message !== "") {
              setHomeData({
                loading: true,
                homeRevision: null,
                entidades: [],
                etapaActual: -1,
                etapaActualMaxima: -1,
                chartsRevision: homeData.chartsRevision,
                homeProduccion: {
                  dataDocumentos: [],
                  dataMateriales: [],
                  loading: false,
                },
                chartsProduccion: homeData.chartsProduccion
              });
            }
            else {
              setHomeData({
                loading: true,
                homeRevision: response.data.entity.homeRevision,
                entidades: response.data.entity.entidades,
                etapaActualMaxima: response.data.entity.etapaActual,
                etapaActual: homeData.etapaActual < 0 ? response.data.entity.etapaActual : homeData.etapaActual,
                chartsRevision: homeData.chartsRevision,
                homeProduccion: homeData.homeProduccion,
                chartsProduccion: homeData.chartsProduccion,
              })
              let idEstado = homeAprobacion.idEstado;
              let listaEntidades = Object.entries(response.data.entity.entidades);
              if (listaEntidades.length == 1)
                idEstado = listaEntidades[0][0];

              if (response.data.entity.etapaActual == 2) {
                setHomeAprobacion({
                  loading: false,
                  urlAcuerdo: null,
                  data: null,
                  idEstado: idEstado,
                })
              }
            }
          }
          else {
            localStorage.clear();
            window.location = "/login"
          }
        }
      ).catch(err => {
        console.log("Error:=>: doRequestOptions", err)
        if (err.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
      });
  }

  function doRequestHomeAprobacion(idEstado) {
    let data = JSON.stringify({
      idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
      idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
      idEstado: parseInt(idEstado),
      idCorte: user.infoMenu.detalleSelec.corte,
      anio: user.infoMenu.detalleSelec.anio,
      tipoProceso: user.infoMenu.detalleSelec.tipoProceso,
    })
    axios
      .post(
        urlConsultaHomeAprobacion, data, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
          Accept: "application/json",
        }
      }
      ).then(
        response => {
          if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          }
          else if (response.data.entity.code === 200) {
            if (response.data.entity.message !== null && response.data.entity.message !== "") {
            }
            else {
              let urlAcuerdo = null;
              if (response.data.entity.homeAprobacion !== null && response.data.entity.homeAprobacion.archivoAcuerdoBase64 !== "") {
                const acuerdoBase64 = 'data:application/pdf;base64,' + response.data.entity.homeAprobacion.archivoAcuerdoBase64;
                const blob2 = base64toBlob(acuerdoBase64);
                urlAcuerdo = URL.createObjectURL(blob2);
              }
              setHomeAprobacion({
                loading: true,
                urlAcuerdo: urlAcuerdo,
                data: response.data.entity.homeAprobacion,
                idEstado: idEstado
              });
            }
          }
          else {
            localStorage.clear();
            window.location = "/login"
          }
        }
      ).catch(err => {
        console.log("Error:=>: doRequestOptions", err)
        if (err.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
      });
  }

  /*function doRequestHomeAdjudicacion(idTipoDocumentacion) {
    let data = JSON.stringify({
      idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
      idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
      idEstado: homeAprobacion.data.idEstado,
      idCorte: user.infoMenu.detalleSelec.corte,
      idTipoDocumentacion: idTipoDocumentacion,
      anio: user.infoMenu.detalleSelec.anio,
      tipoProceso: user.infoMenu.detalleSelec.tipoProceso,
    })
    axios
      .post(
        urlConsultaHomeAdjudicacion, data, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
          Accept: "application/json",
        }
      }
      ).then(
        response => {
          if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          }
          else if (response.data.entity.code === 200) {
            if (response.data.entity.message !== null && response.data.entity.message !== "") {
            }
            else {
              setHomeAdjudicacion({
                loading: true,
                data: response.data.entity.data,
              });
              setChartAdjudicacion({
                chart: chartAdjudicacion.chart,
                loading: false
              });
            }
          }
          else {
            localStorage.clear();
            window.location = "/login"
          }
        }
      ).catch(err => {
        console.log("Error:=>: doRequestOptions", err)
        if (err.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
      });
  }*/

  function doRequestHomeProduccion() {
    if (user.infoMenu.detalleSelec === null)
      return;

    let idEstado = user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado;
    let data = JSON.stringify({
      idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
      idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
      idEstado: idEstado,
      idCorte: user.infoMenu.detalleSelec.corte,
      anio: user.infoMenu.detalleSelec.anio,
      tipoProceso: user.infoMenu.detalleSelec.tipoProceso,
    })
    axios
      .post(
        urlConsultaHomeProduccion, data, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
          Accept: "application/json",
        }
      }
      ).then(
        response => {
          if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
            setHomeData({
              loading: true,
              homeRevision: null,
              entidades: [],
              etapaActual: -1,
              etapaActualMaxima: -1,
              chartsRevision: homeData.chartsRevision,
              homeProduccion: {
                dataDocumentos: [],
                dataMateriales: [],
                loading: false,
              },
              chartsProduccion: homeData.chartsProduccion
            });
          }
          else if (response.data.entity.code === 200) {
            if (response.data.entity.message !== null && response.data.entity.message !== "") {
              setHomeData({
                loading: true,
                homeRevision: null,
                entidades: [],
                etapaActual: -1,
                etapaActualMaxima: -1,
                chartsRevision: homeData.chartsRevision,
                homeProduccion: {
                  dataDocumentos: [],
                  dataMateriales: [],
                  loading: false,
                },
                chartsProduccion: homeData.chartsProduccion
              });
            }
            else {
              setHomeData({
                loading: homeData.loading,
                homeRevision: homeData.homeRevision,
                entidades: homeData.entidades,
                etapaActualMaxima: homeData.etapaActual,
                etapaActual: homeData.etapaActual,
                chartsRevision: homeData.chartsRevision,
                homeProduccion: {
                  dataDocumentos: response.data.entity.listaHomeProduccionDocumentos,
                  dataMateriales: response.data.entity.listaHomeProduccionMateriales,
                  loading: true,
                },
                chartsProduccion: homeData.chartsProduccion,
              })
            }
          }
          else {
            localStorage.clear();
            window.location = "/login"
          }
        }
      ).catch(err => {
        console.log("Error:=>: doRequestOptions", err)
        if (err.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
      });
  }

  /*useEffect(() => {
    if (chartAdjudicacion.loading)
      return;
    if (!homeAdjudicacion.loading)
      return;
    if (chartAdjudicacion.chart !== null)
      chartAdjudicacion.chart.destroy();

    if (homeAdjudicacion.data === null) {
      setChartAdjudicacion({
        chart: null,
        loading: true
      })
      return;
    }

    let chart = new Chart({
      container: "container-adjudicacion",
      autoFit: true,
      height: 500,
      renderer: 'svg'
    });
    chart.coordinate().transpose().scale(1, -1);
    chart.data(homeAdjudicacion.data);

    chart.axis('empresa', {
      label: {
        style: {
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fill: "333333"
        },
        formatter: (text, item, index) => {
          if (text.length > 7)
            return text.substring(0, 6) + "...";
          else
            return text;
        },
        autoRotate: false,
        autoEllipsis: false,
      },
      title: {
        style: {
          fill: "333333",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        },
      },
    });
    chart.scale('empresa', {
      alias: 'Empresa',
      nice: true,
    });

    chart.axis('articulosAdjudicados', {
      label: {
        style: {
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fill: "333333"
        },
        autoRotate: false,
        autoEllipsis: false,
      },
      title: {
        style: {
          fill: "333333",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        },
      },
    });
    chart.scale('articulosAdjudicados', {
      alias: 'Total articulos adjudicados',
      nice: true,
    });

    chart.tooltip({
      showMarkers: false,
    });
    chart.interaction('element-active');
    chart.legend(false);
    chart
      .interval()
      .position('empresa*articulosAdjudicados')
      .color('empresa', '#780047');
    chart.render();

    setChartAdjudicacion({
      chart: chart,
      loading: true
    });
  }, [chartAdjudicacion])*/

  function cleanChartsRevision() {
    if (homeData.chartsRevision.chartCargas !== null && homeData.chartsRevision.chartValidaciones !== null) {
      if (homeData.chartsRevision.chartCargas !== null)
        homeData.chartsRevision.chartCargas.destroy();
      if (homeData.chartsRevision.chartValidaciones !== null)
        homeData.chartsRevision.chartValidaciones.destroy();
    }
    setHomeData({
      loading: homeData.loading,
      homeRevision: homeData.homeRevision,
      entidades: homeData.entidades,
      etapaActual: homeData.etapaActual,
      etapaActualMaxima: homeData.etapaActualMaxima,
      chartsRevision: {
        chartCargas: null,
        chartValidaciones: null,
        loading: false
      },
      homeProduccion: homeData.homeProduccion,
      chartsProduccion: homeData.chartsProduccion
    });
  }

  function cleanChartsProduccion() {
    if (homeData.chartsProduccion.chartDocumentos !== null)
      homeData.chartsProduccion.chartDocumentos.destroy();
    if (homeData.chartsProduccion.chartMateriales !== null)
      homeData.chartsProduccion.chartMateriales.destroy();

    setHomeData({
      loading: homeData.loading,
      homeRevision: homeData.homeRevision,
      entidades: homeData.entidades,
      etapaActual: homeData.etapaActual,
      etapaActualMaxima: homeData.etapaActualMaxima,
      chartsRevision: homeData.chartsRevision,
      homeProduccion: homeData.homeProduccion,
      chartsProduccion: {
        chartDocumentos: null,
        chartMateriales: null,
        loading: false,
      }
    });
  }

  function createChartsProduccion() {

    let mapaEntidades = {};
    homeData.homeProduccion.dataDocumentos.map((value, index) => {
      let entidad = mapaEntidades[value];
      if (entidad === undefined)
        mapaEntidades[value.nombreEstado] = true;
    })

    let heightContainer = tamanioColumn * Object.entries(mapaEntidades).length;

    let chartDocumentos = new Chart({
      container: "container-produccion-documentos",
      autoFit: true,
      height: heightContainer,
      width: '100%',
      renderer: 'svg',
      pixelRatio: window.devicePixelRatio,
    });
    chartDocumentos.data(homeData.homeProduccion.dataDocumentos);
    chartDocumentos.axis('nombreEstado', {
      label: {
        style: {
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fill: "333333"
        },
        formatter: (text, item, index) => {
          if (text.length > 7)
            return text.substring(0, 6) + "...";
          else
            return text;
        },
        autoRotate: false,
        autoEllipsis: false,
      },
      title: {
        style: {
          fill: "333333",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        },
      },
    });
    chartDocumentos.scale('nombreEstado', {
      alias: 'Estado',
      nice: true,
    });
    chartDocumentos.axis('value', false);
    chartDocumentos.interaction('active-region');
    chartDocumentos.legend({
      position: 'top',
    });
    chartDocumentos.coordinate('rect').transpose();
    chartDocumentos.tooltip({
      shared: true,
      showMarkers: false,
      showContent: true,
    });
    chartDocumentos
      .interval()
      .adjust('stack')
      .position('nombreEstado*value')
      .color('subclasificacion', ['#d787bc', '#d5007f', '#780047'])
      //.color('subclasificacion', ['#d787bc', '#c12b7d', '#440a30'])
      .label('value', (val) => {
        let colorText = 'white';
        return {
          position: 'middle',
          offset: 0,
          content: (originData) => {
            if (val == 0)
              return "";
            return originData.value + "%";
          },
          style: {
            fill: colorText,
            stroke: '#fff'
          }
        };
      });
    chartDocumentos.render();

    mapaEntidades = {};
    homeData.homeProduccion.dataMateriales.map((value, index) => {
      let entidad = mapaEntidades[value];
      if (entidad === undefined)
        mapaEntidades[value.nombreEstado] = true;
    })

    heightContainer = tamanioColumn * Object.entries(mapaEntidades).length;

    let chartMateriales = new Chart({
      container: "container-produccion-materiales",
      autoFit: true,
      height: heightContainer,
      width: '100%',
      renderer: 'svg',
      pixelRatio: window.devicePixelRatio,
    });
    chartMateriales.data(homeData.homeProduccion.dataMateriales);
    chartMateriales.axis('nombreEstado', {
      label: {
        style: {
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fill: "333333"
        },
        formatter: (text, item, index) => {
          if (text.length > 7)
            return text.substring(0, 6) + "...";
          else
            return text;
        },
        autoRotate: false,
        autoEllipsis: false,
      },
      title: {
        style: {
          fill: "333333",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        },
      },
    });
    chartMateriales.scale('nombreEstado', {
      alias: 'Estado',
      nice: true,
    });
    chartMateriales.axis('value', false);
    chartMateriales.interaction('active-region');
    chartMateriales.legend({
      position: 'top',
    });
    chartMateriales.coordinate('rect').transpose();
    chartMateriales.tooltip({
      shared: true,
      showMarkers: false,
      showContent: true,
    });
    chartMateriales
      .interval()
      .adjust('stack')
      .position('nombreEstado*value')
      .color('subclasificacion', ['#d5007f'])
      .label('value', (val) => {
        let colorText = 'white';
        return {
          position: 'middle',
          offset: 0,
          content: (originData) => {
            if (val == 0)
              return "";
            return originData.value + "%";
          },
          style: {
            fill: colorText,
            stroke: '#fff'
          }
        };
      });
    chartMateriales.render();

    setHomeData({
      loading: homeData.loading,
      homeRevision: homeData.homeRevision,
      entidades: homeData.entidades,
      etapaActual: homeData.etapaActual,
      etapaActualMaxima: homeData.etapaActualMaxima,
      chartsRevision: homeData.chartsRevision,
      homeProduccion: homeData.homeProduccion,
      chartsProduccion: {
        chartDocumentos: chartDocumentos,
        chartMateriales: chartMateriales,
        loading: true,
      }
    });
  }

  function createChartsRevision() {
    let chartCargas = new Chart({
      container: "container-revision-cargas",
      autoFit: true,
      height: 300,
      width: '100%',
      renderer: 'svg',
      pixelRatio: window.devicePixelRatio
    });
    chartCargas.data(homeData.homeRevision.cargas);
    chartCargas.axis('group', {
      label: {
        style: {
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fill: "333333",
        },
        formatter: (text, item, index) => {
          if (text.length > 7)
            return text.substring(0, 6) + "...";
          else
            return text;
        },
        autoRotate: true,
        autoEllipsis: false,
        autoHide: expandir
      },
      /*title: {
        style: {
          fill: "333333",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        },
      },*/
    });
    /*chartCargas.scale('group', {
      alias: homeData.homeRevision.isByEntidad ? 'Tipo de clasificación' : 'Estado',
      nice: true,
    });*/
    chartCargas.axis('value', {
      label: {
        style: {
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fill: "333333"
        }
      },
      title: {
        style: {
          fill: "333333",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        },
      },
    });
    chartCargas.interaction('active-region');
    chartCargas.scale('value', {
      alias: 'Número de artículos',
      nice: true,
    });
    chartCargas.legend({
      position: 'bottom',
    });
    chartCargas.tooltip({
      shared: true,
      showMarkers: false,
      showContent: true,
    });
    chartCargas
      .interval()
      .adjust('stack')
      .position('group*value')
      .color('type*group', (type, group) => {
        if (type === 'Artículos por cargar') {
          return '#1890ff';
        }
        if (type === 'Artículos cargados') {
          return '#ced4d9';
        }
      })
      .color('type', ['#780047', '#d5007f'])
      .label('value*type', (val, type) => {
        let colorText = 'black';
        if (type === 'Artículos por cargar')
          colorText = 'white';
        return {
          position: 'middle',
          offset: 0,
          content: (originData) => {
            if (val == 0)
              return "";
            return originData.value;
          },
          style: {
            fill: colorText,
            stroke: '#fff'
          }
        };
      });
    chartCargas.render();

    if (homeData.homeRevision.validaciones === null) {
      setHomeData({
        loading: homeData.loading,
        homeRevision: homeData.homeRevision,
        entidades: homeData.entidades,
        etapaActual: homeData.etapaActual,
        etapaActualMaxima: homeData.etapaActualMaxima,
        chartsRevision: {
          chartCargas: chartCargas,
          chartValidaciones: null,
          loading: true
        },
        homeProduccion: homeData.homeProduccion,
        chartsProduccion: homeData.chartsProduccion
      });
      return;
    }

    let chartValidaciones = new Chart({
      container: "container-revision-validacion",
      autoFit: true,
      height: 300,
      width: '100%',
      renderer: 'svg',
      pixelRatio: window.devicePixelRatio,
    });
    chartValidaciones.data(homeData.homeRevision.validaciones);
    chartValidaciones.axis('group', {
      label: {
        style: {
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fill: "333333"
        },
        formatter: (text, item, index) => {
          if (text.length > 7)
            return text.substring(0, 6) + "...";
          else
            return text;
        },
        autoRotate: false,
        autoEllipsis: false,
      },
      title: {
        style: {
          fill: "333333",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        },
      },
    });
    chartValidaciones.interaction('active-region');
    chartValidaciones.scale('group', {
      //alias: homeData.homeRevision.isByEntidad ? 'Tipo de clasificación de elección' : 'Estado',
      alias: 'Tipo de clasificación de elección',
      nice: true,
    });
    chartValidaciones.axis('value', {
      label: {
        style: {
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fill: "333333"
        }
      },
      title: {
        style: {
          fill: "333333",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        },
      },
    });
    chartValidaciones.scale('value', {
      alias: 'Número de artículos',
      nice: true,
    });
    chartValidaciones.legend({
      position: 'right',
    });
    chartValidaciones.tooltip({
      shared: true,
      showMarkers: false,
      showContent: true,
    });
    chartValidaciones
      .interval()
      .adjust('stack')
      .position('group*value')
      .color('type*group', (type, group) => {
        if (type === 'Artículos por validar') {
          return '#1890ff';
        }
        if (type === 'Artículos validados') {
          return '#ced4d9';
        }
      })
      .color('type', ['#780047', '#d5007f'])
      .label('value*type', (val, type) => {
        let colorText = 'black';
        if (type === 'Artículos por validar')
          colorText = 'white';
        return {
          position: 'middle',
          offset: 0,
          content: (originData) => {
            if (val == 0)
              return "";
            return originData.value;
          },
          style: {
            fill: colorText,
            stroke: '#fff'
          }
        };
      });
    chartValidaciones.render();
    setHomeData({
      loading: homeData.loading,
      homeRevision: homeData.homeRevision,
      entidades: homeData.entidades,
      etapaActual: homeData.etapaActual,
      etapaActualMaxima: homeData.etapaActualMaxima,
      chartsRevision: {
        chartCargas: chartCargas,
        chartValidaciones: chartValidaciones,
        loading: true
      },
      homeProduccion: homeData.homeProduccion,
      chartsProduccion: homeData.chartsProduccion
    });
  }

  useEffect(() => {
    //console.log("Entro actualizar el hook user global:=>", user.infoMenu);
    if (user.infoMenu.detalleSelec !== null) {
      setHomeData({
        loading: false,
        homeRevision: null,
        entidades: [],
        etapaActual: -1,
        etapaActualMaxima: -1,
        chartsRevision: homeData.chartsRevision,
        homeProduccion: {
          dataDocumentos: [],
          dataMateriales: [],
          loading: false,
        },
        chartsProduccion: homeData.chartsProduccion
      });


      setHomeAprobacion({
        loading: true,
        urlAcuerdo: null,
        data: null,
        idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado
      })
      /*setHomeAdjudicacion({
        loading: false,
        data: []
      })
      setHomeProduccion({
        loading: true,
        dataDocumentos: []
      })*/
    }
  }, [user.infoMenu])

  /*useEffect(() => {
    if (!homeData.chartsRevision.loading && homeData.etapaActual == 1)
      createChartsRevision();
  }, [homeData.chartsRevision]);*/

  useEffect(() => {
    if (!homeData.loading && user.infoMenu.detalleSelec !== null && homeData.etapaActual > -2)
      doRequestHome();
    else if (homeData.loading && homeData.etapaActual != 1) {
      cleanChartsRevision();
      if (homeData.etapaActual == 3 && !homeData.homeProduccion.loading)
        doRequestHomeProduccion();
      else if (homeData.etapaActual == 2)
        cleanChartsProduccion();
      else if (homeData.etapaActual == 3)
        createChartsProduccion();
    }
    else if (homeData.loading && homeData.etapaActual == 1) {
      createChartsRevision();
      cleanChartsProduccion();
    }
  }, [homeData.loading, homeData.etapaActual, homeData.homeProduccion.loading]);

  useEffect(() => {
    if (!homeAprobacion.loading && homeAprobacion.idEstado !== null)
      doRequestHomeAprobacion(homeAprobacion.idEstado);
  }, [homeAprobacion]);

  function callback(key) {
    setHomeData({
      loading: key != 1,
      homeRevision: null,
      entidades: homeData.entidades,
      etapaActual: key,
      etapaActualMaxima: homeData.etapaActualMaxima,
      chartsRevision: homeData.chartsRevision,
      homeProduccion: {
        loading: key != 3,
        dataDocumentos: [],
        dataMateriales: [],
      },
      chartsProduccion: homeData.chartsProduccion
    });

    let idEstado = homeAprobacion.idEstado;
    let listaEntidades = Object.entries(homeData.entidades);
    if (listaEntidades.length == 1)
      idEstado = listaEntidades[0][0];

    setHomeAprobacion({
      loading: key != 2,
      urlAcuerdo: null,
      data: null,
      idEstado: idEstado,
    })
  }

  function handleChangeEntidad(key) {
    //console.log("key:=>", key);
    setHomeAprobacion({
      loading: false,
      urlAcuerdo: null,
      data: null,
      idEstado: key,
    })
  }

  /*function handleChangeTipoDocumentacion(key) {
    console.log("key:=>", key);
    setHomeAdjudicacion({
      loading: false,
      homeAdjudicacion: [],
    })
    setChartAdjudicacion({
      chart: chartAdjudicacion.chart,
      loading: false
    });
    doRequestHomeAdjudicacion(key);
  }*/

  function onClickDownload() {
    //console.log("Entro a descagar");
    let a = document.createElement('a');
    a.href = homeAprobacion.urlAcuerdo;
    /* a.download = "DOCSMATS-" + homeAprobacion.data.nombreArchivoAcuerdo; */
    a.download = "DOCSMATS-" + "acuerdoAprobacion";
    a.click();
  }

  return (
    <div>
      <h1 className="titulo-admin">¡Bienvenido/a!</h1>
      <h1 className="subtitulo-admin">Sistema de Seguimiento a Documentos y Materiales OPL</h1>
      {homeData.etapaActual >= 0 && (
        <Tabs defaultActiveKey={`${homeData.etapaActual}`} onChange={callback} className="tabs-home">
          <TabPane tab="Revisión" key="1" disabled={1 > homeData.etapaActualMaxima} >
            <div>
              {!homeData.loading && <div className="div_cargando"><Spin size="large" /></div>}
              {homeData.loading ?
                (
                  <div>
                    <h1 className="titulo-admin-grafica">Carga de artículos</h1>
                    <br />
                  </div>
                )
                : null
              }
              {homeData.loading && <div id="container-revision-cargas" className="div-charts" />}
              {homeData.loading ?
                (
                  <div>
                    <br />
                    <h1 className="titulo-admin-grafica">Artículos validados</h1>
                    <br />
                  </div>
                )
                : null
              }
              {homeData.loading && <div id="container-revision-validacion" className="div-charts" />}
            </div>
          </TabPane>
          <TabPane tab="Aprobación" key="2" disabled={2 > homeData.etapaActualMaxima} >
            <div>
              {Object.entries(homeData.entidades).length > 1 ? (
                <div>
                  <label className="texto-16-admin">Entidad</label>
                  <br />
                  <label className="texto-14-admin">
                    <Icon type="info-circle" theme="filled" className="icono-info" />
                    Selecciona para conocer el estatus de la aprobación
                  </label>
                  <br />
                  <Select
                    onSelect={handleChangeEntidad}
                    defaultValue="Seleccionar opción"
                    placeholder="Seleccionar opción"
                    id="entidades"
                    style={{ width: 250 }}
                  >
                    {Object.entries(homeData.entidades).map((value, index) => {
                      //console.log("value:=>", value);
                      return <Select.Option key={value[0]}>{value[1]}</Select.Option>
                    })}
                  </Select>
                  <br />
                  <br />
                </div>
              ) : <div />
              }
              {homeAprobacion.loading && homeAprobacion.data !== null ?
                <div>
                  <h1 className="titulo-admin">Acuerdo de sesión</h1>
                  <label className="texto-14-admin">
                    <Icon type="info-circle" theme="filled" className="icono-info" />
                    Descarga aquí el acuerdo de sesión más reciente cargado en el sistema
                  </label>
                  <div>
                    <br />
                    <Button className="boton-descargar-home" onClick={onClickDownload}><DownloadOutlined /> Descargar </Button>
                    <label className="texto-16-admin"><span style={{ paddingLeft: '10px' }} />
                      Fecha de sesión: {homeAprobacion.data.fechaSesion}
                    </label>
                  </div>
                  <br />
                </div> : null
              }
              {!homeAprobacion.loading && homeAprobacion.idEstado ? <div className="div_cargando"><Spin size="large" /></div> : null}
            </div>
          </TabPane>
          <TabPane tab="Producción" key="3" disabled={3 > homeData.etapaActualMaxima} >
            <div>
              {homeData.homeProduccion.loading ?
                <div>
                  {homeData.homeProduccion.dataDocumentos.length > 0 ?
                    <h1 className="titulo-admin">Avance de produccion documentos</h1> : null
                  }
                  <br />
                  <div id="container-produccion-documentos" />
                  <br />
                  {homeData.homeProduccion.dataMateriales.length > 0 ?
                    <h1 className="titulo-admin">Avance de produccion materiales</h1> : null
                  }
                  <br />
                  <div id="container-produccion-materiales" />
                </div> : null}
              {!homeData.homeProduccion.loading ? <div className="div_cargando"><Spin size="large" /></div> : null}
            </div>

          </TabPane>
          {/*<TabPane tab="Producción" key="3">
            <div>
              <h3>Avance de produccion</h3>
              <h4>En la gráfica se muestran las empresas más atrasadas en su total de producción</h4>
              <br />
              <div id="container-produccion" />
            </div>
            </TabPane>*/}
        </Tabs>
      )
      }
    </div >
  );
}