import React from "react";

import RegistrarVerificacion from "../../../components/RegistrarVerificacion/RegistrarVerificacion"; //Se importa el componente para los catálogos

export default function RegistrarVerificacionPrincipal(props) {

    return (
        <RegistrarVerificacion
            idVerificacion={props.location.state.idVerificacion}
        />
    );

}