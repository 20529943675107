import React from "react";
import RegistrarAdjudicaciones from "../../../components/RegistrarAdjudicaciones/RegistrarAdjudicaciones"; //Se importa el componente para los catálogos

export default function RegistrarAdjudicacionesPrincipal(props) {
    return (
         <RegistrarAdjudicaciones 
            idArticulo={props.location.state.idArticulo} 
            nombreArticulo={props.location.state.nombreArticulo}
            idTipoDocumentacion={props.location.state.idTipoDocumentacion}/>
    );
}