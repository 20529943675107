import React from "react";
import ConsultarAdjudicaciones from "../../../components/ConsultarAdjudicaciones/ConsultarAdjudicaciones"; //Se importa el componente para los catálogos

export default function ConsultarAdjudicacionesPrincipal(props) {
    return (
        <ConsultarAdjudicaciones
            idArticulo={props.location.state.idArticulo}
            nombreArticulo={props.location.state.nombreArticulo}
            idTipoDocumentacion={props.location.state.idTipoDocumentacion} />
    );
}