import React, { useState, useEffect, useCallback } from "react";
import { Select, Row, Col, Form, Icon, Spin, DatePicker, Input, InputNumber, Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import "./FormRegistroAdjudicacion.scss";

import { basePath } from "../../api/config";

export default function FormRegistroAdjudicacion(props) {

    const { getFieldDecorator } = props.form;

    const dateFormat = "DD-MM-YYYY";
    const timeFormat = "HH:mm";

    const [rangeDates, setRangeDates] = useState({
        minInicio: moment().startOf('day'),
        minFin: moment().startOf('day')
    });

    const [enabledRangeDate, setEnabledRangeDate] = useState({
        fechaInicio: false,
        fechaFin: false,
    });

    const handleGuardaAdjudicacion = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                props.handleSubmit(values);
            }
        });
    };

    const handleRangeNumber = (rule, value, callback) => {
        if (value && (value > rule.max || value < rule.min)) {
            callback(rule.message)
        }
        callback()
    }

    const handleValuesChange = () => console.log('Value changes');

    return (
        <div>
            <h1 className="titulo-adjudicacion">{props.nombreArticulo}</h1>
            <label className="texto-14-adjudicacion">Los datos con ( <label className="rojo-adjudicacion">*</label> ) son requeridos.</label>
            <br />
            <br />
            <div>
                <Form onSubmit={handleGuardaAdjudicacion} layout="vertical">
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <label className="rojo-adjudicacion">* </label><label className="texto-16-adjudicacion">Empresa</label>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={23}>
                                <label className="rojo-adjudicacion">* </label><label className="texto-16-adjudicacion">Tipo de adjudicación</label>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <label className="rojo-adjudicacion">* </label><label className="texto-16-adjudicacion">Fecha de adjudicación</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <Form.Item name="empresa" hasFeedback>
                                    {getFieldDecorator('empresa', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese una empresa.',
                                            }
                                        ],
                                    })(
                                        <Input
                                            placeholder="Ingresa nombre de la empresa"
                                            maxLength={524}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={23}>
                                <Form.Item name="tipoAdjudicacion" hasFeedback>
                                    {getFieldDecorator('tipoAdjudicacion', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione un tipo de ajudicación.',
                                            }
                                        ],
                                    })(
                                        <Select
                                            placeholder="Seleccionar opción"
                                        >
                                            {props.optionsTipoAdjudicaciones.map((value, index) => {
                                                return <Select.Option key={value}>{value}</Select.Option>;
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="fechaAdjudicacion" hasFeedback>
                                {getFieldDecorator('fechaAdjudicacion', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Seleccione una fecha de adjudicación.',
                                        }
                                    ],
                                })(
                                    <DatePicker
                                        placeholder="Selecciona"
                                        format={dateFormat}
                                        disabledDate={current => {
                                            return current.startOf('day') > moment().endOf('day')
                                        }}
                                        onChange={value => {
                                            props.form.setFieldsValue({
                                                fechaInicioProduccion: null,
                                                fechaFinProduccion: null
                                            })
                                            if (value !== null) {
                                                setEnabledRangeDate({
                                                    fechaInicio: true,
                                                    fechaFin: false
                                                });
                                            }
                                            else {
                                                setEnabledRangeDate({
                                                    fechaInicio: false,
                                                    fechaFin: false
                                                });
                                            }
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <Col span={6}>
                                    <label className="rojo-adjudicacion">* </label><label className="texto-16-seguimiento">Montos adjudicados</label>
                                </Col>
                                <Col span={6} offset={1}>
                                    <label className="rojo-adjudicacion">* </label><label className="texto-16-seguimiento">Cantidad a producir</label>
                                </Col>
                                <Col span={10} offset={1}>
                                    <label className="rojo-adjudicacion">* </label><label className="texto-16-seguimiento">Total</label>
                                </Col>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={11}>
                                <label className="rojo-adjudicacion">* </label><label className="texto-16-seguimiento">Fecha inicio de producción.</label>
                            </Col>
                            <Col span={11} offset={1}>
                                <label className="rojo-adjudicacion">* </label><label className="texto-16-seguimiento">Fecha fin de producción.</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <Col span={6}>
                                    <Form.Item name="montosAdjudicados" hasFeedback>
                                        {getFieldDecorator('montosAdjudicados', {
                                            rules: [
                                                {
                                                    type: 'number',
                                                    message: 'Montos no valida.',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Ingrese los montos ajudicados.',
                                                }
                                            ],
                                        })(
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                min={0.001}
                                                max={99999.999}
                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                precision={3}
                                                onChange={
                                                    value => {
                                                        const cantidadProducir = props.form.getFieldValue('cantidadProducir');
                                                        props.form.setFieldsValue({
                                                            total: isNaN(cantidadProducir) ? value : value * cantidadProducir
                                                        })
                                                    }
                                                }
                                            />
                                        )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={6} offset={1}>
                                    <Form.Item name="cantidadProducir" hasFeedback>
                                        {getFieldDecorator('cantidadProducir', {
                                            rules: [
                                                {
                                                    type: 'integer',
                                                    message: 'Cantidad a producir no valida.',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Ingrese la cantidad a producir.',
                                                },
                                            ],
                                        })(
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                min={1}
                                                max={999999999}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={
                                                    value => {
                                                        const montosAdjudicados = props.form.getFieldValue('montosAdjudicados');
                                                        props.form.setFieldsValue({
                                                            total: isNaN(montosAdjudicados) ? value : value * montosAdjudicados
                                                        })
                                                    }
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={10} offset={1}>
                                    <Form.Item name="total" hasFeedback>
                                        {getFieldDecorator('total', {
                                            rules: [
                                                {
                                                    type: 'number',
                                                    message: 'Cantidad total no valida.',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Ingrese el total.',
                                                },
                                                {
                                                    validator: handleRangeNumber,
                                                    message: 'Canitdad fuera del rango permitido.',
                                                    min: 0.001,
                                                    max: 999999999.999
                                                }
                                            ],
                                        })(
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                min={0.001}
                                                max={999999999.999}
                                                disabled={true}
                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                precision={3}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={11}>
                                <Form.Item name="fechaInicioProduccion" hasFeedback>
                                    {getFieldDecorator('fechaInicioProduccion', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione una fecha inicio de producción.',
                                            }
                                        ],
                                    })(
                                        <DatePicker
                                            placeholder="Selecciona"
                                            format={dateFormat}
                                            disabled={!enabledRangeDate.fechaInicio}
                                            disabledDate={current => {
                                                return current.startOf('day') < props.form.getFieldValue('fechaAdjudicacion').startOf('day')
                                            }}
                                            onChange={value => {
                                                props.form.setFieldsValue({
                                                    fechaFinProduccion: null
                                                })
                                                if (value !== null) {
                                                    setEnabledRangeDate({
                                                        fechaInicio: true,
                                                        fechaFin: true
                                                    });
                                                }
                                                else {
                                                    setEnabledRangeDate({
                                                        fechaInicio: true,
                                                        fechaFin: false
                                                    });
                                                }
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={1}>
                                <Form.Item name="fechaFinProduccion" hasFeedback>
                                    {getFieldDecorator('fechaFinProduccion', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione una fecha fin de producción.',
                                            }
                                        ],
                                    })(
                                        <DatePicker
                                            placeholder="Selecciona"
                                            format={dateFormat}
                                            disabled={!enabledRangeDate.fechaFin}
                                            disabledDate={current => {
                                                return (
                                                    current.startOf('day') < props.form.getFieldValue('fechaInicioProduccion').endOf('day')
                                                )
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Col span={23}>
                                <label className="texto-16-adjudicacion">Observaciones</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <Form.Item name="observaciones">
                                    {getFieldDecorator('observaciones', {})(
                                        <Input.TextArea
                                            placeholder="Ingresa"
                                            rows={2}
                                            autoSize={{ minRows: 2, maxRows: 2 }}
                                            allowClear={true}
                                            maxLength={1500}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={4} offset={8}>
                            <Col>
                                <Form.Item >
                                    <Button className="agregar" type="primary" htmlType="submit" disabled={!props.enableCaptura}>
                                        <PlusOutlined />Agregar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}