import React from "react";
import './ReporteAprobacion.scss';
import { Tabs, Button, message } from "antd";
import { Redirect } from 'react-router';
import axios from "axios";
import { basePath } from "../../../api/config";
import ReporteAprobacionDocumentos from "./ReporteAprobacionDocumentos";
import ReporteAprobacionMateriales from "./ReporteAprobacionMateriales";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
var tiempoMensaje = 5;
class ReporteAprobacion extends React.Component {
    constructor(props) {
        
        super(props);

        this.state = {
            activeTab: "1",
            comboHidden: true,
            options: [],
            isLoadingDocumentos: false,
            isLoadingMateriales: false,
            isDescargando: false,
            redirect: false,
            documentos: [],
            materiales: [],
            nombreOple: [],
            selectedOption: [],
            selectedOptionObject: [],
            optionsMateriales: [],
            optionTodosLosDocumentos: []
        };

    }

    componentDidMount() {

        this.initReporteDocumentos();

        this.initReporteMateriales();

    }


    initReporteDocumentos = () => {

        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if(this.isEstateDefined(entidad)==false){
            entidad = 0;
         
         }
  
        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: parseInt(entidad)
        })
     

        axios.post(
            `${basePath}/consultaTipoDocumentacionTodosLosDocumentos`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {

                if (resp.data.entity.code === 200) {
                    resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                        this.state.optionTodosLosDocumentos.push({
                            value: item.idTipoDocumentacion,
                            label: item.nombreDocumentacion,
                        });
                    });

                    this.getDocumentos(this.state.optionTodosLosDocumentos[0].value);
                   

                }
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                } 
                else {
                    console.log("Error.componentDidMount: ", resp.data.message)
                    message.error("Ocurrió un error al consultar la aprobación de documentación electoral. Inténtalo más tarde",tiempoMensaje);
                    /* alert(resp.data.causa) */
                }
            }).catch(err => {

                console.log("Error.componentDidMount: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                message.error("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.",tiempoMensaje);
               /*  alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.") */
            })
        
    }



    initReporteMateriales = () => {

        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if(this.isEstateDefined(entidad)==false){
            entidad = 0;
         }

        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: parseInt(entidad),
        })

        axios.post(
            `${basePath}/consultaTipoDocumentacionMateriales`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {
                if (resp.data.entity.code === 200) {
                    resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                        this.state.optionsMateriales.push({
                            value: item.idTipoDocumentacion,
                            label: item.nombreDocumentacion,
                        });
                    });

                    this.getMateriales();

                }
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                } 
                else {
                    console.log("Error.componentDidMount: ", resp.data.message)
                    message.error("Ocurrió un error al consultar la aprobación de materiales electorales. Inténtalo más tarde",tiempoMensaje);
                    /* alert(resp.data.causa) */
                }
            }).catch(err => {

                console.log("Error.componentDidMount: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                message.error("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.",tiempoMensaje);
                /* alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.") */
            })
        
    }


    getDocumentos = (idTipoDocumentacion) => {

        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if(this.isEstateDefined(entidad)==false){
            entidad = 0;
         }

            this.setState({
                isLoadingDocumentos: true
            });

            let data = JSON.stringify({
                idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
                idEstado: parseInt(entidad),
                idTipoDocumentacion: idTipoDocumentacion
            })

            axios.post(
                `${basePath}/consultaReporteAprobacion`, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            })
                .then(resp => {
                    if (resp.data.entity.code === 200) {
                        this.setState({
                            nombreOple: resp.data.entity.reporteAprobacion.nombreOple,
                            documentos: resp.data.entity.reporteAprobacion,
                            isLoadingDocumentos: false
                        });


                    }
                    else if (resp.data.entity.code == 403) {
                        localStorage.clear();
                        window.location = "/login"
                    } 
                    else {
                        console.log("Error.componentDidMount: ", resp.data.message)
                        message.error("Ocurrió un error al obtener el reporte, favor de intentar más tarde.",tiempoMensaje);
                       /*  alert(resp.data.causa) */

                        this.setState({
                            nombreOple: [],
                            documentos: []
                         

                        });
                    }
                }).catch(err => {
                    console.log("Error.componentDidMount: ", err)
                    if (err.response != null && err.response.status == 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                    message.error("Ocurrió un error al obtener la información del reporte, favor de intentar más tarde.",tiempoMensaje);
                   /*  alert("Ocurrió un error al obtener la información del reporte, favor de intentar más tarde.") */

                    this.setState({
                        nombreOple: [],
                        documentos: [],
                        isLoadingDocumentos: false,
                    });

                })

    }



    getMateriales = () => {
     
        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if(this.isEstateDefined(entidad)==false){
           entidad = 0;
        }
       

            if (this.state.optionsMateriales.length > 0) {
                
                    this.setState({
                        isLoadingMateriales: true
                    });

                let data = JSON.stringify({
                    idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                    idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
                    idEstado: parseInt(entidad),
                    idTipoDocumentacion: this.state.optionsMateriales[0].value
                })
                axios.post(
                    `${basePath}/consultaReporteAprobacion`, data, {
                    headers: {
                        'Authorization': localStorage.getItem('accessToken'),
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                })
                    .then(resp => {
                        if (resp.data.entity.code === 200) {
                            this.setState({
                                nombreOple: resp.data.entity.reporteAprobacion.nombreOple,
                                materiales: resp.data.entity.reporteAprobacion,
                                isLoadingMateriales: false
                            });

                        }
                        else if (resp.data.entity.code == 403) {
                            localStorage.clear();
                            window.location = "/login"
                        } 
                        else {
                            console.log("Error.componentDidMount: ", resp.data.message)
                            message.error("Ocurrió un error al obtener el reporte, favor de intentar más tarde.",tiempoMensaje);
                            /* alert(resp.data.causa) */

                            this.setState({
                                nombreOple: [],
                                materiales: [],
                           
                            });
                        }
                    }).catch(err => {
                        console.log("Error.componentDidMount: ", err)
                        if (err.response != null && err.response.status == 403) {
                            localStorage.clear();
                            window.location = "/login"
                            return;
                        }
                        message.error("Ocurrió un error al obtener el reporte, favor de intentar más tarde.",tiempoMensaje);
                        /* alert("Ocurrió un error al obtener el reporte, favor de intentar más tarde.") */

                        this.setState({
                            nombreOple: [],
                            materiales: [],
                            isLoadingMateriales: false,
                        });

                    })

            }
        

    }


    execChildActions = () => {
        if (this.state.activeTab === "1") {
            this.getMateriales();
        }

    };

    changeTab = activeKey => {

        this.setState({
            activeTab: activeKey
        });

        this.execChildActions();
    };


    downloadExcel = () => {

        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');
        var nombreEstado = localStorage.getItem('ESTADO');
        if(this.isEstateDefined(entidad)==false){
           entidad = 0;
           nombreEstado = "TODOS";
        }
       

        this.setState({
            isDescargando: true
        });

        let data = JSON.stringify({
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: entidad,
            nombreEstado: nombreEstado,
            documentos: this.state.documentos,
            materiales: this.state.materiales,
        })

        axios.post(
            `${basePath}/descargaReporteAprobacion`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {
                if (resp.data.entity.code === 200) {
                    this.setState({
                        isDescargando: false,
                        disabledFiltro: false,

                    });

                    var sampleArr = base64ToArrayBuffer(resp.data.entity.reporteAprobacion);
                    saveByteArray("reporteAprobacion.xlsx", sampleArr);

                }
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                } 
                else {
                    console.log("Error.downloadExcel: ", resp.data.entity.message)
                    message.error(resp.data.entity.causa,tiempoMensaje);
                   /*  alert(resp.data.entity.causa) */
                }
            }).catch(err => {
                console.log("Error.downloadExcel: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                this.setState({
                    isDescargando: false,
                });
                message.error("Ocurrió un error al descargar el archivo, favor de intentar más tarde.",tiempoMensaje);
                /* alert("Ocurrió un error al descargar el archivo, favor de intentar más tarde.") */
            })


        function base64ToArrayBuffer(base64) {
            var binaryString = window.atob(base64);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        }

        function saveByteArray(reportName, byte) {
            var blob = new Blob([byte], { type: "application/xlsx" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = reportName;
            link.download = fileName;
            link.click();
        }

    }


    isEstateDefined =(entidad)=>{

        if(entidad == 'null'|| entidad == 'undefined' || entidad == null){
            return false;
        }else{
            return true;
        }

    
    }

    isOc =()=>{
        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if(entidad == 0 || this.isEstateDefined(entidad)==false){
        
            return true;
        }else{
      
            return false;
        }

      
    }
 
    printReporteDocumentos = (infoArray)=>{
        let reportes=[];

        if(infoArray.length>0){
            if(infoArray.length>0){
                for(var i =0; i < infoArray.length; i++){
                    reportes.push(
                        <ReporteAprobacionDocumentos key={i} documentos={infoArray[i].articulos} isLoading={this.state.isLoadingDocumentos}  isOc={this.isOc()}/>
                    )
                }
            }
        }else{
            reportes.push(
            <ReporteAprobacionDocumentos key={0} documentos={[]} isLoading={this.state.isLoadingDocumentos}  isOc={this.isOc()}/>
            )

        }
        
      
        return reportes;
    };

    
    printReporteMateriales = (infoArray)=>{
        let reportes=[];

        if(infoArray.length>0){
            for(var i =0; i < infoArray.length; i++){
                reportes.push(
                    <ReporteAprobacionMateriales key={i} materiales={infoArray[i].articulos} isLoading={this.state.isLoadingMateriales}  isOc={this.isOc()}/>
                )
            }
        }else{
            reportes.push(
            <ReporteAprobacionMateriales key={1} materiales={[]} isLoading={this.state.isLoadingMateriales}  isOc={this.isOc()}/>
            )
        }
      
        return reportes;
    };

    render() {


        if (this.state.redirect) {
            return <Redirect push to="/reportes" />;
        }

        return (
            <div className="div_reporteAprobacion">

                <h1 className="titulo-reportes">Aprobación de la documentación y materiales electorales del OPL</h1>
                <label className="texto-12-reportes">Los datos con ( <label className="rojo-reportes">*</label> ) son requeridos.</label>
                <br />
                <br />

                <Tabs defaultActiveKey="1" onChange={this.changeTab}>

                    <TabPane tab="Documentos" key="1" >
                        <div className="subreporte">
                            {this.printReporteDocumentos(this.state.documentos)}
                        </div>
                    </TabPane>

                    <TabPane tab="Materiales" key="2"  >
                        <div className="subreporte">
                        {this.printReporteMateriales(this.state.materiales)}
                        </div>
                    </TabPane>



                </Tabs>

                <div className="div_boton_descarga">
                    <Button disabled={false} id="regresar"
                        onClick={this.downloadExcel}
                        className="descarga"

                    >
                        <VerticalAlignBottomOutlined /> Descargar
          </Button>
                </div>

            </div>
        );
    }
}

export default ReporteAprobacion;