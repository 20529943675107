import React, { useState, useEffect } from "react";
import "./Proceso_Menu.scss";
import { Popover, Select, Icon } from "antd";
import axios from "axios";
import { basePath } from "../../api/config"; //cambiaInfoMenu
import { ID_SISTEMA as idSistema } from "../../utils/constanst";
import useAuth from "../../hooks/useAuth";

const { Option } = Select;

export default function ProcesoMenu({ history }) {

  const url = `${basePath}/cambiaInfoMenu`;
  const { user, setUser } = useAuth();
  const { user: usuario, idMunicipio, ambito, versionUsuario, infoMenu } = user
  const { idEstado, idDistrito } = usuario
  const rolUsuario = usuario.rol[0]
  var infoMenuGeografico = { infoMenu, idSistema, idEstado, idDistrito, idMunicipio, ambito, rolUsuario, versionUsuario }
  const [infoMenuHook, setInfoMenuHook] = useState()
  const [tipoGeografico, setTipoGeografico] = useState()

  useEffect(() => {
    let infoMenuAux = infoMenu;
    if (infoMenuAux) {
      let tipoGeo = obtieneTipoLista(infoMenuAux);
      setTipoGeografico(tipoGeo);
    }
    setInfoMenuHook(infoMenuAux)
  }, [infoMenu])


  const seleccionaDetalle = async (detalleSelec) => {
    let infoMenu = {
      ...infoMenuHook
    }
    infoMenu.detalleSelec = detalleSelec.key ? null : detalleSelec
    infoMenu = limpiaNivelOC(infoMenu)
    infoMenuGeografico.infoMenu = infoMenu
    if (!detalleSelec.key) {
      infoMenuGeografico.tipoCambioGeografico = 1
      const response = await axios.post(url, infoMenuGeografico)
      infoMenu = response.data.infoMenu
    }

    localStorage.setItem("INFO_MENU", JSON.stringify(infoMenu));
    setInfoMenuHook(infoMenu)
    setUser((user) => {
      return { ...user, infoMenu }
    })

    localStorage.setItem("DESCRIPCION", detalleSelec.descripcion);
    localStorage.setItem("ID_DETALLE_PROCESO", detalleSelec.idDetalleProceso);
    localStorage.setItem("ID_PROCESO_ELECTORAL", detalleSelec.idProcesoElectoral);
    localStorage.setItem("ANIO", detalleSelec.anio);
    localStorage.setItem("TIPO_PROCESO", detalleSelec.tipoProceso);
    localStorage.setItem("ESTADO", "null");
    localStorage.setItem("ID_ESTADO_SELECCIONADO", "null");

    history.location.pathname !== "/home" && history.push("/home");
  }

  const seleccionaEstado = async (estadoSelec) => {
    let infoMenu = {
      ...infoMenuHook
    }
    infoMenu.estadoSelec = estadoSelec.key ? null : estadoSelec
    localStorage.setItem("INFO_MENU", JSON.stringify(infoMenu))
    setUser((user) => {
      return { ...user, infoMenu }
    })
    localStorage.setItem("ESTADO", estadoSelec.nombreEstado);
    localStorage.setItem("ID_ESTADO_SELECCIONADO", estadoSelec.idEstado);
    history.location.pathname !== "/home" && history.push("/home");
  }

  const limpiaNivelOC = (infoMenu) => {
    infoMenu = {
      ...infoMenu,
      estadoSelec: null,
      listaEstados: null,
      distritoFedSelec: null,
      listaDistritosFed: null,
      distritoLocSelec: null,
      listaDistritosLoc: null,
      municipioSelec: null,
      listaMunicipios: null,
      listMenu: null
    }
    return infoMenu;
  }

  const obtieneTipoLista = (infoMenu) => {
    let tipoGeograficoAux
    if (infoMenu) {
      if (infoMenu.listaDistritosFed) {
        tipoGeograficoAux = {
          nombreLista: 'listaDistritosFed',
          nombreID: 'idDistrito',
          nombreGeografia: 'nombreDistrito',
          nombreSelect: 'distritoFedSelec'
        }
      } else if (infoMenu.listaDistritosLoc) {
        tipoGeograficoAux = {
          nombreLista: 'listaDistritosLoc',
          nombreID: 'idDistrito',
          nombreGeografia: 'nombreDistrito',
          nombreSelect: 'distritoLocSelec'
        }
      } else if (infoMenu.listaMunicipios) {
        tipoGeograficoAux = {
          nombreLista: 'listaMunicipios',
          nombreID: 'idMunicipio',
          nombreGeografia: 'nombreMunicipio',
          nombreSelect: 'municipioSelec'
        }
      } else {
        tipoGeograficoAux = null
      }
    }

    return tipoGeograficoAux
  }

  function MenuUsuario() {
    return (
      <div>
        <div className="etiqueta">Proceso Electoral</div>
        <Select
          defaultValue={(infoMenuHook && infoMenuHook.detalleSelec) ? infoMenuHook.detalleSelec.descripcion : "Selecciona"}
          style={{ width: 280 }}
        //disabled={!(versionUsuario === "OC")}
        >
          <Option value="-1" onClick={seleccionaDetalle}> Selecciona </Option>
          {(infoMenuHook && infoMenuHook.listaDetalles) &&
            infoMenuHook.listaDetalles.map((detalle, i) => (
              <Option onClick={seleccionaDetalle.bind(this, detalle)} key={i}>{detalle.descripcion}</Option>
            ))}

        </Select>
        <p> </p>

        <div className="etiqueta">Entidad</div>
        <Select
          defaultValue={(infoMenuHook && infoMenuHook.estadoSelec) ? infoMenuHook.estadoSelec.nombreEstado : "Selecciona"}
          style={{ width: 280 }}
          disabled={!((infoMenuHook && infoMenuHook.listaEstados) && versionUsuario === 'OC')}
        >
          {
            (infoMenuHook && infoMenuHook.estadoSelec) ?
              (
                console.log("infoMenuHook.estadoSelec.idEstado:=>", infoMenuHook.estadoSelec.idEstado),
                localStorage.setItem("ESTADO", infoMenuHook.estadoSelec.nombreEstado),
                localStorage.setItem("ID_ESTADO_SELECCIONADO", infoMenuHook.estadoSelec.idEstado)
              )
              : 
              (
                localStorage.setItem("ESTADO", null),
                localStorage.setItem("ID_ESTADO_SELECCIONADO", null)
              )
          }
          <Option value="-1" onClick={seleccionaEstado} > Selecciona </Option>
          {(infoMenuHook && infoMenuHook.listaEstados) &&
            infoMenuHook.listaEstados.map((estado) => (
              <Option onClick={seleccionaEstado.bind(this, estado)} key={estado.idEstado}>{estado.nombreEstado}</Option>
            ))}
        </Select>
      </div>
    );
  }

  const content = () => (
    <MenuUsuario />
  );

  return (
    <div className="procesoContainer">
      <Popover content={content()} trigger={!(versionUsuario === "JD") ? "click" : ""} placement="bottomRight" >
        <div className="proceso">
          <div>
            <span>
              {(infoMenuHook && infoMenuHook.detalleSelec) ?
                infoMenuHook.detalleSelec.descripcion
                : (
                  <div>
                    {versionUsuario === "OC" ? "OFICINAS CENTRALES" : "PROCESO ELECTORAL"}
                  </div>
                )
              }
            </span>
          </div>
          <div>
            <span>{(infoMenuHook && infoMenuHook.estadoSelec) && (<><Icon type="environment" theme="filled" /> {infoMenuHook.estadoSelec.nombreEstado}</>)}</span>
          </div>
        </div>
      </Popover>
    </div>
  );
}