import React from "react";
import './Seguimiento.css';
import { Button, Icon, Cascader, Row, Col, List, Skeleton, Input, DatePicker, Form, Select, message } from "antd";
import { Tooltip } from '@material-ui/core';
import { FormOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Redirect } from 'react-router';
import axios from "axios";
import moment from 'moment';
import { basePath } from "../../../api/config";
import jwtDecode from "jwt-decode";

let isConsulta = false;
let isCau = false;
let isCapturaOpl = false;
var tiempoMensaje = 5;

class ConsultaSeguimientoProduccion extends React.Component {
    constructor(props) {
        super(props);

        // this.handleGuardaActa = this.handleGuardaActa.bind(this);

        this.state = {
            options: [],
            redirect: false,
            isLoadingTabla: false,
            cantidadTotalProducir: "",
            periodoProduccion: "",
            listaTabla: [],
            ultimoRegistro: 0,
            editarRegistro: false,
            fechaInicioPeriodo: null,
            fechaFinPeriodo: null,
            cantidadFaltante: 0,
            cantidadAcumuladaLimite: 0,
            fechaUltimoRegistro: null,
        };
    }

    componentDidMount() {

        const roUsario = localStorage.getItem("ROL_USUARIO");

        isConsulta = roUsario == 'DOCSYMAT.CONSULTA.OC' ? true :
            roUsario == 'DOCSYMAT.CAPTURA.JL' ? true :
                roUsario == 'DOCSYMAT.CONSULTA.JL' ? true :
                    roUsario == 'DOCSYMAT.CONSULTA.UTVOPL.OC' ? true :
                        roUsario == 'DOCSYMAT.CONSULTA.OPLE.JL' ? true :
                            roUsario == 'DOCSYMAT.SUPERVISOR.OC' ? true :
                                roUsario == 'DOCSYMAT.REVISOR.OC' ? true : false;

        isCau = roUsario == 'DOCSYMAT.CAU.OC' ? true : false;

        isCapturaOpl = roUsario == 'DOCSYMAT.CAPTURA.OPLE.JL' ? true : false;

        let data = JSON.stringify({
            idArticulo: parseInt(localStorage.getItem("ID_ARTICULO_SEG")),
            idEstado: parseInt(localStorage.getItem('ID_ESTADO_SELECCIONADO')),
            idTipoDocumentacion: parseInt(localStorage.getItem('ID_TIPO_DOC_SEG')),
            idAprobacion: parseInt(localStorage.getItem('ID_APROBACION_SEG'))
        })

        // axios.get("http://localhost:3000/JsonHelpers/empresasEjemplo.json", {})
        axios.post(
            `${basePath}/obtieneListaEmpresasByArticulo`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {
                if (resp.data.entity.code === 200) {
                    let options = [];
                    resp.data.entity.listaEmpresas.forEach((item) => {
                        let idEmpresaSelect = item.idAdjudicacion + "-" + item.idEmpresa
                        options = [...options, { value: idEmpresaSelect, label: item.nombreEmpresa }];
                    });
                    this.setState({
                        options: options
                    });
                    if (options.length === 1)
                        this.doRequestProduccion(options[0].value);
                } else {
                    console.log("Error.componentDidMount: ", resp.data.entity.message)
                    /* alert(resp.data.causa) */
                    message.error(resp.data.entity.causa, tiempoMensaje);
                }
            }).catch(err => {
                console.log("Error.componentDidMount: ", err)
                /* alert("ocurrió un error al obtener las empresas asociadas aal artículo, favor de intentar más tarde.") */
                message.error("Ocurrió un error al obtener las empresas asociadas al artículo. Favor de intentarlo más tarde", tiempoMensaje);
            })

    }

    redirectBack = () => {
        this.setState({
            redirect: true
        });
    }

    onChangeFiltro = (value) => {
        this.doRequestProduccion(value);
    }

    disabledStartDate = current => {
        return current && current <= moment(this.state.fechaInicioPeriodo, "DD-MM-YYYY").subtract(1, 'day').endOf('day') || current >= moment(this.state.fechaFinPeriodo, "DD-MM-YYYY").endOf('day');
    };

    disabledEndDate = current => {
        return current && current <= moment(this.state.fechaInicioPeriodo, "DD-MM-YYYY").subtract(1, 'day').endOf('day') || current >= moment(this.state.fechaFinPeriodo, "DD-MM-YYYY").endOf('day');
    };

    validaCantidaProducida = (rule, value, callback) => {

        if (+value > +this.state.cantidadTotalProducir) {

            callback('Excede la cantidad total asignada.')
        }
        if (+value <= +this.state.cantidadAcumuladaLimite)
            callback('El campo debe ser mayor a la cantidad acumulada.')
        callback()
    };

    borrarRegistro = (item) => {

        let usuario = jwtDecode(localStorage.getItem('accessToken'));
        let data = JSON.stringify({
            idProduccion: parseInt(item.idProduccion),
            numeroSeguimiento: parseInt(item.numeroSeguimiento),
            semana: parseInt(item.semana),
            usuario: usuario.sub
        })

        axios.post(
            `${basePath}/eliminaSeguimientoProduccion`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {
                if (resp.data.entity.code === 200) {
                    console.log("resp.data");
                    console.log(resp.data.entity);

                    /* alert(resp.data.message) */
                    message.success(resp.data.entity.message, tiempoMensaje);

                    this.setState({
                        editarRegistro: false
                    });

                    this.onChangeFiltro(localStorage.getItem("ID_ADJUDICACION_EMPRESA_SEG"));
                }
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                }
                else {
                    console.log("Error.handleGuardaActa: ", resp.data.entity.message)
                    message.error(resp.data.entity.causa, tiempoMensaje);
                    /*  alert(resp.data.causa) */
                }
            }).catch(err => {
                console.log("Error.handleGuardaActa: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                message.error("Ocurrió un error al guardar el avance a la producción. Favor de intentar más tarde.", tiempoMensaje);
                /*  alert("ocurrio un error al guardar el acta, favor de intentar más tarde.") */
            })
    }

    editarRegistro = () => {
        this.setState({
            editarRegistro: true
        });
    }

    handleGuardaActa = (item) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                const { fechaInicioComponent, fechaFinComponent, cantidadProducida } = values;

                let value = localStorage.getItem("ID_ADJUDICACION_EMPRESA_SEG");

                let splited = value.split("-");

                let fI = fechaInicioComponent[parseInt(item.semana)];
                let fF = fechaFinComponent[parseInt(item.semana)];
                let cP = cantidadProducida[parseInt(item.semana)];

                let usuario = jwtDecode(localStorage.getItem('accessToken'));

                let data = JSON.stringify({
                    idAdjudicacion: parseInt(splited[0]),
                    idEmpresa: parseInt(splited[1]),
                    idProduccion: parseInt(item.idProduccion),
                    numeroSeguimiento: parseInt(item.numeroSeguimiento),
                    semana: parseInt(item.semana),
                    fechaInicio: fI,
                    fechaFin: fF,
                    cantidadProducida: cP,
                    usuario: usuario.sub,

                    idArticulo: parseInt(localStorage.getItem("ID_ARTICULO_SEG")),
                    idTipoDocumentacion: localStorage.getItem('ID_TIPO_DOC_SEG'),
                    idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                    idEstado: parseInt(localStorage.getItem('ID_ESTADO_SELECCIONADO')),
                    idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
                    anioProceso: localStorage.getItem('ANIO'),
                    tipoProceso: localStorage.getItem('TIPO_PROCESO')
                })

                axios.post(
                    `${basePath}/editaSeguimientoProduccion`, data, {
                    headers: {
                        'Authorization': localStorage.getItem('accessToken'),
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                })
                    .then(resp => {
                        if (resp.data.entity.code === 200) {
                            console.log("resp.data");
                            console.log(resp.data.entity);

                            /*  alert(resp.data.message) */
                            message.success(resp.data.entity.message, tiempoMensaje);

                            this.setState({
                                editarRegistro: false
                            });

                            this.onChangeFiltro(localStorage.getItem("ID_ADJUDICACION_EMPRESA_SEG"));
                        }
                        else if (resp.data.entity.code == 403) {
                            localStorage.clear();
                            window.location = "/login"
                        }
                        else {
                            console.log("Error.handleGuardaActa: ", resp.data.entity.message)
                            message.error(resp.data.entity.causa, tiempoMensaje)
                            /*  alert(resp.data.causa) */
                        }
                    }).catch(err => {
                        console.log("Error.handleGuardaActa: ", err)
                        if (err.response != null && err.response.status == 403) {
                            localStorage.clear();
                            window.location = "/login"
                            return;
                        }
                        message.error("Ocurrió un error al guardar el avance de producción. Favor de intentar más tarde.", tiempoMensaje);
                        /* alert("ocurrio un error al guardar el acta, favor de intentar más tarde.") */
                    })
            }
        });
    }

    cancelarEditarRegistro = () => {
        this.setState({
            editarRegistro: false
        });
    }

    doRequestProduccion(value) {
        this.setState({
            isLoadingTabla: true
        });

        if (value.length !== 0) {
            localStorage.setItem("ID_ADJUDICACION_EMPRESA_SEG", value);
            let splited = value.split("-");

            let data = JSON.stringify({
                idAprobacion: parseInt(localStorage.getItem('ID_APROBACION_SEG')),
                idEmpresa: parseInt(splited[1]),
                idAdjudicacion: parseInt(splited[0]),
                idArticulo: parseInt(localStorage.getItem("ID_ARTICULO_SEG")),
                idEstado: parseInt(localStorage.getItem('ID_ESTADO_SELECCIONADO')),
                idTipoDocumentacion: parseInt(localStorage.getItem('ID_TIPO_DOC_SEG'))
            })

            // axios.get("http://localhost:3000/JsonHelpers/tablaMovimientosArticulos.json", {})
            axios.post(
                `${basePath}/obtieneTablaSeguimientoProduccion`, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            })
                .then(resp => {
                    console.log("resp:");
                    console.log(resp.data.entity);

                    if (resp.data.entity.code === 200) {
                        let periodoProduccion = resp.data.entity.fechaInicioPeriodo + " a " + resp.data.entity.fechaFinPeriodo;

                        this.setState({
                            isLoadingTabla: false,
                            cantidadTotalProducir: resp.data.entity.cantidadTotal,
                            periodoProduccion: periodoProduccion,
                            listaTabla: resp.data.entity.tablaSeguimientoProduccion,
                            ultimoRegistro: resp.data.entity.tablaSeguimientoProduccion.length,
                            fechaInicioPeriodo: resp.data.entity.fechaInicioPeriodo,
                            fechaFinPeriodo: resp.data.entity.fechaFinPeriodo,
                            cantidadFaltante: parseInt(resp.data.entity.cantidadFaltante),
                            cantidadAcumuladaLimite: parseInt(resp.data.entity.cantidadAcumuladaLimite),
                            fechaUltimoRegistro: resp.data.entity.fechaUltimoRegistro,
                        });
                    }
                    else if (resp.data.entity.code == 403) {
                        localStorage.clear();
                        window.location = "/login"
                    }
                    else {
                        console.log("Error.componentDidMount: ", resp.data.entity.message)
                        this.setState({
                            isLoadingTabla: false,
                            cantidadTotalProducir: "",
                            periodoProduccion: "",
                            listaTabla: [],
                            ultimoRegistro: 0,
                            fechaInicioPeriodo: null,
                            fechaFinPeriodo: null,
                            cantidadFaltante: 0,
                            cantidadAcumuladaLimite: 0,
                            fechaUltimoRegistro: null,
                        });
                        message.error("Selecciona una entidad del proceso electoral.", tiempoMensaje);
                        /* alert(resp.data.causa) */
                    }
                }).catch(err => {
                    console.log("Error.onChangeFiltro: ", err)
                    if (err.response != null && err.response.status == 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                    this.setState({
                        isLoadingTabla: false,
                        cantidadTotalProducir: "",
                        periodoProduccion: "",
                        listaTabla: [],
                        ultimoRegistro: 0,
                        fechaInicioPeriodo: null,
                        fechaFinPeriodo: null,
                        cantidadFaltante: 0,
                        cantidadAcumuladaLimite: 0,
                        fechaUltimoRegistro: null,
                    });
                    message.error("Ocurrió un error al obtener los documentos y materiales. Favor de intentar más tarde.", tiempoMensaje);
                    /* alert("ocurrio un error al obtener los documentos y materiales, favor de intentar más tarde.") */
                })
        } else {
            this.setState({
                isLoadingTabla: false,
                cantidadTotalProducir: "",
                periodoProduccion: "",
                listaTabla: [],
                ultimoRegistro: 0,
                fechaInicioPeriodo: null,
                fechaFinPeriodo: null,
                cantidadFaltante: 0,
                cantidadAcumuladaLimite: 0,
                fechaUltimoRegistro: null,
            });
        }
    }

    render() {

        const { getFieldDecorator, getFieldValue } = this.props.form;

        if (this.state.redirect) {
            return <Redirect push to="/produccion/seguimiento" />;
        }

        return (
            <div>

                <Button type="link" onClick={this.redirectBack} className="button-link">
                    <Icon type="arrow-left" />
                    Regresar
                </Button>
                <br />
                <br />
                <h1 className="titulo-seguimiento">{localStorage.getItem('NOMBRE_ARTICULO_SEG')}</h1>
                <br />

                <label className="texto-16-seguimiento"><label className="rojo-seguimiento">*</label> Empresa</label>
                <br />
                <br />
                <div>
                    {
                        this.state.options !== undefined && this.state.options !== null && this.state.options.length > 0 ?
                            (
                                <Select className="seleccion"
                                    placeholder="Seleccione una opción"
                                    onChange={this.onChangeFiltro}
                                    defaultValue={
                                        this.state.options.length > 0 && this.state.options.length == 1
                                            ? this.state.options[0].value : "Seleccione una opción"
                                    }

                                    disabled={
                                        this.state.options.length > 0 && this.state.options.length == 1
                                    }
                                >
                                    {
                                        this.state.options.map((value, index) => {
                                            return <Select.Option style={{ width: '100%' }} id="opcion" key={value.value}>{value.label}</Select.Option>;
                                        })
                                    }
                                </Select>
                            ) : null
                    }
                </div>
                <br />
                <br />
                <Row type="flex" justify="start">
                    <Col span={6}>
                        <label className="texto-16-bold-seguimiento">Cantidad total a producir</label>
                        <br />
                        <label className="texto-16-seguimiento">{this.state.cantidadTotalProducir}</label>
                    </Col>
                    <Col span={7}>
                        <label className="texto-16-bold-seguimiento">Periodo de producción</label>
                        <br />
                        <label className="texto-16-seguimiento">{this.state.periodoProduccion}</label>
                    </Col>
                </Row>
                <br />
                <Form layout="vertical">
                    <List
                        header={
                            <Row className="header-tabla-gris">
                                <Col span={4} className="roboto-normal-16-white texto-centrado">
                                    Semana
                                </Col>
                                <Col span={4} className="roboto-normal-16-white">
                                    Fecha de inicio
                                </Col>
                                <Col span={4} className="roboto-normal-16-white">
                                    Fecha de captura
                                </Col>
                                <Col span={4} className="roboto-normal-16-white">
                                    Cantidad acumulada
                                </Col>
                                <Col span={4} className="roboto-normal-16-white">
                                    Porcentaje
                                </Col>
                                <Col span={4} className="roboto-normal-16-white texto-centrado">
                                    Acciones
                                </Col>
                            </Row>
                        }
                        size="large"
                        loading={this.state.isLoadingTabla}
                        itemLayout="horizontal"
                        dataSource={this.state.listaTabla}
                        renderItem={item =>
                            <List.Item>
                                <Skeleton title={false} loading={this.state.isLoadingTabla} active>
                                    <List.Item.Meta
                                        description={
                                            <Row>
                                                <Col span={4} className="texto-centrado">
                                                    {item.semana}
                                                </Col>
                                                <Col span={4}>
                                                    {
                                                        (this.state.editarRegistro === true && this.state.ultimoRegistro === parseInt(item.semana))
                                                            ?
                                                            <Form.Item
                                                                key={parseInt(item.semana)}
                                                            >
                                                                {getFieldDecorator(`fechaInicioComponent[${parseInt(item.semana)}]`, {
                                                                    //initialValue: moment(item.fechaInicio, 'DD-MM-YYYY'),
                                                                    initialValue:
                                                                        (moment(this.state.fechaUltimoRegistro, "DD-MM-YYYY").endOf('day') < moment(this.state.fechaInicioPeriodo, "DD-MM-YYYY").startOf('day') ?
                                                                            moment(this.state.fechaInicioPeriodo, "DD-MM-YYYY") :
                                                                            moment(this.state.fechaUltimoRegistro, "DD-MM-YYYY").startOf('day') >= moment(this.state.fechaInicioPeriodo, "DD-MM-YYYY").startOf('day') &&
                                                                                moment(this.state.fechaUltimoRegistro, "DD-MM-YYYY").endOf('day') <= moment(this.state.fechaFinPeriodo, "DD-MM-YYYY").endOf('day') ?
                                                                                moment(this.state.fechaUltimoRegistro, "DD-MM-YYYY") : moment(this.state.fechaFinPeriodo, "DD-MM-YYYY")
                                                                        ),
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: 'Selecciona una fecha de inicio.'
                                                                        }
                                                                    ]
                                                                })(
                                                                    <DatePicker
                                                                        //disabledDate={this.disabledStartDate}
                                                                        //disabled={this.state.disabledDate}
                                                                        disabled={true}
                                                                        format="DD-MM-YYYY"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                            :
                                                            item.fechaInicio
                                                    }
                                                </Col>
                                                <Col span={4}>
                                                    {
                                                        (this.state.editarRegistro === true && this.state.ultimoRegistro === parseInt(item.semana))
                                                            ?
                                                            <Form.Item
                                                                key={parseInt(item.semana)}
                                                            >
                                                                {getFieldDecorator(`fechaFinComponent[${parseInt(item.semana)}]`, {
                                                                    // initialValue: moment(item.fechaFin, 'DD-MM-YYYY'),
                                                                    initialValue:
                                                                        (moment().endOf('day') < moment(this.state.fechaInicioPeriodo, "DD-MM-YYYY").startOf('day') ?
                                                                            moment(this.state.fechaInicioPeriodo, "DD-MM-YYYY") :
                                                                            moment().startOf('day') >= moment(this.state.fechaInicioPeriodo, "DD-MM-YYYY").startOf('day') &&
                                                                                moment().endOf('day') <= moment(this.state.fechaFinPeriodo, "DD-MM-YYYY").endOf('day') ?
                                                                                moment().startOf('day') : moment(this.state.fechaFinPeriodo, "DD-MM-YYYY")
                                                                        ),
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: 'Selecciona una fecha de Captura.'
                                                                        }
                                                                    ]
                                                                })(
                                                                    <DatePicker
                                                                        //disabledDate={this.disabledEndDate}
                                                                        //disabled={this.state.disabledDate}
                                                                        disabled={true}
                                                                        format="DD-MM-YYYY"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                            :
                                                            item.fechaFin
                                                    }
                                                </Col>
                                                <Col span={4}>
                                                    {
                                                        (this.state.editarRegistro === true && this.state.ultimoRegistro === parseInt(item.semana))
                                                            ?
                                                            <Form.Item
                                                                key={parseInt(item.semana)}
                                                            >
                                                                {getFieldDecorator(`cantidadProducida[${parseInt(item.semana)}]`, {
                                                                    initialValue: item.cantidadProducida,
                                                                    rules: [
                                                                        {
                                                                            pattern: '^[0-9]*$',
                                                                            message: 'Solo se aceptan números'
                                                                        },
                                                                        {
                                                                            max: 6,
                                                                            message: 'Tamaño máximo de 6 números.'
                                                                        },
                                                                        {
                                                                            required: true,
                                                                            message: 'Ingresa la cantidad acumulada.'
                                                                        },
                                                                        {
                                                                            validator: this.validaCantidaProducida
                                                                        }
                                                                    ]
                                                                })(
                                                                    <Input className="tamanio-input-tabla"></Input>
                                                                )}
                                                            </Form.Item>
                                                            :
                                                            item.cantidadProducida
                                                    }
                                                </Col>
                                                <Col span={4}>
                                                    {item.porcentajeAvance}&nbsp;%
                                                </Col>
                                                <Col span={4} className="texto-centrado">
                                                    {
                                                        isCapturaOpl === true ? "" :
                                                            this.state.ultimoRegistro === parseInt(item.semana)
                                                                ?
                                                                this.state.editarRegistro === true
                                                                    ?
                                                                    <div>
                                                                        <Tooltip title="Aceptar"><Button type="link" disabled={isCau} onClick={() => this.handleGuardaActa(item)} className="boton-ok" htmlType="submit"><Icon type="check" /></Button></Tooltip>
                                                                        <Tooltip title="Cancelar"><Button type="link" onClick={this.cancelarEditarRegistro} className="boton-cancelar"><Icon type="close" /></Button></Tooltip>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <Tooltip title="Eliminar"><Button type="link" disabled={isConsulta || isCau} onClick={() => this.borrarRegistro(item)} className="boton-circular-rosa"><DeleteOutlined /></Button></Tooltip>
                                                                        <Tooltip title="Modificar"><Button type="link" disabled={isConsulta} onClick={this.editarRegistro} className="boton-circular-rosa"><FormOutlined /></Button></Tooltip>
                                                                    </div>
                                                                :
                                                                ""
                                                    }

                                                </Col>
                                            </Row>
                                        }
                                    />
                                </Skeleton>
                            </List.Item>
                        }
                    />
                </Form>
            </div >
        );
    }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(ConsultaSeguimientoProduccion);
export default WrappedDynamicFieldSet;