import React from "react";
import '../Reportes.css';
import { List, Col, Row, Skeleton} from "antd";
import { Redirect } from 'react-router';
import { fileToObject } from "antd/lib/upload/utils";




const headers = [     {tag: "Material",           width:8, style:"texto-centrado"},
                      {tag: "Cantidad a producir", width:3, style:"texto-centrado"},
                      {tag: "Cantidad producida",  width:4, style:"texto-centrado"},
                      {tag: "Fecha de elaboración",width:4, style:"texto-centrado"},
                      {tag: "Observaciones",       width:5, style:"texto-centrado"}];
                         
const headersOc = [   {tag: "Entidad",              width:3, style:"texto-centrado"},
                      {tag: "Material",           width:6, style:"texto-centrado"},
                      {tag: "Cantidad a producir", width:3, style:"texto-centrado"},
                      {tag: "Cantidad producida",  width:4, style:"texto-centrado"},
                      {tag: "Fecha de elaboración",width:4, style:"texto-centrado"},
                      {tag: "Observaciones",       width:4, style:"texto-centrado"}];

const subHeaders = [  {tag: "",       field: "nombre",             width:8, style:"texto-izquierda"},
                      {tag: "Piezas", field: "piezasAsignadas",    width:3, style:"texto-centrado"},
                      {tag: "Piezas", field: "cantidadAcumulada",  width:2, style:"texto-centrado"},
                      {tag: "%",      field: "porcentajeAcumulado",width:2, style:"texto-centrado"},
                      {tag: "Inicio", field: "fechaInicio",        width:2, style:"texto-centrado"},
                      {tag: "Término",field: "fechaFin",           width:2, style:"texto-centrado"},
                      {tag: "",       field: "observaciones",      width:5, style:"texto-centrado"}];
                        
const subHeadersOc = [{tag: "",       field: "nombreEstado",       width:3, style:"texto-centrado"},
                      {tag: "",       field: "nombre",             width:6, style:"texto-izquierda"},
                      {tag: "Piezas", field: "piezasAsignadas",    width:3, style:"texto-centrado"},
                      {tag: "Piezas", field: "cantidadAcumulada",  width:2, style:"texto-centrado"},
                      {tag: "%",      field: "porcentajeAcumulado",width:2, style:"texto-centrado"},
                      {tag: "Inicio", field: "fechaInicio",        width:2, style:"texto-centrado"},
                      {tag: "Término",field: "fechaFin",           width:2, style:"texto-centrado"},
                      {tag: "",       field: "observaciones",      width:4, style:"texto-centrado"}];
                         

class ReporteProduccionMateriales extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
       
            redirect: false,
   
        };

    }

    componentDidMount() {


    }

    buildHeaders = (structure) =>{

        let headers=[];
    
        for(var i=0;i<structure.length;i++){
            headers.push(
                <Col xs={structure[i].width} className={structure[i].style} key={i}>
                {structure[i].tag}                
            </Col>
            )
        }
        return headers;
    };


    generateTableData(data,structure){
        let tableData=[];
        const mapa = new Map(Object.entries(data));
        for(var i =0; i < structure.length; i++){
            tableData.push(
                <Col span={structure[i].width} className={structure[i].style+' fila'} key={i}>
                {mapa.get(structure[i].field)}
               </Col>
            )
        }
        return tableData;
    }

    isOc =()=>{
        var entidad = localStorage.getItem('ID_ESTADO_SELECCIONADO');

        if(entidad == 0 || this.isEstateDefined==false){
            console.log('es OC!!!')
            return true;
        }else{
            console.log('NOOOO ES OC!!!')
            return false;
        }

      
    }

    render() {

        if (this.state.redirect) {
            return <Redirect push to="/reportes" />;
        }

        
        return (

            <div>
                <br />
                <List
                    header={
                    <Row>
                     <Col>
                    <Row className="header-tabla-gris">
                        {this.buildHeaders(this.props.isOc==true?headersOc:headers)}
                        
                    </Row>
                    <Row className="subheader-tabla">
                         {this.buildHeaders(this.props.isOc==true?subHeadersOc:subHeaders)}
                    </Row>
                    </Col>
                    </Row>
                    }
                    pagination={{
                        pageSize: 6,
                    }}
                    size="large"
                    loading={this.props.isLoading}
                    itemLayout="horizontal"
                    dataSource={this.props.materiales}
                    renderItem={item =>
                        <List.Item>
                            <Skeleton title={false} loading={this.props.isLoading} >
                                <List.Item.Meta
                                    description={
                                        <Row>
                                             {this.generateTableData(item,this.props.isOc==true?subHeadersOc:subHeaders)}
                                        </Row>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    }

                />
                </div>
        );
    
}
}

export default ReporteProduccionMateriales;