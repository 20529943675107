import React from "react";

import ConsultarVerificacion from "../../../components/ConsultarVerificacion/ConsultarVerificacion"; //Se importa el componente para los catálogos

export default function ConsultarVerificacionPrincipal(props) {

    return (
        <ConsultarVerificacion
            idVerificacion={props.location.state.idVerificacion}
        />
    );

}