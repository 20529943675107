import React, { useState, useEffect } from "react";
import { Select, Row, Col, Form, Upload, Icon, DatePicker, TimePicker, Input, Button, List, Skeleton, Modal, Switch, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { basePath } from "../../api/config";
import useAuth from "../../hooks/useAuth";
import Mensajes from "../Mensajes/Mensajes";
import axios from "axios";
import moment from 'moment';
import 'moment/locale/es';
import './FormRegistroVerificacion.css';
moment.locale('es');
export default function FormRegistroVerificacion(props) {


    const { user } = useAuth();

    var tiempoMensaje = 5;



    const urlConsultaEmpresasVerificacion = `${basePath}/consultaEmpresasVerificacion`;
    const urlGuardaVerificacion = `${basePath}/guardaVerificacion`;

    const dateFormat = "DD-MM-YYYY";
    const timeFormat = "HH:mm";

    const handleValuesChange = (e) => {
        console.log('Value changes', e);
        doRequestEmpresas(e);
    }

    const { getFieldDecorator } = props.form;

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    const [cargaArchivo, setCargaArchivo] = useState(props.file);

    const [empresas, setEmpresas] = useState(Object.entries(props.empresas));

    const [selected, setSelected] = useState(props.selected);

    const [modalVisible, setModalVisible] = useState(false);

    const [tipoCaptura, setTipoCaptura] = useState(null);

    const [cambiaTipoCaptura, setCambiaTipoCaptura] = useState(props.verificacion === null || props.verificacion === undefined || props.verificacion.tipoCaptura === null || props.verificacion.tipoCaptura === undefined
        ? null : props.verificacion.tipoCaptura);

    const [disableSwitch, setDisableSwitch] = useState(true);




    const handleGuardaVerificacion = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            //console.log("values en handleGuardaVerificacion()", values);
            if (!err) {
                //console.log("Object.entries(selected).length:=>", Object.entries(selected).length);
                console.log("tipoCaptura:=>", tipoCaptura);
                let cuentaAspectosContestados = 0;
                let aspectosRequeridos = tipoCaptura ? props.aspectosRequeridosDocs : props.aspectosRequeridosMats;
                let tipoArticulos = tipoCaptura ? 2 : 1;
                Object.entries(selected).map((value, index) => {
                    console.log("value[1]:=>", value[1]);
                    if (tipoArticulos === value[1].tipoArticulo
                        && (value[1].tipoRespuesta === 1 || value[1].tipoRespuesta === 2)
                        && value[1].respuesta !== null && value[1].respuesta !== undefined)
                        cuentaAspectosContestados++;
                })
                console.log("cuentaAspectosContestados:=>", cuentaAspectosContestados);
                console.log("props.aspectosRequeridos:=>", aspectosRequeridos);
                if (cuentaAspectosContestados == aspectosRequeridos) {
                    //console.log('Received values of form: ', values);
                    setModalVisible(true);
                }
                else {
                    /* setVisibleAlert(
                        {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: "Favor de contestar a todos las preguntas solicitadas"
                        }); */
                        message.error("Favor de contestar a todas las preguntas solicitadas", tiempoMensaje)
                }
                //props.handleSubmit(values);
            }
        });
    };

    const onRadioChange = e => {
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        let selectRatio = selected[name];
        setSelected({ ...selected, [name]: { respuesta: value, tipoArticulo: selectRatio.tipoArticulo, tipoRespuesta: selectRatio.tipoRespuesta } })
        //console.log("selected:=>", selected);
    };

    const onSwitchChange = (c, e) => {
        setTipoCaptura(c);
    };

    const onSelect = (v) => {
        var result = empresas.reduce(function (map, obj) {
            map[obj[0]] = obj[1];
            return map;
        }, {});
        if (result[v].tipoArticulosEnProd !== 3) {
            if (tipoCaptura !== (result[v].tipoArticulosEnProd == 2)) {
                setTipoCaptura(null);
                setCambiaTipoCaptura(result[v].tipoArticulosEnProd == 2)
            }
            setDisableSwitch(true);
        }
        else {
            setTipoCaptura(null);
            setCambiaTipoCaptura(true)
            setDisableSwitch(false);
        }
    }

    const handleModalEnviar = () => {
        let values = props.form.getFieldsValue();
        console.log("values en handleModalEnviar()", values);
        doRequestGuardaVerificacion(values);
    }

    const handleModalCancel = () => {
        setModalVisible(false);
    }

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    function doRequestEmpresas(idOple) {
        setVisibleAlert(
            {
                activado: false,
                tipoMensaje: "",
                mensaje: ""
            });
        let data = JSON.stringify({
            idOple: idOple,
            idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
        })
        axios
            .post(
                urlConsultaEmpresasVerificacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    //console.log("ERROR", response)
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: "10.- Ocurrió un error. Comunícate al CAU"
                            });
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                });
                        }
                        else {
                            setEmpresas(Object.entries(response.data.entity.empresas));
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    });
            });
    }




    function doRequestGuardaVerificacion(values) {
        setVisibleAlert(
            {
                activado: false,
                tipoMensaje: "",
                mensaje: ""
            });
        const formData = new FormData();
        if (props.verificacion !== undefined && props.verificacion !== null &&
            props.verificacion.idVerificacion !== undefined && props.verificacion.idVerificacion !== null)
            formData.append("verificacion.idVerificacion", props.verificacion.idVerificacion);
        formData.append("verificacion.idOple", values.OPL);
        formData.append("verificacion.idProceso", user.infoMenu.detalleSelec.idProcesoElectoral);
        formData.append("verificacion.idDetalleProceso", user.infoMenu.detalleSelec.idDetalleProceso);
        formData.append("verificacion.idEmpresa", values.empresa);
        formData.append("verificacion.nombreResponsable", values.nombreResponsable);
        formData.append("verificacion.primerApellidoResponsable", values.primerApellidoResponsable);
        if (values.segundoApellidoResponsable !== undefined && values.segundoApellidoResponsable !== null)
            formData.append("verificacion.segundoApellidoResponsable", values.segundoApellidoResponsable);
        formData.append("verificacion.idCargo", values.Cargo);
        formData.append("verificacion.documentosProduccion", values.documentosProduccion);
        if (values.obsevacioadicional !== undefined && values.obsevacioadicional !== null && values.obsevacioadicional !== "")
            formData.append("verificacion.observaciones", values.obsevacioadicional);
        formData.append("verificacion.fechaVerificacion", values.fechaVerificacion);
        formData.append("verificacion.horaVerificacion", values.horaVerificacion);
        formData.append("verificacion.tipoCaptura", tipoCaptura);
        formData.append("file", values.evidencia.file, values.evidencia.name);
        formData.append("anioProceso", user.infoMenu.detalleSelec.anio);
        formData.append("tipoProceso", user.infoMenu.detalleSelec.tipoProceso);
        formData.append("idProceso", user.infoMenu.detalleSelec.idProcesoElectoral);
        formData.append("idDetalleProceso", user.infoMenu.detalleSelec.idDetalleProceso);

        if (tipoCaptura) {
            props.dataAspectosDocs.map((value, index) => {
                /*console.log("value:=>", value);
                console.log(props.form.getFieldValue(`observaciones-${value.idAspecto}`), props.form.getFieldValue(`observaciones-${value.idAspecto}`));
                console.log("selected[]", selected[value.idAspecto]);*/
                formData.append("resultadosVerificacion[" + index + "].idAspecto", value.idAspecto);
                if (selected[value.idAspecto] !== undefined) {
                    formData.append("resultadosVerificacion[" + index + "].respuesta", selected[value.idAspecto].respuesta);
                    formData.append("resultadosVerificacion[" + index + "].tipoArticulo", selected[value.idAspecto].tipoArticulo);
                }
                if (props.form.getFieldValue(`observaciones-${value.idAspecto}`) !== undefined &&
                    props.form.getFieldValue(`observaciones-${value.idAspecto}`) !== null &&
                    props.form.getFieldValue(`observaciones-${value.idAspecto}`) !== "")
                    formData.append("resultadosVerificacion[" + index + "].observaciones", props.form.getFieldValue(`observaciones-${value.idAspecto}`));
            })
        }
        else {
            props.dataAspectosMats.map((value, index) => {
                /*console.log("value:=>", value);
                console.log(props.form.getFieldValue(`observaciones-${value.idAspecto}`), props.form.getFieldValue(`observaciones-${value.idAspecto}`));
                console.log("selected[]", selected[value.idAspecto]);*/
                formData.append("resultadosVerificacion[" + index + "].idAspecto", value.idAspecto);
                if (selected[value.idAspecto] !== undefined) {
                    formData.append("resultadosVerificacion[" + index + "].respuesta", selected[value.idAspecto].respuesta);
                    formData.append("resultadosVerificacion[" + index + "].tipoArticulo", selected[value.idAspecto].tipoArticulo);
                }
                if (props.form.getFieldValue(`observaciones-${value.idAspecto}`) !== undefined &&
                    props.form.getFieldValue(`observaciones-${value.idAspecto}`) !== null &&
                    props.form.getFieldValue(`observaciones-${value.idAspecto}`) !== "")
                    formData.append("resultadosVerificacion[" + index + "].observaciones", props.form.getFieldValue(`observaciones-${value.idAspecto}`));
            })
        }

        //console.log("selected:=>", selected);

        axios
            .post(
                urlGuardaVerificacion, formData, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'multipart/form-data',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    //console.log("ERROR guardar", response)
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403 && (response.data.entity.code !== 406 && response.data.entity.causa !== "No existen correos asociados a los criterios solicitados.")) {
                        setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: "10.- Ocurrió un error. Comunícate al CAU"
                                });
                    }
                    else if (response.data.entity.code === 406 && response.data.entity.causa === "No existen correos asociados a los criterios solicitados.") {
                        setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: "No es posible notificar y guardar el registro de verificación, porque no existe un correo asociado. Favor de agregar uno en el Catálogo de correos."
                            });
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                });
                        }
                        else {
                            //props.form.resetFields();
                            window.location = "/produccion/verificaciones"
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    });
            });
        setModalVisible(false);
    }


    


    const propsUpload = {
        multiple: false,
        onRemove: file => {
            setCargaArchivo(file);
        },
        beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            const isSomething = file.size > 0;
            const isSize = (file.size / 1024 / 1024) < 50; //Limita hasta 50 MB
            if (!isPDF) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El formato del archivo no corresponde a un CSV. Favor de verificarlo."
                    mensaje: "El formato del archivo no corresponde a un PDF. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El formato del archivo no corresponde a un PDF. Favor de borrarlo y cargar uno válido.")
                return true;
            }
            if (!isSomething) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El archivo viene vacío. Favor de verificarlo."
                    mensaje: "El archivo se encuentra vacío. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo se encuentra vacío. Favor de borrarlo y cargar uno válido.")
                return true;
            }
            if (!isSize) {
                /* setVisibleAlert({
                    activado: true,
                    tipoMensaje: "error",
                    //mensaje: "El archivo pesa más de 10 MB. Favor de verificarlo."
                    mensaje: "El archivo pesa más de 50 MB. Favor de borrarlo y cargar uno válido."
                }); */
                message.error("El archivo pesa más de 50 MB. Favor de borrarlo y cargar uno válido.")
                return false;
            }
            else {
                //console.log(file);
                setCargaArchivo(file);
               /*  setVisibleAlert({
                    activado: true,
                    tipoMensaje: "success",
                    mensaje: "El archivo fue pre-cargado exitosamente"
                }); */
                message.success("El archivo fue pre-cargado exitosamente.")
            }
            return false;
        },
        cargaArchivo,
    };

    useEffect(() => {
        setTipoCaptura(cambiaTipoCaptura)
    }, [cambiaTipoCaptura]);

    return (
        <div>
            {visibleAlert.activado ?
                (
                    <div>
                        <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                        <br />
                    </div>
                ) : null}
            <Modal
                visible={modalVisible}
                title='¿Está seguro de guardar la información?'
                footer={[
                    <div>
                        <Button onClick={handleModalCancel} className="boton-modal-cancelar"
                        >
                            Cancelar
                        </Button>
                        <Button type="primary"
                            onClick={handleModalEnviar}
                            className="boton-modal-aceptar"
                        >
                            Aceptar
                        </Button>
                    </div>
                ]}
            />
            <h1 className="titulo-verificacion">Verificación de producción</h1>
            <label className="texto-12-verificacion">Los datos con ( <label className="rojo-verificacion">*</label> ) son requeridos.</label>
            <br />
            <br />
            <div>
                <Form id="verificacion" onSubmit={handleGuardaVerificacion} layout="vertical">
                    <Row>
                        <Col span={10}>
                            <Col span={23}>
                                <label className="rojo-verificacion">* </label>
                                <label className="texto-16-verificacion">Nombre del OPL</label>
                            </Col>
                        </Col>
                        <Col span={7}>
                            <Col span={23}>
                                <label className="rojo-verificacion">* </label>
                                <label className="texto-16-verificacion">Fecha de Verificación</label>
                            </Col>
                        </Col>
                        <Col span={7}>
                            <Col span={23}>
                                <label className="rojo-verificacion">* </label>
                                <label className="texto-16-verificacion">Hora de Verificación</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Col span={23}>
                                <Form.Item name="OPL" hasFeedback>
                                    {
                                        getFieldDecorator('OPL', {
                                            initialValue: props.verificacion !== undefined && props.verificacion !== null
                                                && props.verificacion.idOple !== undefined && props.verificacion.idOple !== null ?
                                                props.verificacion.idOple.toString() : undefined,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Seleccione una nombre del OPL.',
                                                }
                                            ],
                                        })(
                                            <Select
                                                placeholder="Seleccione una opción"
                                                disabled={
                                                    props.verificacion !== undefined && props.verificacion !== null &&
                                                    props.verificacion.idOple !== undefined && props.verificacion.idOple !== null
                                                }
                                                onChange={handleValuesChange}
                                            >
                                                {Object.entries(props.oples).map((value, index) => {
                                                    return <Select.Option id="opcion" key={value[0]}>{value[1]}</Select.Option>;
                                                })}
                                            </Select>
                                        )}
                                </Form.Item>
                                {/*<Form.Item name="OPL" hasFeedback>
                                    {getFieldDecorator('OPL', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese nombre del OPL.',
                                            }
                                        ],
                                    })(<Input placeholder="Ingresa nombre del OPL"
                                        maxLength={524}
                                    />)}
                                </Form.Item>*/}
                            </Col>
                        </Col>
                        <Col span={7}>
                            <Col span={23}>
                                <Form.Item name="fechaVerificacion" hasFeedback>
                                    {getFieldDecorator('fechaVerificacion', {
                                        initialValue: props.verificacion !== undefined && props.verificacion !== null && props.verificacion.fechaVerificacion !== undefined
                                            ? moment(props.verificacion.fechaVerificacion, dateFormat) : undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione la fecha de la verificación.',
                                            }
                                        ],
                                    })(
                                        <DatePicker
                                            placeholder="Seleccionar"
                                            format={dateFormat}
                                            disabledDate={current => {
                                                return current.startOf('day').diff(moment().startOf('day'), 'days') > 0
                                            }}
                                            locale={'es'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={7}>
                            <Col span={23}>
                                <Form.Item name="horaVerificacion" hasFeedback>
                                    {getFieldDecorator('horaVerificacion', {
                                        initialValue: props.verificacion !== undefined && props.verificacion !== null && props.verificacion.horaVerificacion !== undefined
                                            ? moment(props.verificacion.horaVerificacion, timeFormat) : undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione la hora de la verificación.',
                                            }
                                        ],
                                    })(
                                        <TimePicker
                                            placeholder="Seleccionar"
                                            format={timeFormat}
                                            locale={'es'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <label className="rojo-verificacion">* </label>
                                <label className="texto-16-verificacion">Empresa</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <Form.Item name="empresa" hasFeedback>
                                    {getFieldDecorator('empresa', {
                                        initialValue: props.verificacion !== undefined && props.verificacion !== null
                                            && props.verificacion.idEmpresa !== undefined && props.verificacion.idEmpresa !== null ?
                                            props.verificacion.idEmpresa.toString() : undefined
                                        ,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione una empresa.',
                                            }
                                        ],
                                    })(
                                        <Select
                                            placeholder="Seleccione una opción"
                                            onSelect={onSelect}
                                            disabled={empresas.length <= 1 && props.verificacion !== undefined && props.verificacion !== null
                                                && props.verificacion.idEmpresa !== undefined && props.verificacion.idEmpresa !== null}
                                        >
                                            {empresas.map((value, index) => {
                                                return <Select.Option id="opcion"
                                                    key={value[0]}>{value[1].nombreEmpresa}
                                                </Select.Option>;
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <label className="rojo-verificacion">*</label>
                            <label className="texto-16-verificacion"> Nombre del responsable OPL</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <label className="texto-14-verificacion">
                                <Icon type="info-circle" theme="filled" className="icono-info" />
                                Al menos uno de los apellidos es obligatorio.
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Col span={23}>
                                <label className="texto-16-verificacion">Primer apellido</label>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={23}>
                                <label className="texto-16-verificacion">Segundo apellido</label>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={23}>
                                <label className="rojo-verificacion">* </label><label className="texto-16-verificacion">Nombre(s)</label>
                            </Col>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Col span={23}>
                                <Form.Item name="primerApellidoResponsable" hasFeedback>
                                    {getFieldDecorator('primerApellidoResponsable', {
                                        initialValue: props.verificacion !== undefined && props.verificacion !== null ?
                                            props.verificacion.primerApellidoResponsable : undefined,
                                        rules: [
                                            {
                                                pattern: new RegExp("^[a-zA-ZáéíóúÁÉÍÓÚÑñäëïöüÄËÏÖÜ ]*$"),
                                                message: 'No se permiten caracteres especiales.',
                                              },
                                            {
                                                required: true,
                                                message: 'Ingrese primer apellido del responsable.',
                                            }
                                        ],
                                    })(<Input placeholder="Primer apellido"
                                        maxLength={50}
                                    />)}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={23}>
                                <Form.Item name="segundoApellidoResponsable" hasFeedback>
                                    {getFieldDecorator('segundoApellidoResponsable', {
                                        initialValue: props.verificacion !== undefined && props.verificacion !== null
                                            && props.verificacion.segundoApellidoResponsable !== null
                                            ? props.verificacion.segundoApellidoResponsable : null,
                                        rules: [
                                            {
                                                pattern: new RegExp("^[a-zA-ZáéíóúÁÉÍÓÚÑñäëïöüÄËÏÖÜ ]*$"),
                                                message: 'No se permiten caracteres especiales.',
                                              },
                                            {
                                                required: false,
                                                message: 'Ingrese segundo apellido del responsable.',
                                            }
                                        ],
                                    })(<Input placeholder="Segundo apellido"
                                        maxLength={50}
                                    />)}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={23}>
                                <Form.Item name="nombreResponsable" hasFeedback>
                                    {getFieldDecorator('nombreResponsable', {
                                        initialValue: props.verificacion !== undefined && props.verificacion !== null
                                            ? props.verificacion.nombreResponsable : null,
                                        rules: [
                                            {
                                                pattern: new RegExp("^[a-zA-ZáéíóúÁÉÍÓÚÑñäëïöüÄËÏÖÜ ]*$"),
                                                message: 'No se permiten caracteres especiales.',
                                              },
                                            {
                                                required: true,
                                                message: 'Ingrese nombre(s) del responsable.',
                                            }
                                        ],
                                    })(<Input placeholder="Nombre(s)" 
                                        maxLength={50}  
                                       
                                    />
                                   
                                    )}

                                      
                                </Form.Item>
                    
                            </Col>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <label className="rojo-verificacion">* </label>
                                <label className="texto-16-verificacion">Cargo del responsable del OPL</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Col span={23}>
                                <Form.Item name="Cargo" hasFeedback>
                                    {getFieldDecorator('Cargo', {
                                        initialValue: props.verificacion !== undefined && props.verificacion !== null
                                            && props.verificacion.idCargo !== undefined && props.verificacion.idCargo !== null ?
                                            props.verificacion.idCargo.toString() : undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione un cargo del OPL.',
                                            }
                                        ],
                                    })(
                                        <Select
                                            placeholder="Seleccione una opción"
                                        >
                                            {Object.entries(props.cargos).map((value, index) => {
                                                return <Select.Option id="opcion" key={value[0]}>{value[1]}</Select.Option>;
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                                {/*<Form.Item name="Cargo" hasFeedback>
                                    {getFieldDecorator('Cargo', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese cargo del responsable del OPL.',
                                            }
                                        ],
                                    })(<Input placeholder="Ingresa cargo del responsable"
                                        maxLength={524}
                                    />)}
                                </Form.Item>*/}
                            </Col>
                        </Col>
                    </Row>
                    {
                        tipoCaptura !== null ? (
                            <div>
                                <Row>
                                    <Col span={23}>
                                        <Form.Item label={<label className="texto-16-verificacion">Tipo verificación</label>} name="tipoCaptura" hasFeedback>
                                            <Switch
                                                checkedChildren="Documentos"
                                                unCheckedChildren="Materiales"
                                                defaultChecked={tipoCaptura}
                                                disabled={disableSwitch}
                                                onChange={onSwitchChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={23}>
                                        <label className="rojo-verificacion">* </label>
                                        <label className="texto-16-verificacion">
                                            Nombre de (los)
                                            {
                                                tipoCaptura ? " documento(s) " : " material(es) "
                                            }
                                            que se están produciendo
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item name="documentosProduccion" hasFeedback>
                                            {getFieldDecorator('documentosProduccion', {
                                                initialValue: props.verificacion !== undefined && props.verificacion !== null
                                                    ? props.verificacion.documentosProduccion : null,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Ingrese el nombre de (los) documento(s) que se están produciendo',
                                                    }
                                                ],
                                            })(
                                                <Input.TextArea placeholder="(Especificar, en su caso, la elección a la cual corresponden)"
                                                    rows={3} autoSize={{ minRows: 3, maxRows: 3 }}
                                                    allowClear={true} maxLength={250} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <List
                                        header={
                                            <div>
                                                <Row className="header-1-tabla-gris">
                                                    <Col span={9} className="header-1-texto-medium-16-white texto-centrado">
                                                        Aspectos
                                                </Col>
                                                    <Col span={6} className="header-1-texto-medium-16-white texto-centrado">
                                                        Respuesta
                                                </Col>
                                                    <Col span={9} className="header-1-texto-medium-16-white texto-centrado">
                                                        Observaciones
                                                 </Col>
                                                </Row>
                                                <Row className="header-2-tabla-gris">
                                                    <Col span={8} className="header-2-texto-normal-16-white texto-centrado">
                                                        {tipoCaptura ? "Documentos" : "Materiales"}
                                                    </Col>
                                                    <Col span={6} offset={1} className="header-2-texto-normal-16-white texto-centrado">
                                                        <Col span={4}>
                                                            Sí
                                            </Col>
                                                        <Col span={4}>
                                                            No
                                            </Col>
                                                        <Col span={16} >
                                                            Parcialmente
                                            </Col>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        size="large"
                                        loading={!props.loaded}
                                        itemLayout="horizontal"
                                        dataSource={tipoCaptura ? props.dataAspectosDocs : props.dataAspectosMats}
                                        renderItem={item =>
                                            <List.Item>
                                                <Skeleton title={false} loading={false} active>
                                                    <List.Item.Meta
                                                        description={
                                                            <Row>
                                                                <Col span={8} className="col-aspecto">
                                                                    {item.aspecto}
                                                                </Col>
                                                                <Col span={6} offset={1}>
                                                                    {
                                                                        item.tipoRespuesta === 1 || item.tipoRespuesta === 2 ? (
                                                                            <Col span={4} className="texto-centrado">
                                                                                <Input
                                                                                    type="radio"
                                                                                    checked={selected[item.idAspecto].respuesta == 1}
                                                                                    onChange={onRadioChange}
                                                                                    name={item.idAspecto}
                                                                                    value={1}
                                                                                />
                                                                            </Col>
                                                                        ) : null}
                                                                    {
                                                                        item.tipoRespuesta === 1 || item.tipoRespuesta === 2 ? (
                                                                            <Col span={4} className="texto-centrado">
                                                                                <Input
                                                                                    type="radio"
                                                                                    checked={selected[item.idAspecto].respuesta == 0}
                                                                                    onChange={onRadioChange}
                                                                                    name={item.idAspecto}
                                                                                    value={0}
                                                                                />
                                                                            </Col>
                                                                        ) : null}
                                                                    <Col span={16} className="texto-centrado">
                                                                        {
                                                                            item.tipoRespuesta === 2 ? (
                                                                                <Input
                                                                                    type="radio"
                                                                                    checked={selected[item.idAspecto].respuesta == 2}
                                                                                    onChange={onRadioChange}
                                                                                    name={item.idAspecto}
                                                                                    value={2}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                    </Col>
                                                                </Col>
                                                                <Col span={8} offset={1} className="col-aspecto">
                                                                    {
                                                                        item.tipoRespuesta === 1 || item.tipoRespuesta === 2 ? (
                                                                            <Form.Item name={`observaciones-${item.idAspecto}`}>
                                                                                {getFieldDecorator(`observaciones-${item.idAspecto}`, {
                                                                                    initialValue: item.observaciones !== undefined && item.observaciones !== null
                                                                                        ? item.observaciones : null,
                                                                                })(
                                                                                    <Input.TextArea maxLength={250} />
                                                                                )}
                                                                            </Form.Item>
                                                                        )
                                                                            : (
                                                                                <Form.Item name={`observacion-${item.idAspecto}`} hasFeedback>
                                                                                    {getFieldDecorator(`observaciones-${item.idAspecto}`, {
                                                                                        initialValue: item.observaciones !== undefined && item.observaciones !== null
                                                                                            ? item.observaciones : null,
                                                                                        rules: [
                                                                                            {
                                                                                                required: true,
                                                                                                message: 'Campo obligatorio.',
                                                                                            }
                                                                                        ],
                                                                                    })(
                                                                                        <Input.TextArea placeholder="Explicar brevemente"
                                                                                            rows={3} autoSize={{ minRows: 3, maxRows: 3 }}
                                                                                            allowClear={true} maxLength={250} />
                                                                                    )}
                                                                                </Form.Item>
                                                                            )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                    />
                                                </Skeleton>
                                            </List.Item>
                                        }
                                    >
                                    </List>
                                </Row>
                            </div>
                        ) : null
                    }

                    <br />
                    <Row>
                        <Col span={16}>
                            <Col span={23}>
                                <label className="texto-16-verificacion">Observaciones Adicionales</label>
                            </Col>
                        </Col>
                        <Col span={8} className="texto-centrado">
                            <Col span={24}>
                                <label className="rojo-verificacion">* </label>
                                <label className="texto-16-verificacion">Evidencia</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <Col span={24}>
                                <Form.Item name="obsevacioadicional" hasFeedback>
                                    {getFieldDecorator('obsevacioadicional', {
                                        initialValue: props.verificacion !== undefined && props.verificacion !== null
                                            ? props.verificacion.observaciones : null,
                                    })(
                                        <Input.TextArea placeholder="Si tiene, ingrese observaciones adicionales"
                                            rows={3} autoSize={{ minRows: 3, maxRows: 3 }}
                                            allowClear={true} maxLength={500} />
                                    )}

                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={8} className="texto-centrado">
                            <Col span={24}>
                                <Form.Item name="evidencia" hasFeedback>
                                    {getFieldDecorator('evidencia', {
                                        initialValue: cargaArchivo !== undefined && cargaArchivo !== null
                                            ? cargaArchivo : null,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Seleccione la evidencia de verificación.',
                                            }
                                        ],
                                    })
                                        (
                                            <Upload {...propsUpload} accept=".pdf"
                                                fileList={cargaArchivo !== undefined && cargaArchivo !== null ? [cargaArchivo] : []}
                                            >
                                                <Button type="submit" className="subir-evidencia">
                                                    <UploadOutlined /> Subir </Button>
                                            </Upload>
                                        )}
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}