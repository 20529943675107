import React, { useState, Fragment } from "react";
import { Row, Col, Form, Icon, Input, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import {GoogleReCaptchaProvider,GoogleReCaptcha} from 'react-google-recaptcha-v3';
import "./Login.scss";
import axios from "axios";
import { ACCESS_TOKEN } from "../../utils/constanst";
import { basePath } from "../../api/config";
//import SecureStorage from "secure-web-storage";

function Login(props) {
  //Key para poder generar el recapcha, está key puede cambiar dependiendo el proyecto ya que se obtiene a través de una cuenta gmail
  const CAP_KEY = "6LeYVOIUAAAAAOEOezYnZm3K9w9kpcXRdm5nGcuK";
  const { Logo } = props; //Se recibe el logo por props
  const { getFieldDecorator } = props.form;
  //Hooks
  const [capcha, setCapcha] = useState(false);
  const [inputs, setInputs] = useState({
    usuario: "",
    password: "",
    versionAplicacion: "1",  
    idSistema: "217"
  });

  const onloadCallback = () => {
    console.log("captcha successfully loaded"); //Realiza un callback al capcha
  };
  //Funcíon que obtiene los cambios que a tenido el formulario
  const changeForm = e => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const recaptchaRef = React.createRef();
  const verifyCallback = response => {
    setCapcha({ capcha }); //función que comprueba que el recapcha se selecciono correctamente
  };

  //recaptcha v3
  function recapcha(){
    return(
      <GoogleReCaptchaProvider reCaptchaKey={CAP_KEY}>
        <GoogleReCaptcha onVerify={token => console.log(token)} />
         {onloadCallback()}
      </GoogleReCaptchaProvider>/* ,
     document.getElementsByClassName('login-form') */
    );
  }
  const content = () => {
    return recapcha();
    }

    
  //--------------Para encriptar los valores del localStorage------------------
/*var CryptoJS = require("crypto-js");
 
var SECRET_KEY = 'my secret key';
 
var secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
 
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
 
        data = data.toString();
 
        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
 
        data = data.toString(CryptoJS.enc.Utf8);
 
        return data;
    }
});*/
//-----------------------------------------


  const login = e => {
    e.preventDefault();
    props.form.validateFields((err) => {
      if (!err) {
        /* setError(false); */
      //Se realiza la petición al server
      const url = `${basePath}/validateUser`;
      //Se realiza la peticion
      axios.post(url, inputs).then(response => {             
        const { code, message } = response.data.entity;        
        if (code === 400 || code === 500) {
          alert(message);
        } else {
          let procesoElectoral = "";
          let idDetalleProceso = null;
          let idProcesoElectoral = null;
          let estado = "";
          let idEstadoSeleccionado = null;
          let distrito = "";
          let anio = null;
          let tipoProceso = null;          
          const { tknA, idSistema, idEstado, idDistrito, idMunicipio, ambito, rolUsuario, versionUsuario } = response.data.entity.datosUsuario; //Se obtiene el token de json, el estado, el id distrito, el id sistema, el id estado, el id distrito, el id municipio, el ámbito, el rol usuario y la versión del usuario
          const {detalleSelec, estadoSelec, distritoFedSelec, distritoLocSelec, municipioSelec} = response.data.entity.datosUsuario.infoMenu;            
          detalleSelec !== null ? procesoElectoral = detalleSelec.descripcion : procesoElectoral = null;
          detalleSelec !== null ? idDetalleProceso = detalleSelec.idDetalleProceso : idDetalleProceso = null;
          detalleSelec !== null ? idProcesoElectoral = detalleSelec.idProcesoElectoral : idProcesoElectoral = null;
          detalleSelec !== null ? anio = detalleSelec.anio : anio = null;
          detalleSelec !== null ? tipoProceso = detalleSelec.tipoProceso : tipoProceso = null;
          estadoSelec !== null ? estado = estadoSelec.nombreEstado : estado = null;                    
          estadoSelec !== null ? idEstadoSeleccionado = estadoSelec.idEstado : idEstadoSeleccionado = null;
          distritoFedSelec !== null ? 
          (distrito = distritoFedSelec.nombreDistrito) :
          (             
              distritoLocSelec !== null ? 
              (distrito = distritoLocSelec.nombreDistrito) : 
              (
                municipioSelec !== null ? 
                (distrito = municipioSelec.nombreMunicipio) : 
                distrito = null
              )
          );          

		      //const { descripcion } = response.data.entity.datosUsuario.infoMenu.listaDetalles[0];  //Se obtiene la descripción del proceso electoral en caso que el usuario cuente con uno asociado          
          let listaDetalles = [];
          let infoMenus = [];
		      listaDetalles = JSON.parse(JSON.stringify(response.data.entity.datosUsuario.infoMenu.listaDetalles));
          infoMenus = JSON.parse(JSON.stringify(response.data.entity.datosUsuario.infoMenu));
          localStorage.setItem(ACCESS_TOKEN, tknA); //Se almacena el token en el localStorage
		      localStorage.setItem("ESTADO", estado); //Se almacena el nombre del estado en el localStorage
          localStorage.setItem("DISTRITO", distrito); //Se almacena el nombre del distrito en el localStorage
          localStorage.setItem("ID_DISTRITO", idDistrito); //Se almacena el id del distrito en el localStorage 
          localStorage.setItem("DESCRIPCION", procesoElectoral); //Se almacena la descripción (detalle) del proceso electoral en el localStorage      
          localStorage.setItem("ID_DETALLE_PROCESO", JSON.parse(idDetalleProceso)); //Se almacena el id de la descripción (detalle) del proceso electoral en el localStorage         
          localStorage.setItem("ID_PROCESO_ELECTORAL", idProcesoElectoral); //Se almacena el id del proceso electoral en el localStorage
          localStorage.setItem("VERSION_USUARIO", versionUsuario); //Se almacena la versión del usuario en el localStorage          
          localStorage.setItem("LISTA_DETALLES", JSON.stringify(listaDetalles)); //Se almacena la lista de detalles (procesos electorales) en el localStorage 
          localStorage.setItem("INFO_MENU", JSON.stringify(infoMenus)); //Se almacena el infoMenu del JSON en el localStorage
          localStorage.setItem("ID_SISTEMA", idSistema); //Se almacena el id del sistema en el localStorage
          localStorage.setItem("ID_ESTADO", idEstado); //Se almacena el id del estado en el localStorage
          localStorage.setItem("ID_ESTADO_SELECCIONADO", idEstadoSeleccionado); //Se almacena el id del estado seleccionado, el cual cambiará del ID_ESTADO cuando el usuario sea un Admin OC y elija otro estado en el menú del proceso electoral 
          localStorage.setItem("ID_MUNICIPIO", idMunicipio); //Se almacena el id del municipio en el localStorage 
          localStorage.setItem("ANIO", anio); //Se almacena el año del proceso electoral en el localStorage
          localStorage.setItem("AMBITO", ambito); //Se almacena el ámbito en el localStorage 
          localStorage.setItem("TIPO_PROCESO", tipoProceso); //Se almacena el tipo del proceso en el localStorage 
          localStorage.setItem("ROL_USUARIO", rolUsuario); //Se almacena el rol del usuario en el localStorage
          localStorage.setItem("menu",
              JSON.stringify(response.data.entity.datosUsuario.infoMenu.listMenu)
            );
          window.location.href = "/home"; //Se redirecciona al home                    
        }        
      });
    };
  });
}

  return (
  
    <Row className="login-square">
    <Col span={20} offset={2}>
    <Row align="center"><Col span={24}>
      <Row gutter={[0,48]}>
        <Col span={24}><Logo /></Col>
      </Row>
      <Form onChange={changeForm} onSubmit={login}>
      <Form.Item>
        {getFieldDecorator("user", {
            rules: [{ required: true, message: "El nombre de usuario es obligatorio, favor de verificarlo." },
                    {max:50, message: "Número de caracteres permitidos: 50"},
                    {pattern: /^[^<>%$&|#]*$/ , message:'Usuario inválido, ingresa nuevamente.'}
                  ]
          })(
          <Input
            prefix={<Icon type="user" style={{ color: "#d5007f" }} />}
            placeholder="Usuario"
            name="usuario"
            style={{ width: "100%" }}
            required={true}
          />
          )}
        </Form.Item>
        <Form.Item>
        {getFieldDecorator("pass", {
            rules: [{ required: true, message: "La contraseña es obligatoria, favor de verificarla." },
                    {max:50, message: "Número de caracteres permitidos: 50"},
                    {pattern: /^[^<> ]*$/, message:'Dato inválido, ingresa nuevamente.'}]
          })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: "#d5007f" }} />}
            type="password"
            name="password"
            placeholder="Contraseña"
            style={{ width: "100%" }}
          />
          )}
          
        </Form.Item>
        <Form.Item>
          <Button className="submit-button" type="primary" htmlType="submit" size="large" block>
            Iniciar Sesión
          </Button>
        </Form.Item>
      </Form>
      <Row>
        <Col span={24} className="change-pass">
          <a className="url" href="https://cua.ine.mx/claveacceso/" target="blank">Cambiar contraseña</a>
        </Col>
      </Row>
      { }   
      <div>
        <form onSubmit={() => { recaptchaRef.current.execute(); }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={CAP_KEY}
            onloadCallback={onloadCallback}
          />
        </form>
      </div>
    </Col>
    </Row>
    </Col>
  </Row>

/*
    <div className="div_login">
      <div className="login-image">
        <Logo />
      </div>
      <Form className="login-form" onChange={changeForm} onSubmit={login}>
        <Form.Item>
        {getFieldDecorator("user", {
            rules: [{ required: true, message: "El nombre de usuario es obligatorio, favor de verificarlo." },
                    {max:50, message: "Número de caracteres permitidos: 50"},
                    {pattern: /^[^<>%$&|#]*$/ , message:'Usuario inválido, ingresa nuevamente.'}
                  ]
          })(
          <Input
            prefix={<Icon type="user" style={{ color: "#d5007f" }} />}
            placeholder="Usuario"
            name="usuario"
            style={{ width: "100%" }}
            required={true}
          />
          )}
        </Form.Item>
        <Form.Item>
        {getFieldDecorator("pass", {
            rules: [{ required: true, message: "La contraseña es obligatoria, favor de verificarla." },
                    {max:50, message: "Número de caracteres permitidos: 50"},
                    {pattern: /^[^<>]*$/, message:'Dato inválido, ingresa nuevamente.'}]
          })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: "#d5007f" }} />}
            type="password"
            name="password"
            placeholder="Contraseña"
            style={{ width: "100%" }}
          />
          )}
          
        </Form.Item>
        <div className="change-pass ">
          <a className="url" href="https://cua.ine.mx/claveacceso/" target="blank">Cambiar contraseña</a>
        </div>        
        <Form.Item>
          <button type="submit" className="login-form__button">
            Iniciar Sesión
          </button>
        </Form.Item>        
      </Form>
		{ }   
    <div>
<form onSubmit={() => { recaptchaRef.current.execute(); }}>
<ReCAPTCHA
  ref={recaptchaRef}
  size="invisible"
  sitekey={CAP_KEY}
  onloadCallback={onloadCallback}
/>
</form></div>      
    </div>*/
  );
}

const LoginForm = Form.create()(Login);
export default LoginForm;
