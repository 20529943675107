import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import 'antd/dist/antd.css';
import { Button, Icon, Table, Modal, message, Input} from "antd";
import { Redirect } from 'react-router';
import { basePath } from "../../api/config";
import useAuth from "../../hooks/useAuth";
import Mensajes from "../Mensajes/Mensajes";
import { base64toBlob } from "../../utils/Utils"
import './ConsultarVerificaciones.css'
import GridFilters from "../../utils/GridFilters";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";

export default function ConsultarVerificaciones(props) {

    const urlConsultaVerificaciones = `${basePath}/consultaVerificaciones`;
    const urlDescargaEvidencia = `${basePath}/descargaEvidencia`;
    const urlEliminaVerificacion = `${basePath}/eliminaVerificacion`;
    var tiempoMensaje = 5;
    const { user } = useAuth();

    const dateFormat = "DD-MM-YYYY";

    const [redirect, setRedirect] = useState({
        redirecting: false,
        pathname: "",
        state: {},
    });

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    const [verificaciones, setVerificaciones] = useState({
        data: [],
        enableCaptura: false,
        enableModificar: false,
        loaded: false
    });

    const [modalVisible, setModalVisible] = useState({
        visible: false,
        idVerificacion: -1
    });

    const [busqueda, setbusqueda] = useState({
        searchText: '',
        searchedColumn: ''
    });

    const [entrada, setentrada] = useState({
        searchInput: ''
    });
    
 const  getColumnSearchProps = (dataIndex,title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input className='buscar'
              ref={node => {
                entrada.searchInput = node;
              }}
              placeholder={`Buscar ${title}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              
            />
            <div>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                className="buscarbtn"
              >
                Buscar
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" 
              className="reiniciarbtn"
              >
                Reiniciar
              </Button>
            </div>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#e149a4' : '#e149a4'}} />,
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => entrada.searchInput.select());
          }
        },
        render: text =>
          busqueda.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[busqueda.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
   
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setbusqueda({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      const handleReset = clearFilters => {
        clearFilters();
        setbusqueda({ searchText: '' });
      };
    
    useEffect(() => {
        if (!verificaciones.loaded)
            doRequestConsulta();
    }, [verificaciones]);

    const handleNuevoRegistro = (e) => {
        setRedirect(
            {
                redirecting: true,
                pathname: '/produccion/registrarverificacion',
                state: {}
            }
        );
    };

    const handleClickDescargar = useCallback(idVerificacion => {
        console.log("handleClickDescargar", idVerificacion);
        doRequestDescargaEvidencia(idVerificacion);
    });

    const handleClickEliminar = useCallback(idVerificacion => {
        console.log("handleClickEliminar", idVerificacion);
        setModalVisible({
            visible: true,
            idVerificacion: idVerificacion
        });
        //doRequestEliminaVerificacion(idVerificacion);
    });

    const handleClickConsultar = useCallback(idVerificacion => {
        console.log("handleClickConsultar", idVerificacion);
        setRedirect(
            {
                redirecting: true,
                pathname: '/produccion/consultarVerificacion',
                state: {
                    idVerificacion: idVerificacion
                }
            }
        );
    });

    function doRequestConsulta() {
        let data = JSON.stringify({
            idEntidad: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
            idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
        })
        axios
            .post(
                urlConsultaVerificaciones, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                       /*  setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                           /*  setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            setVerificaciones({
                                loaded: true,
                                data: response.data.entity.listaVerificaciones,
                                enableCaptura: response.data.entity.enableCaptura,
                                enableModificar: response.data.entity.enableModificar,
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.", tiempoMensaje);
            });
        setModalVisible({
            visible: false,
            idVerificacion: -1
        });
    }

    function doRequestDescargaEvidencia(idVerificacion) {
        setVisibleAlert(
            {
                activado: false,
                tipoMensaje: "",
                mensaje: ""
            });
        let data = JSON.stringify({
            idEntidad: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
            idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
            idVerificacion: idVerificacion
        })
        axios
            .post(
                urlDescargaEvidencia, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            if (response.data.entity.fileBase64 !== null && response.data.entity.fileBase64 !== "") {
                                const fileBase64 = 'data:application/pdf;base64,' + response.data.entity.fileBase64;
                                const blob = base64toBlob(fileBase64);
                                let a = document.createElement('a');
                                a.href = URL.createObjectURL(blob);
                                /* a.download = "DOCSMATS-" + response.data.entity.fileName; */
                                a.download = "DOCSMATS-" + "evidenciaVerificacionProduccion";
                                a.click();
                            }
                            setVerificaciones({
                                loaded: false,
                                data: [],
                                enableCaptura: false,
                                enableModificar: false,
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    }

    function doRequestEliminaVerificacion(idVerificacion) {
        setVisibleAlert(
            {
                activado: false,
                tipoMensaje: "",
                mensaje: ""
            });
        let data = JSON.stringify({
            idEntidad: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
            idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
            idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
            anioProceso: user.infoMenu.detalleSelec.anio,
            tipoProceso: user.infoMenu.detalleSelec.tipoProceso,
            idVerificacion: idVerificacion
        })
        axios
            .post(
                urlEliminaVerificacion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "warning",
                                    mensaje: response.data.entity.message
                                }); */
                                message.warning(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "success",
                                    mensaje: "Se ha eliminado la verificación correctamente."
                                }); */
                                message.success("Se ha eliminado la verificación correctamente.",tiempoMensaje);
                            setVerificaciones({
                                loaded: false,
                                data: [],
                                enableCaptura: false,
                                enableModificar: false,
                            });
                        }
                    }
                    else {
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                if (err.response.status === 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
            });
    }

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    const handleModalEnviar = () => {
        doRequestEliminaVerificacion(modalVisible.idVerificacion);
    }

    const handleModalCancel = () => {
        setModalVisible({
            visible: false,
            idVerificacion: -1
        });
    }

    const renderRedirect = () => {
        if (redirect.redirecting) {
            return <Redirect to={{
                pathname: redirect.pathname,
                state: redirect.state
            }
            } />
        }
    }

    const columns = [
        {
            title: "No.",
            dataIndex: "consecutivo",
        },
        {
            /*title: (
                <div style={{ textAlign: 'center' }}>
                    <div className="filtro">Empresa</div>
                    {/*<div className="filtro"><Input style={{ width: '100%' }}/></div>}
                </div>
            ),*/
            title: "Fecha de verificación",
            dataIndex: "fechaVerificacion",
            width: "150px",
            sorter: (a, b) => moment(a.fechaVerificacion, dateFormat).unix() - moment(b.fechaVerificacion, dateFormat).unix()
        },
        {
            title: "Nombre del OPL",
            dataIndex: "nombreOPL",
            filtered: true,
            ...getColumnSearchProps('nombreOPL',' OPL')
        },
        {
            title: "Nombre de la empresa",
            dataIndex: "nombreEmpresa",
            filter: true,
            ...getColumnSearchProps('nombreEmpresa', ' Empresa')
        },
        {
            title: "Fecha de captura",
            dataIndex: "fechaCaptura",
            width: "150px",
            sorter: (a, b) => moment(a.fechaCaptura, dateFormat).unix() - moment(b.fechaCaptura, dateFormat).unix()
        },
        {
            title: "Acciones",
            width: "170px",
            align: "center",
            dataIndex: "operation",
            render: (text, record) => {
                return (
                    <div className="contenedor-acciones">
                        <Button
                            className="ilist"
                            type="link"
                            shape="circle"
                            onClick={handleClickConsultar.bind(
                                this,
                                record.idVerificacion)}
                        >
                            <Icon type="eye" />
                        </Button>
                        <Button
                            className="ilist"
                            type="link"
                            shape="circle"
                            disabled={!verificaciones.enableCaptura}
                            onClick={handleClickEliminar.bind(
                                this,
                                record.idVerificacion)}
                        >
                            <Icon type="delete" />
                        </Button>
                        <Button
                            className="ilist"
                            type="link"
                            shape="circle"
                            onClick={handleClickDescargar.bind(
                                this,
                                record.idVerificacion)}
                        >
                            <Icon type="download" />
                        </Button>
                    </div>
                )
            }
        }
    ];
    return (
        <div>
            {renderRedirect()}
            {visibleAlert.activado ?
                (
                    <div>
                        <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                        <br />
                    </div>
                ) : null
            }
            <Modal
                visible={modalVisible.visible}
                title='¿Está seguro de eliminar la verificación?'
                closable={false}
                footer={[
                    <div className="confirmacion-footer">
                        <Button onClick={handleModalCancel} className="boton-modal-cancelar"
                        >
                            Cancelar
                        </Button>
                        <Button type="primary"
                            onClick={handleModalEnviar}
                            className="boton-modal-aceptar"
                            disabled={!verificaciones.enableModificar}
                        >
                            Aceptar
                        </Button>
                    </div>
                ]}
            />
            <h1 className="titulo-verificacion">Verificaciones</h1>
            <br />
            <br />
            <Button
                onClick={handleNuevoRegistro}
                disabled={!verificaciones.enableCaptura}
                className="boton-agregar-verificacion"
            >
                <div>
                    <div>
                        <Icon type="plus" className="icono-plus-verificacion"/> <Icon type="form" className="icono-form-verificacion"/>
                    </div>
                    Nueva verificación
                </div>
            </Button>
            <br />
            <br />
            <br />
            <h2 className="titulo-listado-verificacion">Lista de registros</h2>
            <Table
                className="tabla-verificaciones"
                pagination={{
                    pageSize: 10,
                }}
                bordered
                dataSource={verificaciones.data}
                columns={columns}
                loading={!verificaciones.loaded}
            />
        </div>
    )

}