import React from "react";
//import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./Revision.css";
import { Typography, Select, Button, Spin, Input, message } from "antd";
import SelectDocumentos from "../../../components/ListaDocs/SelectDocs";
import axios from "axios";

import { basePath } from "../../../api/config";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import Mensajes from "../../../components/Mensajes/Mensajes";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { base64toBlob } from "../../../utils/Utils"
import GridFilters from "../../../utils/GridFilters";
import Paginacion from "../../../components/ListaDocs/Paginacion";
import ReactTable from "react-table-v6";

const urlConsultaDocumentos = `${basePath}/consultaHistorico`;
const urlTiposClasificacion = `${basePath}/obtenHistoricoRevision`;
var tiempoMensaje = 5;
class CargasEsp extends React.Component {
  constructor(props) {
    super(props);


    const estadoSel = props.user.infoMenu.estadoSelec !== null ? props.user.infoMenu.estadoSelec.idEstado : props.user.infoMenu.detalleSelec.idEstado;
    const procesoSel = props.user.infoMenu.detalleSelec.idProcesoElectoral;
    const detalleSel = props.user.infoMenu.detalleSelec.idDetalleProceso;
    let data = JSON.stringify({
      parametros: {
        idProceso: procesoSel,
        idEstado: estadoSel,
        idDetalleProceso: detalleSel
      },
      idArticulo: this.props.location.state.idArticulo,
      idTipoDocumentacion: this.props.location.state.idTipoDocumentacion
      // TODO COLOCAR LOS OTROS PARAMETROS
    });

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickDescargar = this.handleClickDescargar.bind(this);
    this.handleClickDescargarEvidencia = this.handleClickDescargarEvidencia.bind(this);
    //this.obtenHistoricoRevision = this.obtenHistoricoRevision(this);
    this.state = {
      consulta: false,
      seleccion: 0,
      posts: [],
      urlPDF1: null,
      urlPDF2: null,
      urlPDF3: null,
      idArticulo: this.props.location.state.idArticulo,
      nombreArticulo: this.props.location.state.nombreArticulo,
      idTipoDocumentacion: this.props.location.state.idTipoDocumentacion,
      idEstadoSeleccionado: estadoSel,
      idProcesoSeleccionado: procesoSel,
      idDetalleProcesoSeleccionado: detalleSel,
      modalVisible: false,
      modalLoading: false,
      dataSelect: data,
      loading: true,
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      }
    };
  }
  componentDidMount() {
    let data = JSON.stringify({
      parametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado
      },
      idTipoDocumentacion: this.state.idTipoDocumentacion,
      idArticulo: this.state.idArticulo
    })
    // this.setState({ dataSelect: data, idEstadoSeleccionado: estadoSel, idProcesoSeleccionado: procesoSel, idDetalleProcesoSeleccionado: detalleSel });
  }
  handleSelect(value) {
    this.setState({
      /*posts: response.data.entity,
      urlPDF1: urlPDF1,
      urlPDF2: urlPDF2,
      urlZIP: urlZIP,
      modalVisible: false,
      seleccion: value,*/
      consulta: false
    });
    const currentToken = localStorage.getItem('accessToken');
    const anio = this.props.user.infoMenu.detalleSelec.anio;
    const tipoProceso = this.props.user.infoMenu.detalleSelec.tipoProceso;
    let data = JSON.stringify({
      parametros: {
        idProceso: this.state.idProcesoSeleccionado,
        idEstado: this.state.idEstadoSeleccionado,
        idDetalleProceso: this.state.idDetalleProcesoSeleccionado,
        anioProceso: anio,
        tipoProceso: tipoProceso
      },
      tipodocumentacion: this.state.idTipoDocumentacion,
      idArticulo: this.state.idArticulo,
      etapaHistorico: value
    });
    axios
      .post(
        urlConsultaDocumentos, data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          /* this.setState(
            {
              visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: response.data.entity.causa
              }
            }); */
            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            this.setState(
              {
                visibleAlert: {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message
                },
                loading: false,
              });
          }
          else {
            let urlPDF1 = null;
            let urlPDF2 = null;
            let urlPDF3 = null;
            if (response.data.entity.archivoUnicoBase64 !== null && response.data.entity.archivoUnicoBase64 !== "") {
              const disenioUnicoBase64 = 'data:application/pdf;base64,' + response.data.entity.archivoUnicoBase64;
              const blob1 = base64toBlob(disenioUnicoBase64);
              urlPDF1 = URL.createObjectURL(blob1);
            }
            if (response.data.entity.cargaHistoricoBase64 !== null && response.data.entity.cargaHistoricoBase64 !== "") {
              const disenioOPLBase64 = 'data:application/pdf;base64,' + response.data.entity.cargaHistoricoBase64;
              const blob2 = base64toBlob(disenioOPLBase64);
              urlPDF2 = URL.createObjectURL(blob2);
            }
            if (response.data.entity.archivoEvidenciaBase64 !== null && response.data.entity.archivoEvidenciaBase64 !== "") {
              const archivoEvidenciaBase64 = 'data:application/pdf;base64,' + response.data.entity.archivoEvidenciaBase64;
              const blob = base64toBlob(archivoEvidenciaBase64);
              urlPDF3 = URL.createObjectURL(blob);
            }
            this.setState({
              posts: response.data.entity,
              urlPDF1: urlPDF1,
              urlPDF2: urlPDF2,
              urlPDF3: urlPDF3,
              modalVisible: false,
              seleccion: value,
              loading: false,
              consulta: true
            });
          }
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        this.setState({
          /* visibleAlert: {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada",
          }, */
          loading: false,
          consulta: false
        });
        message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
      });
  }

  handleClose() {
    this.setState({
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      }
    });
  }

  obtenHistoricoRevision(etapa) {
    this.handleSelect(etapa);
  }

  handleClickDescargar() {
    console.log("Entro a descagar");
    let a = document.createElement('a');
    a.href = this.state.urlPDF1;
    /* a.download = "DOCSMATS-" + this.state.posts.nombreArchivoUnico; */
    a.download = "DOCSMATS-" + "disenioUnico";
    a.click();
    //window.location.href = consulta.url;
  }

  handleClickDescargarEvidencia() {
    console.log("Entro a descagar");
    let a = document.createElement('a');
    a.href = this.state.urlPDF3;
    /* a.download = "DOCSMATS-" + this.state.posts.nombreArchivoEvidencia; */
    a.download = "DOCSMATS-" + "evidenciaRevision";
    a.click();
    //window.location.href = consulta.url;
  }

  render() {

    const wrapperS = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',


    };

    const columns = [
      {
        Header: "No.",
        accessor: "consecutivo",
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        filterable: false,
        style: {
          textAlign: "center"
        },
      },
      {
        Header: "Tipo",
        accessor: "tipoEspecificacion",
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        ...GridFilters
      },
      {
        Header: "Especificación",
        accessor: "especificacion",
        ...GridFilters
      },
      {
        Header: "Observación",
        accessor: "observacion",
        ...GridFilters
      },
    ];

    return (
      <div>
        <Button
          id="regresar"
          type="link"
          href="/revision/cargas">
          <ArrowLeftOutlined />Regresar</Button>
        <h1 id="titulo">Cargas</h1>
        <p id="msg">
          <span id="smsg">*</span>Histórico de cargas
        </p>
        {this.state.visibleAlert.activado ?
          (
            <Mensajes tipoMensaje={this.state.visibleAlert.tipoMensaje} mensaje={this.state.visibleAlert.mensaje} handleClose={this.handleClose} />
          ) : null}
        <p>
          <SelectDocumentos
            onChange={this.handleSelect}
            data={this.state.dataSelect}
            rutaWS={urlTiposClasificacion}
          />
        </p>
        {this.state.loading ?
          (
            <div className="div_cargando">
              <br />
              <Spin size="large" />
            </div>
          )
          :
          (
            <div>
              {this.state.consulta ? (
                <div>
                  <div className="principal-div">

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                      <div style={{ width: '48%', float: 'left', margin: '5px' }}>
                        <h3>{this.state.nombreArticulo} (Diseño Unico)</h3>
                      </div>
                      <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                      <div style={{ height: '750px', width: '48%', float: 'left', margin: '5px' }}>
                        {this.state.urlPDF1 !== null ?
                          (
                            <Worker
                              /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                              workerUrl={window.location.origin + "/pdf.worker.min.js"}
                            >
                              <Viewer fileUrl={this.state.urlPDF1} />
                            </Worker>
                          )
                          :
                          (
                            <div
                              style={{
                                alignItems: 'center',
                                border: '2px dashed rgba(0, 0, 0, .3)',
                                display: 'flex',
                                fontSize: '2rem',
                                height: '100%',
                                justifyContent: 'center',
                                width: '100%',
                              }}
                            >
                            </div>
                          )}
                      </div>
                      <div style={{ height: '750px', width: '48%', float: 'right', margin: '5px' }}>
                        {this.state.urlPDF2 !== null ?
                          (
                            <Worker
                              /* workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`} */
                              workerUrl={window.location.origin + "/pdf.worker.min.js"}
                            >
                              <Viewer fileUrl={this.state.urlPDF2} />
                            </Worker>
                          )
                          :
                          (
                            <div
                              style={{
                                alignItems: 'center',
                                border: '2px dashed rgba(0, 0, 0, .3)',
                                display: 'flex',
                                fontSize: '2rem',
                                height: '100%',
                                justifyContent: 'center',
                                width: '100%',
                              }}
                            >
                            </div>
                          )}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                      <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                        <Button className="descargar" onClick={this.handleClickDescargar}> <DownloadOutlined />Descargar </Button>
                      </div>
                      <div style={{ width: '48%', float: 'right', margin: '5px' }}>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                      <div style={{ width: '48%', float: 'left', margin: '5px' }}>
                        <br />
                        <h4><span>Especificaciones Anexo 4.1</span></h4>
                        <ul>
                          {this.state.posts.especificacionesTecnicas.map((item) => (
                            //console.log(item),
                            <li>{item.nombre}</li>
                          ))}
                        </ul>
                      </div>
                      <div style={{ width: '500px', float: 'right', margin: '5px' }}>
                        <br />
                        <h4><span>Especificaciones Únicas</span></h4>
                        <ul>
                          {this.state.posts.especificacionesDisenio.map((item) => (
                            //console.log(item),
                            <li>{item.nombre}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {this.state.posts.tipoHistorico && this.state.posts.observaciones !== null ?
                    <div>
                      <p>
                        <ReactTable
                          id="ilist"
                          columns={columns}
                          data={this.state.posts.observaciones}
                          filterable={true}
                          pageSize={5}
                          showPagination={true}
                          PaginationComponent={Paginacion}
                          noDataText={"Sin datos que mostrar"}
                        />
                      </p>
                    </div>
                    : null},
                  {this.state.posts.tipoHistorico && this.state.urlPDF3 !== null ? (
                    <div className="div_boton_descargar_evidencia">
                      <Button className="descargar_evidencia" onClick={this.handleClickDescargarEvidencia}> <DownloadOutlined />Descargar Evidencia</Button>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div >
          )
        }
      </div>
    );
  }
}
export default CargasEsp;
