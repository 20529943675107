import React from "react";
import ReactTable from "react-table-v6";
import "antd/dist/antd.css";
import "./cargas.css";
import Paginacion from "./Paginacion";
///import { DeleteOutlined, UploadOutlined, EyeOutlined, CheckOutlined } from "@ant-design/icons";
//import axios from "axios";

//const httpsAgent = new https.Agent({ ca: fs.readFileSync(certPath) });
//const httsAgent = new https.Agent({ rejectUnauthorized: false });

class ListaDocs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.datos,
      eventos: props.eventos,
      columnas: props.columnas
    };
    this.handleClickConsultar = this.handleClickConsultar.bind(this);
    this.handleClickCargar = this.handleClickCargar.bind(this);
    this.handleClickEliminar = this.handleClickEliminar.bind(this);
    this.handleClickModificar = this.handleClickModificar.bind(this);
  }

  componentWillReceiveProps(datos) {
    this.setState({ posts: datos.datos })
  }

  handleClickConsultar(id, conAlerta) {
    this.state.eventos.map((evento, index) => {
      Object.entries(evento).map((metodo, index) => {
        if (metodo[0] === "consultar") {
          metodo[1](id, conAlerta);
        }
      });
    });
  }

  handleClickCargar(id, conAlerta) {
    this.state.eventos.map((evento, index) => {
      Object.entries(evento).map((metodo, index) => {
        if (metodo[0] === "cargar")
          metodo[1](id, conAlerta);
      });
    });
  }

  handleClickEliminar(id, conAlerta) {
    this.state.eventos.map((evento, index) => {
      Object.entries(evento).map((metodo, index) => {
        if (metodo[0] === "eliminar")
          metodo[1](id, conAlerta);
      });
    });
  }

  handleClickModificar(id, conAlerta) {
    this.state.eventos.map((evento, index) => {
      Object.entries(evento).map((metodo, index) => {
        if (metodo[0] === "modificar")
          metodo[1](id, conAlerta);
      });
    });
  }

  render() {
    console.log("-----------------------------------------Se dibuja la lista");
    return (
      <ReactTable
        id="ilist"
        columns={this.state.columnas}
        data={this.props.datos}
        filterable={this.props.filterable}
        pageSize={10}
        showPagination={true}
        PaginationComponent={Paginacion}
        noDataText={"No hay datos"}
      />
    );
  }
}

export default ListaDocs;
