import React from "react";
import './Reportes.css';
import { Tabs } from "antd";
import { Redirect } from 'react-router';
import ReporteInventario from "./InventarioOPL/ReporteInventario";
import ReporteProduccion from "./Produccion/ReporteProduccion";
import ReporteAprobacion from "./Aprobacion/ReporteAprobacion";
import ReporteAdjudicacion from "./Adjudicacion/ReporteAdjudicacion";
import ReporteRevision from "./Revision/ReporteRevision";


const { TabPane } = Tabs;

class Reportes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }

    componentDidMount() {

    }




    render() {
        if (this.state.redirect) {
            return <Redirect push to="/reportes" />;
        }

        return (
            <div>
                <h1 className="titulo-main-reportes">Reportes</h1>
                <h1 className="texto-16-reportes">Selecciona alguno de los reportes disponibles.</h1>

                <Tabs defaultActiveKey="1" tabPosition="top" >

                    <TabPane tab="Inventario" key="1">
                        <div className="reporte">
                            <ReporteInventario />
                        </div>
                    </TabPane>
                    <TabPane tab="Revisión" key="3">
                        <div className="reporte">
                            <ReporteRevision />
                        </div>
                    </TabPane>
                    <TabPane tab="Aprobación" key="4">
                        <div className="reporte">
                            <ReporteAprobacion />
                        </div>
                    </TabPane>
                    <TabPane tab="Adjudicación" key="5">
                        <div className="reporte">
                            <ReporteAdjudicacion />
                        </div>
                    </TabPane>
                    <TabPane tab="Producción" key="2">
                        <div className="reporte">
                            <ReporteProduccion />
                        </div>
                    </TabPane>



                </Tabs>
            </div>
        );
    }
}

export default Reportes;