import React from "react";
import { Select, Button, message } from "antd";
import axios from "axios";
import Mensajes from "../../components/Mensajes/Mensajes";
const { Option } = Select;
var tiempoMensaje = 5;
class SelectDocumentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      muestraOpciones: false,
      idEstadoSeleccionado: -1,
      idProcesoSeleccionado: -1,
      idDetalleProcesoSeleccionado: -1,
      visibleAlert: {
        activado: false,
        tipoMensaje: "",
        mensaje: ""
      }
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    /*const estadoSel = localStorage.getItem('ID_ESTADO_SELECCIONADO');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const detalleSel = localStorage.getItem('ID_DETALLE_PROCESO');
    const currentToken = localStorage.getItem('accessToken');
    this.setState({idEstadoSeleccionado: estadoSel, idProcesoSeleccionado: procesoSel, idDetalleProcesoSeleccionado: detalleSel});
    let data = JSON.stringify({
      idProceso: procesoSel,
      idEstado: estadoSel,
      idDetalleProceso: detalleSel
    })*/
    //console.log(data);
    const currentToken = localStorage.getItem('accessToken');
    axios
      .post(
        this.props.rutaWS, this.props.data,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'application/json',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 300) {
          this.setState(
            {
              /* visibleAlert: {
                activado: true,
                tipoMensaje: "error",
                mensaje: response.data.entity.causa
              }, 
              posts: [],
              muestraOpciones: false*/
            });
            message.error("10.- Ocurrió un error. Comunícate con el CAU.",tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            this.setState(
              {
                /* visibleAlert: {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message
                }, */
                posts: [],
                muestraOpciones: false
              });
              message.warning(response.data.entity.message,tiempoMensaje)
          }
          else {
            this.setState({
              visibleAlert: {
                activado: false,
                tipoMensaje: "",
                mensaje: ""
              },
              posts: response.data.entity,
              muestraOpciones: true,
            });
            if (response.data.entity.porDefecto !== null)
              this.props.onChange(response.data.entity.porDefecto);
            //console.log(response.data.entity);
          }
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        this.setState(
          {
            /* visibleAlert: {
              activado: true,
              tipoMensaje: "error",
              mensaje: "Ha ocurrido un error al realizar la petición solicitada"
            }, */
            posts: [],
            muestraOpciones: false
          });
          message.error("Ha ocurrido un error al realizar la petición solicitada",tiempoMensaje);
      });
  }

  handleChange(value) {
    console.log("Seleccion", value);
    this.props.onChange(value);
  }

  render() {
    return (
      <div>
        {this.state.visibleAlert.activado ?
          (
            <Mensajes tipoMensaje={this.state.visibleAlert.tipoMensaje} mensaje={this.state.visibleAlert.mensaje} handleClose={this.handleClose} />
          ) : null}
        {!this.state.muestraOpciones ? (
          <Select

            disabled
          />
        ) : (
            <div>
              {this.state.posts.porDefecto === null ? (
                <Select
                  onSelect={this.handleChange}
                  defaultValue="Seleccionar opción"
                  placeholder="Seleccionar opción"
                  id="opcion"
                >
                  {Object.entries(this.state.posts.select).map((value, index) => {
                    return <Option id="opcion" key={value[0]}>{value[1]}</Option>;
                  })}
                </Select>)
                : (<Select
                  onSelect={this.handleChange}
                  defaultValue={this.state.posts.porDefecto}
                  id="opcion"
                >
                  {Object.entries(this.state.posts.select).map((value, index) => {
                    return <Option id="opcion" key={value[0]}>{value[1]}</Option>;
                  })}
                </Select>)}
            </div>
          )}
      </div>
    );
  }
}

export default SelectDocumentos;
