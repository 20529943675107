import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from "./config/routes";//Se importan todas las rutas del sistema
import AuthProvider from './api/AuthProvider';//Se importa la configuración del provider
import AdminLogin from './pages/Admin/Login/Login';
import useAuth from "./hooks/useAuth";
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
 
function App() {
  return (
    <ConfigProvider locale={esES}>
      <AuthProvider>
        <LoginLayaout/>
      </AuthProvider> 
    </ConfigProvider>
  )
}

const LoginLayaout = () => {
  const {user} = useAuth();
  return(
    <>
      { (user.user)?
        <Router>
          <Switch>           
            {routes.map((route, i) => (
              <RoutesWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </Router> :
        <Router>
          <Switch>           
            <Route
              path="/"
              render= { AdminLogin }
            />
          </Switch>
        </Router> 
      }
    </>
   )
}

//Función que itera las rutas
function RoutesWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => <route.component routes={route.routes} {...props} />}
    />
  );
}
export default App;
