import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from 'react-router';
import axios from "axios";
import { Divider, Form, Spin, Button, Modal, message } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import Table from "react-table-v6";
import Paginacion from "../../../components/ListaDocs/Paginacion";
import "./RegistrarSesion.scss";
import { basePath } from "../../../api/config";
import Mensajes from "../../../components/Mensajes/Mensajes";
import FormRegistroSesion from "./FormRegistroSesion";
import { useHistory } from "react-router-dom";

let isRolConsulta = false;
let isRolCAU = false;
let isRolVerifica = false;
var tiempoMensaje = 5;
const confirm = Modal.confirm;

export default function RegistrarSesion(props) {
    let history = useHistory();
    const date = new Date();
    const urlConsultaTiposSesion = `${basePath}/consultaTiposSesiones`;
    const urlConsultaSesiones = `${basePath}/consultaSesiones`;
    //const urlConsultaTiposSesion = `https://localhost:8443/docs_ws_adjudica/ws/consultaTiposSesiones`;
    const urlGuardaSesion = `${basePath}/guardaSesionConsejo`;
    //const urlGuardaSesion = `https://localhost:8443/docs_ws_adjudica/ws/guardaSesion`;
    // const fechaActual = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    var fechaCreacion = moment().format('DD-MM-YYYY');
    const urlEliminaSesionConsejo = `${basePath}/eliminaSesionConsejo`;
    let fecha = "";
    let nombre = "";
    let tipo = "";
    let fechac = "";
    let obs = "";
    let archivo = "";

    const [count, setCount] = useState(0);
    const [optionsTA, setOptionsTA] = useState({
        data: [],
        loadingData: false,

    });
    //console.log ("informacion optiones", optionsTA.data);
    const [sesiones, setSesiones] = useState({
        data: [],
        loadingData: false
    });

    const [sesionC, setSesionC] = useState({
        data: [],
        loadingData: false
    });

    const dateFormat = "DD-MM-YYYY";

    const [visibleAlert, setVisibleAlert] = useState({
        activado: false,
        tipoMensaje: "",
        mensaje: ""
    });

    isRolConsulta = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.OC' ? true :
        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.OC' ? true :
            localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.JL' ? true :
                localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.JL' ? true :
                    localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.UTVOPL.OC' ? true :
                        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.OPLE.JL' ? true :
                            localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.SUPERVISOR.OC' ? true :
                                localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.REVISOR.OC' ? true : false;

    isRolCAU = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.ADMIN.OC' ? true :
        localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAPTURA.OPLE.JL' ? true : false; //puede hacer todo

    isRolVerifica = localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CAU.OC' ? true : false;//puede hacer todo menos guardar

    function doRequestOptions() {
        console.log("Entró en doRequestOptions()");
        axios
            .post(
                urlConsultaTiposSesion, null, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            }
            )
            .then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        console.log("0response:=>", response);
                        if (count >= 1) {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "error",
                                    mensaje: response.data.entity.causa
                                }); */
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                        }
                        setCount(count + 1);
                    }
                    else if (response.data.entity.code === 200) {
                        //console.log("1response:=>", response);
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            //  console.log("1xresponse:=>", response);
                            setOptionsTA({
                                data: ["Ordinaria", "Extraordinaria", "Especial"],
                                //data: response.data.catalogoTiposSesion,
                                loadingData: true,
                            });
                        }
                        else {
                            console.log("2response:=>", response);
                            setOptionsTA({
                                data: ["Ordinaria", "Extraordinaria", "Especial"],
                                //data: response.data.catalogoTiposSesion,
                                loadingData: true,

                            });
                        }
                    }
                    else {
                        console.log("response3:=>", response);
                        localStorage.clear();
                        // console.log("Borro el local storage");
                        window.location = "/login"
                    }
                }
            ).catch(err => {
                console.log("Error:=>: doRequestOptions", err)
                setOptionsTA({
                    data: [],
                    loadingData: true
                });
                if (count >= 1) {
                    /* setVisibleAlert(
                        {
                            activado: true,
                            tipoMensaje: "error",
                            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                        }); */
                        message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
                }
                setCount(count + 1);
            });
    };

    function doRequestSesiones() {
        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: localStorage.getItem("ID_ESTADO_SELECCIONADO"),
            anioProceso: localStorage.getItem("ANIO")
        })
        console.log("--------Entró en doRequestSesiones");
        if (localStorage.getItem("ID_ESTADO_SELECCIONADO") === 'null') {
            /* setVisibleAlert(
                {
                    activado: true,
                    tipoMensaje: "warning",
                    mensaje: "Se debe seleccionar un estado"
                }); */
                message.warning("Se debe seleccionar un estado.",tiempoMensaje);
        }
        else {
            axios
                .post(
                    urlConsultaSesiones, data, {
                    headers: {
                        'Authorization': localStorage.getItem('accessToken'),
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                }
                ).then(
                    response => {
                        if (response.data.entity.code !== 200 && response.data.entity.code !== 300) {
                            if (count >= 1) {
                               /*  setVisibleAlert(
                                    {
                                        activado: true,
                                        tipoMensaje: "error",
                                        mensaje: response.data.entity.causa
                                    }); */
                                message.error(response.data.entity.causa,tiempoMensaje);
                            }
                            setCount(count + 1);
                        }
                        else if (response.data.entity.code === 200) {
                            if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            }
                            else {
                                console.log("No hay mensajes");
                                setSesionC({
                                    data: response.data.entity.dtoSesiones,
                                    loadingData: true
                                });
                            }
                        }
                        else {
                            localStorage.clear();
                            // console.log("Borro el local storage");
                            window.location = "/login"
                        }
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (count >= 1) {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                            }); */
                            message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
                    }
                    setCount(count + 1);
                });
        }
    };

    function doRequestGuarda(values) {
        const data = new FormData();
        data.append("idProcesoElectoral", localStorage.getItem('ID_PROCESO_ELECTORAL'));
        data.append("idDetalleProceso", localStorage.getItem('ID_DETALLE_PROCESO'));
        if (localStorage.getItem("ID_ESTADO_SELECCIONADO") !== null) {
            data.append("idEstado", localStorage.getItem("ID_ESTADO_SELECCIONADO"));
        }
        else {
            data.append("idEstado", localStorage.getItem("ID_ESTADO"));
        }
        data.append("anioProceso", localStorage.getItem("ANIO"));
        data.append("tipoProceso", localStorage.getItem("TIPO_PROCESO"));
        data.append("rolUsuario", localStorage.getItem("ROL_USUARIO"));
        data.append("fechaSesion", values.fechaSesion);
        data.append("tipoSesion", values.tipoSesion);
        data.append("acuerdos", values.Archivo.file);
        data.append("nombreAcuerdo", values.nombreAcuerdo);
        data.append("numeroAcuerdo", values.numeroAcuerdo);
        if (values.observaciones === undefined || values.observaciones === null)
            data.append("observaciones", "");
        else
            data.append("observaciones", values.observaciones);

        setSesionC({
            data: [],
            loadingData: false
        })

        setVisibleAlert(
            {
                activado: false,
                tipoMensaje: "",
                mensaje: ""
            });

        axios
            .post(
                urlGuardaSesion, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'multipart/form-data',
                    Accept: "application/json",
                }
            }
            ).then(
                response => {
                    if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                        if (response.data.entity.code === 406)
                            message.error("Error en la creación de sesión de aprobación. "+response.data.entity.causa+".",tiempoMensaje);
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: response.data.entity.causa
                            }); */
                        else
                            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
                    }
                    else if (response.data.entity.code === 200) {
                        if (response.data.entity.message !== null && response.data.entity.message !== "") {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "success",
                                    mensaje: response.data.entity.message
                                }); */
                                message.success(response.data.entity.message,tiempoMensaje);
                        }
                        else {
                            /* setVisibleAlert(
                                {
                                    activado: true,
                                    tipoMensaje: "error",
                                    mensaje: response.data.entity.message
                                }); */
                                message.error(response.data.entity.message,tiempoMensaje);
                        }
                    }
                    else {
                        localStorage.clear();
                        console.log("Borro el local storage");
                        window.location = "/login"
                    }
                    setOptionsTA({
                        data: [],
                        loadingData: false
                    });
                }
            ).catch(err => {
                console.log("Error:=>: doRequestGuarda", err)
                /* setVisibleAlert(
                    {
                        activado: true,
                        tipoMensaje: "error",
                        mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                    }); */
                    message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
                setOptionsTA({
                    data: [],
                    loadingData: false
                });
            });
    };

    function showConfirmGuardar() {
        Modal.confirm({
            title: '¿Está seguro de guardar la información?',
            icon: <ExclamationCircleOutlined />,
            //content: 'Some descriptions',
            okText: 'Aceptar',
            cancelText: 'Cancelar',
            onOk() {
                console.log('Aceptar');
                doRequestGuarda();
            },
            onCancel() {
                console.log('Cancelar');
            },
        });
    }

    function handleGuardaSesion(values) {
        console.log("values:=>", values);
        values.fechaSesion = values.fechaSesion.format(dateFormat);
        //value.fechaCaptura = value.fechaCaptura.format(dateFormat);
        values.fechaCaptura = fechaCreacion;
        values.key = (new Date()).getTime();
        values.noEditable = false;
        doRequestGuarda(values);
    }

    // console.log("SESIONES.DATA3", sesiones.data[0]);
    sesiones.data && (sesiones.data.map((item) => (//obtengo los valores del arreglo que trae los datos que agregó el usuario
        //console.log(item),
        fecha = item.fechaSesion,
        nombre = item.nombreAcuerdo,
        fechac = item.fechaCaptura,
        archivo = item.Archivo,
        obs = item.observaciones,
        tipo = item.tipoSesion,
        console.log("SESIONES.DATA4", fecha, nombre, fechac, obs, tipo, archivo)
    )))

    console.log("archivo HAPPY 1x", archivo);



    function handleRemoveData(newData, deleteRow) {
        console.log("handleRemoveData:=>newData ", newData);
        console.log("handleRemoveData:=>deleteRow ", deleteRow);
        setSesiones({
            data: newData,
            loadingData: false
        });
    }

    const handleClose = () => {
        setVisibleAlert({
            activado: false,
            tipoMensaje: "",
            mensaje: ""
        });
    }

    const handleClickGuardar = useCallback(() => {
        showConfirmGuardar();
    });

    useEffect(() => {
        if (!optionsTA.loadingData) {
            doRequestOptions();
            doRequestSesiones();
        }
    }, [optionsTA]);

    const [redirect, setRedirect] = useState({
        redirecting: false,
        url: "",
        idAprobacion: -1,

    });
    const handleClickConsultar = useCallback((id) => {
        console.log("Handle consultar sesion");
        setRedirect({
            redirecting: true,
            url: '/aprobacion/consultaSesion',
            idAprobacion: id,
        })
    });

    function handleClickEliminar(id, numeroAcuerdo) {
        console.log("Entró en doRequest()");

        confirm({
            title: 'Confirmación',
            content: '¿Está seguro que desea eliminar la sesión con número de acuerdo '+numeroAcuerdo+'?',
            okText: 'Aceptar',
            cancelText: 'Cancelar',
            onOk() {
                setVisibleAlert(
                    {
                        activado: false,
                        tipoMensaje: "",
                        mensaje: ""
                    });
                let data = JSON.stringify({
                    idEstado: localStorage.getItem('ID_ESTADO_SELECCIONADO'),
                    idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                    idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
                    anioProceso: localStorage.getItem('ANIO'),
                    tipoProceso: localStorage.getItem("TIPO_PROCESO"),
                    rolUsuario: localStorage.getItem("ROL_USUARIO"),
                    idAprobacion: id
                })
                console.log("doRequest.data: ", data);
        
                axios
                    .post(
                        urlEliminaSesionConsejo, data, {
                        headers: {
                            'Authorization': localStorage.getItem('accessToken'),
                            'Content-Type': 'application/json',
                            Accept: "application/json",
                        }
                    }
                    ).then(
                        response => {
                            if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
                                /* setVisibleAlert(
                                    {
                                        activado: true,
                                        tipoMensaje: "error",
                                        mensaje: response.data.entity.causa
                                    }); */
                                    console.log('error!:',response.data.entity.causa);
                                    console.log('vesion:',response.data.entity.wsrest);
                                    message.error(response.data.entity.causa,tiempoMensaje);
                            }
                            else if (response.data.entity.code === 200) {
                                if (response.data.entity.message !== null && response.data.entity.message !== "") {
                                    /* setVisibleAlert(
                                        {
                                            activado: true,
                                            tipoMensaje: "success",
                                            mensaje: response.data.entity.message
                                        }); */
                                        message.success(response.data.entity.message,tiempoMensaje);
                                }
                            }
                            else {
                                localStorage.clear();
                                // console.log("Borro el local storage");
                                window.location = "/login"
                            }
                            setOptionsTA({
                                data: [],
                                loadingData: false
                            });
                        }
                    ).catch(err => {
                        /* setVisibleAlert(
                            {
                                activado: true,
                                tipoMensaje: "error",
                                mensaje: "Ha ocurrido un error al realizar la petición solicitada"
                            }); */
                            message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
                        setOptionsTA({
                            data: [],
                            loadingData: false
                        });
                    }
                    );
            },
            onCancel() {
            },
          });
    };

    const columns = [

        {
            Header: "Número de acuerdo",
            accessor: "numeroAcuerdo",
            width: "5%",
            editable: true,

            align: "center"
        },
        {
            Header: "   Fecha sesión",
            accessor: "fechaSesionString",
            width: "12%",
            editable: true,

            align: "center"
        },
        {
            Header: "   Tipo de sesión",
            accessor: "tipoSesion",
            width: "16%",
            editable: true,
            align: "center"
        },

        {
            Header: "  Nombre del acuerdo",
            accessor: "nombreAcuerdo",
            width: "21%",
            align: "center",
            editable: true
        },
        {
            Header: "Fecha de registro",
            accessor: "fechaHoraString",
            width: "12%",
            editable: true,
            align: "center"
        },
        {
            Header: "Observaciones",
            accessor: "observaciones",
            width: "23%",
            align: "center",
            editable: true
        },
        {
            Header: "Acciones",
            style: {
                color: "blue"
            },
            width: 125,
            maxWidth: 125,
            minWidth: 125,

            Cell: props => {
                return (
                    console.log("Se pintan las celdas"),
                    <div className="div-acciones">
                        <Tooltip title="Consultar">
                            <Button
                                className="ilist"
                                type="link"
                                shape="circle"
                                onClick={handleClickConsultar.bind(this, props.original.idAprobacion, props.original.nombreAcuerdo)}

                            //disabled={!value.isActivo}
                            >{console.log("PROPS", props.original.idAprobacion, props.original.nombreAcuerdo)}
                                <EyeOutlined />
                            </Button>
                        </Tooltip>
                        <Divider className="dcolor" type="vertical" />

                        <Tooltip title="Eliminar">
                            <Button
                                className="ilist"
                                type="primary"
                                shape="circle"
                                accept=""
                                onClick={handleClickEliminar.bind(this, props.original.idAprobacion, props.original.numeroAcuerdo)}
                                disabled={isRolConsulta || isRolVerifica}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>


                    </div>
                );
            },
            style: {
                color: "blue"
            },
            sortable: false,
            filterable: false
        },
    ];

    const renderRedirect = () => {
        if (redirect.redirecting) {
            return <Redirect to={{
                pathname: redirect.url,
                state: {
                    idAprobacion: redirect.idAprobacion,
                }
            }
            } />
        }
    }

    const WrappedFormRegistroSesion = Form.create({ name: 'sesion' })(FormRegistroSesion);

    return (
        <div id="principal" className="div_consultaadj">
            {renderRedirect()}
            {visibleAlert.activado ?
                (
                    <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
                ) : null}
            <br />
            {!sesionC.loadingData ? (<Spin size="large" />) : (
                <div>
                    <WrappedFormRegistroSesion
                        optionsTiposSesion={optionsTA.data}
                        mostrarFormulario={isRolVerifica || isRolCAU}
                        enableAgregar={isRolCAU}
                        handleSubmit={handleGuardaSesion} />

                    {/*<TablaRegistroSesion
                        id="tablaSesiones"
                        optionsTiposSesion={optionsTA.data}
                        data={sesiones.data}
                        enableEdit={false}
                        enableDelete={true}
                        handleRemoveData={handleRemoveData.bind(this)}
                        noDataText={"Sin datos que mostrar"}
                    />
                    <div> 
                    
                        </div>
                        <div className="div_botones">
                        <Button
                            className="cancelar"
                            onClick={handleClickCancelar.bind(this)}
                        > Cancelar </Button>
                        <Button
                            style={{ marginLeft: '10px' }}
                            className="guardar"
                            onClick={handleClickGuardar.bind(this)}
                            disabled={sesiones.data.length === 0||isRolVerifica||isRolConsulta}
                        > Guardar </Button>
                    </div>
                    <br />
                    <br />*/}
                    <div >
                        {console.log("SESIONES", sesionC.data)}
                        <h3>Sesiones OPL registradas</h3>
                        <Table
                            columns={columns}
                            data={sesionC.data}//ver dato

                            defaultPageSize={10}
                            PaginationComponent={Paginacion}
                            filterable={true}
                            noDataText={"Sin datos que mostrar"}
                        />
                    </div>

                </div>
            )}
        </div>
    )

}