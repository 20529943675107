import React, { useState, useEffect } from "react"
import { Modal, Select, Row, Col, Form, Icon, Spin, DatePicker, Input, Button, Cascader, message } from "antd";
import axios from "axios";
import { basePath } from "../../api/config";
import moment from 'moment';
import jwtDecode from "jwt-decode";
import { Redirect } from 'react-router';
import Mensajes from "../Mensajes/Mensajes";
const antIcon = <Icon type="loading" style={{ fontSize: 30 }} spin />;
const { Option } = Select;
var tiempoMensaje = 5;
class FormActaSeguimiento extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleGuardaActa = this.handleGuardaActa.bind(this);


        this.state = {
            options: [],
            listaEmpresas: [],
            fechaInicio: "",
            fechaFin: "",
            cantidadTotal: 0,
            cantidadAcumulada: false,
            fechaUltimoRegistro: "",
            disabledDate: true,
            redirect: false,
            nombreEmpresa: "",
            visibleAlert: {
                activado: false,
                tipoMensaje: "",
                mensaje: "",
            }
        };


    }


    handleCancel = () => {
        this.setState({
            options: null,
            listaEmpresas: null,
            fechaInicio: "",
            fechaFin: "",
            cantidadTotal: 0,
            cantidadAcumulada: 0,
            fechaUltimoRegistro: "",
            disabledDate: true,
            nombreEmpresa:""
        });

        this.props.form.resetFields();
        localStorage.removeItem("ID_ARTICULO_SEG");
        localStorage.removeItem("ID_TIPO_DOC_SEG");
        localStorage.removeItem("ID_APROBACION_SEG");
        localStorage.removeItem("NOMBRE_ARTICULO_SEG");

        this.props.setIsVisible();
        this.props.setIsLoadingEmpresaTrue();


    }

    handleClose() {
        console.log("Cerrar modal")

        this.setState(

            {
                visibleAlert: {
                    activado: false,
                    tipoMensaje: "",
                    mensaje: ""
                },
                options: null,
                listaEmpresas: null,
                fechaInicio: "",
                fechaFin: "",
                cantidadTotal: 0,
                cantidadAcumulada: 0,
                fechaUltimoRegistro: "",
                disabledDate: true,
            });

        this.props.form.resetFields();
        localStorage.removeItem("ID_ARTICULO_SEG");
        localStorage.removeItem("ID_TIPO_DOC_SEG");
        localStorage.removeItem("ID_APROBACION_SEG");
        localStorage.removeItem("NOMBRE_ARTICULO_SEG");


        this.props.setIsLoadingEmpresaTrue();
        this.props.setIsVisible();
        // setTimeout( window.location.reload(), 8000)
    }

    /*restarDias=(fecha, dias)=>{
        fecha.setDate(fecha.getDate() - dias);
        return fecha;
      }*/

    onChangeFiltro = (value) => {

        if (value.length !== 0) {
            let splited = value[0].split("-");

            const registroAdjudicacion = this.state.listaEmpresas.filter(listaEmpresas => listaEmpresas.idAdjudicacion.includes(splited[0]));
            const registroEmpresa = registroAdjudicacion.filter(registroAdjudicacion => registroAdjudicacion.idEmpresa.includes(splited[1]));

            console.log("registroEmpresa")
            console.log(registroEmpresa)

            this.props.form.resetFields();
            //var d=new Date();
            //var ahora = (this.restarDias(d, 1));
            //var hoy =moment().endOf('day');
            var hoy =moment().startOf('day')
            var fechaInicio = moment(registroEmpresa[0].fechaInicio, "DD-MM-YYYY").toDate();
            var fechaFin = moment(registroEmpresa[0].fechaFin, "DD-MM-YYYY").toDate();
            var disableDate = ((fechaInicio <= hoy) && (hoy <= fechaFin)) ? false : true;
            this.setState({
                fechaInicio: registroEmpresa[0].fechaInicio,
                fechaFin: registroEmpresa[0].fechaFin,
                cantidadTotal: parseInt(registroEmpresa[0].cantidadTotal),
                cantidadAcumulada: parseInt(registroEmpresa[0].cantidadAcumulada),
                fechaUltimoRegistro: registroEmpresa[0].fechaUltimoRegistro,
                nombreEmpresa: registroEmpresa[0].nombreEmpresa,
                disabledDate: disableDate
            });
        } else {
            this.setState({
                fechaInicio: "",
                fechaFin: "",
                cantidadTotal: 0,
                cantidadAcumulada: 0,
                fechaUltimoRegistro: "",
                nombreEmpresa: "",
                disabledDate: true
            });
        }

    }

    disabledStartDate = current => {
        return current && current <= moment(this.state.fechaInicio, "DD-MM-YYYY").subtract(1, 'day').endOf('day') || current >= moment(this.state.fechaFin, "DD-MM-YYYY").endOf('day');
    };

    disabledEndDate = current => {
        return current && current <= moment(this.state.fechaInicio, "DD-MM-YYYY").subtract(1, 'day').endOf('day') || current >= moment(this.state.fechaFin, "DD-MM-YYYY").endOf('day');
    };

    validaCantidaProducida = (rule, value, callback) => {

        if (value > this.state.cantidadTotal) {
            callback('Excede la cantidad total asignada.')
        }
        else if (value === 0 && this.state.cantidadAcumulada === 0) {
            callback('Debe registrar una cantidad.')
        }
        else if (value <= this.state.cantidadAcumulada || value === 0)
            callback('El campo debe ser mayor a la última cantidad acumulada.');
        //callback('El campo debe ser mayor a la cantidad acumulada (' + this.state.cantidadAcumulada.toString() + ')');
        callback()
    };

    validaFechas = (rule, value, callback) => {
        if (value > this.state.cantidadTotal) {
            callback('Excede la cantidad total asignada.')
        }
        else if (value === 0 && this.state.cantidadAcumulada === 0) {
            callback('Debe registrar una cantidad.')
        }
        else if (value <= this.state.cantidadAcumulada || value === 0)
            callback('El campo debe ser mayor a la última cantidad acumulada.');
        //callback('El campo debe ser mayor a la cantidad acumulada (' + this.state.cantidadAcumulada.toString() + ')');
        callback()
    };

    handleGuardaActa(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                const { empresa, fechaInicio, fechaTermino, cantidadProducida } = values

                let usuario = jwtDecode(localStorage.getItem('accessToken'));
                let splited = empresa[0].split("-");

                let data = JSON.stringify({
                    idAprobacion: parseInt(localStorage.getItem('ID_APROBACION_SEG')),
                    idEmpresa: parseInt(splited[1]),
                    idAdjudicacion: parseInt(splited[0]),
                    idArticulo: parseInt(localStorage.getItem("ID_ARTICULO_SEG")),
                    idEstado: parseInt(localStorage.getItem('ID_ESTADO_SELECCIONADO')),
                    idTipoDocumentacion: parseInt(localStorage.getItem('ID_TIPO_DOC_SEG')),
                    fechaInicio: fechaInicio,
                    fechaFin: fechaTermino,
                    cantidadProducida: cantidadProducida,
                    usuario: usuario.sub,

                    anioProceso: localStorage.getItem('ANIO'),
                    idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                    idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
                    tipoProceso: localStorage.getItem('TIPO_PROCESO'),
                })
                console.log("Producción",data)
                
                axios.post(
                    `${basePath}/guardaProduccion`, data, {
                    headers: {
                        'Authorization': localStorage.getItem('accessToken'),
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                })
                    .then(resp => {
                        if (resp.data.entity.code === 200) {
                            console.log("RESP.DATA");
                            console.log(resp.data.entity);
                            console.log(resp.data.entity.message);
                            //alert(resp.data.message)

                            message.success("Se guardó correctamente el avance", tiempoMensaje);
                            this.handleCancel();
                            //window.location.href = '/produccion/seguimiento';
                            //setTimeout(window.location.reload(), 6000000);

                        }
                        else if (resp.data.entity.code == 403) {
                            localStorage.clear();
                            window.location = "/login"
                        } else {
                            console.log("Error.handleGuardaActa: ", resp.data.entity.message)

                            //alert(resp.data.causa)

                            this.setState(
                                {

                                    visibleAlert: {
                                        activado: true,
                                        tipoMensaje: "error",
                                        mensaje: resp.data.entity.causa
                                    }
                                });
                        }
                    }).catch(err => {
                        console.log("Error.handleGuardaActa: ", err)
                        if (err.response != null && err.response.status == 403) {
                            localStorage.clear();
                            window.location = "/login"
                            return;
                        }
                        //alert("ocurrio un error al guardar el acta, favor de intentar más tarde.")
                        /*  this.setState(
                             {
                                 visibleAlert: {
                                     activado: true,
                                     tipoMensaje: "error",
                                     mensaje: "Ocurrió un error al guardar el acta, favor de intentar más tarde."
                                 }
                             }); */
                        message.error("Ocurrió un error al guardar el avance de producción. Favor de intentar más tarde.", tiempoMensaje);
                    })

            }
        });
    }

    render() {



        const { getFieldDecorator, getFieldValue } = this.props.form;

        if (this.state.redirect) {
            return <Redirect push to="/produccion/seguimiento" />;
        }

        if (this.props.isVisible) {
            if (this.props.isLoadingEmpresa) {

                let data = JSON.stringify({
                    idArticulo: parseInt(localStorage.getItem("ID_ARTICULO_SEG")),
                    idEstado: parseInt(localStorage.getItem('ID_ESTADO_SELECCIONADO')),
                    idTipoDocumentacion: parseInt(localStorage.getItem('ID_TIPO_DOC_SEG')),
                    idAprobacion: parseInt(localStorage.getItem('ID_APROBACION_SEG'))
                })

                axios.post(
                    `${basePath}/consultaEmpresas`, data, {
                    headers: {
                        'Authorization': localStorage.getItem('accessToken'),
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                })
                    .then(resp => {
                        if (resp.data.entity.code === 200) {
                            this.props.setIsLoadingEmpresaFalse();
                            this.setState({
                                options: [],
                                listaEmpresas: []
                            });
                            let options = [];
                            resp.data.entity.listaEmpresas.forEach((item) => {
                                let idEmpresaSelect = item.idAdjudicacion + "-" + item.idEmpresa
                                options = [...options, { value: idEmpresaSelect, label: item.nombreEmpresa }];
                                /*this.state.options.push({
                                    value: idEmpresaSelect,
                                    label: item.nombreEmpresa
                                });
                                this.setState({
                                    listaEmpresas: resp.data.listaEmpresas
                                });
                                this.props.setIsLoadingEmpresaFalse();*/
                            });
                            this.setState({
                                options: options,
                                listaEmpresas: resp.data.entity.listaEmpresas,
                            });
                            if (options.length === 1)
                                this.onChangeFiltro([options[0].value]);
                        } else if (resp.data.entity.code == 403) {
                            localStorage.clear();
                            window.location = "/login"
                        }
                        else {
                            console.log("Error.componentDidMount: ", resp.data.entity.message)

                            this.setState({
                                options: [],
                                listaEmpresas: []
                            });

                            //alert(resp.data.causa)
                            /* this.setState(
                                {
                                    visibleAlert: {
                                        activado: true,
                                        tipoMensaje: "error",
                                        mensaje: resp.data.causa
                                    }
                                }); */
                            message.error(resp.data.entity.causa, tiempoMensaje);

                            this.props.setIsVisible();
                            this.props.setIsLoadingEmpresaTrue();
                        }
                    }).catch(err => {
                        console.log("Error.onChangeFiltro: ", err)
                        if (err.response != null && err.response.status == 403) {
                            localStorage.clear();
                            window.location = "/login"
                            return;
                        }
                        this.setState({
                            options: [],
                            listaEmpresas: []
                        });
                        this.props.setIsVisible();
                        this.props.setIsLoadingEmpresaTrue();
                        // alert("ocurrio un error al obtener los documentos y materiales, favor de intentar más tarde.")
                        /* this.setState(
                         {
                             visibleAlert: {
                                 activado: true,
                                 tipoMensaje: "error",
                                 mensaje: "Ocurrió un error al obtener los documentos y materiales, favor de intentar más tarde."
                             }
                         }); */
                        message.error("Ocurrió un error al obtener los documentos y materiales. Favor de intentar más tarde.", tiempoMensaje);
                    })

            }
            /*if (this.state.cerrarModal === null) {
                this.state.cerrarModal = this.props.isVisible;

            }*/

            /*else if(this.state.cerrarModal===null && this.state.cancela===1){
                this.state.cerrarModal=false; 
                this.state.cancela=-1
                console.log("Valor de la cerrar", this.state.cerrarModal);
            }*/

            //console.log("Valor de la alerta", this.state.visibleAlert);
        }

        return (
            //console.log("Alerta", this.state.visibleAlert),
            <div><Modal

                visible={this.props.isVisible}
                footer={[this.state.visibleAlert.activado ?
                    (
                        setTimeout(this.handleClose(), 80000) //*handleClose={this.handleClose} *
                    ) : null
                ]}
                centered={true}
                closable={false}
            >


                <Form onSubmit={this.handleGuardaActa} layout="vertical">
                    <Spin spinning={false} indicator={antIcon} tip="guardando...">
                        <Row>
                            <h2 className="titulo-rosa">{localStorage.getItem("NOMBRE_ARTICULO_SEG")}</h2>
                        </Row>
                        <Row>
                            <Form.Item
                                label={<label className="texto-16-seguimiento">Empresa</label>}
                            >
                                {getFieldDecorator('empresa', {
                                    initialValue: this.state.options !== undefined && this.state.options !== null
                                        && this.state.options.length > 0 && this.state.options.length == 1
                                        ? [this.state.options[0].value] : undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Selecciona una empresa.'
                                        }
                                    ]
                                })(
                                    <Cascader
                                        options={this.state.options}
                                        onChange={this.onChangeFiltro}
                                        placeholder="Seleccione una opción"
                                        disabled={
                                            (this.state.options !== undefined && this.state.options !== null
                                                && this.state.options.length > 0 && this.state.options.length == 1)
                                            || this.state.options === undefined && this.state.options === null
                                        }
                                    />
                                )}
                            </Form.Item>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label={<label className="texto-16-seguimiento">Fecha de inicio</label>}
                                >
                                    {getFieldDecorator('fechaInicio', {
                                        // initialValue: moment(this.state.fechaInicio, "DD-MM-YYYY"),
                                        initialValue:
                                            (moment(this.state.fechaUltimoRegistro, "DD-MM-YYYY").endOf('day') < moment(this.state.fechaInicio, "DD-MM-YYYY").startOf('day') ?
                                                moment(this.state.fechaInicio, "DD-MM-YYYY") :
                                                moment(this.state.fechaUltimoRegistro, "DD-MM-YYYY").startOf('day') >= moment(this.state.fechaInicio, "DD-MM-YYYY").startOf('day') &&
                                                    moment(this.state.fechaUltimoRegistro, "DD-MM-YYYY").endOf('day') <= moment(this.state.fechaFin, "DD-MM-YYYY").endOf('day') ?
                                                    moment(this.state.fechaUltimoRegistro, "DD-MM-YYYY") : moment(this.state.fechaFin, "DD-MM-YYYY")
                                            ),
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Selecciona una fecha de inicio.'
                                            }
                                        ]
                                    })(
                                        <DatePicker
                                            //disabledDate={this.disabledStartDate}
                                            //disabled={this.state.disabledDate}
                                            disabled={true}
                                            format="DD-MM-YYYY"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={<label className="texto-16-seguimiento">Fecha de captura</label>}
                                >
                                    {getFieldDecorator('fechaTermino', {
                                        // initialValue: moment(this.state.fechaFin, "DD-MM-YYYY"),
                                        initialValue:
                                            (moment().endOf('day') < moment(this.state.fechaInicio, "DD-MM-YYYY").startOf('day') ?
                                                moment(this.state.fechaInicio, "DD-MM-YYYY") :
                                                moment().startOf('day') >= moment(this.state.fechaInicio, "DD-MM-YYYY").startOf('day') &&
                                                    moment().endOf('day') <= moment(this.state.fechaFin, "DD-MM-YYYY").endOf('day') ?
                                                    moment().startOf('day') : moment(this.state.fechaFin, "DD-MM-YYYY")
                                            ),
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Selecciona la fecha de captura.'
                                            }
                                        ]
                                    })(
                                        <DatePicker
                                            //disabledDate={this.disabledEndDate}
                                            //disabled={this.state.disabledDate}
                                            disabled={true}
                                            format="DD-MM-YYYY"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={<label className="texto-16-seguimiento">Cantidad acumulada</label>}
                                >
                                    {getFieldDecorator('cantidadProducida', {
                                        rules: [
                                         
                                            {
                                                pattern: '^[0-9]*$',
                                                message: 'Solo se aceptan números'
                                            },
                                            {
                                                max: 9,
                                                message: 'Tamaño máximo de 9 números.'
                                            },
                                            {
                                                required: true,
                                                message: 'Ingrese la cantidad acumulada.'
                                            },
                                            {
                                                validator: this.validaCantidaProducida
                                            },
                                        ]
                                    })(
                                        <Input disabled={this.state.disabledDate} maxlength="9" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
{this.state.nombreEmpresa}
                        {(this.state.nombreEmpresa==="")? 
                         <Row >
                         <label className="texto-16-seguimiento">
                           
                         </label> 
                     </Row> 
                        :
                        (this.state.disabledDate)? 
                         <Row >
                         <label className="texto-16-bold-seguimiento">
                             {"La fecha actual no se encuentra dentro del rango de fechas asignado. "}
                         </label> 
                     </Row> :
                        <Row >
                            <label className="texto-16-seguimiento">
                                {"La cantidad acumulada hasta el momento de la empresa "}
                            </label>
                            <label className="texto-16-bold-seguimiento">
                                {this.state.disabledDate ? "" : this.state.nombreEmpresa}
                            </label>
                            <label className="texto-16-seguimiento">
                                {" es de "}
                            </label>
                            <label className="texto-16-bold-seguimiento">
                                {this.state.disabledDate ? "" : this.state.cantidadAcumulada.toString()}
                            </label>
                            <label className="texto-16-seguimiento">
                                {" piezas "}
                            </label>
                        </Row> 
                        }
                        <br />
                        <br />
                        <Row>
                            <Col span={8} offset={5}>
                                <Form.Item >
                                    <Button onClick={() => this.handleCancel()} className="button-cancel">
                                        Cancelar
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item >
                                    <Button className="button-submit" disabled={this.props.isCau || this.state.disabledDate} type="primary" htmlType="submit">
                                        Guardar
                                </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </Form>
            </Modal>
            </div>
        );

    }

}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(FormActaSeguimiento);
export default WrappedDynamicFieldSet;