import React from "react";
import '../Reportes.css';
import { Tabs, Button, Cascader, List, Col, Row, Skeleton, message } from "antd";
import { Redirect } from 'react-router';
import axios from "axios";
import { basePath } from "../../../api/config";
import ReporteRevisionDocumentos from "./ReporteRevisionDocumentos";
import ReporteRevisionMateriales from "./ReporteRevisionMateriales";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";




const { TabPane } = Tabs;
var tiempoMensaje = 5;


class ReporteRevision extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activeTab: "1",
            comboHidden: true,
            options: [],
            isLoadingDocumentos: false,
            isLoadingMateriales: false,
            isDescargando: false,
            redirect: false,
            documentos: [],
            materiales: [],
            nombreOple: [],
            selectedOption: [],
            selectedOptionObject: [],
            optionsMateriales: [],
            optionTodosLosDocumentos: [],
            porcentajeA: "",
            porcentajeU: "",
            estado:""
        };

    }
    
    componentDidMount() {

        this.initReporteDocumentos();

        this.initReporteMateriales();
        
    }

    

    initReporteDocumentos = () => {
        this.state.estado =localStorage.getItem('ID_ESTADO_SELECCIONADO');
        if(this.state.estado == 'null' || this.state.estado == 'undifine'){
            this.state.estado=0;
            //this.state.estado=parseInt(this.state.estado); 
            //console.log("SELECCIONADO", this.state.estado);
       }
      
            let data = JSON.stringify({
                idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
                idEstado: this.state.estado,
            })
            console.log('consultando catalogo documentos ', data)

            axios.post(
                `${basePath}/consultaCatalogoTipoDocumentacion`, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            })
                .then(resp => {

                    console.log('Arreglo que contiene todas las clasificaciones:', resp)

                    if (resp.data.entity.code === 200) {
                        resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                            if(item.idTipoDocumentacion < 8){
                            this.state.optionTodosLosDocumentos.push({
                                value: item.idTipoDocumentacion,
                                label: item.nombreDocumentacion,
                            });}
                        });
                        console.log('arreglo que contiene todas las opciones: ', resp.data.entity.listaTipoDocumentacion)
                        console.log('arreglo despues de filtar todas las opciones: ',  this.state.optionTodosLosDocumentos)
                        console.log('tamaño del arreglo que contiene todas las opciones: ', this.state.optionTodosLosDocumentos.length)
                       
                        this.getCatalogoDocumentos();


                    }
                    else if (resp.data.entity.code == 403) {
                        localStorage.clear();
                        window.location = "/login"
                    } 
                    else {
                        console.log("Error.componentDidMount: ", resp.data.message)
                        message.error("Ocurrió un error al consultar la documentación electoral. Inténtalo más tarde",tiempoMensaje);
                        //alert(resp.data.causa)
                    }
                }).catch(err => {

                    console.log("Error.componentDidMount: ", err)
                    if (err.response != null && err.response.status == 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                    message.error("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.",tiempoMensaje);
                    //alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.")
                })
        
    }

   

    getCatalogoDocumentos = () => {
        this.state.estado =localStorage.getItem('ID_ESTADO_SELECCIONADO');
        if(this.state.estado == 'null' || this.state.estado == 'undifine'){
            this.state.estado=0;
            //this.state.estado=parseInt(this.state.estado); 
            //console.log("SELECCIONADO", this.state.estado);
       }

        let data = JSON.stringify({
            idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: this.state.estado,
        })
        console.log('consultando catalogo documentos ', data)

        axios.post(
            `${basePath}/consultaCatalogoTipoDocumentacion`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {

                console.log('respuesta ', resp)
                this.complementaCombo();
                if (resp.data.entity.code === 200) {
                    resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                        if(item.idTipoDocumentacion < 8){
                        this.state.options.push({
                            value: item.idTipoDocumentacion,
                            label: item.nombreDocumentacion,
                        });}
                    });
                    console.log('arreglo 1 que contiene todas las opciones: ', resp.data.entity.listaTipoDocumentacion)
                        console.log('arreglo 1 despues de filtrar todas las opciones: ',  this.state.optionTodosLosDocumentos)
                        console.log('tamaño del arreglo 1 que contiene todas las opciones: ', this.state.optionTodosLosDocumentos.length)

                   


                }
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                } 
                else {
                    console.log("Error.componentDidMount: ", resp.data.message)
                    message.error("Ocurrió un error al obtener el reporte, favor de intentar más tarde.",tiempoMensaje);
                    //alert(resp.data.causa)
                }
            }).catch(err => {

                console.log("Error.componentDidMount: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                message.error("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.",tiempoMensaje);
                //alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.")
            })



    }

    complementaCombo = () => {

        //console.log('opcion todos los documentos: ', this.state.optionTodosLosDocumentos)
        if (this.state.optionTodosLosDocumentos.length > 0) {
            console.log('agregando opción de todas')
            const l =this.state.optionTodosLosDocumentos.length;
            this.state.options.push({
                value: 0,
                label: "TODAS",
            })
            console.log('opción de todas')
        } else {
            console.log('no se agregó otra opción porque: ', this.state.optionTodosLosDocumentos.length)
            console.log('y además ', this.state.optionTodosLosDocumentos[0])
        }
    }

    initReporteMateriales = () => {

        this.state.estado =localStorage.getItem('ID_ESTADO_SELECCIONADO');
        if(this.state.estado == 'null' || this.state.estado == 'undifine'){
            this.state.estado=0;
            //this.state.estado=parseInt(this.state.estado); 
            //console.log("SELECCIONADO", this.state.estado);
       }

            let data = JSON.stringify({
                idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
                idEstado: this.state.estado,
            })
            console.log('consultando catalogo materiales ', data)

            axios.post(
                `${basePath}/consultaTipoDocumentacionMateriales`, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            })
                .then(resp => {

                    console.log('respuesta ', resp)

                    if (resp.data.entity.code === 200) {
                        resp.data.entity.listaTipoDocumentacion.forEach((item) => {
                            this.state.optionsMateriales.push({
                                value: item.idTipoDocumentacion,
                                label: item.nombreDocumentacion,
                            });
                        });

                        this.getMateriales();

                    }
                    else if (resp.data.entity.code == 403) {
                        localStorage.clear();
                        window.location = "/login"
                    } 
                    else {
                        console.log("Error.componentDidMount: ", resp.data.message)
                        message.error("Ocurrió un error al consultar los materiales electorales. Inténtalo más tarde",tiempoMensaje);
                       // alert(resp.data.causa)
                    }
                }).catch(err => {

                    console.log("Error.componentDidMount: ", err)
                    if (err.response != null && err.response.status == 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                    message.error("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.",tiempoMensaje);
                    //alert("Ocurrió un error al obtener la clasificación de elección, favor de intentar más tarde.")
                })
        
    }


    onChangeFiltro = (value, seleccion) => {

        this.getDocumentos(value, seleccion);

    }

    getDocumentos = (valor, seleccion) => {

        this.state.estado =localStorage.getItem('ID_ESTADO_SELECCIONADO');
        if(this.state.estado == 'null' || this.state.estado == 'undifine'){
            this.state.estado=0;
           // this.state.estado=parseInt(this.state.estado); 
            //console.log("SELECCIONADO", this.state.estado);
       }

            this.setState({
                isLoadingDocumentos: true,
                selectedOption: valor,
                selectedOptionObject: seleccion
            });

            let data = JSON.stringify({
                idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                idEstado: this.state.estado,
                idTipoClasificacion: parseInt(valor)
            })
            console.log('consultando documentos: ', data)

            axios.post(
                `${basePath}/consultaReporteRevision`, data, {
                headers: {
                    'Authorization': localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                }
            })
                .then(resp => {
                    if (resp.data.entity.code === 200) {
                        if(resp.data.entity.articulos === null){
                            this.setState({
                                nombreOple: "",                        
                                documentos: [],
                                isLoadingDocumentos: false,
                                porcentajeA: "",
                                porcentajeU: ""
                            });
                        }
                        else{
                        resp.data.entity.articulos.forEach((item) => {
                            if(item.articulo==='PORCENTAJE DE AVANCE'){
                            if(item.totalNoCumplidos !== null)
                            {
                                item.totalNoCumplidos= item.totalNoCumplidos.toFixed(2);
                            }
                            if(item.puntosACumplirA !== null)
                            {
                                item.puntosACumplirA= item.puntosACumplirA.toFixed(2);
                            }
                            if(item.puntosACumplirU !== null)
                            {
                                item.puntosACumplirU= item.puntosACumplirU.toFixed(2);
                            }
                            if(item.puntosNoCumplidosA !== null)
                            {
                                item.puntosNoCumplidosA= item.puntosNoCumplidosA.toFixed(2);
                            }
                            if(item.puntosNoCumplidosU !== null)
                            {
                                item.puntosNoCumplidosU= item.puntosNoCumplidosU.toFixed(2);
                            }
                        }else{
                            if(item.totalNoCumplidos !== null)
                            {
                                item.totalNoCumplidos= item.totalNoCumplidos.toFixed();
                            }
                            if(item.puntosACumplirA !== null)
                            {
                                item.puntosACumplirA= item.puntosACumplirA.toFixed();
                            }
                            if(item.puntosACumplirU !== null)
                            {
                                item.puntosACumplirU= item.puntosACumplirU.toFixed();
                            }
                            if(item.puntosNoCumplidosA !== null)
                            {
                                item.puntosNoCumplidosA= item.puntosNoCumplidosA.toFixed();
                            }
                            if(item.puntosNoCumplidosU !== null)
                            {
                                item.puntosNoCumplidosU= item.puntosNoCumplidosU.toFixed();
                            }
                        }

                        });

                        this.setState({
                            nombreOple: resp.data.entity.nombreOple,                        
                            documentos: resp.data.entity.articulos,
                            isLoadingDocumentos: false,
                            porcentajeA: resp.data.entity.porcentajeA,
                            porcentajeU: resp.data.entity.porcentajeU
                        });
                        console.log("DOCUMENTOS", this.state.documentos[0].totalNoCumplidos)
                    }     
                    } 
                    else if (resp.data.entity.code == 403) {
                        localStorage.clear();
                        window.location = "/login"
                    } 
                    else {
                        console.log("Error.componentDidMount: ", resp.data.message)
                        //message.error(resp.data.causa,tiempoMensaje);
                        //alert(resp.data.causa)

                        this.setState({
                            nombreOple: [],
                            documentos: [],
                            isLoadingDocumentos: true,

                        });
                    }
                }).catch(err => {
                    console.log("Error.componentDidMount: ", err)
                    if (err.response != null && err.response.status == 403) {
                        localStorage.clear();
                        window.location = "/login"
                        return;
                    }
                    message.error("Ocurrió un error al obtener la información del reporte de revisión OPL, favor de intentar más tarde.",tiempoMensaje);
                    //alert("Ocurrió un error al obtener la información del reporte de revisión OPL, favor de intentar más tarde.")

                    this.setState({
                        nombreOple: [],
                        documentos: [],
                        isLoadingDocumentos: true,
                    });

                })

        

        this.setState({
            isLoadingDocumentos: false
        });

    }



    getMateriales = () => {
        this.state.estado =localStorage.getItem('ID_ESTADO_SELECCIONADO');
        if(this.state.estado == 'null' || this.state.estado == 'undifine'){
            this.state.estado=0;
          //  this.state.estado=parseInt(this.state.estado); 
            //console.log("SELECCIONADO", this.state.estado);
       }

            if (this.state.optionsMateriales.length > 0) {

                let idTipoMateriales = this.state.optionsMateriales[0] ||

                    this.setState({
                        isLoadingMateriales: true
                    });

                let data = JSON.stringify({
                    idProcesoElectoral: localStorage.getItem('ID_PROCESO_ELECTORAL'),
                    idEstado: this.state.estado,
                    idTipoClasificacion: this.state.optionsMateriales[0].value
                })

                console.log('consultando materiales: ', data)
                axios.post(
                    `${basePath}/consultaReporteRevision`, data, {
                    headers: {
                        'Authorization': localStorage.getItem('accessToken'),
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                })
                    .then(resp => {
                        if (resp.data.entity.code === 200) {
                           if(resp.data.entity.articulos === null){
                            this.setState({
                                nombreOple: "",                        
                                materiales: [],
                                isLoadingMateriales: false,
                                porcentajeA: "",
                                porcentajeU: ""
                            });
                           }
                           else{
                            resp.data.entity.articulos.forEach((item) => {
                                if(item.articulo==='PORCENTAJE DE AVANCE'){
                                if(item.totalNoCumplidos !== null)
                                {
                                    item.totalNoCumplidos= item.totalNoCumplidos.toFixed(2);
                                }
                                if(item.puntosACumplirA !== null)
                                {
                                    item.puntosACumplirA= item.puntosACumplirA.toFixed(2);
                                }
                                if(item.puntosACumplirU !== null)
                                {
                                    item.puntosACumplirU= item.puntosACumplirU.toFixed(2);
                                }
                                if(item.puntosNoCumplidosA !== null)
                                {
                                    item.puntosNoCumplidosA= item.puntosNoCumplidosA.toFixed(2);
                                }
                                if(item.puntosNoCumplidosU !== null)
                                {
                                    item.puntosNoCumplidosU= item.puntosNoCumplidosU.toFixed(2);
                                }
                            }else{
                                if(item.totalNoCumplidos !== null)
                                {
                                    item.totalNoCumplidos= item.totalNoCumplidos.toFixed();
                                }
                                if(item.puntosACumplirA !== null)
                                {
                                    item.puntosACumplirA= item.puntosACumplirA.toFixed();
                                }
                                if(item.puntosACumplirU !== null)
                                {
                                    item.puntosACumplirU= item.puntosACumplirU.toFixed();
                                }
                                if(item.puntosNoCumplidosA !== null)
                                {
                                    item.puntosNoCumplidosA= item.puntosNoCumplidosA.toFixed();
                                }
                                if(item.puntosNoCumplidosU !== null)
                                {
                                    item.puntosNoCumplidosU= item.puntosNoCumplidosU.toFixed();
                                }
                            }
    
    
                            });
    

                                
                            this.setState({
                                nombreOple: resp.data.entity.nombreOple,
                                materiales: resp.data.entity.articulos,
                                isLoadingMateriales: false,
                                porcentajeA: resp.data.entity.porcentajeA,
                                porcentajeU: resp.data.entity.porcentajeU
                            });
                            console.log("MATERIALES",this.state.materiales[0].totalNoCumplidos)
                         }
                        } 
                        else if (resp.data.entity.code == 403) {
                            localStorage.clear();
                            window.location = "/login"
                        } 
                        else {
                            console.log("Error.componentDidMount: ", resp.data.message)
                            message.error("Ocurrió un error al obtener el reporte, favor de intentar más tarde.",tiempoMensaje);
                           // alert(resp.data.causa)

                            this.setState({
                                nombreOple: [],
                                materiales: [],
                                isLoadingMateriales: true,

                            });
                        }
                    }).catch(err => {
                        console.log("Error.componentDidMount: ", err)
                        if (err.response != null && err.response.status == 403) {
                            localStorage.clear();
                            window.location = "/login"
                            return;
                        }
                        message.error("Ocurrió un error al obtener el reporte de revisión OPL, favor de intentar más tarde.",tiempoMensaje);
                        //alert("Ocurrió un error al obtener el reporte de revisión OPL, favor de intentar más tarde.")

                        this.setState({
                            nombreOple: [],
                            materiales: [],
                            isLoadingMateriales: true,
                        });

                    })

            }
        

        this.setState({
            isLoadingMateriales: false
        });

    }

     

    manejaComboHidden = () => {

        if (this.state.activeTab === "1") {
            this.setState({
                comboHidden: false
            });
        } else {
            this.setState({
                comboHidden: true
            });
        }

    };

    
    execChildActions = () => {
        if (this.state.activeTab === "1") {
            this.getMateriales();
        }

    };

    changeTab = activeKey => {

        this.setState({
            activeTab: activeKey
        });

        this.manejaComboHidden()
        this.execChildActions();
    };


    downloadExcel = () => {

        this.state.estado =localStorage.getItem('ID_ESTADO_SELECCIONADO');
        if(this.state.estado == 'null' || this.state.estado == 'undifine'){
            this.state.estado='0'
            this.state.estado=parseInt(this.state.estado); 
            console.log("SELECCIONADO", this.state.estado);
       }
        this.setState({
            isDescargando: true
        });

        let data = JSON.stringify({
            idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
            idEstado: this.state.estado,
            idClasificacionEleccion: this.state.selectedOptionObject[0].value,
            nombreEstado: localStorage.getItem('ESTADO'),
            clasificacionEleccion: this.state.selectedOptionObject[0].label,
            documentos: this.state.documentos,
            materiales: this.state.materiales,
        })

        console.log('Descargando reporte con los siguientes parametros: ', data)

        axios.post(
            `${basePath}/descargaReporteRevision`, data, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                Accept: "application/json",
            }
        })
            .then(resp => {
                if (resp.data.entity.code === 200) {
                    this.setState({
                        isDescargando: false,
                        disabledFiltro: false,

                    });

                    var sampleArr = base64ToArrayBuffer(resp.data.entity.reporteRevsion);
                    saveByteArray("reporteRevision.xlsx", sampleArr);

                } 
                else if (resp.data.entity.code == 403) {
                    localStorage.clear();
                    window.location = "/login"
                } 
                else {
                    console.log("Error.downloadExcel: ", resp.data.entity.message)
                   // message.error(resp.data.entity.causa,tiempoMensaje);
                    //alert(resp.data.entity.causa)
                }
            }).catch(err => {
                console.log("Error.downloadExcel: ", err)
                if (err.response != null && err.response.status == 403) {
                    localStorage.clear();
                    window.location = "/login"
                    return;
                }
                this.setState({
                    isDescargando: false,
                });
                message.error("Ocurrió un error al descargar el reporte, favor de intentar más tarde.",tiempoMensaje);
               // alert("Ocurrió un error al descargar el reporte, favor de intentar más tarde.")
            })


        function base64ToArrayBuffer(base64) {
            var binaryString = window.atob(base64);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        }

        function saveByteArray(reportName, byte) {
            var blob = new Blob([byte], { type: "application/xlsx" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = reportName;
            link.download = fileName;
            link.click();
        }

    }


    render() {


        if (this.state.redirect) {
            return <Redirect push to="/reportes" />;
        }

        return (
            <div>

                <h1 className="titulo-reportes">Revisión de la documentación y materiales electorales del OPL</h1>
                <label className="texto-12-reportes">Los datos con ( <label className="rojo-reportes">*</label> ) son requeridos.</label>
                <br />
                <br />

                {this.state.comboHidden &&
                    <label className="texto-16-reportes"><label className="rojo-reportes">*</label> Tipo de clasificación de elección</label>

                }
                {this.state.comboHidden &&
                    <br />
                }
                {this.state.comboHidden &&
                    <Cascader style={{ width: 390 }} options={this.state.options} onChange={this.onChangeFiltro} value={this.state.selectedOption} placeholder="Seleccione una opción" />
                }

                <Tabs defaultActiveKey="1" onChange={this.changeTab}>

                    <TabPane tab="Documentos" key="1" >
                        <div className="subreporte">
                            
                            <ReporteRevisionDocumentos documentos={this.state.documentos} isLoading={this.state.isLoadingDocumentos} porcentajeA={this.state.porcentajeA} porcentajeU={this.state.porcentajeU} estado={this.state.estado} />
                            
                            </div>

                    </TabPane>

                    <TabPane tab="Materiales" key="2"  >
                        <div className="subreporte">
                            <ReporteRevisionMateriales materiales={this.state.materiales} isLoading={this.state.isLoadingMateriales} porcentajeA={this.state.porcentajeA} porcentajeU={this.state.porcentajeU} estado={this.state.estado}/>
                        </div>
                        <div className="subreporte">
                        {/*this.state.porcentajeA &&
                            <Row>
                                <Col span={9}>Porcentaje de avance</Col>
                                <Col span={6}>{this.state.porcentajeA}</Col>
                                <Col span={6}>{this.state.porcentajeU}</Col>
                        </Row>*/}
                            
                        </div>
                    </TabPane>



                </Tabs>

                <div className="div_boton_descarga">
                    <Button disabled={!(this.state.selectedOptionObject.length > 0)} id="regresar"
                        onClick={this.downloadExcel}
                        className="descarga"

                    >
                        <VerticalAlignBottomOutlined /> Descargar
          </Button>
                </div>

            </div>
        );
    }
}

export default ReporteRevision;