import React from "react";
import images from "../../config/carrusel";
import { Carousel } from "antd";
import './Carrusel.scss';

export default function Carrusel() {
  return (
    <Carousel 
      className="carrusel"
      showThumbs={false}
      autoplay={true}
      infiniteLoop={true}
      showStatus={false}
      interval={10000}>
      {images.map(img => (
        <img key={img.id} height={500} src={img.url} alt=""/>
      ))}
    </Carousel>
  );
}

