import React from "react";
import { Layout, Button } from "antd"; //Se importan los componentes de ant desig
import "./Header.scss";
import LogoUser from "../../assets/img/user.svg";
//cerrar sesión
import { logout } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function Header(props) {
  const {user,setUser} = useAuth();
  const { Logo, history} = props; //Se recibe el logo por props
  const { Header } = Layout; //Se importa el header del componente del Layout

  const logoutUser = () => {
    logout();
    setUser(() => {
      return {
        user: null,
        isLoading: true,
        infoMenu: null,
        idSistema: null,
        idMunicipio: null,
        ambito: null,
        versionUsuario: null
      }
    });
    history.push("/");
  };

  const returnHome = () => {
    window.location.href = "/home";
  };

  return (
    <Header>
      <div className="menu-top">
        {/*Se crea la estructura del header  */}
        <div className="menu-top__logo"  onClick={returnHome}>
          <Logo />
        </div>
        <div className="menu-top__ayuda">
          <p>
            {user.user &&
              <>
                <img src={LogoUser} className="user" alt="user"/>
                {user.user.sub} |
                <Button type="link" onClick={logoutUser}>
                  <CloseCircleOutlined /> Cerrar sesión
                </Button>
              </>
            }
          </p>
        </div>
      </div>
    </Header>
  );
}
