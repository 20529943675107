import React, { useState, useEffect, useCallback } from "react";
import { Button, Divider, Upload, Modal, Spin, message } from 'antd';
import "./ConsultaCatalogos.scss";
//import TablaCorreosElectronicos from "./TablaCorrElec";
import Tabla from "../ListaDocs/ListaDocs";
import { FormOutlined, PlusOutlined, DeleteOutlined, UploadOutlined, EyeOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { basePath } from "../../api/config";
import axios from "axios";
import Mensajes from "../../components/Mensajes/Mensajes";
import { Redirect } from 'react-router';
import Paginacion from "../ListaDocs/Paginacion";
import { Tooltip } from '@material-ui/core';
import { UNIDAD_DEOE, UNIDAD_JL, UNIDAD_UTVOPL, UNIDAD_OPL } from "../../utils/constanst";
import useAuth from "../../hooks/useAuth";

import set from "lodash/fp/set";
import { Field } from "redux-form";
import Table from "react-table-v6";
import * as BS from "react-bootstrap";

import FormProvider from "../../utils//FormProvider";
import HighlightCell from "../../utils/HighlightCell";
import GridFilters from "../../utils/GridFilters";

import { Provider } from "react-redux";
import store from "../../utils/store";

const { confirm } = Modal;

var disableConsultaOC = (localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.CONSULTA.OC' || localStorage.getItem("ROL_USUARIO") == 'DOCSYMAT.REVISOR.OC') ? true : false;
var tiempoMensaje = 5;

function TablaCorreos(props) {
  const [editableData, setEditableData] = useState({
    data: props.initialData,
    editing: null
  });

  var enableCaptura = props.enableCaptura;
  var enableModificar = props.enableModificar;
  
  /****************EDIT ACTIONS******************* */
  const editModes = {
    view: props => (
      <div style={{ textAlign: "center" }}>
        <Tooltip title="Modificar">
          <Button
            id="ilist"
            type="link"
            shape="circle"
            disabled={!enableCaptura || disableConsultaOC}
            onClick={props.onEdit}
          >
            <FormOutlined />
          </Button>
        </Tooltip>
        <Divider type="vertical" />
          <Tooltip title="Eliminar">
            <Button
              id="ilist"
              type="link"
              shape="circle"
              disabled={disableConsultaOC}
              onClick={props.onDelete}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
      </div>
      /*<Button bsSize="xs" bsStyle="link" onClick={props.onEdit}>
        Edit
      </Button>*/
    ),
    edit: props => (
      <React.Fragment>
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Aceptar">
            <Button
              id="aceptar"
              type="link"
              htmlType="submit"
              disabled={!enableCaptura || !enableModificar}
              shape="circle"
            >
              <CheckOutlined />
            </Button>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Cancelar">
              <Button
                id="cancelar"
                type="link"
                shape="circle"
                disabled={!enableCaptura}
                onClick={props.onCancel}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
        </div>
        {/*<ButtonBS type="submit" bsStyle="link" bsSize="xs">
          Save
        </ButtonBS>
  
        <ButtonBS bsStyle="link" bsSize="xs" onClick={props.onCancel}>
          Cancel
    </ButtonBS>*/}
      </React.Fragment >
    )
  };

  function ActionsCell(props) {
    const {
      mode,
      actions: { onEdit, onCancel, onDelete }
    } = props.columnProps.rest;
    const Buttons = editModes[mode];
    return <Buttons onEdit={() => onEdit(props.index)} onCancel={onCancel} onDelete={onDelete} />;
  }
  /****************END EDIT ACTIONS******************* */

  const editableComponent = ({ input, editing, value, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component {...input} {...rest} children={children} />;
  };

  const editableColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = editableData.editing === props.original;
      const fieldProps = {
        component: editableComponent,
        editing,
        props
      };

      return <Field name={props.column.id} {...fieldProps} />;
    }
  };

  const getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: editableData.editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => {
          setEditableData({ data: editableData.data, editing: rowProps.original })
        },
        onCancel: () => {
          if (editableData.editing.nuevoDato === undefined)
            setEditableData({ data: editableData.data, editing: null });
          else
            setEditableData({ data: editableData.data.slice(1), editing: null });
        },
        onDelete: () => {
          // console.log("rowProps", rowProps);
          props.onDelete(rowProps);
        }
      }
    }) ||
    {};

  //Se definen las columnas de la tabla de Correos electrónicos
  const columns = [
    {
      Header: "Entidad",
      accessor: "estado",
      style: {
        textAlign: "left"
      },
      width: 200,
      maxWidth: 300,
      minWidth: 200,
      ...editableColumnProps
    },
    {
      Header: "Área de adscripción",
      accessor: "unidad",
      width: 200,
      maxWidth: 250,
      minWidth: 200,
      ...editableColumnProps,
    },
    {
      Header: "Cargo",
      accessor: "cargo",
      width: 200,
      maxWidth: 300,
      minWidth: 200,
      ...editableColumnProps
    },
    {
      Header: "Nombre",
      accessor: "nombre",
      style: {
        textAlign: "left"
      },
      width: 150,
      maxWidth: 200,
      minWidth: 150,
      ...editableColumnProps
    },
    {
      Header: "Apellido Paterno",
      accessor: "apellidoPaterno",
      style: {
        textAlign: "left"
      },
      width: 150,
      maxWidth: 200,
      minWidth: 150,
      ...editableColumnProps
    },
    {
      Header: "Apellido Materno",
      accessor: "apellidoMaterno",
      style: {
        textAlign: "left"
      },
      width: 150,
      maxWidth: 200,
      minWidth: 150,
      ...editableColumnProps
    },
    {
      Header: "Correo electrónico",
      accessor: "correoElectronico",
      style: {
        textAlign: "left"
      },
      width: 235,
      maxWidth: 300,
      minWidth: 235,
      ...editableColumnProps
    },
    {
      Header: "Acciones",
      style: {
        color: "blue"
      },
      sortable: false,
      filterable: false,
      getProps: getActionProps,
      Cell: ActionsCell
    }
  ];

  const handleSubmit = useCallback(values => {
    console.log("Entro", values);
    props.handleSubmit(values);
    /*const index = editableData.data.indexOf(editableData.editing);
    setEditableData({
      data: set(`[${index}]`, editableData.editing, editableData.data)
    })*/
  })

  const handleAdd = useCallback(() => {
    let nuevoCorreo = {
      idCorreo: null,
      estado: "",
      unidad: "",
      cargo: "",
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      correoElectronico: "",
      nuevoDato: true
    };
    setEditableData({
      data: [nuevoCorreo, ...props.initialData],
      editing: nuevoCorreo
    });
  })

  return (
    <React.Fragment>
      <BS.Panel bsStyle="primary">
        <BS.Panel.Heading>
          <BS.Clearfix>
            <div className="div_enca_titulos">
              {/*<div className="titulo">Correos electrónicos</div> */}
              <h3>Correos electrónicos</h3>
              <Button disabled={disableConsultaOC} className="agregar"
                onClick={handleAdd.bind(this)}
              > <PlusOutlined /> Agregar
              </Button>
            </div>
            {/*<BS.Button className="pull-right">Add New</BS.Button>*/}
          </BS.Clearfix>
        </BS.Panel.Heading>

        <FormProvider
          form="inline"
          onSubmit={handleSubmit.bind(this)}
          //onSubmitSuccess={() => setEditableData({ editing: null, data: editableData.data })}
          initialValues={editableData.editing}
          enableReinitialize
        >
          {formProps => {
            return (
              <form onSubmit={formProps.handleSubmit}>
                <Table
                  columns={columns}
                  data={editableData.data}
                  defaultPageSize={5}
                  PaginationComponent={Paginacion}
                  noDataText={"Sin datos que mostrar"}
                />
              </form>
            );
          }}
        </FormProvider>
      </BS.Panel>
    </React.Fragment>
  );
}

export default function ConsultaCatalogos(props) {

  const urlCargaDisenioArticulo = `${basePath}/cargaDisenioUnico`;
  const urlConsultaConfiguracionCatalogos = `${basePath}/consultaConfiguracionCatalogos`;
  const urlEliminaArticulo = `${basePath}/eliminaArticulo`;
  const urlEliminaCorreo = `${basePath}/eliminaCorreo`;
  const urlActualizaCorreo = `${basePath}/actualizaCorreo`;

  const { user } = useAuth();

  const [consulta, setConsulta] = useState({
    posts: [],
    cargada: false
  });

  const [visibleAlert, setVisibleAlert] = useState({
    activado: false,
    tipoMensaje: "",
    mensaje: ""
  });

  const [redirect, setRedirect] = useState({
    redireccion: false,
    idArticulo: -1
  });

  const [redirectNuevo, setRedirectNuevo] = useState({
    redireccion: false
  });

  const [redirectModificar, setRedirectModificar] = useState({
    redireccion: false
  });

  const [cargaArticulo, setCargaArticulo] = useState({
    idArticulo: -1
  });

  const [cargaArchivo, setCargaArchivo] = useState({
    archivo: null,
    consultar: false
  });

  const handleClickAgregarArticulo = useCallback(() => {
    setRedirectNuevo({
      redireccion: true
    })
  });

  const handleClickModificar = useCallback(() => {
    setRedirectModificar({
      redireccion: true
    })
  });

  const handleClickCargar = useCallback((id, conAlerta) => {
    setCargaArticulo({
      idArticulo: id
    });
  });

  const handleBeforeUpload = useCallback((file) => {
    const isPDF = file.type === 'application/pdf';
    const isSomething = file.size > 0;
    const isSize = (file.size / 1024 / 1024) < 50;

    if (!isPDF) {
      /*
      setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El formato del archivo no corresponde a un PDF. Favor de verificarlo."
      });*/
      message.error("El formato del archivo no corresponde a un PDF. Favor de verificarlo.", tiempoMensaje);
      return false;
    }
    if (!isSomething) {
      /*
      setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El archivo se encuentra vacío. Favor de verificarlo."
      });*/
      message.error("El archivo se encuentra vacío. Favor de verificarlo.", tiempoMensaje);
      return false;
    }
    if (!isSize) {
      /*
      setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El archivo pesa más de 250 MB. Favor de verificarlo."
      });*/
      message.error("El archivo pesa más de 50 MB. Favor de verificarlo.", tiempoMensaje);
      return false;
    }
    else {
      setCargaArchivo({
        archivo: file,
        consultar: true
      });
      return false;
    }
  });

  const handleClickConsultar = useCallback((id, conAlerta) => {
    console.log("Handle consultar");
    setRedirect({
      redireccion: true,
      idArticulo: id
    })
  });

  function handleClickEliminar(id, conAlerta) {
    console.log("Handle elimnar");
    showConfirm(id);
  }

  function handleClickEliminarCorreo(props) {
    console.log("Handle elimnar correo", props);
    showConfirmEliminarCorreo(props.original.idCorreo);
  }

  const handleClose = () => {
    setVisibleAlert({
      activado: false,
      tipoMensaje: "",
      mensaje: ""
    });
  }

  const eventHandlersArticulos = [
    { "cargar": handleClickCargar },
    { "eliminar": handleClickEliminar },
  ];

  const propsUpload = {
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      showRemoveIcon: false,
    },
    beforeUpload: handleBeforeUpload.bind(),
    fileList: []
  };

  //Se definen las columnas de la tabla de Especificaciones técnicas y de diseño
  const columnsArticulos = [
    {
      Header: "Carga de diseño único",
      accessor: "disenioCargado",
      sortable: false,
      filterable: false,
      style: {
        textAlign: "center"
      },
      width: 175,
      maxWidth: 175,
      minWidth: 175,
      Cell: v => {
        if (v.value) return <span><CheckOutlined /></span>;
        else return <span><CloseOutlined /></span>;
      }
    },

    {
      Header: "Tipo clasificación de elección",
      accessor: "tipoDocumentacion",
      width: 450,
      maxWidth: 550,
      minWidth: 450,
      ...GridFilters
    },
    {
      Header: "Documento/Material",
      accessor: "nombre",

      style: {
        textAlign: "left"
      },
      ...GridFilters
    },
    {
      Header: "Acciones",
      width: 175,
      maxWidth: 175,
      minWidth: 175,
      Cell: props => {
        /*console.log("Props:");
        console.log(props);*/
        return (
          <div className="acciones">
            <Tooltip title="Consultar">
              <Button
                id="ilist"
                type="link"
                shape="circle"
                // disabled = {disableConsultaOC}
                onClick={handleClickConsultar.bind(this, props.original.idArticulo, false)}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
            <Divider id="dcolor" type="vertical" />
            <Upload {...propsUpload}>
              <Tooltip title="Subir archivo">
                <Button
                  id="ilist"
                  type="primary"
                  shape="circle"
                  disabled = {disableConsultaOC}
                  onClick={handleClickCargar.bind(this, props.original.idArticulo, false)}
                >
                  <UploadOutlined />
                </Button>
              </Tooltip>
            </Upload>
            <Divider id="dcolor" type="vertical" />
              <Tooltip title="Eliminar">
                <Button
                  id="ilist"
                  type="primary"
                  shape="circle"
                  disabled = {disableConsultaOC}
                  onClick={handleClickEliminar.bind(this, props.original.idArticulo, false)}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
          </div>
        );
      },
      style: {
        color: "blue"
      },
      sortable: false,
      filterable: false
    }
  ];

  function doRequest() {
    let data = JSON.stringify({
      idEstado: user.infoMenu.estadoSelec !== null ? user.infoMenu.estadoSelec.idEstado : user.infoMenu.detalleSelec.idEstado,
      idProceso: user.infoMenu.detalleSelec.idProcesoElectoral,
      idDetalleProceso: user.infoMenu.detalleSelec.idDetalleProceso,
    })

    axios
      .post(
        urlConsultaConfiguracionCatalogos, data, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
          Accept: "application/json",
        }
      }
      ).then(
        response => {
          if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
            /* setVisibleAlert(
              {
                activado: true,
                tipoMensaje: "error",
                mensaje: response.data.entity.causa
              }); */
              message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje)
          }
          else if (response.data.entity.code === 200) {
            if (response.data.entity.message !== null && response.data.entity.message !== "") {
              /* setVisibleAlert(
                {
                  activado: true,
                  tipoMensaje: "warning",
                  mensaje: response.data.entity.message
                }); */
                message.warning(response.data.entity.message,tiempoMensaje);
            }
            else {
              setConsulta({
                posts: response.data.entity,
                cargada: true
              });
            }
          }
          else {
            localStorage.clear();
            // console.log("Borro el local storage");
            window.location = "/login"
          }
        }
      ).catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        /* setVisibleAlert(
          {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
          }); */
          message.error("Ha ocurrido un error al realizar la petición solicitada",tiempoMensaje);
      });
  };

  function doRequestCarga() {
    if (!consulta.posts.enableModificar)
      return;
    const currentToken = localStorage.getItem('accessToken');
    const procesoSel = localStorage.getItem('ID_PROCESO_ELECTORAL');
    const idDetalleProceso = localStorage.getItem('ID_DETALLE_PROCESO');
    const anio = localStorage.getItem('ANIO');
    const tipoProceso = localStorage.getItem("TIPO_PROCESO");
    const formData = new FormData();
    formData.append("idProceso", procesoSel);
    formData.append("idDetalleProceso", idDetalleProceso);
    formData.append("anio", anio);
    formData.append("tipoProceso", tipoProceso);
    formData.append("idArticulo", cargaArticulo.idArticulo);
    formData.append("archivo", cargaArchivo.archivo);
    axios
      .post(
        urlCargaDisenioArticulo, formData,
        {
          headers: {
            'Authorization': currentToken,
            'Content-Type': 'multipart/form-data',
            Accept: "application/json",
            //"Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          /*setVisibleAlert(
            {
              activado: true,
              tipoMensaje: "error",
              mensaje: response.data.entity.causa
            });*/
            message.error("10.- Ocurrió un error. Comunícate al CAU", tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            /*setVisibleAlert(
              {
                activado: true,
                tipoMensaje: "warning",
                mensaje: response.data.entity.message
              });*/
            message.warning(response.data.entity.message, tiempoMensaje);
          }
          else {
            /*
            setVisibleAlert(
              {
                activado: true,
                tipoMensaje: "success",
                mensaje: "El archivo fue cargado exitosamente."
              });*/
            message.success("El archivo fue cargado exitosamente.", tiempoMensaje);
          }
          setConsulta({
            cargada: false,
            posts: []
          });
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        /* setVisibleAlert(
          {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
          }); */
          message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
        setConsulta({
          cargada: false,
          posts: []
        });
      });
  };

  function doRequestElimina(idArticulo) {
    let idEstadoLet = null;
    if (localStorage.getItem('ID_ESTADO_SELECCIONADO') !== null) {
      idEstadoLet = parseInt(localStorage.getItem('ID_ESTADO_SELECCIONADO'), 10);
    }
    else {
      idEstadoLet = parseInt(localStorage.getItem('ID_ESTADO'), 10);
    }     
    console.log("idEstadoLet: ", idEstadoLet);
    let data = JSON.stringify({
      idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
      idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
      idEstado: idEstadoLet,
      idArticulo: idArticulo
    })

    axios
      .post(
        urlEliminaArticulo, data, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
          Accept: "application/json",
        }
      }
      ).then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          /* setVisibleAlert(
            {
              activado: true,
              tipoMensaje: "error",
              mensaje: response.data.entity.causa
            }); */
            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            /* setVisibleAlert({
              activado: true,
              tipoMensaje: "warning",
              mensaje: response.data.entity.message
            }); */
            message.warning(response.data.entity.message,tiempoMensaje);
          }
          else {
            /* setVisibleAlert(
              {
                activado: true,
                tipoMensaje: "success",
                mensaje: "El Documento/Material fue eliminado exitosamente."
              }); */
              message.success("El Documento/Material fue eliminado exitosamente.",tiempoMensaje);
          }
          setConsulta({
            cargada: false,
            posts: []
          });
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        /* setVisibleAlert(
          {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
          }); */
          message.error("Ha ocurrido un error al realizar la petición solicitada.",tiempoMensaje);
        setConsulta({
          cargada: false,
          posts: []
        });
      });
  }

  function doRequestEliminaCorreo(idCorreo) {
    console.log("Se elimina el idCorreo:=>", idCorreo);
    let data = JSON.stringify({
      idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
      idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
      idCorreo: idCorreo
    })

    axios
      .post(
        urlEliminaCorreo, data, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
          Accept: "application/json",
        }
      }
      ).then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          /* setVisibleAlert(
            {
              activado: true,
              tipoMensaje: "error",
              mensaje: response.data.entity.causa
            }); */
            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            /* setVisibleAlert({
              activado: true,
              tipoMensaje: "warning",
              mensaje: response.data.entity.message
            }); */
            message.warning(response.data.entity.message,tiempoMensaje);
          }
          else {
            /* setVisibleAlert(
              {
                activado: true,
                tipoMensaje: "success",
                mensaje: "El Correo fue eliminado exitosamente."
              }); */
              message.success("El correo electrónico fue eliminado exitosamente.",tiempoMensaje);
          }
          setConsulta({
            cargada: false,
            posts: []
          });
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        /* setVisibleAlert(
          {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
          }); */
          message.error("Ha ocurrido un error al realizar la petición solicitada.", tiempoMensaje);
        setConsulta({
          cargada: false,
          posts: []
        });
      });
  }

  function doRequestGuardaCorreo(correo) {
    console.log("Se guardó el correo:=>", correo);
    let data = JSON.stringify({
      idProceso: localStorage.getItem('ID_PROCESO_ELECTORAL'),
      idDetalleProceso: localStorage.getItem('ID_DETALLE_PROCESO'),
      dtoCatalogoCorreo: {
        idCorreo: correo.idCorreo,
        estado: correo.estado.toUpperCase(),
        unidad: correo.unidad.toUpperCase(),
        cargo: correo.cargo,
        nombre: correo.nombre,
        apellidoPaterno: correo.apellidoPaterno,
        apellidoMaterno: correo.apellidoMaterno,
        correoElectronico: correo.correoElectronico,
      }
    })
    console.log("data en doRequestGuardaCorreo(): ", data);
    
    axios
      .post(
        urlActualizaCorreo, data, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
          Accept: "application/json",
        }
      }
      ).then(response => {
        if (response.data.entity.code !== 200 && response.data.entity.code !== 403) {
          /* setVisibleAlert(
            {
              activado: true,
              tipoMensaje: "error",
              mensaje: response.data.entity.causa
            }); */
            message.error("10.- Ocurrió un error. Comunícate al CAU",tiempoMensaje);
        }
        else if (response.data.entity.code === 200) {
          if (response.data.entity.message !== null && response.data.entity.message !== "") {
            /* setVisibleAlert({
              activado: true,
              tipoMensaje: "warning",
              mensaje: response.data.entity.message
            }); */
            message.warning(response.data.entity.message,tiempoMensaje);
          }
          else {
            /* setVisibleAlert(
              {
                activado: true,
                tipoMensaje: "success",
                mensaje: "El Correo fue guardado exitosamente."
              }); */
              message.success("El correo electrónico fue guardado exitosamente.",tiempoMensaje);
          }
          setConsulta({
            cargada: false,
            posts: []
          });
        }
        else {
          localStorage.clear();
          // console.log("Borro el local storage");
          window.location = "/login"
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location = "/login"
          return;
        }
        /* setVisibleAlert(
          {
            activado: true,
            tipoMensaje: "error",
            mensaje: "Ha ocurrido un error al realizar la petición solicitada"
          }); */
          message.error("Ha ocurrido un error al realizar la petición solicitada",tiempoMensaje);
        setConsulta({
          cargada: false,
          posts: []
        });
      });  
  }

  const renderRedirect = () => {
    if (redirect.redireccion) {
      return <Redirect to={{
        pathname: '/configuracion/consultararticulo',
        state: { idArticulo: redirect.idArticulo }
      }
      } />
    }
  }

  const renderRedirectNuevo = () => {
    if (redirectNuevo.redireccion) {
      return <Redirect to={{
        pathname: '/configuracion/creararticulo',
        state: { enableModificar: consulta.posts.enableModificar },
      }
      } />
    }
  }

  const renderRedirectModificar = () => {
    if (redirectModificar.redireccion) {
      return <Redirect to={{
        pathname: '/configuracion/catalogos',
        state: { numMinArchivos: 1, enableModificar: consulta.posts.enableModificar },
      }
      } />
    }
  }

  function showConfirm(idArticulo) {
    confirm({
      title: '¿Está seguro de eliminar el Documento/Material seleccionado?',
      icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      okButtonProps: { disabled: !consulta.posts.enableModificar },
      onOk() {
        console.log('Aceptar');
        if (consulta.posts.enableModificar)
          doRequestElimina(idArticulo);
      },
      onCancel() {
        console.log('Cancelar');
      },
    });
  }

  function showConfirmEliminarCorreo(idCorreo) {
    confirm({
      title: '¿Está seguro de eliminar el Correo seleccionado?',
      icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      okButtonProps: { disabled: !consulta.posts.enableModificar },
      onOk() {
        console.log('Aceptar');
        if (consulta.posts.enableModificar)
          doRequestEliminaCorreo(idCorreo);
      },
      onCancel() {
        console.log('Cancelar');
      },
    });
  }

  function showConfirmGuardarCorreo(correo) {
    confirm({
      title: '¿Está seguro de guardar el Correo seleccionado?',
      icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      onOk() {
        console.log('Aceptar');
        if (consulta.posts.enableModificar)
          doRequestGuardaCorreo(correo);
      },
      onCancel() {
        console.log('Cancelar');
      },
    });
  }

  function handleSubmit(value) {
    console.log("handleSubmit: ", value);
    console.log("handleSubmit.correoElectronico: ", value.correoElectronico);
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    const caracteresValidos = /[A-Za-zñÑáéíóúÁÉÍÓÚüÜ]+$/;
    const valor = 10;   
    
    if (value.estado.length < 6) {
      console.log("Estado no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "Debe ingresar una entidad."
      }); */
      message.error("Se debe ingresar una entidad.",tiempoMensaje);
    }
    else if (value.estado.length>50) {
      console.log("Estado no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "La entidad ingresada sobrepasa el número máximo de caracteres permitido (50)."
      }); */
      message.error("La entidad ingresada sobrepasa el número máximo de caracteres permitido.",tiempoMensaje);
    }
     else if (value.unidad.length > 10) {
      console.log("Área es inválida");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El área de adscripción ingresada sobrepasa el número máximo de caracteres permitido (10)."
      }); */
      message.error("El área de adscripción ingresada sobrepasa el número máximo de caracteres permitido.",tiempoMensaje);
    }
    else if (value.unidad.length < 2) {
      console.log("Área es inválida");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "Debe ingresar un área de adscripción."
      }); */
      message.error("Se debe ingresar un área de adscripción.",tiempoMensaje);
    }
    else if (value.unidad !== UNIDAD_JL && value.unidad !== UNIDAD_DEOE && value.unidad !== UNIDAD_OPL && value.unidad !== UNIDAD_UTVOPL){
      console.log("Él área de adscripción es inválida");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El área de adscripción es inválida. Sólo se acepta: DEOE, JL, UTVOPL u OPL."
      }); */
      message.error("El área de adscripción es inválida. Sólo se acepta: DEOE, JL, UTVOPL u OPL.",tiempoMensaje);
    } 
    else if (value.cargo.length < 2) {
      console.log("Cargo no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "Debe ingresar un cargo."
      }); */
      message.error("Se debe ingresar un cargo.",tiempoMensaje);
    }
    else if (value.cargo.length > 50) {
      console.log("Cargo es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El cargo ingresado sobrepasa el número máximo de caracteres permitido (50)."
      }); */
      message.error("El cargo ingresado sobrepasa el número máximo de caracteres permitido.",tiempoMensaje);
    }
    else if (value.nombre.length < 1) {
      console.log("Nombre no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "Debe ingresar un nombre."
      }); */
      message.error("Se debe ingresar un nombre.",tiempoMensaje);
    }
    else if (value.nombre.length > 50) {
      console.log("Nombre no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El nombre ingresado sobrepasa el número máximo de caracteres permitido (50)."
      }); */
      message.error("El nombre ingresado sobrepasa el número máximo de caracteres permitido.",tiempoMensaje);
    }
    else if (value.apellidoPaterno.length < 1) {
      console.log("apellido no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "Debe ingresar un apellido paterno."
      }); */
      message.error("Se debe ingresar el apellido paterno.",tiempoMensaje);
    }
    else if (value.apellidoPaterno.length > 40) {
      console.log("apellido no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El apellido paterno ingresado sobrepasa el número máximo de caracteres permitido (40)."
      }); */
      message.error("El apellido paterno ingresado sobrepasa el número máximo de caracteres permitido.",tiempoMensaje);
    }
    else if (value.apellidoMaterno.length < 0) {
      console.log("apellido no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "Debe ingresar un apellido materno."
      }); */
      message.error("Se debe ingresar el apellido materno.",tiempoMensaje);
    } 
    else if (value.apellidoMaterno.length > 40) {
      console.log("apellido no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "El apellido materno ingresado sobrepasa el número máximo de caracteres permitido (40)."
      }); */
      message.error("El apellido materno ingresado sobrepasa el número máximo de caracteres permitido",tiempoMensaje);
    }
    else if (value.correoElectronico.length< 10) {
      console.log("El correo electrónico no es inválido");
      /* setVisibleAlert({
        activado: true,
        tipoMensaje: "error",
        mensaje: "Debe ingresar un correo electrónico."
      }); */
      message.error("Se debe ingresar el correo electrónico.",tiempoMensaje);
    }   
      else if (!expression.test(value.correoElectronico)) {
        console.log("El correo electrónico es inválido");
        /* setVisibleAlert({
          activado: true,
          tipoMensaje: "error",
          mensaje: "El correo electrónico es inválido."
        }); */
        message.error("El correo electrónico es inválido.",tiempoMensaje);
      }
      else if (value.correoElectronico.length>50) {
        console.log("El correo electrónico no es inválido");
        /* setVisibleAlert({
          activado: true,
          tipoMensaje: "error",
          mensaje: "El correo electrónico ingresado sobrepasa el número máximo de caracteres permitido (50)."
        }); */
        message.error("El correo electrónico ingresado sobrepasa el número máximo de caracteres permitido.",tiempoMensaje);
      }
      else if (!caracteresValidos.test(value.estado) || !caracteresValidos.test(value.unidad) || !caracteresValidos.test(value.cargo)
        || !caracteresValidos.test(value.nombre) || !caracteresValidos.test(value.apellidoPaterno)
        || !caracteresValidos.test(value.apellidoMaterno)) {
          message.error("No se permiten números ni caracteres especiales, solo letras.");
        }
      
    else {
      if (consulta.posts.enableModificar)
        showConfirmGuardarCorreo(value);
    }    
  }

  useEffect(() => {
    if (!consulta.cargada)
      doRequest();
  }, [consulta]);

  useEffect(() => {
    /*console.log("cargaArchivo use effect:", cargaArchivo);
    console.log("cargaArchivo use effect:", cargaArticulo);*/
    if (cargaArchivo.consultar)
      doRequestCarga();
  }, [cargaArchivo]);

  return (
    <div className="div_consultacatalogos">
      {renderRedirect()}
      {renderRedirectNuevo()}
      {renderRedirectModificar()}
      {visibleAlert.activado ?
        (
          <Mensajes tipoMensaje={visibleAlert.tipoMensaje} mensaje={visibleAlert.mensaje} handleClose={handleClose} />
        ) : null}
      <br />
     
      {!consulta.cargada ? (<Spin size="large" />) : (
        <div>
          {/* <div className="div_titulo">
            <div className="encabezado"> Catálogos </div>
          </div> */}
          <h1 id="titulo">Catálogos</h1>
          <div className="div_tablas">
            <Provider store={store}>
              <TablaCorreos
                initialData={consulta.posts.dtoCatalogoCorreos}
                enableCaptura={consulta.posts.enableCaptura}
                enableModificar={consulta.posts.enableModificar}
                onDelete={handleClickEliminarCorreo.bind(this)}
                handleSubmit={handleSubmit.bind(this)} />
            </Provider>
          </div>
          <div className="div_enca_titulos">
            {/* <div className="titulo">Lista de Documentos/Materiales</div> */}
            <h3>Lista de Documentos/Materiales</h3>
            <Button className="agregar"
              disabled={!consulta.posts.enableCaptura || disableConsultaOC}
              onClick={handleClickAgregarArticulo.bind(this)}> <PlusOutlined /> Agregar
            </Button>
          </div>
          <div className="div_tablas">
            <Tabla
              eventos={eventHandlersArticulos}
              datos={consulta.posts.dtoCatalogoArticulos}
              columnas={columnsArticulos}
              enableCaptura={consulta.posts.enableCaptura}
              enableModificar={consulta.posts.enableModificar}
              filterable={true} />
          </div>
          <div className="div_boton_modificar">
            <Button
              className="modificar_catalogos"
              disabled={!consulta.posts.enableCaptura || !consulta.posts.enableModificarCatalogos || disableConsultaOC}
              onClick={handleClickModificar.bind(this)}
            > Modificar catálogos </Button>
            {/*href="/configuracion/consultarcatalogos"> Guardar </Button>*/}
          </div>
        </div>
      )}
    </div>
  );
}