import React from "react";
import ModificarAdjudicaciones from "../../../components/ModificarAdjudicaciones/ModificarAdjudicaciones"; //Se importa el componente para los catálogos

export default function ModificarAdjudicacionesPrincipal(props) {
    return (
        <ModificarAdjudicaciones
            idArticulo={props.location.state.idArticulo}
            nombreArticulo={props.location.state.nombreArticulo}
            idTipoDocumentacion={props.location.state.idTipoDocumentacion} />
    );
}