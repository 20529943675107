import React from "react";
import '../Reportes.css';
import { List, Col, Row, Skeleton, Button, Cascader } from "antd";
import axios from "axios";
import { basePath } from "../../../api/config";
import { Redirect } from 'react-router';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const headers = [
{ tag: "Tipo de documentación", width: 3, style: "texto-centrado", field: "nombreTipoEleccion" },
{ tag: "Documento", width: 6, style: "texto-izquierda margen-izquierda-10", field: "articulo" },
{ tag: "Puntos que debe cumplir según Anexo 4.1", width: 3, style: "texto-centrado", field: "puntosACumplirA" },
{ tag: "Puntos que no cumplen según Anexo 4.1", width: 3, style: "texto-centrado", field: "puntosNoCumplidosA" },
{ tag: "Puntos que debe cumplir según especificaciones únicas", width: 3, style: "texto-centrado", field: "puntosACumplirU" },
{ tag: "Puntos que no cumplen según especificaciones únicas", width: 3, style: "texto-centrado", field: "puntosNoCumplidosU" },
{ tag: "Total de puntos con los que no cumple", width: 3, style: "texto-centrado", field: "totalNoCumplidos" }];


const headersOC = [
    { tag: "Entidad", width: 4, style: "texto-centrado", field: "nombreEstado" },
    { tag: "Tipo de documentación", width: 3, style: "texto-centrado", field: "nombreTipoEleccion" },
    { tag: "Documento", width: 6, style: "texto-izquierda margen-izquierda-10", field: "articulo" },
    { tag: "Puntos que debe cumplir según Anexo 4.1", width: 2, style: "texto-centrado", field: "puntosACumplirA" },
    { tag: "Puntos que no cumplen según Anexo 4.1", width: 2, style: "texto-centrado", field: "puntosNoCumplidosA" },
    { tag: "Puntos que debe cumplir según especificaciones únicas", width: 2, style: "texto-centrado", field: "puntosACumplirU" },
    { tag: "Puntos que no cumplen según especificaciones únicas", width: 2, style: "texto-centrado", field: "puntosNoCumplidosU" },
    { tag: "Total de puntos con los que no cumple", width: 3, style: "texto-centrado", field: "totalNoCumplidos" }];


class ReporteRevisionDocumentos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
        };



    }

    componentDidMount() {


    }
   
    generateHeaders = (structure) => {
        let headers = [];
        for (var i = 0; i < structure.length; i++) {
            headers.push(
                <Col xs={structure[i].width} className={structure[i].style} key={i}>
                    {structure[i].tag}
                </Col>
            )
        }
        return headers;
    };

    generateTable(data, structure) {
        let tableData = [];
        const mapa = new Map(Object.entries(data));
        for (var i = 0; i < structure.length; i++) {
            tableData.push(
                <Col span={structure[i].width} className={structure[i].style + ' fila'} key={i} style={{'word-break': 'break-word'}}>
                   
                    { mapa.get(structure[i].field)}
                </Col>
            )
        }
        return tableData;
    }



    render() {

        if (this.state.redirect) {
            return <Redirect push to="/reportes" />;
        }


        return (

            <div>
                <br />
                <List
                    header={
                        <Row className="header-tabla-gris">
                            {this.generateHeaders( this.props.estado!=0 ? headers : headersOC)}
                        </Row>
                    }
                    pagination={{
                        pageSize: 10,
                    }}
                    size="large"
                    loading={this.props.isLoading}
                    itemLayout="horizontal"
                    dataSource={this.props.documentos}
                    renderItem={item =>
                        <List.Item>
                            <Skeleton title={false} loading={this.props.isLoading} active>
                                <List.Item.Meta
                                    description={
                                        <Row>
                                            {this.generateTable(item, this.props.estado !=0 ? headers : headersOC)}
                                        </Row>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    }


                />

            </div>

           

        );

    }
}


export default ReporteRevisionDocumentos;